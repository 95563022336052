import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import PageDescription from '../../components/PageDescription';
import Pagination from '../../../utils/Pagination';
import Table from '../../components/Table';
import { list_all_observations, fetch_all_observation_statuses, export_observations_data } from '../../../../services/observations/Service';
import '../../css/observations.css';



const AllObservations = () => {

    const [observations, setObservations] = useState([]); 
    const [error, setError] = useState(""); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0); 
    const [statusList, setStatusList] = useState([]); 
    const [filters, setFilters] = useState({
        searchQuery: '',
        status: '',
        priority: ''
    });

    useEffect(() => {   
        setCurrentPage(1);
    }, [perPage]);

    useEffect(() => { 
        setObservations([]); 
        fetchAllObservations(); 
    }, [currentPage, perPage]); 

    useEffect(() => {   
        fetchAllStatuses(); 
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchAllObservations(filters.searchQuery, filters.status, filters.priority);
        }, 500);

        return () => clearTimeout(timer);
    }, [filters.searchQuery, filters.status, filters.priority]);

    // fetching all observations here 
    async function fetchAllObservations(searchQuery, status, priority) {
        try {     
            const response = await list_all_observations({perPage: perPage, pageNumber: currentPage, searchQuery, status, priority});
 
            if(response.data.code === 200) {
                setObservations(response.data.data?.observations || []);
                setTotalPages(response?.data?.data?.totalPages || 0);
                setTotalEntries(response?.data?.data?.totalEntries || 0);
                setError("");
            } else { 
                setError(response.data?.message || "Something went wrong");
            }   
        } catch(e) { 
            setError("Something went wrong while fetching observations");
        }
    }

    // Updated fetchAllStatuses with error handling
    async function fetchAllStatuses() {
        try {
            const response = await fetch_all_observation_statuses({role: "Moderator"});
            if (response.data.code === 200) {
                setStatusList(response.data?.data?.statuses || []);
                setError("");
            } else {
                setError(response.data?.message || "Something went wrong");
            }
        } catch (e) {
            setError("Something went wrong while fetching status list");
        }
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleClearFilters = () => {
        setFilters({
            searchQuery: '',
            status: '',
            priority: ''
        });
        fetchAllObservations();
    };

    const exportObservations = async (e) => {
        try {
            const response = await export_observations_data({
                searchQuery: filters.searchQuery,
                status: filters.status,
                priority: filters.priority
            });

            // if (!response.ok) {
            //     throw new Error('Export failed');
            // }
        
              // Get the blob directly from the response
              const url = window.URL.createObjectURL(new Blob([response.data]));
              
              var blob = new Blob([response.data], {
                type: "text/plain;charset=utf-8",
              });

              const link = document.createElement('a');
              link.href = url;
              link.download = new Date().toISOString().split('T')[0] + "_observations.csv";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            
        } catch (e) {
            console.log(e);
            setError("Something went wrong while exporting observations");
        }
    };

  return (
    <div className='container-fluid observation-dashboard-page'>
      <div className="row align-items-center pb-3 sticky-top-bar">
          <div className="col-md"> 
              <h4 className="mb-0 content-main-title">All Observations</h4>
          </div>
          <div className="col-md">
              <div className="row g-3 align-items-center">
              </div>
          </div>

          <div className="col-md text-end">
              <Link to="/add-observation" className="btn btn-primary add-new-program-btn">
                  <i className="bi bi-person-plus-fill me-2"></i> Add New Observation
              </Link>
          </div>
        </div>

        <PageDescription image={"https://storage.googleapis.com/ksabupatest/dashboardresources/icons/usermanagement.png"} text={"The User Management module allows admins to easily add or remove users of the apps and provide them with access to the application. This is essential for maintaining and controlling user access."} />
        

        {/* --------------------- Main Content -------------------- */}
        <div className='comntent-UserManagement'>
            <div className='container-fluid'>
                    
                    <div className='row mb-3'>
                        
                        <div className='col-md-4'>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="searchQuery"
                                    value={filters.searchQuery}
                                    onChange={handleFilterChange}
                                    placeholder="Search By Observation ID"
                                    className="form-control input-field-ui ps-5 w-100"
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <select
                                name="status"
                                value={filters.status}
                                onChange={handleFilterChange}
                                className='form-select input-field-ui w-100'
                            >
                                <option value="">--Select Status--</option>
                                {statusList.map((status, index) => (
                                    <option key={index} value={status.status}>{status.status}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select
                                name="priority"
                                value={filters.priority}
                                onChange={handleFilterChange}
                                className='form-select input-field-ui w-100'
                            >
                                <option value="">--Select Priority--</option>
                                <option value="blocker">Blocker</option>
                                <option value="major">Major</option>
                                <option value="minor">Minor</option>
                                <option value="good_to_have">Good-to-have</option>
                            </select>
                        </div>
                        <div className='col-md-1'>
                            <button 
                                className='btn btn-primary btn-primary clear-search-btn w-100'
                                onClick={handleClearFilters}
                            >
                                Clear
                            </button>
                        </div>

                        <div className='col-md-1'>
                            <button 
                                className='btn btn-primary btn-primary clear-search-btn w-100'
                                onClick={e => exportObservations(e)}
                            >
                                Export
                            </button>
                        </div>
                </div>
            </div>

            <Table data={observations} 
                currentPage={currentPage} 
                perPage={perPage} 
                totalPages={totalPages} 
                totalEntries={totalEntries} 
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                setError={setError}
                fetchAllObservations={fetchAllObservations}
            />
        </div>

    </div>
  )
}
    
export default AllObservations; 
