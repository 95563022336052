import React from 'react';
import './ContentRCMTeamDashboard.css';
import ContenteamDashboardTable from './ContentRCMTeamDashboard/ContenteamDashboardTable';

function ContentRCMTeamDashboard() {

  return (
    <>
        <section className='ReportsPrograms'>
         
            <ContenteamDashboardTable />

        </section>


        
    </>
  );
}

export default ContentRCMTeamDashboard;
