import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Commingsoon } from '../../utils/CommonLibrary';
import { validateFormServiceAdd } from '../../utils/validationUtils';
import { CustomLoader } from '../../utils/CommonLibrary';
import { fetchCategoryData,editService,fetchAllDataService,fetchAllDeleteDataService,fetchAllUserDataService, fetchAllUserDeleteDataServiceLength, fetchAllUserDeleteDataService, fetchUserExistService } from '../../../services/mtp/Service';

const TableAddNewServices = ({ data: initialData,key,serviceRequestData }) => {
    
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedService, setSelectedService] = useState('All');
    const [data, setData] = useState(initialData);
    const [deletedData, setDeletedData] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [serviceDeleteData, setServiceDeleteData] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
    const [showUserServiceModal, setShowUserServiceModal] = useState(false);
    const [showUserServiceMessage, setShowUserServiceMessage] = useState('');
    
    const [selectedData, setSelectedData] = useState('');
    const [loading, setLoading] = useState(false);
    //edit
    const [isEditing, setIsEditing] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [selectedServiceName, setSelectedServiceName] = useState(null);
    const [errorMessage,setErrorMessage] = useState('');

    const [formData, setFormData] = useState({
        serviceId:'',
        selectedValue: '',
        serviceName: '',
        serviceRequestType: '',
        description: ''
    })

    const [errors, setErrors] = useState({
        serviceId:'',
        selectedValue: '',
        serviceName: '',
        serviceRequestType: '',
        description: ''
    })

    const handleClose = () =>{  
         setShowEditModal(false);
        setShowDeleteModal(false);
        setErrorMessage("");
        setErrors({
            selectedValue: '',
            serviceName: '',
            serviceRequestType: '',
            description: ''
        })
    }
    const handleViewClose = () =>{  
        setShowViewModal(false);
   }
   const handleViewDeleteClose = () =>{  
    setShowUserDeleteModal(false);
}

const handleServiceDeleteClose = () =>{  
    setShowUserServiceModal(false);
}

   
    useEffect(() => {
        fetchData();
    }, [key]);

    const fetchAllData = async () => {
        fetchAllDataService()
        .then((response) => {   
            setData(response.data.data);
        });
    }
    const fetchAllDeleteData = async (serviceId) => {
         fetchAllDeleteDataService(serviceId)
         .then((response) => {   
             setDeletedData(response.data.data);
             setLoading(false);
         });
    }

     

    const fetchData = async () => {
        fetchCategoryData()
        .then((response) => {   
            setSelectedData(response.data.data);
        });
    }

    const handleEditClick = (serviceName,serviceId) => {
        setIsEditing(true);
        setSelectedServiceId(serviceId);
        setSelectedServiceName(serviceName);
        const serviceToEdit = data.find(data => data.serviceName === serviceName);
        const selectedCategory = selectedData.find(option => option.categoryName === serviceToEdit.categoryName);
        const selectedRequestType = serviceRequestData.find(option => option.serviceRequestTypeId === serviceToEdit.serviceRequestType);
        setFormData({
            selectedValue: selectedCategory ? selectedCategory.categoryId : '',
            serviceName: serviceToEdit.serviceName,
            serviceId: serviceId,
            serviceRequestType: selectedRequestType ? selectedRequestType.serviceRequestTypeId : '',
            description: serviceToEdit.description
        });
        setShowEditModal(true);
    }

   const handleUserServiceView = (serviceId,pathwayServiceId)=>{
        setLoading(true);
        fetchAllUserDataService(serviceId,pathwayServiceId)
        .then((response) => {   
            setViewData(response.data.data);
            setLoading(false);
            
        });
        setShowViewModal(true); 
    }

    const handleUserServiceDelete = (serviceId,pathwayServiceId,memberServiceRelId,pathwayMonth)=>{
        setLoading(true);
        fetchAllUserDeleteDataServiceLength(serviceId,pathwayServiceId,pathwayMonth,memberServiceRelId)
        .then((response) => {   
            setServiceDeleteData(response.data.data);
            setLoading(false); 
        });
        setShowUserDeleteModal(true); 
        
    }

    const handleUserExistService = ()=>{
        setLoading(true);
        fetchUserExistService()
        .then((response) => {   
            setShowUserServiceMessage(response.data.data.message);
            setShowUserServiceModal(true); 
            setLoading(false);
        });
        
        
    }

    const handleDeleteConfirmation = ()=>{
        setLoading(true);
        fetchAllUserDeleteDataService(serviceDeleteData)
        .then((response) => {   
            setServiceDeleteData(response.data.data);
            setLoading(false);
            fetchAllData();
            setShowUserDeleteModal(false);
            //setShowViewModal(false);
            setShowDeleteModal(false);
        });
        
        
    }

    

    const handleDeleteClick = (serviceName,serviceId) => {
        setIsEditing(true);
        setLoading(true);
        setSelectedServiceId(serviceId);
        setSelectedServiceName(serviceName);
        fetchAllDeleteData(serviceId);
        setShowDeleteModal(true);
    }

    const columns = [
      {
        name: "Category",
        selector: (row) => row.categoryName,
        sortable: true,
      },
      {
        name: "Services",
        selector: (row) => row.serviceName,
        sortable: true,
      },
      {
        name: "Service Description",
        selector: (row) => row.description,
        sortable: true,
      },
      {
        name: "Completed Services",
        selector: (row) => row.totalCompleted,
        sortable: true,
      },
      {
        name: "Due Services",
        selector: (row) => row.totalDue,
        sortable: true,
      },
      {
        name: "Action",
        selector: (row) => row.action,
        cell: (row) => (
          <div>
            <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id="tooltip-top"
                                              placement="top"
                                            >
                                              Edit Service
                                            </Tooltip>
                                          }
                                        >
            <button
              className="btn btn-Edit"
              onClick={() => handleEditClick(row.serviceName, row.serviceId)}
              aria-label="Edit Service"
            >
              <i className="bi bi-pencil"></i>
            </button>
            </OverlayTrigger>
            {" "}
            |{" "}
            {row.serviceBtn1 > 0 ? (
                <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    placement="top"
                  >
                    Delete Service
                  </Tooltip>
                }>
              <button
                className="btn btn-Cancel"
                onClick={() =>
                  handleDeleteClick(row.serviceName, row.serviceId)
                }
                aria-label=" Delete Service"
              >
                <i className="bi bi-trash"></i>
              </button>
              </OverlayTrigger>
            ) : (
              <>
                {row.serviceBtn2 > 0 ? (
                    <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="tooltip-top"
                        placement="top"
                      >
                        Delete Service
                      </Tooltip>
                    }>
                  <button
                    className="btn btn-Cancel"
                    onClick={() => handleUserExistService()}
                    aria-label=" Delete Service"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                  </OverlayTrigger>
                ) : (
                    <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="tooltip-top"
                    placement="top"
                  >
                    Delete Service
                  </Tooltip>
                }>
                  <button
                    className="btn btn-Cancel"
                    onClick={() =>
                      handleUserServiceDelete(
                        row.serviceId,
                        row.memberServiceRelId,
                        row.pathwayServiceId,
                        0
                      )
                    }
                    aria-label=" Delete Service"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                  </OverlayTrigger>
                )}
              </>
            )}{" "}
          </div>
        ),
      },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCategory('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery = 
            (user.fromCareNavigator && user.fromCareNavigator.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.toCareNavigator && user.toCareNavigator.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.categoryName && user.categoryName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.serviceName && user.serviceName.toLowerCase().includes(searchQuery.toLowerCase()));

        const matchesCategory = selectedCategory === 'All' || user.categoryName.includes(selectedCategory);

        return matchesSearchQuery && matchesCategory;
    });

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['selectedValue']: event.target.value}));
        
    };
   const handleChangeRequest = (event) =>{
    setFormData((prevData) => ({ ...prevData, ['serviceRequestType']: event.target.value}));
   }

   const onValueChange = (e) =>{    
    setFormData({...formData, [e.target.name]: e.target.value});
}

const editNewService = async (e) => {
    e.preventDefault();
    setErrors('')

    const validationErrors = validateFormServiceAdd(formData);
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
    }else{
        setLoading(true);
        editService(formData)
        .then((response) => {   
            if(response.data.code == 200){
                setLoading(false);
                fetchAllData();
                setShowEditModal(false);
            }else{
                setLoading(false);
                setErrorMessage(response.data.data.message);
            }
        });
    }
}

const deleteNewService = async (e) => {
    e.preventDefault();
    setErrors('');
    fetchAllData();
}
    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                            <input
                                type="text"
                                placeholder="Search By Category or Service Name"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-4 px-1'>
                        <select
                            value={selectedCategory}
                            onChange={e => setSelectedCategory(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Category--</option>
                            {selectedData.length > 0 && selectedData.map((option) => (
                                <option key={option.categoryId} value={option.categoryName}>
                                    {option.categoryName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
             <Commingsoon />
             { loading && <CustomLoader /> }

{/* <div className="modal fade" id="editServiceModal" tabIndex="-1" aria-labelledby="editServiceModalLabel" aria-hidden="true"> */}
<div className={`modal fade ${showEditModal ? 'show' : ''}`} id="editServiceModal" tabIndex="-1" aria-labelledby="editServiceModalLabel" aria-hidden={!showEditModal} style={{display: showEditModal ? 'block' : 'none'}}>
  <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Edit Service</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    {errorMessage ?(<p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>):null}
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={editNewService}> 
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor='Condition' class="form-label">Category</label>
                                    <span style={{color:'red'}}> *</span>
                                        <select class="form-select input-field-ui" aria-label="Default select example" value={formData['selectedValue']} onChange={handleChange}>
                                            <option value="">Select Category</option>
                                                {selectedData.length > 0 && selectedData.map((option) => (
                                                  
                                                        <option key={option.categoryId} value={option.categoryId} >
                                                        {option.categoryName}
                                                    </option>
                                                   
                                                ))}
                                        </select>
                                        {(errors['selectedValue'] ) && <div className="text-danger">{errors['selectedValue']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Condition' class="form-label">Service Name</label>
                                        <span style={{color:'red'}}> *</span>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Service Name"
                                        rows="3"
                                        name="serviceName"
                                        onChange={(e) => onValueChange(e)} value={formData['serviceName']}
                                        />
                                        {errors['serviceName'] && <div className="text-danger">{errors['serviceName']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Description' class="form-label">Description</label>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Description"
                                        rows="3"
                                        name="description"
                                        onChange={(e) => onValueChange(e)} value={formData['description']}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                    <label htmlFor='request' class="form-label">Service Request Type</label>
                                    <span style={{color:'red'}}> *</span>
                                        <select class="form-select input-field-ui" aria-label="Default select example" name="serviceRequestType" value={formData['serviceRequestType']} onChange={handleChangeRequest}>
                                            <option value="">Select Service Request Type</option>
                                                {serviceRequestData.length > 0 && serviceRequestData.map((option) => (
                                                       <option key={option.serviceRequestTypeId} value={option.serviceRequestTypeId} selected={formData['serviceRequestType'] === option.serviceRequestTypeName}>
                                                        {option.serviceRequestTypeName}
                                                    </option> 
                                                   
                                                ))}
                                        </select>
                                        {(errors['serviceRequestType'] ) && <div className="text-danger">{errors['serviceRequestType']}</div>}
                                    </div>
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Save Service
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>


            <div className={`modal fade ${showDeleteModal ? 'show' : ''}`} id="deleteServiceModal" tabIndex="-1" aria-labelledby="deleteServiceModalLabel" aria-hidden={!showDeleteModal} style={{display: showDeleteModal ? 'block' : 'none'}}>
  <div className="modal-dialog modal-dialog-centered modal-xl" >
  
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Delete Service - {selectedServiceName}</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                        
                    </div>
                    <div class="modal-body pt-1 mb-2">
                    <p className='mb-2'>This service is linked to the active pathways.</p>
                    <div className="table-responsive addNewServiceTable">
                    <table className="table table-bordered table-striped mb-0">
                        <thead>
                            <tr>
                                <th>Program</th>
                                <th>Condition</th>
                                <th>Pathway</th>
                                {/* <th>Service</th> */}
                                <th>Month</th>
                                <th>Due Services</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deletedData.map((service, index) => (
                            <tr key={index}>
                                    <td>{service.programName}</td>
                                    <td>{service.conditionName}</td>
                                    <td>{service.pathwayName}</td>
                                    {/* <td>{service.serviceName}</td> */}
                                    <td>{service.pathwayMonthShow}</td>
                                    <td>{service.totalDue}</td>
                                    <td>
                                        {service.viewShowBtn ? (<><button className="btn btn-Edit" onClick={() => handleUserServiceView(service.serviceId,service.pathwayServiceId)} aria-label="View Service" title="View Service">
                                        <i className="bi bi-eye"></i></button> | </>) : ("")} <button className="btn btn-Cancel" onClick={() => handleUserServiceDelete(service.serviceId,service.pathwayServiceId,service.memberServiceRelId,service.pathwayMonth)} aria-label="Delete Service" title="Delete Service">
                                        <i className="bi bi-trash"></i></button>
                                    </td>
                                </tr>
                            ))}
    
                        </tbody>
                    </table>
                </div>
                            </div>
                           
                        </div>
                  
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>


<div className={`modal fade ${showViewModal ? 'show' : ''}`} id="viewServiceModal" tabIndex="-1" aria-labelledby="viewServiceModalLabel" aria-hidden={!showViewModal} style={{display: showViewModal ? 'block' : 'none'}}>
<div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">View Service</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleViewClose}
                        ></button>
                    </div>
                    <div className="modal-body pt-1 mb-2">
                    {viewData.length > 0 ? ( <>
                    
                        <div className="table-responsive addNewServiceTable">
                        <table className="table table-bordered table-striped mb-0">
                        <thead>
                            <tr>
                                <th>Member Name</th>
                                <th>Service Name</th>
                                {/* <th>Month</th> */}
                                <th>Due Date</th>
                                <th>Booking Status</th>
                            </tr>
                        </thead>
                        <tbody>

                             {viewData.map((user, index) => (
                            <tr key={index}>
                                    <td>{user.memberName}</td>
                                    <td>{user.serviceName}</td>
                                    {/* <td>{user.pathwayMonth}</td> */}
                                    <td>{user.dueDate}</td>
                                    <td>{user.bookingStatus}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                    </div>
                    </> ) : (
                        <p className='tw-text-center'>
                            No Data available
                        </p>
                    ) }
                </div>
                           
                        </div>
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>

<div className={`modal fade ${showUserDeleteModal ? 'show' : ''}`} id="userDeleteServiceModalLabel" tabIndex="-1" aria-labelledby="userDeleteServiceModalLabel" aria-hidden={!showUserDeleteModal} style={{display: showUserDeleteModal ? 'block' : 'none'}}>
  <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                    {/* <h4 className="main-title">Delete</h4> */}
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleViewDeleteClose}
                        ></button>
                    </div>
                    <div className="modal-body pt-1 mb-2 mx-auto w-80">
                    {serviceDeleteData.message}
                    </div>
                    <div className="mx-auto w-40 mb-4">
                    <button tpe="button" className="btn btn-primary program-save-btn me-2" onClick={handleDeleteConfirmation}>
                        <i className="fas fa-trash-alt"></i>Delete</button>
                                        {/* <button tpe="button" className="btn btn-secondary clear-btn" onClick={handleViewDeleteClose}>
                                        <i className="fas fa-trash-alt" ></i>Cancel </button> */}
                                        </div>

                        </div>
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>


            <div className={`modal fade ${showUserServiceModal ? 'show' : ''}`} id="userShowServiceModalLabel" tabIndex="-1" aria-labelledby="userShowServiceModalLabel" aria-hidden={!showUserServiceModal} style={{display: showUserServiceModal ? 'block' : 'none'}}>
  <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="main-title" style={{ flexDirection: 'column-reverse' }}>Delete</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleServiceDeleteClose}
                        ></button>
                    </div>
                    <div className="modal-body pt-1 mb-2 mx-auto w-80">
                        {showUserServiceMessage}
                    </div>
                    <div className="mx-auto w-40 mb-4">
                    {/* <button tpe="button" className="btn btn-primary program-save-btn me-2" onClick={handleDeleteConfirmation}>
                        <i className="fas fa-trash-alt"></i>Delete</button>
                                        <button tpe="button" className="btn btn-secondary clear-btn" onClick={handleViewDeleteClose}>
                                        <i className="fas fa-trash-alt" ></i>Cancel </button> */}
                                        </div>

                        </div>
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>
        </>

        
    );
}

export default TableAddNewServices;
