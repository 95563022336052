import React, { useState } from 'react';
import './CorporateChallenges.css';
import { Link } from 'react-router-dom';
import CorporateChallengesTable from './component/CorporateChallengesTable';


function CorporateChallenges() {


  return (
    <>
      <section className='CorporateChallenges'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Corporate Challenges</h1>
            </div>
            <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-challenges">Create Challenge</Link></div>
          </div>

          <div className='corporate-challenges mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ta7a5/kjr08jm4xj.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo. Vivamus et interdum felis, ut vulputate tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo.</p>
              </div>
            </div>
          </div>

          <div className='corporate-challenges'>
            
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive tableCorporateChallenges">
                   <CorporateChallengesTable/>
                   </div>
                </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default CorporateChallenges;
