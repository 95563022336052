import React, { useState, useEffect } from 'react';
import { fetchReportTypes, fetchHealthLockerFiles, fetchHealthlockerFileUrl } from "../../services/rpa/Service";
import PaginationComponent from '../componenents/PaginationComponent/PaginationComponent';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './UploadMemberFile.css';
import DataTable from 'react-data-table-component';

function UploadMemberFileTable({ refreshVar }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFileType, setSelectedFileType] = useState('All');
    const [reportTypes, setReportTypes] = useState([]);
    const [filesList, setFilesList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [error, setError] = useState("");
    const [fileUrlId, setFileUrlId] = useState("");

    useEffect(() => {
        getReportTypes();
    }, [])

    useEffect(() => {
        getHealthLockerFiles();
    }, [searchQuery, selectedFileType, currentPage, rowsPerPage, refreshVar])

    const getReportTypes = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchReportTypes(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setReportTypes(response.data.data.reportTypes);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getHealthlockerFileUrl = async (fileId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthlockerFileUrl(fileId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    window.open(response.data.data.fileUrl, '_blank', 'noopener,noreferrer');
                    setFileUrlId("");
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getHealthLockerFiles = async () => {
        setError("");
        setFilesList([])
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthLockerFiles(searchQuery, selectedFileType, currentPage, rowsPerPage, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setTotalRecords(response.data.data.totalRecords);
                    setFilesList(response.data.data.files);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const fetchData = async (currentPage, rowsPerPage) => {
        setCurrentPage(currentPage);
        setRowsPerPage(rowsPerPage);
    }

    const handleClear = () => {
        setSearchQuery('');
        setSelectedFileType('All');
    };

    /*const filteredData = data.filter(item => {
        const matchesSearchQuery =
            item.memberID.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.fileType.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesFileType = selectedFileType === 'All' || item.fileType === selectedFileType;

        return matchesSearchQuery && matchesFileType;
    });*/

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Membership Number..."
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select name="reportTypeId" className="form-control input-field-ui" value={selectedFileType} onChange={e => setSelectedFileType(e.target.value)}>
                            <option value="All">Show All</option>
                            {
                                reportTypes.map((item, index) => {
                                    return <option value={item.reportTypeId}>{item.reportType}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div >
            <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
            <div className='table-responsive'>


                <table className='table table-striped mb-0'>
                    <thead>
                        <tr>
                            <th>Membership Number</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>File Type</th>
                            <th>File</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filesList.map((item, index) => {

                                return <tr key={index}>
                                    <td>{item.membershipNo}</td>
                                    <td>{item.date}</td>
                                    <td>{item.description}</td>
                                    <td>{item.fileType}</td>
                                    <td>
                                        <button onClick={() => {
                                            setFileUrlId(item.fileId);
                                            getHealthlockerFileUrl(item.fileId);
                                        }} className={`btn btn-success health-locker-btn me-2 ${fileUrlId == item.fileId ? "hide" : ""}`}><i className="bi bi-eye"></i></button>
                                        {
                                            fileUrlId === item.fileId &&
                                            <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "35px" }} alt="" /></span>
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <PaginationComponent totalRecords={totalRecords} fetchData={fetchData} />
        </>
    );
}

export default UploadMemberFileTable;
