import React from 'react';
import MemberProgressDetails from '../components/MemberProgressDetails/MemberProgressDetails';

function MemberProgress() {

  return (
    <>
       <MemberProgressDetails />
    </>
  );
}
export default MemberProgress;