import React from 'react';
import './HomeScreen.css';


function HomeScreen() {

  return (
    <>
      <section className='HomeScreen'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Home Screen</h1>
            </div>
            {/* <div class="col-md-auto text-end"><Link class="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='home-screen mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/49h43/qjfh54s88j.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-3">Provides admins with an overview of program and condition-specific data, including key performance indicators (KPIs) on member engagement and service utilization.</p>
              </div>
            </div>
          </div>

          <div className='home-screen'>
            <div className="row">
              <div className="col-md-12">
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeScreen;
