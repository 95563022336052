import React, { useEffect, useState, useCallback } from 'react';
import { approve_or_reject_observation, 
  fetch_single_observation,
  fetch_observation_thread, 
  fetch_all_status_list, 
  change_observation_status
} from '../../../../services/observations/Service';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../utils/Utils';
import CommentForm from '../../components/CommentForm';
import ConfirmationModal from '../../components/ConfirmationModal';


const ViewObservation = () => {

  const [observationData, setObservationData] = useState({});
  const { observationId } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    title: '',
    message: '',
    confirmButtonText: '',
    confirmButtonVariant: '',
    onConfirm: () => {},
  });

  const [observationThread, setObservationThread] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    fetchSingleObservation();
    fetchAllStatusList();

    if(!(observationData.status === 'Rejected' || observationData.status === 'Pending Review')){
        fetchObservationThread();
    }
  }, []);


  async function fetchSingleObservation() {
    try {
      const response = await fetch_single_observation({ observationId: observationId });

      if (response.data.code === 200) {
        setObservationData(response.data.data?.observation || {});
      } else {

      }
    } catch (error) {
      console.error(error);
    }
  }



  // approve observation 
  const handleApproveObservation = async (observationId, action) => { 
    try{ 
    let resp =  await approve_or_reject_observation({observationId: observationId, action: action}); 

    if(resp?.data?.code === 200){ 
          setObservationData({...observationData, status: resp?.data?.data?.status});
    }else{ 

    }

    }catch(e){ 
    }finally{
      setShowConfirmModal(false);
    }
}   


async function fetchAllStatusList(){ 
  try{ 
    let resp = await fetch_all_status_list({role: 'Moderator'}); 

    if(resp?.data?.code === 200){ 
      setStatusList(resp?.data?.data?.statuses || []);
    }
  }catch(e){ 

  }
}



  const handleBackClick = () => {
    window.history.back();
  };

  const handleAcceptObservation = () => {
    setModalConfig({
      title: 'Accept Observation',
      message: 'Are you sure you want to accept this observation?',
      confirmButtonText: 'Accept',
      confirmButtonVariant: 'success',
      onConfirm: () => {
        // Add your accept logic here
        console.log('Observation accepted');
        handleApproveObservation(observationId, 'approve');
      }
    });
    setShowConfirmModal(true);
  };

  const handleRejectObservation = () => {
    setModalConfig({
      title: 'Reject Observation',
      message: 'Are you sure you want to reject this observation?',
      confirmButtonText: 'Reject',
      confirmButtonVariant: 'danger',
      onConfirm: () => {
        handleApproveObservation(observationId, 'reject');
      }
    });
    setShowConfirmModal(true);
  };


  const fetchObservationThread = useCallback(async () => {
    try {
      const response = await fetch_observation_thread({
        observationId: observationId
      });
      
      if (response.data.code === 200) {
        setObservationThread(response.data?.data?.comments || []);
      } else {
        // setError(response.data.message);
      }
    } catch (error) {
      
    }
  }, [observationId]);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setStatusError('');
    setApiError('');
  };

  const handleStatusUpdate = async () => {
    // Validation
    if (!selectedStatus) {
      setStatusError('Please select a status');
      return;
    }

    try {
      const response = await change_observation_status({
        observationId: observationId,
        statusId: selectedStatus
      });

      if (response?.data?.code === 200) {
        setObservationData({...observationData, status: response?.data?.data?.status});
        setSelectedStatus('');
        fetchSingleObservation();
      } else {
        setApiError(response?.data?.message || 'Failed to update status');
      }
    } catch (error) {
      setApiError('Something went wrong while updating status');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center pb-3 sticky-top-bar">
        <div className="col-md" style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackClick}><i class="bi bi-chevron-left me-1"></i> Back</button>
          <div className="col">
            <h4 className="mb-0 content-main-title">{observationData.title}</h4>
          </div>
        </div>

      </div>

      <div className='content-ProgramPerformance ' style={{marginBottom:"70px"}}>
        <div className='observation-wrapper'>
          {/* <h1 className='dashboard-graph-title'>{observationData.title}</h1> */}
          <div className='row text-center px-2'>

          <div className="col-md-4 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Observed By :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {capitalizeFirstLetter(observationData.firstname) + ' ' + capitalizeFirstLetter(observationData.lastname) + '(' + observationData.observationBy + ')'}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Status :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.status}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Current Responsibility :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {capitalizeFirstLetter(observationData.current_responsibility)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Priority :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {capitalizeFirstLetter(observationData.priority)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Application :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {capitalizeFirstLetter(observationData.dashboardName)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Observed On :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.createdDate}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Last Action Date :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.lastActionDate}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
         
            <div className="row">
            <div className='col-md-12 mt-3 px-4'>
             <h5 className='dashboard-graph-title' style={{justifyContent:'flex-start'}}>Issue:</h5>          
             <p dir="auto">{observationData.description}</p>
             <hr className='my-4' />
          </div>   
               

         
            </div>
          

          {
            observationThread.length > 0 && 
            observationThread.map((item, index) => (
              <div className='observation-content w-100 px-3'>
                <div className="comment-wrapper">
                  <div className="comment-info">
                    <div className="comment-author mb-2">
                      <div className="avatar comment-avatar">
                        <img
                          src="https://storage.googleapis.com/ksabupatest/2024/10/30/v9wj7/74g44s3273.webp"
                          alt=""
                          className="user-avatar"
                        />
                      </div>

                      <div className="comment-meta">
                        <span title="Javed Ali">
                          <a href="/hc/en-us/profiles/33068671500563-Javed-Ali">
                            {capitalizeFirstLetter(item.firstname) + ' ' + capitalizeFirstLetter(item.lastname) + ' (' + item.commentBy + ')'}
                          </a>
                        </span>

                        <ul className="meta-group">
                          <li className="meta-data">
                            <time datetime="2024-10-09T14:14:25Z" title="2024-10-09 19:44" data-datetime="relative">
                              {item.createdTime}
                            </time>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <section className="comment-body">
                      <div className="zd-comment ps-5" dir="auto">
                        <p dir="auto">{item.comment}</p>
                         { 
                          item.attachments && item.attachments.length && item.attachments.map((attachment, index) => (
                            <div className='mt-3'>
                               <img src={attachment} alt="attachment" className='img-fluid' />
                            </div>
                          ))
                         }
                      </div>
                    </section>
                  </div>
                </div>
                <hr />
              </div>  
            ))
          }

          {
            observationData.status === 'Pending Review' ?
            <div className="d-flex gap-2 mb-4">
              <button 
                className="btn btn-primary btn-primary program-save-btn me-2"
                onClick={handleAcceptObservation}
              >
                {/* <i className="fa fa-check me-1"></i> */}
                Accept Observation
              </button>
              <button 
                className="btn clear-btn"
                onClick={handleRejectObservation}
              >
                {/* <i className="fa fa-times me-1"></i> */}
                Reject Observation
              </button>
            </div>
            : 
            <div>
              { 
                observationData.status === 'Rejected' || observationData.status === 'Closed' ? 
                <h5 className='dashboard-graph-title px-3 text-danger mb-3'>
                  {observationData.status === 'Rejected' ? 'Observation Rejected' : 'Observation has been closed'}
                </h5>
                :   
                <div className='row mt-4 px-3'>
                  <div className='col-md-12'>
                    <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>Add Comment <span className="text-danger">*</span></h5>
                    <CommentForm observationId={observationId} commentBy={`Moderator`} fetchObservationThread={fetchObservationThread} />
                    <hr className="my-4" />
                  </div>
                  
                  

                  <div className='col-md-12'>
                    <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>Update Status <span className="text-danger">*</span></h5>
                    <div className="row align-items-center">
                      <div className="col-md-5 mb-3">
                        {/* <label className="form-label">Select Status <span className="text-danger">*</span></label> */}
                        <select 
                          name="status"
                          className={`form-select control-input-ui ${statusError ? 'is-invalid' : ''}`}
                          value={selectedStatus}
                          onChange={handleStatusChange}
                        >
                          <option value="" disabled>Select Status</option>
                          { 
                            statusList.map((item, index) => (
                              <option key={item.id} value={item.id}>{item.status}</option>
                            ))
                          }
                        </select>
                        {statusError && <div className="invalid-feedback">{statusError}</div>}
                      </div>

                      <div className="col-12 mb-3">
                        {apiError && <div className="alert alert-danger mb-3">{apiError}</div>}
                        <button 
                          type="button" 
                          className="btn btn-success uplaod-file-btn"
                          onClick={handleStatusUpdate}
                        >
                          Update Status
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>

      <ConfirmationModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        title={modalConfig.title}
        message={modalConfig.message}
        confirmButtonText={modalConfig.confirmButtonText}
        confirmButtonVariant={modalConfig.confirmButtonVariant}
        onConfirm={modalConfig.onConfirm}
      />
    </div>
  )
}

export default ViewObservation; 