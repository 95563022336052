import CryptoJS from 'crypto-js';
import Loader from "react-js-loader";
import { useSelector } from 'react-redux';


export const decryptCipherString = (ciphertext) => {

    try {
        var key = 'a1b2c3d4e5f6g7h8';

        var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
        var keyWA = CryptoJS.enc.Utf8.parse(key);
        var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
        var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
        var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
        var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
        var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
        var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);

    } catch {
        window.location.href = '/logout';
    }

    return decryptedMessage;
}

export const decryptCipherJson = (ciphertext) => {
    try {
        var key = 'a1b2c3d4e5f6g7h8';
        var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
        var keyWA = CryptoJS.enc.Utf8.parse(key);
        var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
        var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
        var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
        var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
        var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
        var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);

        if (!decryptedMessage) {
            // window.location.href = '/logout';
        }
    } catch {
        // window.location.href = '/logout';
    }

    return JSON.parse(decryptedMessage);
}

export const CustomLoader = () => {

    return (
        <div className="loading-overlay col-md-12">
            <div className="loader-position">
                <Loader type="spinner-default" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={100} />
            </div>
        </div>
    )

}

export const CustomLoaderTele = () => {

    return (
        <div className="loading-overlay loading-overlay-tele col-md-12">
            <div className="loader-position">
                <Loader type="box-rectangular" bgColor={"#03335b"} color={'#FFFFFF'} size={80} />
            </div>
        </div>
    )

}

export const encryptData = (text) => {
    try {
        const key_hex = process.env.REACT_APP_KEY;
        const iv_hex = process.env.REACT_APP_IV;

        const key = CryptoJS.enc.Hex.parse(key_hex);
        const iv = CryptoJS.enc.Hex.parse(iv_hex);

        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    } catch (error) {
        console.error("encryptData Exception = ", error);
        return false;
    }
};

export const decryptData = (encryptedText) => {
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
    const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};


export const Commingsoon = () => {

    const webex = useSelector(state => state.webex);

    return (
        <div className="modal fade" id="upcomingFeatureModal" tabIndex="-1" aria-labelledby="upcomingFeatureModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{ height: "260px" }} alt="Upcoming Feature" />
                        <h1 className="modal-title mt-3">Upcoming Feature:</h1>
                        <h4 className="modal-subtitle mb-3">Available in Phase 2</h4>
                    </div>
                </div>
            </div>
        </div>
    );

};


export const CustomFieldLevelLoader = () => {
    return (
        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f0j83/menjruksxj.gif" style={{ width: '80px' }} alt="Loader..."></img>
    )
}

export const formatDateWithOrdinal = (date) => {
    const day = date.getDate();
    const options = { month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    const getOrdinalSuffix = (n) => {
        const s = ["th", "st", "nd", "rd"];
        const v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0];
    };
    const [month, year] = formattedDate.split(' ');

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;

};


export const makeFileNameDate = (d) => {
    if (d === undefined) d = new Date();
    const name = `${d.getFullYear()} -${(d.getMonth() + 1)
        .toString()
        .padStart(2, '0')
        } -${d.getDate()
            .toString()
            .padStart(2, '0')
        } -${d.getHours()
            .toString()
            .padStart(2, '0')
        } -${d.getMinutes()
            .toString()
            .padStart(2, '0')
        } -${d.getSeconds()
            .toString()
            .padStart(2, '0')
        }`;
    return name;
}