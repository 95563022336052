import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NewRequestRegisterLaterTable from "./NewRequestRegisterLaterTable";
import PastRequestRegisterLaterTable from "./PastRequestRegisterLaterTable";


function ContentMemberRegisterLaterDetails() {

    return (
        <>
        <section className='ContentMemberDeEnrollment'>
        <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md">
                    <h1 className="main-title">Member Enroll Later</h1>
                </div>
                <div className="col-md text-end">
                    
                </div>
            </div>

            <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/usermanagement.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Enroll later requests are for members who want future program enrollment. Approve these requests and assign care navigators when the date arrives.</p>
                        </div>
                    </div>
                </div>
            <div className="container-fluid">
            
                <div className="row align-items-center pb-3">
                <div className='comntent-MemberDeEnrollment mb-3'>
                <div className="row">
                    <div className="col-md-12">
                    <Tabs>
                        <TabList>
                        <Tab>New Request</Tab>
                        <Tab>Past Request</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="table-responsive ContentMemberRegisterLaterTable">
                                <NewRequestRegisterLaterTable />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="table-responsive ContentMemberRegisterLaterTable">
                                <PastRequestRegisterLaterTable />
                            </div>
                        </TabPanel>
                    </Tabs>
                    
                    </div>
                </div>
                </div>

               
                </div>
            </div>
            </section>
            
        </>
    );
}

export default ContentMemberRegisterLaterDetails;
