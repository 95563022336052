import React from 'react';
import './ContentConfiguration.css';
import ContentConfigurationDetails from './ContentConfiguration/ContentConfigurationDetails';

function ContentConfiguration() {

  return (
    <>
        <section className='ContentConfiguration'>

            <ContentConfigurationDetails />

        </section>


        
    </>
  );
}

export default ContentConfiguration;
