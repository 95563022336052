import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
const ViewAssessmentV2 = (props) => {
    const assessmentJson = props.assessmentJson ? props.assessmentJson : {}
    const memberInfo = props.memberBasicInfo ? props.memberBasicInfo : [];

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <b><label>Smoker: </label></b> <br />
                        <span>
                            {
                                assessmentJson.smoker !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.smoker == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>
                    <div className='col'>
                        <b><label>Has Obese: </label></b><br />
                        <span>
                            {
                                assessmentJson.obese !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.obese == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>
                    <div className='col'>
                        <b><label>Is Playing Sport: </label></b> <br />
                        <span>
                            {
                                assessmentJson.playSport !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.playSport == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>
                    <div className='col'>
                        <b><label>T.B.D: </label></b><br />
                        <span>
                            {
                                assessmentJson.tbd !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.tbd == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>

                    <div className="col-12"><hr /></div>

                </div>

                <div className='row mt-2'>
                    <div className='col-12'><h5>Medical History:</h5></div>
                    <div className='col-2 mb-2'>
                        <b><label>Active Complaints: </label></b><br />
                        <span>{assessmentJson.medicalHistory.activeComplaints}</span>
                    </div>
                    <div className='col-2 mb-2'>
                        <b><label>Past History: </label></b> <br />
                        <span>{assessmentJson.medicalHistory.pastHistory}</span>
                    </div>
                    <div className='col-8 mb-2'>
                        <b><label>Previous hospitalizations, surgeries, or treatments related to the chronic condition: </label></b><br />
                        <span>
                            {
                                assessmentJson.medicalHistory.previousTreatments !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.medicalHistory.previousTreatments == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                        {
                            assessmentJson.medicalHistory.previousTreatments === "Y" &&
                            <div className='col-12'>
                                <b><label>Details: </label></b><br />
                                <span>{assessmentJson.medicalHistory.previousTreatmentsDetails}</span>
                            </div>
                        }
                    </div>

                    <div className='col-4 mb-2'>
                        <b><label>Allergy History: </label></b><br />
                        <span>{
                            assessmentJson.medicalHistory.allergyHistory !== "" && (
                                <>
                                    <span>
                                        {
                                            (assessmentJson.medicalHistory.allergyHistory == "Y") ? "Yes" : "No"
                                        }
                                    </span>
                                </>
                            )
                        }
                        </span>

                        {
                            assessmentJson.medicalHistory.allergyHistory === "Y" &&
                            <div className=' mt-2'>
                                <b><label>Details: </label></b><br />
                                <span>{assessmentJson.medicalHistory.allergyHistoryDetails}</span>
                            </div>
                        }
                    </div>

                    <div className='col-4 mb-2'>
                        <b><label>Mental Health Assessment: </label></b><br />
                        <span>{assessmentJson.medicalHistory.mentalHealthAssessment}</span>
                    </div>
                    <div className="col-12"><hr /></div>
                </div>
                {
                    assessmentJson.medications && assessmentJson.medications.length > 0 &&
                    <>
                        <div className='row mt-2'>
                            <div className='col-12'><h5>Medications History:</h5></div>
                        </div>
                        {/* <Row>
                            <Col md="3"><b>Category</b></Col>
                            <Col md="3"><b>Drug Name</b></Col>
                            <Col md="3"><b>Code</b></Col>
                            <Col md="3">Frequency</Col>
                            <Col md="3">Duration</Col>
                            <Col md="3">Supply From</Col>
                            <Col md="3">Supply To</Col>
                            <Col md="3">Notes</Col>
                        </Row> */}
                        {

                            assessmentJson.medications ?

                                assessmentJson.medications.map((item, index) => {
                                    return <>
                                        <Row>
                                            <Col md="3" className='mb-2'>
                                                <b>Category</b><br />
                                                {item.categoryName}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Drug Name</b><br />
                                                {item.drugName}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Codee</b><br />
                                                {item.code}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Frequency</b><br />
                                                {item.frequency}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Duration</b><br />
                                                {item.duration}</Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Supply From</b><br />
                                                {
                                                    moment(item.supplyFrom).isValid() ?
                                                        moment(item.supplyFrom).format('DD MMM, YYYY') : ""
                                                }
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Supply To</b><br />
                                                {moment(item.supplyTo).isValid() ?
                                                    moment(item.supplyTo).format('DD MMM, YYYY') : ""}</Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Remarks</b><br />
                                                {item.notes}</Col>
                                        </Row>
                                        <div className="col-12"><hr /></div>
                                    </>
                                }) : ""
                        }
                    </>
                }
                {
                    assessmentJson.medicalHistory.newMedications == "Y" &&
                    <div>
                        <b><label>Recent changes in medication or new medications: </label></b><br />
                        <span>{assessmentJson.medicalHistory.newMedicationsDetails}</span>
                    </div>
                }

                <div className='row mt-2'>
                    <div className='col-12'><h5>Patient adherence:</h5></div>
                    <div className='col-4 mb-2'>
                        <b><label>Compliance with prescribed medications: </label></b><br />
                        <span>
                            {
                                assessmentJson.patientAdherence.compliancePrescribedMedicines !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.patientAdherence.compliancePrescribedMedicines == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>

                    <div className='col-4 mb-2'>
                        <b><label>Follow-through with recommended lifestyle changes: </label></b><br />
                        <span>
                            {
                                assessmentJson.patientAdherence.followLifestyleChanges !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.patientAdherence.followLifestyleChanges == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>

                    <div className='col-4 mb-2'>
                        <b><label>Attendance at follow-up appointments: </label></b><br />
                        <span>
                            {
                                assessmentJson.patientAdherence.attendanceAtAppointments !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.patientAdherence.attendanceAtAppointments == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>

                    <div className='col-4 mb-2'>
                        <b><label>Patient's understanding of their condition and treatment plan: </label></b><br />
                        <span>
                            {
                                assessmentJson.patientAdherence.understaningOfTretmentPlan !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.patientAdherence.understaningOfTretmentPlan == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>

                    <div className='col-4 mb-2'>
                        <b> <label>Patient's goals for managing their condition: </label></b><br />
                        <span>
                            {
                                assessmentJson.patientAdherence.goalForManagingCondition !== "" && (
                                    <>
                                        <span>
                                            {
                                                (assessmentJson.patientAdherence.goalForManagingCondition == "Y") ? "Yes" : "No"
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </span>
                    </div>
                    <div className="col-12"><hr /></div>
                </div>

                {
                    memberInfo.gender === "F" &&
                    <>
                        <div className='row mt-2'>
                            <div className='col-12'><h5>Obstetric & Gynecology history:</h5></div>
                            <div className='col-6'>
                                <b><label>Current pregnancy: </label></b><br />
                                <span>
                                    {
                                        assessmentJson.obGynHistory.currentPregnancy !== "" && (
                                            <>
                                                <span>
                                                    {
                                                        (assessmentJson.obGynHistory.currentPregnancy == "Y") ? "Yes" : "No"
                                                    }
                                                </span>
                                            </>
                                        )
                                    }
                                </span>

                            </div>
                            {
                                assessmentJson.obGynHistory.currentPregnancy == "Y" &&
                                <div className='col-6'>
                                    <b><label>Last Menstrual Period Date: </label></b><br />
                                    <span>
                                        {
                                            (moment(assessmentJson.obGynHistory.lastMenses).isValid()) ?
                                                moment(assessmentJson.obGynHistory.lastMenses).format('DD MMM, YYYY') : ""
                                        }
                                    </span>
                                </div>
                            }
                            <div className="col-12"><hr /></div>
                        </div>
                    </>
                }


                <div className='row mt-2' >
                    <div className='col-12'><h5>Family medical history:</h5></div>

                    <div className='col-12'>
                        <label></label>
                        <span>
                            {
                                assessmentJson.familyMedicalHistory
                            }
                        </span>
                    </div>
                    <div className="col-12"><hr /></div>
                </div>

                <div className='row mt-2' >
                    <div className='col-12'><h5>Previous screening :</h5></div>

                    <div className='col-3 mb-2'>
                        <b> <label>Mammography</label></b><br />
                        {
                            assessmentJson.previousScreenings.mammography !== "" && (
                                <>
                                    <span>
                                        {
                                            (assessmentJson.previousScreenings.mammography == "Y") ? "Yes" : "No"
                                        }
                                    </span>
                                    {
                                        assessmentJson.previousScreenings.mammography == "Y" && (moment(assessmentJson.previousScreenings.mammographyDate).isValid()) &&
                                        <span> on {moment(assessmentJson.previousScreenings.mammographyDate).format('DD MMM, YYYY')}</span>
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className='col-3 mb-2'>
                        <b><label>Pap smear</label></b><br />
                        {
                            assessmentJson.previousScreenings.papsmear !== "" && (
                                <>
                                    <span>
                                        {
                                            (assessmentJson.previousScreenings.papsmear == "Y") ? "Yes" : "No"
                                        }
                                    </span>
                                    {
                                        assessmentJson.previousScreenings.papsmear == "Y" && (moment(assessmentJson.previousScreenings.papsmearDate).isValid()) &&
                                        <span> on {moment(assessmentJson.previousScreenings.papsmearDate).format('DD MMM, YYYY')}</span>
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className='col-3 mb-2'>
                        <b><label>Bone density tests</label></b> <br />
                        {
                            assessmentJson.previousScreenings.boneDensityTests !== "" &&
                            (
                                <>
                                    <span>
                                        {(assessmentJson.previousScreenings.boneDensityTests === "Y") ? "Yes" : "No"}
                                    </span>

                                    {
                                        assessmentJson.previousScreenings.boneDensityTests == "Y" && (moment(assessmentJson.previousScreenings.boneDensityTestsDate).isValid()) &&
                                        <span> on {moment(assessmentJson.previousScreenings.boneDensityTestsDate).format('DD MMM, YYYY')}</span>
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className='col-3 mb-2'>
                        <b><label>PSA</label></b> <br />
                        {
                            assessmentJson.previousScreenings.psa !== "" && (
                                <>
                                    <span>
                                        {
                                            (assessmentJson.previousScreenings.psa == "Y") ? "Yes" : "No"
                                        }
                                    </span>
                                    {
                                        assessmentJson.previousScreenings.psa == "Y" && (moment(assessmentJson.previousScreenings.psaDate).isValid()) &&
                                        <span> on {moment(assessmentJson.previousScreenings.psaDate).format('DD MMM, YYYY')}</span>
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className='col-3 mb-2'>
                        <b><label>Previous Fecal immunochemical test (FIT) </label></b><br />
                        {
                            assessmentJson.previousScreenings.previousFIT !== "" && (
                                <>
                                    <span>
                                        {
                                            (assessmentJson.previousScreenings.previousFIT == "Y") ? "Yes" : "No"
                                        }
                                    </span>
                                    {
                                        assessmentJson.previousScreenings.previousFIT == "Y" && (moment(assessmentJson.previousScreenings.previousFITDate).isValid()) &&
                                        <span> on {moment(assessmentJson.previousScreenings.previousFITDate).format('DD MMM, YYYY')}</span>
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className='col-3 mb-2'>
                        <b> <label>Colonoscopy</label></b><br />
                        {
                            assessmentJson.previousScreenings.colonoscopy !== "" && (
                                <>
                                    <span>
                                        {
                                            (assessmentJson.previousScreenings.colonoscopy == "Y") ? "Yes" : "No"
                                        }
                                    </span>
                                    {
                                        assessmentJson.previousScreenings.colonoscopy == "Y" && (moment(assessmentJson.previousScreenings.colonoscopyDate).isValid()) &&
                                        <span> on {moment(assessmentJson.previousScreenings.colonoscopyDate).format('DD MMM, YYYY')}</span>
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className='col-3 mb-2'>
                        <b><label>Other</label></b><br />
                        <span>
                            {
                                assessmentJson.previousScreenings.other
                            }
                        </span>
                    </div>
                    <div className="col-12"><hr /></div>
                </div>
                {
                    assessmentJson.labResults && assessmentJson.labResults.length > 0 &&
                    <>
                        <div className='row mt-2'>
                            <div className='col'><h5>Lab Results History:</h5></div>
                        </div>

                        {/* <Row>
                            <Col>Category</Col>
                            <Col>Service</Col>
                            <Col>Code</Col>
                            <Col>Condition</Col>
                            <Col>Date</Col>
                            <Col>Attachment</Col>
                            <Col>Provider</Col>
                            <Col>Notes</Col>
                        </Row> */}
                        {

                            assessmentJson.labResults ?

                                assessmentJson.labResults.map((item, index) => {
                                    return <>
                                        <Row>
                                            <Col md="3" className='mb-2'>
                                                <b>Category</b><br />
                                                {item.categoryName}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Service</b><br />
                                                {item.assessmentServiceName}</Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Code</b><br />
                                                {item.code}</Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Condition</b><br />
                                                {item.patientCondition}</Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Date</b><br />
                                                {moment(item.date).isValid() ?
                                                    moment(item.date).format('DD MMM, YYYY') : ""}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Attachment</b><br />
                                                {item.attachmentUrl}</Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Provider</b><br />
                                                {item.provider}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Remarks</b><br />
                                                {item.notes}</Col>
                                        </Row>
                                        <div className="col-12"><hr /></div>
                                    </>
                                }) : ""
                        }
                    </>
                }

                {
                    assessmentJson.imagingResults && assessmentJson.imagingResults.length > 0 &&
                    <>
                        <div className='row mt-2'>
                            <div className='col'><h5>Imaging Results History:</h5></div>
                        </div>

                        {/*<Row>
                            <Col>Category</Col>
                            <Col>Service</Col>
                            <Col>Code</Col>
                            <Col>Condition</Col>
                            <Col>Date</Col>
                            <Col>Attachment</Col>
                            <Col>Provider</Col>
                            <Col>Notes</Col>
                        </Row>*/}
                        {

                            assessmentJson.imagingResults ?

                                assessmentJson.imagingResults.map((item, index) => {
                                    return <>
                                        <Row>
                                            <Col md="3" className='mb-2'>
                                                <b>Category</b><br />
                                                {item.categoryName}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Service</b><br />
                                                {item.assessmentServiceName}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Code</b><br />
                                                {item.code}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Condition</b><br />
                                                {item.patientCondition}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Date</b><br />
                                                {moment(item.date).isValid() ?
                                                    moment(item.date).format('DD MMM, YYYY') : ""}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Attachment</b><br />
                                                {item.attachmentUrl}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Provider</b><br />
                                                {item.provider}
                                            </Col>
                                            <Col md="3" className='mb-2'>
                                                <b>Remarks</b><br />
                                                {item.notes}
                                            </Col>
                                        </Row>
                                    </>
                                }) : ""
                        }
                    </>
                }

            </div>
        </>
    )
}

export default ViewAssessmentV2