import React from 'react';
import './ContentUserManagementDetails.css';
// import ContentUserManagementDetails from './ContentUserManagement/ContentUserManagementDetails';
import ContentAddNewUser from './ContentUserManagement/ContentAddNewUser';

function ContentAddUserManagement() {

  return (
    <>
        <section className='ContentUserManagement'>

            <ContentAddNewUser />

            {/* <ContentUserManagement /> */}

        </section>


        
    </>
  );
}

export default ContentAddUserManagement;
