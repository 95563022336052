import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { fetchAssessmentCategories, searchMedicationDrugs } from "../../components/services/Service";
import DatePicker from 'react-datepicker';
import moment from 'moment';

const Medications = (props) => {
    const c = props.c;
    const listType = props.listType;
    const data = props.data;
    const removeComponent = props.removeComponent;
    const handleFieldChange = props.handleFieldChange;
    const handleSuggestionClick = props.handleSuggestionClick;
    const [categories, setCategories] = useState([]);
    const [drugs, setDrugs] = useState([]);
    const [searchStr, setSearchStr] = useState("");
    const [showSearchBox, setShowSearchBox] = useState(false);
    const searchBoxRef = useRef(null);

    const handleFocus = () => {
        setShowSearchBox(true);
    };

    const handleBlur = (e) => {
        if (searchBoxRef.current && searchBoxRef.current.contains(e.relatedTarget)) {
            return;
        }
        setShowSearchBox(false);
    };

    useEffect(() => {
        getAssessmentCategories();
    }, []);

    const handleSearch = (e) => {
        setSearchStr(e.target.value);
        getMedicationDrugs(e.target.value);
    }

    const getAssessmentCategories = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchAssessmentCategories(listType, ct)
            .then((response) => {
                if (response.data.data.categories)
                    setCategories(response.data.data.categories);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getMedicationDrugs = async (searchStr) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await searchMedicationDrugs(searchStr, ct)
            .then((response) => {
                if (response.data.data.drugs)
                    setDrugs(response.data.data.drugs);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleSuggestionDrugClick = (suggestion) => {
        console.log("suggestion = ", suggestion)
        handleSuggestionClick(suggestion, c)
        setSearchStr("");
        setShowSearchBox(false);
        setDrugs([]);
    };

    const addHandleChange = (e) => {
        handleFieldChange(e, c, listType);
    }

    const addHandleDateChange = (date, name) => {
        const tempE = { name: name, value: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : "" };
        const e = { target: tempE };
        handleFieldChange(e, c, listType);
    }

    return (
        <>
            <tr style={{ verticalAlign: "middle" }}>
                <td style={{ width: "9%" }}>
                    <select className="form-select select-input-ui" aria-label="Default select example" name="categoryId" value={data.categoryId} onChange={addHandleChange}>
                        <option value="" data-type="">Select</option>
                        {
                            categories.map((opt, index) => {
                                return <option key={index} value={opt.categoryId}>{opt.categoryName}</option>
                            })
                        }
                    </select>
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="drugName" name="drugName" style={{ width: "auto !important" }} placeholder="Drug Name" value={data.drugName ? data.drugName : ""} onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    {showSearchBox > 0 && (

                        < div ref={searchBoxRef} style={{
                            position: 'absolute',
                            left: '0',
                            border: '1px solid #ccc',
                            backgroundColor: '#fff',
                            zIndex: 1,
                            padding: '10px',
                            borderRadius: '10px',
                            width: '30%'
                        }}
                        >
                            <label>Search:</label>
                            <input className='form-control control-input-ui' type="text" name="searchStr" value={searchStr} onChange={handleSearch} />
                            <ul className="suggestions-list">
                                {drugs.map((item) => (
                                    <li
                                        key={item.drugId}
                                        onClick={() => handleSuggestionDrugClick(item)}
                                    >
                                        {item.drugName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="Code" name="code" style={{ width: "auto !important" }} placeholder="Code" value={data.code} onChange={addHandleChange} />
                </td>
                <td style={{ width: "15%" }}>
                    <select className="form-select select-input-ui" aria-label="Default select example" name="frequency" value={data.frequency} onChange={addHandleChange}>
                        <option value="">Select Frequency</option>
                        <option value="1">One a day</option>
                        <option value="2">Twice a day</option>
                        <option value="3">Multiple times a day</option>
                    </select>
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="Duration" style={{ width: "auto !important" }} placeholder="Duration" name="duration" value={data.duration} onChange={addHandleChange} />
                </td>
                <td>
                    {/*<input type="text" className="form-control control-input-ui" id="Supply From" style={{ width: "auto !important" }} placeholder="Supply From" name="supplyFrom" value={data.supplyFrom} onChange={addHandleChange} />*/}

                    <DatePicker
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Supply From"
                        className='form-control control-input-ui'
                        selected={data.supplyFrom}
                        name="supplyFrom"
                        onChange={(date) => addHandleDateChange(date, "supplyFrom")}
                        onKeyDown={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />
                </td>
                <td>
                    {/*<input type="text" className="form-control control-input-ui" id="Supply To" style={{ width: "auto !important" }} placeholder="Supply To" name="supplyTo" value={data.supplyTo} onChange={addHandleChange} />*/}
                    <DatePicker
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Supply To"
                        className='form-control control-input-ui'
                        selected={data.supplyTo}
                        name="supplyTo"
                        onChange={(date) => addHandleDateChange(date, "supplyTo")}
                        onKeyDown={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="Notes" style={{ width: "auto !important" }} placeholder="Remarks" name="notes" value={data.notes} onChange={addHandleChange} />
                </td>
                <td>
                    <button type="button" className="btn-close cross-btn-close" aria-label="Close" onClick={() => removeComponent(c, listType)}></button>
                </td>
            </tr >
        </>
    )
}

export default Medications