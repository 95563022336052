import React from 'react';
import { Link } from 'react-router-dom';
import { downloadFile } from '../../../services/rcm/Service';

function DropDownActionNew({uploadId}) {

    const downloadFileApi = async (fileType) => {
        downloadFile(fileType,uploadId.id)
        .then((response) => {
            if(response.data.code == 200){
                window.location.href = response.data.data.fileUrl;
            }            
            }
        );
    }
 
return (
    <>
        <div className='DropDownAction'>
        <div className="btn-group dropend">
        <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
        <i className="bi bi-download"></i>
        </button>
        <ul className="dropdown-menu">
            {uploadId.success && <li className='list-class'><Link className="dropdown-item" onClick={(e) => downloadFileApi('success')}>Successfully Migrated Records</Link></li> } 
            {uploadId.fail && <li className='list-class'><Link className="dropdown-item" onClick={(e) => downloadFileApi('fail')}>Failed Migration Records (with Errors)</Link></li> }
            {uploadId.duplicate && <li className='list-class'><Link className="dropdown-item" onClick={(e) => downloadFileApi('duplicate')}>Duplicate Records</Link></li> }
            {uploadId.all && <li className='list-class'><Link className="dropdown-item" onClick={(e) => downloadFileApi('all')}>All Records</Link></li> }
        </ul>
        </div>
        </div>
    </>
  );
}

export default DropDownActionNew;
