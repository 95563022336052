import React, { useState } from 'react';
import './UserManagementPage.css';
import Sidenavmenubar from '../../componenents/Sidenavmenubar';
import Navbar from '../../componenents/Navbar';
import ContentUserManagement from '../components/ContentUserManagement';


function UserManagementPage() {

  const [title, setTitle] = useState('User Management');

  return (
    <>
        <ContentUserManagement/>
        
    </>
  );
}

export default UserManagementPage;
