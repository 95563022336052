import React, { useCallback, useEffect, useState } from 'react';

const TableModal = ({ 
  show, 
  onClose, 
  data = [], 
  title = "User List",
  page,
  setPage,
  perPage,
  setPerPage,
  totalRows,
  currentRecordsRange,
  totalPages
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const [sortedData, setSortedData] = useState([...data]);

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  const handleContentClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [show, onClose]);

  const handleRowsPerPageChange = (e) => {
    const newPerPage = parseInt(e.target.value);
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when changing rows per page
  };

  const goToFirstPage = () => setPage(1);
  const goToPreviousPage = () => setPage(prev => Math.max(prev - 1, 1));
  const goToNextPage = () => setPage(prev => Math.min(prev + 1, totalPages));
  const goToLastPage = () => setPage(totalPages);

  const handleSort = (key) => {
    let direction = 'asc';
    
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const sorted = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <i className="fa fa-sort tw-ml-2 tw-text-gray-400"></i>;
    }
    return sortConfig.direction === 'asc' ? 
      <i className="fa fa-sort-up tw-ml-2"></i> : 
      <i className="fa fa-sort-down tw-ml-2"></i>;
  };

  const getTitle = (names) => {
    switch (names) {
      case "Attempted":
        return "List of Members with Message Attempted";
      case "Sent":
        return "List of Members with Message Sent";
      case "Delivered":
        return "List of Members with Message Delivered";
      case "Read":
        return "List of Members with Message Read";
      case "Failed":
        return "List of Members with Failed Messages";
      default:
        return "List of Members with Message Attempted";
    }
  };

  if (!show) return null;

  return (
    <div
      className="tw-fixed tw-inset-0 tw-bg-black/10 tw-flex tw-justify-center tw-items-center tw-z-50"
      onClick={onClose}
    >
      <div
        className="tw-bg-white tw-rounded-3xl tw-p-3 tw-w-full tw-max-w-3xl tw-max-h-[80vh] tw-overflow-y-auto"
        onClick={handleContentClick}
      >
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
          <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">{getTitle(title)}</h2>
          <span
            onClick={onClose}
            className="tw-text-gray-500 tw-cursor-pointer tw-text-4xl hover:tw-text-gray-700 tw-border-none tw-bg-transparent tw-mb-1"
          >
            ×
          </span>
        </div>

        <div className="tw-overflow-x-auto tw-rounded-xl">
          <table className="tw-w-full tw-min-w-max">
            <thead>
              <tr className="tw-bg-[#d4e6f0]">
                <th 
                  className="tw-px-6 tw-py-3 tw-text-left tw-text-base tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('memberName')}
                >
                  <div className="tw-flex tw-items-center">
                    Member Name
                    {getSortIcon('memberName')}
                  </div>
                </th>
                <th 
                  className="tw-px-6 tw-py-3 tw-text-left tw-text-base tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('mobile')}
                >
                  <div className="tw-flex tw-items-center">
                    Mobile Number
                    {getSortIcon('mobile')}
                  </div>
                </th>
                <th 
                  className="tw-px-6 tw-py-3 tw-text-left tw-text-base tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('createdTime')}
                >
                  <div className="tw-flex tw-items-center">
                    Date & Time
                    {getSortIcon('createdTime')}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
              {sortedData.length > 0 ? (
                sortedData.map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "tw-bg-gray-100" : "tw-bg-white"
                    }`}
                  >
                    <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-left tw-text-sm">
                      {item.memberName}
                    </td>
                    <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-left tw-text-sm">
                      {item.mobile}
                    </td>
                    <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-left tw-text-sm">
                      {item.createdTime}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="tw-px-6 tw-py-4 tw-text-center tw-text-gray-500"
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div className="rows-per-page">
            <label>Rows per page: </label>
            <select value={perPage} onChange={handleRowsPerPageChange}>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
            </select>
          </div>

          {totalRows > 0 && (
            <>
              <div className="records-info">
                {currentRecordsRange && totalRows > 0 && `${currentRecordsRange} of ${totalRows} records`}
              </div>
         
              <div className="pagination-controls tw-mb-3.5">
                <button onClick={goToFirstPage} disabled={page === 1}>
                  <i className='bx bx-first-page'></i>
                </button>
                <button onClick={goToPreviousPage} disabled={page === 1}>
                  <i className='bx bx-chevron-left'></i>
                </button>
                <button onClick={goToNextPage} disabled={page === totalPages}>
                  <i className='bx bx-chevron-right'></i>
                </button>
                <button onClick={goToLastPage} disabled={page === totalPages}>
                  <i className='bx bx-last-page'></i>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableModal;