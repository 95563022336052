import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import './FormsModal.css';
import MedicationForms from '../MedicationForms/MedicationForms'
import LabRequestForms from '../LabRequestForms/LabRequestForms';
import HomeBasedVaccine from '../HomeBasedVaccine/HomeBasedVaccine';
import HomeCareRequest from '../HomeCareRequest/HomeCareRequest';


const FormsModal = ({ header, memberBasicInfo, showHRAModal, handleShowHRAModal, handleCloseHRAModal, selectedServiceRecord, callHomeBasedServicesHistory, selectedBulkServices }) => {

    const closeModal = async (e) => {
        handleCloseHRAModal();
    };

    return (
        <>
            <Modal id="wpnb2" className='HealthManagement' centered show={showHRAModal} onHide={handleCloseHRAModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>{header} {selectedServiceRecord.serviceName != "" && ` - ${selectedServiceRecord.serviceName}`}</h1>
                </Modal.Header>
                {/* <hr/> */}
                <Modal.Body>
                    {/* <Tabs defaultActiveKey="MedicationRequest" id="uncontrolled-tab-example">
                        <Tab eventKey="MedicationRequest" title="Medication Request">
                             <MedicationForms />
                        </Tab>
                        <Tab eventKey="LabRequest" title="Lab Request">
                            <LabRequestForms />
                        </Tab>
                    </Tabs> */}

                    {header == 'Medication Request' && <MedicationForms closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                    {header == 'Lab Request' && <LabRequestForms closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                    {header == 'Home Care Request' && <HomeCareRequest closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} selectedBulkServices={selectedBulkServices} />}
                    {header == 'Home Based Vaccine' && <HomeBasedVaccine closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FormsModal;