import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FileText, Video, Image as ImageIcon, File } from 'lucide-react';
import Pagination from '../../utils/Pagination';

// Separate Card Component
const TemplateCard = ({ template, isActive, onCardClick, isHovered, onHover, onLeave }) => {
  const getIcon = () => {
    switch (template.type) {
      case 'Document':
        return <FileText className="tw-w-6 tw-h-6 tw-text-blue-500" />;
      case 'Video':
        return <Video className="tw-w-6 tw-h-6 tw-text-orange-600" />;
      case 'Image':
        return <ImageIcon className="tw-w-6 tw-h-6 tw-text-green-500" />;
      default:
        return <File className="tw-w-6 tw-h-6 tw-text-gray-500" />;
    }
  };

  // Parse buttons if they exist and are a string
  const parseButtons = () => {
    if (!template.buttons) return null;
    try {
      return typeof template.buttons === 'string' ? JSON.parse(template.buttons) : template.buttons;
    } catch (e) {
      return null;
    }
  };

  // Parse website URLs if they exist and are a string
  const parseWebsiteUrl = () => {
    if (!template.websiteUrl) return null;
    try {
      return typeof template.websiteUrl === 'string' ? JSON.parse(template.websiteUrl) : template.websiteUrl;
    } catch (e) {
      return null;
    }
  };

  const buttons = parseButtons();
  const websiteUrls = parseWebsiteUrl();

  return (
    <div 
      className={`tw-h-full tw-bg-white tw-rounded-lg tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-relative tw-overflow-hidden ${
        isActive ? 'tw-ring-2 tw-ring-blue-500' : ''
      }`}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {/* Card Header */}
      <div className="tw-p-2 tw-bg-gray-300 tw-min-h-[75px]">
        {isActive && (
          <div className="tw-absolute tw-top-2 tw-right-2 tw-w-6 tw-h-6 tw-bg-green-500 tw-rounded-full tw-flex tw-items-center tw-justify-center">
            <svg className="tw-w-4 tw-h-4 tw-text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        )}
        <div className="tw-flex tw-items-center tw-gap-2">
          {getIcon()}
          <h5 className="tw-font-semibold tw-truncate tw-mt-1 tw-text-gray-500">{template.templateName}</h5>
        </div>
        {template.headerContent && (
          <p className="tw-text-sm tw-mb-0 tw-text-gray-500">{template.headerContent}</p>
        )}
      </div>

      {/* Card Body */}
      <div className="tw-flex tw-flex-col tw-gap-4 tw-border-t tw-border-gray-500">
        {/* Media Section */}
        {(template.type === 'Image' || template.type === 'Video') && template.mediaUrl && (
          <div className="tw-relative tw-aspect-video tw-bg-gray-100 tw-rounded-md tw-overflow-hidden tw-mt-2">
            {template.type === 'Image' ? (
              <img 
                src={template.mediaUrl} 
                alt={template.templateName}
                className="tw-w-full tw-h-full tw-object-cover"
              />
            ) : (
              <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
                <Video className="tw-w-12 tw-h-12 tw-text-gray-400" />
              </div>
            )}
          </div>
        )}

        {/* Content Section */}
        <div className="tw-p-4 tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-rounded-lg">
          {/* Document Header */}
          <div className="tw-flex tw-items-start tw-gap-3 tw-mb-2">
            <div className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded-lg">
              <svg className="tw-w-6 tw-h-6 tw-text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <div>
              <h3 className="tw-font-medium tw-text-[#667781] tw-text-sm">
                {template.headerContent || "Message Preview"}
              </h3>
              <span className="tw-text-xs tw-text-gray-500">{template.type}</span>
            </div>
          </div>

          {/* Message Content */}
          <div className="tw-flex tw-flex-col tw-gap-3">
            {/* Template Body */}
            <div className="tw-text-[15px] tw-leading-relaxed tw-text-[#111b21]"
              dangerouslySetInnerHTML={{ __html: template.templateBody }}
            />

            {/* Footer Content */}
            {template.footerContent && (
              <p className="tw-text-[13px] tw-text-[#8696a0] tw-mt-1">
                {template.footerContent}
              </p>
            )}

            {/* Website URLs (Links and Phone Numbers) */}
            {websiteUrls && websiteUrls.length > 0 && (
              <div className="tw-mt-3 tw-space-y-2">
                {websiteUrls.map((item, index) => (
                  <div key={index}>
                    {item.type === 'URL' && (
                      <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                        <i className="bi bi-link-45deg tw-mr-2"></i>
                        {item.title}
                      </span>
                    )}
                    {item.type === 'PHONE_NUMBER' && (
                      <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                        <i className="bi bi-telephone tw-mr-2"></i>
                        {item.title}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Quick Reply Buttons */}
            {buttons && buttons.length > 0 && (
              <div className="tw-mt-3 tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-flex-wrap tw-gap-2">
                {buttons.map((button, index) => (
                  <span
                    key={index}
                    className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center"
                  >
                    {button.title}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Hover Overlay */}
      <div className={`tw-absolute tw-inset-0 tw-bg-black/50 tw-flex tw-flex-col tw-items-center tw-justify-center tw-transition-opacity tw-duration-300 tw-rounded-lg ${
        isHovered ? 'tw-opacity-100' : 'tw-opacity-0'
      }`}>
        <Link
          to={`/edit-template/${template.templateId}`}
          className="tw-bg-[#2986C4] tw-text-white tw-font-semibold tw-py-2 tw-px-4 tw-rounded tw-mb-2 tw-transition-transform tw-duration-300 tw-transform hover:tw-scale-105"
          onClick={() => onCardClick(template.templateId)}
        >
          Use this template
        </Link>
      </div>
    </div>
  );
};

// Main Component
function WATemplateLibrary({ 
  templates,
  currentPage,
  recordsPerPage,
  onPageChange,
  onLimitChange,
  loading
}) {
  const [activeCard, setActiveCard] = useState(0);
  const [hoveredCard, setHoveredCard] = useState(null);

  if (!templates?.templateData || loading) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-min-h-[200px]">
        <p className="tw-text-gray-500">{loading ? 'Loading...' : 'No templates available'}</p>
      </div>
    );
  }

  return (
    <div className="tw-space-y-6">
      <div className="">
        <div className="tw-grid tw-grid-cols-4 tw-gap-8 tw-w-full">
          {templates.templateData.map((template) => (
            <div key={template.templateId} className="">
              <TemplateCard
                template={template}
                isActive={activeCard === template.templateId}
                onCardClick={setActiveCard}
                isHovered={hoveredCard === template.templateId}
                onHover={() => setHoveredCard(template.templateId)}
                onLeave={() => setHoveredCard(null)}
              />
            </div>
          ))}
        </div>
      </div>

      {templates.totalRecords > 0 && (
        <div className="tw-px-6">
          <Pagination
            currentPage={currentPage}
            totalEntries={templates.totalRecords}
            handlePageChange={onPageChange}
            limit={recordsPerPage}
            onLimitChange={onLimitChange}
          />
        </div>
      )}
    </div>
  );
}

export default WATemplateLibrary;