import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

function ConsultationTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedConsultationStatus, setSelectedConsultationStatus] = useState('All');

    const getStatusColor = (status) => {
        switch (status) {
            case 'Upcoming':
                return '#0066DB';
            case 'Success':
                return '#3E9107';
            case 'Missed':
                return '#E60000';
            default:
                return '#000000';
        }
    };

    const columns = [
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Consultation Status',
            selector: row => row.consultationStatus,
            sortable: true,
            cell: row => (
                <span style={{ color: getStatusColor(row.consultationStatus) }}>
                    {row.consultationStatus}
                </span>
            ),
        },
        {
            name: 'Date Scheduled On',
            selector: row => row.dateScheduledOn,
            sortable: true,
        },
        {
            name: 'Consultation Date',
            selector: row => row.consultationDate,
            sortable: true,
        },
        {
            name: 'Call Duration',
            selector: row => row.callDuration,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => row.action,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const data = [
        {
            id: 1,
            memberName: 'Shabnam Shaikh',
            consultationStatus: 'Upcoming',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '-',
            action: '-',
        },
        {
            id: 2,
            memberName: 'Aaliyah Abeeha',
            consultationStatus: 'Success',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '11:33 mins',
            action: <a href="###" style={{fontSize: '20px'}}><i className="bi bi-play-circle"></i></a>, 
        },
        {
            id: 3,
            memberName: 'Ahmed Amir',
            consultationStatus: 'Missed',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '-',
            action: '-', 
        },
        {
            id: 4,
            memberName: 'Aisha Ahmed',
            consultationStatus: 'Success',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '11:33 mins',
            action: <a href="###" style={{fontSize: '20px'}}><i className="bi bi-play-circle"></i></a>, 
        },
        {
            id: 5,
            memberName: 'Abdul Rehman',
            consultationStatus: 'Missed',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '-',
            action: '-', 
        },
        {
            id: 6,
            memberName: 'Aisha Ahmed',
            consultationStatus: 'Success',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '11:33 mins',
            action: <a href="###" style={{fontSize: '20px'}}><i className="bi bi-play-circle"></i></a>, 
        },
        {
            id: 7,
            memberName: 'Abdul Rehman',
            consultationStatus: 'Missed',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '-',
            action: '-', 
        },
        {
            id: 8,
            memberName: 'Aisha Ahmed',
            consultationStatus: 'Success',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '11:33 mins',
            action: <a href="###" style={{fontSize: '20px'}}><i className="bi bi-play-circle"></i></a>, 
        },
        {
            id: 9,
            memberName: 'Abdul Rehman',
            consultationStatus: 'Missed',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '-',
            action: '-', 
        },
        {
            id: 10,
            memberName: 'Aisha Ahmed',
            consultationStatus: 'Success',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '11:33 mins',
            action: <a href="###" style={{fontSize: '20px'}}><i className="bi bi-play-circle"></i></a>, 
        },
        {
            id: 11,
            memberName: 'Abdul Rehman',
            consultationStatus: 'Missed',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '-',
            action: '-', 
        },
        {
            id: 12,
            memberName: 'Aisha Ahmed',
            consultationStatus: 'Success',
            dateScheduledOn: '04/17/2024',
            consultationDate: '04/23/2024 2:00 pm',
            callDuration: '11:33 mins',
            action: <a href="###" style={{fontSize: '20px'}}><i className="bi bi-play-circle"></i></a>, 
        },
            
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedConsultationStatus('All');
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery = 
            item.memberName.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesStatus = selectedConsultationStatus === 'All' || item.consultationStatus === selectedConsultationStatus;

        return matchesSearchQuery && matchesStatus;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedConsultationStatus}
                            onChange={e => setSelectedConsultationStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Consultation Status--</option>
                            <option value="Upcoming">Upcoming</option>
                            <option value="Success">Success</option>
                            <option value="Missed">Missed</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default ConsultationTable;
