import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from "../../../../../node_modules/highcharts/highcharts-3d";
// import 'bootstrap/dist/css/bootstrap.css';
import { fetchColumnData } from "../../../../services/cn/Service";
import './Column.css';

import highchartsMore from 'highcharts/highcharts-more'; // Import highcharts-more module
import highchartsExporting from 'highcharts/modules/exporting'; // Import exporting module

highcharts3d(Highcharts);
highchartsMore(Highcharts);
highchartsExporting(Highcharts);

const Column = (props) => {
    let ct;
    const [apiResult, setApiResult] = useState([]);

    const columnGraphTitle = (props.columnGraphTitle !== "") ? props.columnGraphTitle : "";
    const columnGraphType = props.columnGraphType;
    const extraParams = (props.extraParams) ? props.extraParams : {};
    const userId = (props.userId) ? props.userId : "";

    extraParams['userId'] = userId;
    useEffect(() => {
        getColumnData();
    }, [columnGraphType])

    const getColumnData = async () => {
        const request = axios.CancelToken.source()
        ct = {
            cancelToken: request.token
        }
        await fetchColumnData(columnGraphType, extraParams, ct)
            .then((response) => {
                if (response.data) {
                    setApiResult(response.data.data);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel()
    }

    // Options for the chart
    const options2 = {
        chart: {
            type: 'column',
            height: 360,
            backgroundColor: 'transparent',
            borderWidth: 0,
            spacing: [0, 0, 30, 10],
            options3d: {
                enabled: true,
                alpha: 0,
                beta: 10,
                depth: 50,
                viewDistance: 25 // Adjusted viewDistance for better 3D effect
            }
        },
        title: {
            text: (columnGraphTitle && columnGraphType != "hra_history") ? columnGraphTitle : null,
            align: 'left',
            style: {
                color: '#333',
                fontSize: '20px',
                fontWeight: '500',
                fontFamily: 'IBM Plex Sans, sans-serif',
            }
        },
        tooltip: {
            enabled: false
        },
        xAxis: {
            categories: (apiResult.xAxis) ? apiResult.xAxis : [],
            labels: {
                style: {
                    fontSize: '14px'
                }
            }
        },
        yAxis: {
            title: {
                text: null
            },
            labels: {
                formatter: function () {
                    return (columnGraphType != "hra_history") ? this.value + '%' : this.value;
                }
            }
        },
        plotOptions: {
            column: {
                borderRadius: 30,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        return (columnGraphType != "hra_history") ? `<span style="background-color:${this.point.color}; padding: 2px 8px; border-radius: 24px; color: #fff; font-size: 14px;">${this.y}%</span>` : `<span style="background-color:${this.point.color}; padding: 2px 8px; border-radius: 24px; color: #fff; font-size: 14px;">${this.y}</span>`
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        colors: ['#1279be'],
        series: (apiResult.series) ? apiResult.series : [],
        legend: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        navigation: {
            buttonOptions: {
                enabled: true,
                symbol: '<i className="bi bi-three-dots"></i>'
            }
        }
    };

    return (
        <div style={{ position: "relative" }} className='mt-2'>
            {
                columnGraphType === "hra_history" &&
                <div className="card-text row align-items-center">
                    <div className='col-auto'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/la8uv/84f1j87shh.png" style={{ height: "36px" }} alt="" />
                    </div>
                    <div className='col ps-0'>
                        <h5 className='card-title'>{columnGraphTitle}</h5>
                        <h5 className='card-subtitle'>{apiResult.heading} <span style={{ color: apiResult.color }}>{apiResult.colorText}</span></h5>
                    </div>
                </div>
            }
            {
                apiResult.series && apiResult.series[0].data.length > 0 ? (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options2}
                    />
                ) : (
                    <>
                        <h1 className='card-title mb-0'>{columnGraphTitle}</h1>
                        <div className="emty-column my-5" style={{ height: "240px" }}>
                            <h5 className="emty-title-graph">There is no data to display</h5>
                        </div>
                    </>
                )
            }

            {
                columnGraphType === "hra_history" &&
                <div className='col-12 mt-3 text-center'>
                    <h5 className='card-subtitle mb-3'><i className={`bi ${apiResult.arrow}`}></i> {apiResult.subText}</h5>
                </div>
            }
        </div>
    );
};

export default Column;
