import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import './Navbar.css';
import LanguageSetting from './LanguageSetting';
import { decruptCipherString, decruptCipherJson } from '../utils/CommonLibrary';
import Notifications from "../cn/components/notifications/Notifications";
import { fetchNotificationsList, updateNotifications } from "../../services/cn/Service";
import { useUserTabs } from '../cn/components/common/UserTabsContext';
import { Commingsoon } from '../cn/components/common/Utils';
import { useSelector } from 'react-redux';

// import logoIcon from '../../images/logo_bupasa.png';

const Navbar = ({ title }) => {

  const webex = useSelector(state => state.webex); 

  const [showBellIcon, setShowBellIcon] = useState(false);
  const [showUnreadDot, setShowUnreadDot] = useState('N');
  const navigate = useNavigate();
  const isSwitch = localStorage.getItem('switch') ? decruptCipherString(localStorage.getItem('switch')) : '';
  const [validUrl, setValidUrl] = useState(isSwitch);
  const menuArr = localStorage.getItem('dashboard') ? localStorage.getItem('dashboard') : '';
  const dashboard = decruptCipherJson(menuArr);

  const [notificationsList, setNotificationsList] = useState([]);


  useEffect(() => {
    if (dashboard.value == 5) {
      setShowBellIcon(true);  
    }
  }, []);

  const { addUserTab } = useUserTabs();
  const handleUserClick = (user) => {
    addUserTab(user);
  };

  const getNotificationsList = async () => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchNotificationsList(ct)
      .then((response) => {
        if (response.data.code === 200) {
          setNotificationsList(response.data.data.notifications);
          setShowUnreadDot(response.data.data.showUnreadDot);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }


  const updateNotificationAction = async (notificationId) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await updateNotifications(notificationId, "actiontaken", ct)
      .then((response) => {
        //getNotifications();
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const handleShowUnreadDot = (ifUnread) => {
    setShowUnreadDot(ifUnread);
  };


  return (
    <>
      <nav className="navbar sticky-top bg-light bg-navbar pt-1">
        <div className="container-fluid mb-2">
          <section style={{ width: "100%" }}>
            <div className="row align-items-center">
              {showBellIcon && <div className="col-auto pe-5">
                <h4 className="mb-0 title-main">
                  <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/j0sal/h87oluwjzj.png" className="logo-bupa-nav pe-2" alt="Profile" />
                </h4>
              </div>}
              <div className="col ps-4 pe-4 text-center">
                <h4 className="mb-0 title-main text-center">{dashboard.text} <span style={{ fontWeight: `${webex.webexLine?'bold':'normal'}` }}>{dashboard.version}</span></h4>
              </div>
              <div className='col-auto d-flex'>
                {showBellIcon &&
                  <>
                    <div className="dropdown-center">
                      <button onClick={getNotificationsList} className="btn btn-secondary dropdown-toggle notification-top-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-bell-fill"></i>
                        {
                          showUnreadDot === "Y" &&
                          <span className="position-absolute top-0 start-100 translate-middle bg-warning border border-1 border-light rounded-circle notification-dot">
                          </span>
                        }
                      </button>
                      <ul className="dropdown-menu dropdown-menu-light p-0">
                        {
                          notificationsList.map((item, index) => {
                            return <>
                              <li key={index} className={`notification-item-border ${item.notificationRead === "Y" ? 'read-message' : 'unread-message'}`}>
                                <a className="dropdown-item">
                                  <div className="notification-item-box py-2">
                                    <div className='row align-items-center'>
                                      <div className='col-2 text-center'>
                                        <i class="bi bi-person-plus-fill notification-icon"></i>
                                      </div>
                                      <div className='col-10 ps-0'>
                                        <h1 className="notification-title"><Link to={'/members/' + encodeURIComponent(item.userId, "UTF-8")} className='' onClick={() => {
                                          updateNotificationAction(item.notificationId);
                                          handleUserClick({ userId: item.userId, name: item.memberName });
                                        }}>{item.memberName}</Link></h1>
                                        <h4 className="notification-subtitle">{item.notificationText}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </>
                          })
                        }

                      </ul>
                    </div>

                    <div className='col-auto pe-4 ps-3'>
                      <button type="button" className="btn message-icon position-relative" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal">
                        <i className="bi bi-chat-left-fill"></i>
                        <span className="position-absolute top-0 start-100 translate-middle bg-warning border border-1 border-light rounded-circle notification-dot">
                        </span>
                      </button>
                    </div>

                  </>}
              </div>
              <div className='col-auto ps-3'>

                <div className='col-auto ps-3'>
                  <div className="dropdown-center">
                    <button className="btn btn-secondary dropdown-toggle profile-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/dhdjh/898otsm9c0.png" className="profile-images" alt="Profile" /> {decruptCipherString(localStorage.getItem('user'))}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-light">
                      {validUrl && <li><Link className="dropdown-item" to={validUrl}><i className="bi bi-card-list"></i> Switch Dashboard</Link></li>}
                      <li><a className="dropdown-item" href='/logout'><i className='bx bx-power-off' style={{ color: "#FF0000" }}></i> Log out</a></li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-auto px-3 text-end">
              <LanguageSetting />
            </div> */}
              </div>
            </div>
          </section>
        </div>
      </nav>
      {title === "cnDashboard" && <Notifications handleShowUnreadDot={handleShowUnreadDot} />}
      <Commingsoon />
    </>
  );
};

export default Navbar;
