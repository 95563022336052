import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { fetch_all_members_reenroll } from '../../../services/met/Service';

function PastRequestReenroll({data}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]); 
    const [selectedProgram, setSelectedProgram] = useState('All');
    const [selectedCondition, setSelectedCondition] = useState('All');
    const [membersEnrollList, setMembersEnrollList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [curentRequestNote, setCurrentRequestNote] = useState("");  
    const navigate = useNavigate();

    useEffect(() => { 
        setFilteredData(data); 
    }, []); 

    useEffect(() => {
        fetchAgentMemberEnrollList();
    }, []); 

     // filtering logic 
     useEffect(() => { 
        let filtered = data.filter((item) => { 
           const matchesSearch = item.memberName.toLowerCase().includes(searchQuery.toLowerCase());
           const matchedMembershipNo = item.membershipNo.toLowerCase().includes(searchQuery.toLowerCase());
           const matchesProgram = selectedProgram === 'All' || item.carePrograms.toLowerCase() === selectedProgram;
           const matchesCondition = selectedCondition === 'All' || item.chronicConditions === selectedCondition;
           return matchesSearch || matchedMembershipNo && matchesProgram && matchesCondition;              
        });

        setFilteredData(filtered);
   }, [searchQuery, selectedProgram, selectedCondition]); 

    const fetchAgentMemberEnrollList = async () => {
        setIsLoading(true);
        try {
            let response = await fetch_all_members_reenroll();
            if (response.data.code === 200) {
                setMembersEnrollList(response.data.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = [
        {
            name: 'Request Date',
            selector: row => row.reEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Requested By',
            selector: row => row.reEnrollRequestBy,
            sortable: true,
        },
        {
            name: 'Request Notes',
            selector: row => 
                <span>
                    {row.reEnrollRequestNote.length   < 100 ? row.reEnrollRequestNote: row.reEnrollRequestNote.slice(0,100) + "..."}
                    { row.reEnrollRequestNote?.length > 100 && <a 
                    onClick={e => setCurrentRequestNote(row.reEnrollRequestNote)}
                    data-bs-toggle="modal" data-bs-target="#notesDisplay"
                    style={{color: 'blue', cursor: 'pointer'}}>  Read More</a>}
                </span>,    
            sortable: true,
        },

        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership No',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Marked Status',
            selector: row => row.previousEnrollmentStatus,
            sortable: true,
        }, 
        {
            name: 'Marked Date',
            selector: row => row.previousAgentActionTime,
            sortable: true,
        },
        {
            name: 'Marked By',
            selector: row => row.previousAgentName,
            sortable: true,
        },
        {
            name: 'Review Date',
            selector: row => row.approvedDate,
            sortable: true,
        },
        {
            name: 'Reviewer',
            selector: row => row.approvedBy,
            sortable: true,
        }, 
        { 
            name: 'Review Notes', 
            selector: row => 
                <span>
                    {row.aprrovedNote.length   < 100 ? row.aprrovedNote: row.aprrovedNote.slice(0,100) + "..."}
                    { row.aprrovedNote?.length > 100 && <a 
                    onClick={e => setCurrentRequestNote(row.aprrovedNote)}
                    data-bs-toggle="modal" data-bs-target="#notesDisplay"
                    style={{color: 'blue', cursor: 'pointer'}}>  Read More</a>}
                </span>,                
            sortable: true  
        },
        {
            name: 'Review Decision',
            selector: row => row.requestStatus,
            sortable: true,
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };  

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('All');
        setSelectedCondition('All');
    };

    

    const handleExport = () => {
        const modifiedData = filteredData.map(item => ({
            'Request Date': item.reEnrollRequestedDate,
            'Requested By': item.reEnrollRequestBy,
            'Request Notes': item.reEnrollRequestNote,
            'Member ID': item.membershipNo,
            'Main Membership No': item.mainMembershipNo,
            'Member Name': item.memberName,
            'Marked Status': item.previousEnrollmentStatus,  
            'Marked Date': item.previousAgentActionTime,
            'Marked By': item.previousAgentName,
            'Review Date': item.approvedDate,
            'Reviewer': item.approvedBy,   
            'Reviewe Notes': item.aprrovedNote,   
            'Review Decision': item.requestStatus,  
        }));

        modifiedData.forEach(v => {
            delete v.approvedBy;
            delete v.approvedDate;
            delete v.logId;
            delete v.mobile;
            delete v.requestStatus;
            delete v.userId;
        });

        exportTableData("past-reenroll-request-data", modifiedData);
    };

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-6 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Member Name or Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    
                    <div className='col-md-6 d-flex justify-content-end'>
                        <div className='col-md-auto px-1'>
                            <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                        </div>
                        <div className='col-md-auto px-1'>
                            <button onClick={handleExport} className="btn btn-primary clear-search-btn">
                                <i className="bi bi-download"></i> Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />



            <div className="modal fade" id="notesDisplay" tabindex="-1" aria-labelledby="notesDisplay" data-bs-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  
                            ></button>
                        </div>
                        <div className="modal-body" style={{wordBreak: 'break-all'}}>
                            <p style={{textAlign: 'justify', padding: '20px 20px', letterSpacing: '1.2px'}}>
                                {curentRequestNote}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default PastRequestReenroll;
