import { initializeApp } from 'firebase/app';


const firebaseConfig = {
  apiKey: "AIzaSyCX-UvtOVwYxZujcLmSdonTTJLLM52cBgE",
  authDomain: "twilliodemo-e898c.firebaseapp.com",
  databaseURL: "https://twilliodemo-e898c-default-rtdb.firebaseio.com/",
  projectId: "twilliodemo-e898c",
  storageBucket: "twilliodemo-e898c.appspot.com",
  messagingSenderId: "965893459698",
  appId: "1:965893459698:web:ae23cc05dc1376503e4a6b"
};

const app = initializeApp(firebaseConfig);


export default app; 