import React from 'react';
import './ProductMaster.css';


function ProductMaster() {

  return (
    <>
      <section className='ProductMaster'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Product Master</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='product-master mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/h4yj5/h4mhrh7hfh.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Upload an image of the "Premium Yoga Mat" to highlight its features. To redeem this product, customers need 800 reward points. This high-quality mat offers superior grip and comfort for all levels of yoga practice</p>
              </div>
            </div>
          </div>

          <div className='product-master p-3'>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Product Name</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Product Name"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Upload Product Image</label>
                    <input type="file" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Upload Product Image"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">MRP</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter MRP"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Points Required to Redeem</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Points Required to Redeem"/>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                <button type="button" id="startProcess" className="btn btn-success uplaod-file-btn">Create Product</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                <hr className='mb-4' />
                <div className="table-responsive">
                <table className="table table-striped mb-0">
                    <thead className='table-dark '>
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Image</th>
                        <th scope="col">MRP</th>
                        <th scope="col">Points</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                            Product Name 1
                            </td>
                            <td>
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f3ys8/z95udjxhse.jpg" className='img-fluid' alt="" />
                            </td>
                            <td>
                            230
                            </td>
                            <td>
                            2000
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                            Product Name 1
                            </td>
                            <td>
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f3ys8/z95udjxhse.jpg" className='img-fluid' alt="" />
                            </td>
                            <td>
                            230
                            </td>
                            <td>
                            2000
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                            <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Product Name"/>
                            </td>
                            <td>
                            <input type="file" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Upload Product Image"/>
                            </td>
                            <td>
                            <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter MRP"/>
                            </td>
                            <td>
                            <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Redeem Point"/>
                            </td>
                            <td>
                                <button className="btn btn-Edit">Save</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                    </tbody>
                    </table>
                    </div>
                    
                </div>
               
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductMaster;
