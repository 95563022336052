import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { decruptCipherJson } from '../../../utils/CommonLibrary';

const UserTabsContext = createContext();

export const UserTabsProvider = ({ children }) => {
    /*const [userTabs, setUserTabs] = useState([]);*/
    const navigate = useNavigate();

    const [userTabs, setUserTabs] = useState(() => {

        const savedTabs = localStorage.getItem('userTabs');
        return savedTabs ? JSON.parse(savedTabs) : [];
    });

    useEffect(() => {

        if (localStorage.getItem('dashboard')) {
            const menuArr = localStorage.getItem('dashboard') ? localStorage.getItem('dashboard') : '';
            const dashboard = decruptCipherJson(menuArr);

            if (dashboard.value == 5) {
                localStorage.setItem('userTabs', JSON.stringify(userTabs));
                if (userTabs.length === 0) {
                    navigate('/dashboard');
                } else {
                    navigate('/members/' + userTabs[userTabs.length - 1].userId)
                }

                let isLoggedIn = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : '';
                if (isLoggedIn === '') {
                    navigate(process.env.REACT_APP_LOGIN_URL);
                }
            }
        }

    }, [userTabs]);

    const addUserTab = (user) => {
        if (!userTabs.some(item => item.userId === user.userId)) {
            setUserTabs((prevTabs) => [...prevTabs, user]);
        }

    };

    const closeUserTab = (user) => {
        setUserTabs((prevTabs) => prevTabs.filter((tab) => tab !== user));
    };

    return (
        <UserTabsContext.Provider value={{ userTabs, addUserTab, closeUserTab }}>
            {children}
        </UserTabsContext.Provider>
    );
};

export const useUserTabs = () => React.useContext(UserTabsContext);
