import React, { useState, useEffect } from 'react';
import "./Dashboard.css"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { fetchHomeBasedServiceTracking } from "../components/services/Service";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CalendarDatePicker from '../../componenents/CalendarDatePicker';
import moment from 'moment';
import { Height, Padding } from '@mui/icons-material';

const ServiceTracking = () => {
    const [membershipNumber, setMembershipNumber] = useState("");
    const [requestType, setRequestType] = useState("");
    const [requestStartDate, setRequestStartDate] = useState(moment());
    const [requestEndDate, setRequestEndDate] = useState(moment());
    const [requestStatus, setRequestStatus] = useState("");
    const [homeBasedServices, setHomeBasedServices] = useState([]);

    const handleDateChange = ({ start, end }) => {
        console.log("Start = ", start, ", End = ", end, ", format start = ", formatDate(start), ", format end = ", formatDate(end));
        setRequestStartDate(start);
        setRequestEndDate(end);
        getHomeBasedServiceTracking(membershipNumber, requestType, formatDate(start), formatDate(end), requestStatus);
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const getHomeBasedServiceTracking = async (membershipNumber, requestType, requestStartDate, requestEndDate, requestStatus) => {
        setHomeBasedServices([]);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        //const formattedStartDate = (requestStartDate !== "") ? moment(requestStartDate).format('YYYY-MM-DD') : "";
        //const formattedEndDate = (requestEndDate !== "") ? moment(requestEndDate).format('YYYY-MM-DD') : "";

        //const formattedStartDate = formatDate(requestStartDate);
        //const formattedEndDate = formatDate(requestEndDate);

        await fetchHomeBasedServiceTracking(membershipNumber, requestType, requestStartDate, requestEndDate, requestStatus, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setHomeBasedServices(response.data.data.homebasedservices);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }
    return <>
        <div class="table-responsive" style={{ height: "480px", overflowX: "hidden", overflowY: "scroll" }}>
            <table className='table table-striped'>
                <thead style={{ position: "sticky", top: "0", backgroundColor: '#fff' }}>
                    <tr>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight:"500", fontSize:"14px" }}>Membership #</th>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight:"500", fontSize:"14px" }}>Request Type</th>
                    </tr>
                    <tr className='mb-2'>
                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }}>
                            <input type="input" name="membershipNumber" value={membershipNumber} onChange={(e) => {
                                if (!/^\d*\.?\d*$/.test(e.target.value)) {
                                    return false;
                                }
                                setMembershipNumber(e.target.value);
                                getHomeBasedServiceTracking(e.target.value, requestType, formatDate(requestStartDate), formatDate(requestEndDate), requestStatus);
                            }
                            } className='form-control control-input-ui-dashboard' />
                        </td>
                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }}>
                            <select name="requestType" className="form-select select-input-ui-dashboard" value={requestType} onChange={(e) => {
                                setRequestType(e.target.value);
                                getHomeBasedServiceTracking(membershipNumber, e.target.value, formatDate(requestStartDate), formatDate(requestEndDate), requestStatus);
                            }}>
                                <option value="">Select</option>
                                <option value="all">All</option>
                                <option value="mr">Medication Requests</option>
                                <option value="lr">Lab Requests</option>
                                <option value="hcr">Home Care Requests</option>
                                <option value="hbv">Home Based Vaccines</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight:"500", fontSize:"14px" }}>Request Date</th>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight:"500", fontSize:"14px" }}>Status</th>
                    </tr>
                    <tr className='mb-2'>
                        
                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }} id="CalendarDatePicker">
                            <CalendarDatePicker startDate={requestStartDate} endDate={requestEndDate} onDateChange={handleDateChange} />
                        </td>
                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }}>
                            <select name="requestStatus" className="form-select select-input-ui-dashboard" value={requestStatus} onChange={(e) => { setRequestStatus(e.target.value); getHomeBasedServiceTracking(membershipNumber, requestType, formatDate(requestStartDate), formatDate(requestEndDate), e.target.value); }}>
                                <option value="">Select</option>
                                <option value="all">All</option>
                                <option value="delayed">Delayed</option>
                                <option value="inprogress">In Progress</option>
                                <option value="completed">Completed</option>
                            </select>
                        </td>
                    </tr>
                    {
                        homeBasedServices.length <= 0 &&
                        <tr><td colSpan={4} style={{ border: "none" }}>No records found</td></tr>
                    }

                </thead>
                <tbody >
                    {
                        homeBasedServices.map((item, index) => {
                            return <tr style={{ fontSize: "14px" }}>
                                <td className='py-2'>{item.membershipNumber}</td>
                                <td>{item.requestType}</td>
                                <td>{item.requestDate}</td>
                                <td className={item.requestStatus}>{item.requestStatus}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default ServiceTracking