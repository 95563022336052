import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

function UploadMemberDateTable({ failData }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const newData = failData.map(item => {
            const parsedValues = JSON.parse(item);
            return {
                status: parsedValues[46],
                reason: parsedValues[45],
                membershipNo: parsedValues[0],
                mainMembershipNo: parsedValues[1],
                memberName: parsedValues[2],
                memberEmail: parsedValues[3],
                memberMobileNo: parsedValues[4],
                gender: parsedValues[5],
                dateofBirth: parsedValues[6],
                city: parsedValues[7],
                memberNetwork: parsedValues[8],
                contractNo: parsedValues[9],
                contractName: parsedValues[10],
                policyStartDate: parsedValues[11],
                policyEndDate: parsedValues[12],
                policyStatus: parsedValues[13],
                policyTotalMembers: parsedValues[14],
                policyMemberType: parsedValues[15],
                hof: parsedValues[16],
                height: parsedValues[17],
                weight: parsedValues[18],
                careProgram: parsedValues[19],
                chronicConditions: parsedValues[20],
                healthRiskCategory: parsedValues[21],
                healthRiskSegment: parsedValues[22],
                personalData: parsedValues[23],
                surgicalHistory: parsedValues[24],
                medicalHistory: parsedValues[25],
                commonChronicProfile: parsedValues[26],
                associatedChronic: parsedValues[27],
                topProvidersVisits: parsedValues[28],
                topSpecialtiesVisits: parsedValues[29],
                topDoctorsVisits: parsedValues[30],
                topConditionsByICD: parsedValues[31],
                opCostPerClaims: parsedValues[32],
                ipCostPerClaims: parsedValues[33],
                totalYtd: parsedValues[34],
                accomodation: parsedValues[35],
                consultation: parsedValues[36],
                medication: parsedValues[37],
                laboratories: parsedValues[38],
                advancedRadiology: parsedValues[39],
                surgicalProcedures: parsedValues[40],
                advancedServices: parsedValues[41],
                dental: parsedValues[42],
                otherServices: parsedValues[43],
            };
        });
        setData(newData);
    }, [failData]);

    const columnDefinitions = [
        { name: 'Status', key: 'status' },
        { name: 'Reason', key: 'reason' },
        { name: 'Membership No', key: 'membershipNo' },
        { name: 'Main Membership No', key: 'mainMembershipNo' },
        { name: 'Member Name', key: 'memberName' },
        { name: 'Member Email', key: 'memberEmail' },
        { name: 'Member Mobile No', key: 'memberMobileNo' },
        { name: 'Gender', key: 'gender' },
        { name: 'Date of Birth', key: 'dateofBirth' },
        { name: 'City', key: 'city' },
        { name: 'Member Network', key: 'memberNetwork' },
        { name: 'Contract No', key: 'contractNo' },
        { name: 'Contract Name', key: 'contractName' },
        { name: 'Policy Start Date', key: 'policyStartDate' },
        { name: 'Policy End Date', key: 'policyEndDate' },
        { name: 'Policy Status', key: 'policyStatus' },
        { name: 'Policy Total Members', key: 'policyTotalMembers' },
        { name: 'Policy Member Type', key: 'policyMemberType' },
        { name: 'HOF', key: 'hof' },
        { name: 'Height (cms.)', key: 'height' },
        { name: 'Weight (lbs.)', key: 'weight' },
        { name: 'Care Program', key: 'careProgram' },
        { name: 'Chronic Conditions', key: 'chronicConditions' },
        { name: 'Health Risk Category', key: 'healthRiskCategory' },
        { name: 'Health Risk Segment', key: 'healthRiskSegment' },
        { name: 'Personal Data', key: 'personalData' },
        { name: 'Surgical History', key: 'surgicalHistory' },
        { name: 'Medical History', key: 'medicalHistory' },
        { name: 'Common Chronic Profile', key: 'commonChronicProfile' },
        { name: 'Associated Chronic', key: 'associatedChronic' },
        { name: 'Top Providers Visits', key: 'topProvidersVisits' },
        { name: 'Top Specialties Visits', key: 'topSpecialtiesVisits' },
        { name: 'Top Doctors Visits', key: 'topDoctorsVisits' },
        { name: 'Top Conditions By ICD', key: 'topConditionsByICD' },
        { name: 'OP Cost Per Claims', key: 'opCostPerClaims' },
        { name: 'IP Cost Per Claims', key: 'ipCostPerClaims' },
        { name: 'Total YTD', key: 'totalYtd' },
        { name: 'Accomodation', key: 'accomodation' },
        { name: 'Consultation', key: 'consultation' },
        { name: 'Medication', key: 'medication' },
        { name: 'Laboratories', key: 'laboratories' },
        { name: 'Advanced Radiology', key: 'advancedRadiology' },
        { name: 'Surgical Procedures', key: 'surgicalProcedures' },
        { name: 'Advanced Services', key: 'advancedServices' },
        { name: 'Dental', key: 'dental' },
        { name: 'Other Services', key: 'otherServices' },

    ];

    const columns = columnDefinitions.map(col => ({
        name: col.name,
        selector: row => row[col.key],
        sortable: true,
        cell: row => (
          <div style={{ color: col.key === 'reason' || col.key === 'status' ? 'red' : 'inherit' }}>
            {col.key === 'reason' && row[col.key]
              ? row[col.key].split(',').map((item, index, array) => (
                  <React.Fragment key={index}>
                    {item}
                    {index < array.length - 1 ? ',' : ''}<br />
                  </React.Fragment>
                ))
              : row[col.key]}
          </div>
        ),
      }));

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationPerPage={10}
            striped
            responsive
            noHeader
            noFooter
            customStyles={customStyles}
        />
    );
}

export default UploadMemberDateTable;
