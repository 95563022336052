import React, { useState } from "react";
import { frequency_configuration_template_creation } from "../../../services/mtp/Service";
import { LoaderLoader } from "../../utils/CommonLibrary";

const maxChars = 200;

function ContentSMS({ labelValue, labelDepth, labelApoinment }) {
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [scheduleSend, setScheduleSend] = useState('');
  const [followUpMessages, setFollowUpMessages] = useState([{ id: 1, message: "", frequency: "", fschedule: "" }]);
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [templateMessage, setTemplateMessage] = useState('');
  const [isScheduleSendButtonVisible, setIsScheduleSendButtonVisible] = useState(true);

  const handleScheduleSendChange = (value) => {
    setScheduleSend(value);
    setIsScheduleSendButtonVisible(false);
  };

//   const handleTextChange = (event) => {
//     setTextareaValue(event.target.value);
//   };

  const handleTextChange = (event) => {
    if(event.target.value.length >= maxChars+1){
      setErrors({textareaValue: `Maximum ${maxChars} characters allowed.` }); 
    }else { 
      setErrors({textareaValue: `` }); 
      setTextareaValue(event.target.value);
    }    
  };

  const handleAddFollowUpMessage = () => {
    //const lastMessage = followUpMessages[followUpMessages.length - 1];
    // if (lastMessage.message.length < 10 || !lastMessage.frequency || !lastMessage.fschedule) {
    //   setErrors({ ...errors, [lastMessage.id]: "Please complete the current follow-up message before adding a new one." });
    //   return;
    // }
    // setErrors({});
    setFollowUpMessages([...followUpMessages, { id: followUpMessages.length + 1, message: "", frequency: "", fschedule: "" }]);
  };

//   const handleFollowUpChange = (id, field, value) => {
//     setFollowUpMessages(
//       followUpMessages.map((message) =>
//         message.id === id ? { ...message, [field]: value } : message
//       )
//     );
//     if (field === 'message' && value.length >= maxChars) {
//       const newErrors = { ...errors };
//       delete newErrors[id];
//       setErrors(newErrors);
//     }
//   };

const handleFollowUpChange = (id, field, value, index=null) => {

    if(value.length >= maxChars+1){
      const newErrors = { ...errors };
      newErrors[`followUp${index}`] = `Maximum ${maxChars} characters allowed.`;
      
      setErrors(newErrors);
      return;
    } 
    else { 
      setErrors({ ...errors, [`followUp${index}`]: "" });
      setFollowUpMessages(
        followUpMessages.map((message) =>
          message.id === id ? { ...message, [field]: value } : message
        )
      );
      if (field === 'message' && value.length >= maxChars) {
        const newErrors = { ...errors };
        delete newErrors[id];
      }
    }
  };


  const validate = () => {
    let newErrors = {};
    if (textareaValue.length === 0) {
      newErrors['textareaValue'] = `Message is required.`;
    }
    if (!selectedFrequency) {
      newErrors['selectedFrequency'] = "Frequency is required.";
    }
    if (!scheduleSend) {
      newErrors['scheduleSend'] = "Schedule send time is required.";
    }
    followUpMessages.forEach((followUp, index) => {
      if (followUp.message.length === 0) {
        newErrors[`followUp${index}`] = `Message is required.`;
      }
      if (!followUp.frequency) {
        newErrors[`frequency${index}`] = "Frequency is required.";
      }
      if (!followUp.fschedule) {
        newErrors[`fschedule${index}`] = "Schedule is required.";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (validate()) {
      try {
        setIsLoading(true);
        const formData = {
          templateId: 0,
          message: textareaValue,
          frequency: selectedFrequency,
          scheduleSend: scheduleSend,
          followUpMessages: followUpMessages,
          labelValue: labelValue,
          labelDepth: labelDepth,
          labelApoinment: labelApoinment,
          type: 'sms',
        };
        let result = await frequency_configuration_template_creation(formData);
        if (result.data.code === 200) {
          setTemplateMessage(result.data.data.message || []);
          setTextareaValue('');
          setSelectedFrequency('');
          setScheduleSend('');
          setFollowUpMessages([{ id: 1, message: "", frequency: "", fschedule: "" }]);
          setErrors({});
          setFormError('');
        } else {
          setTemplateMessage([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error submitting form data:', error);
      }
    } else {
      setFormError(`Please fill in all required fields and ensure all follow-up messages have at least ${maxChars} characters.`);
    }
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-2">
          <label htmlFor='inputPassword6' className="col-form-label">SMS Message</label>
        </div>
        <div className="col-md-10">
          {templateMessage ? (<p style={{ color: 'green', textAlign: 'center' }}>{templateMessage}</p>) : ''}
          <LoaderLoader isLoading={isLoading} />
          <textarea
            className="form-control input-field-ui"
            id="exampleFormControlTextarea1"
            placeholder="Please write the text message you want the member to receive when the Care Navigator consultation is booked."
            rows="3"
            value={textareaValue}
            onChange={handleTextChange}
          ></textarea>
          {errors['textareaValue'] && <div className="text-danger">{errors['textareaValue']}</div>}
          <div className="row mt-3">
            <div className="col-md-4">
              <select
                className={`form-select input-field-ui ${errors['selectedFrequency'] ? 'is-invalid1' : ''}`}
                id="SelectProgram"
                aria-label="Default select example"
                value={selectedFrequency}
                onChange={(e) => setSelectedFrequency(e.target.value)}
              >
                <option value="" disabled>Select Frequency</option>
                <option value="1">1 Time</option>
                <option value="2">2 Times</option>
                <option value="3">3 Times</option>
                <option value="4">4 Times</option>
                <option value="5">5 Times</option>
              </select>
              {errors['selectedFrequency'] && <div className="text-danger">{errors['selectedFrequency']}</div>}
            </div>
            <div className="col-md-4">
              <div className="dropdown">
                {/* <button
                  className={`btn btn-secondary dropdown-toggle Schedule-Send-btn ${errors['scheduleSend'] ? 'is-invalid1' : ''}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-calendar4-week"></i> Schedule Send {scheduleSend}
                </button> */}
                 {isScheduleSendButtonVisible ? (
                                <button
                                    type="button"
                                    className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                     <i className="bi bi-calendar4-week"></i>Schedule Send
                                </button>
                                    ) : (
                                        <button
                                        type="button"
                                        className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">Selected: {scheduleSend}</button>
                                    )}
                <ul className="dropdown-menu dropdown-menu-end">
                  <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('immediate')}>Immediate</font></li>
                  <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('after 15 mins')}>After 15 mins</font></li>
                  <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('after 30 mins')}>After 30 mins</font></li>
                  <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('after 1 hrs')}>After 1 hrs</font></li>
                </ul>
                {errors['scheduleSend'] && <div className="text-danger">{errors['scheduleSend']}</div>}
              </div>
            </div>
            <div className="col-md-4 text-end">
              <h5 className="fw-lighter" style={{fontSize:'16px'}}>{maxChars - textareaValue.length} characters remaining</h5>
            </div>
          </div>
        </div>
      </div>

      {followUpMessages.map((followUp, index) => (
        <div key={followUp.id} className="row mb-3 mt-4">
          <div className="col-md-2">
            <label htmlFor='inputPassword6' className="col-form-label">Message - {followUp.id}</label>
          </div>
          <div className="col-md-10">
            <textarea
              className={`form-control input-field-ui ${errors[`followUp${index}`] ? 'is-invalid1' : ''}`}
              id={`followUpTextarea${followUp.id}`}
              placeholder="Please write the reminder text message you want the member to receive for the Care Navigator consultation."
              value={followUp.message}
              onChange={(e) => handleFollowUpChange(followUp.id, 'message', e.target.value,index)}
              rows="3"
            ></textarea>
            {errors[`followUp${index}`] && <div className="text-danger">{errors[`followUp${index}`]}</div>}
            <div className="row mt-3">
              <div className="col-md-4">
                <select
                  className={`form-select input-field-ui ${errors[`frequency${index}`] ? 'is-invalid1' : ''}`}
                  id="SelectProgram"
                  aria-label="Default select example"
                  value={followUp.frequency}
                  onChange={(e) => handleFollowUpChange(followUp.id, 'frequency', e.target.value)}
                >
                  <option value="" disabled>Select Frequency</option>
                  <option value="1">1 Time</option>
                  <option value="2">2 Times</option>
                  <option value="3">3 Times</option>
                  <option value="4">4 Times</option>
                  <option value="5">5 Times</option>
                </select>
                {errors[`frequency${index}`] && <div className="text-danger">{errors[`frequency${index}`]}</div>}
              </div>
              <div className="col-md-4">
                <div className="dropdown">
                 {/*  <button
                    className={`btn btn-secondary dropdown-toggle Schedule-Send-btn ${errors[`fschedule${index}`] ? 'is-invalid1' : ''}`}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-calendar4-week"></i> Schedule Send {followUp.fschedule}
                  </button> */}
                   {followUp.fschedule ? (
                                <button type="button"
                                className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">Selected: {followUp.fschedule}</button>
                                    ) : (
                                        <button
                                        className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                             <i className="bi bi-calendar4-week"></i> Schedule Send
                                        </button>
                                    )}
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><font className="dropdown-item" href="#" onClick={() => handleFollowUpChange(followUp.id, 'fschedule', 'before 15 mins')}>before 15 mins</font></li>
                    <li><font className="dropdown-item" href="#" onClick={() => handleFollowUpChange(followUp.id, 'fschedule', 'before 30 mins')}>before 30 mins</font></li>
                    <li><font className="dropdown-item" href="#" onClick={() => handleFollowUpChange(followUp.id, 'fschedule', 'before 1 hrs')}>before 1 hrs</font></li>
                  </ul>
                  {errors[`fschedule${index}`] && <div className="text-danger">{errors[`fschedule${index}`]}</div>}
                </div>
              </div>
              <div className="col-md-4 text-end">
                <h5 className="fw-lighter" style={{fontSize:'16px'}}>{maxChars - followUp.message.length} characters remaining</h5>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="row mt-5">
        <div className="col-md-12 text-center">
          <button type="button" className="btn btn-primary Schedule-Send-btn" onClick={handleAddFollowUpMessage}>
            <i className="bi bi-card-checklist me-2"></i> Add More Follow up message
          </button>
        </div>
        {formError && (
          <div className="col-md-12 text-center mt-3 text-danger">
            {formError}
          </div>
        )}
        <div className="col-md-12 text-center mt-4">
          <button type="button" className="btn btn-primary program-save-btn" onClick={handleSave}>Save</button>
        </div>
      </div>
    </>
  );
}

export default ContentSMS;
