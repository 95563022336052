import axios from "axios";
var common_axios = axios.create({});
common_axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("authToken");
    //console.log(token)
    //const uid = localStorage.getItem('uid') ? localStorage.getItem('uid') : '';
    if (token) {
      config.headers["Authorization"] = token;
    }

    config.params = { ...config.params };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

common_axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// Check your Header

common_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 404) {
        window.location.href = "/logout";
      } else if (error.response.status === 401) {
        window.location.href = "/logout";
      } else if (error.response.status === 405) {
        window.location.href = "/no-access";
      }
    }
    //  else {
    //     if (error.message.includes("Network Error")) {
    //         alert("Network Error: Please check your connection.");
    //     } else {
    //         alert("An unknown error occurred. Please try again later.");
    //     }
    // }
    return Promise.reject(error);
  }
);

export default common_axios;
