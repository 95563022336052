import React from 'react';
import './ContentChallenges.css';
import ContentChallengesList from './ContentChallenges/ContentChallengesList';
import ContentCreateNewChallenge from './ContentChallenges/ContentCreateNewChallenge';

function ContentChallenges() {

  return (
    <>
        <section className='ContentChallenges'>

            <ContentChallengesList />
            <ContentCreateNewChallenge/>
            

        </section>
        
    </>
  );
}

export default ContentChallenges;
