import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const options = [
    { label: "CareNavigation (GP)", value: "careNavigation" },
    { label: "Nutritionist consultation (CertifiedDiabeticEducator)", value: "NutritionistConsultation" },
    { label: "Familymedicine consultation", value: "FamilymedicineConsultation" },
    { label: "Ophthalmologist consultation", value: "OphthalmologistConsultation" },
    { label: "Cardiologist consultation", value: "CardiologistConsultation" },
    { label: "Endocrinologist consultation", value: "EndocrinologistConsultation" },
    { label: "Psychiatrist consultation", value: "PsychiatristConsultation" },
    { label: "Psychologist consultation", value: "PsychologistConsultation" },
    { label: "Dietician consultation", value: "DieticianConsultation" },
    { label: "Physiotherapist consultation", value: "PhysiotherapistConsultation" },
    { label: "Podiatrist consultation", value: "PodiatristConsultation" },
    { label: "Pharmacist consultation", value: "PharmacistConsultation" },
    { label: "Dentist consultation", value: "DentistConsultation" },
    { label: "Dermatologist consultation", value: "DermatologistConsultation" },
    { label: "Gynecologist consultation", value: "GynecologistConsultation" },
    { label: "Urologist consultation", value: "UrologistConsultation" },
    { label: "Oncologist consultation", value: "OncologistConsultation" },
    { label: "Hematologist consultation", value: "HematologistConsultation" },
    { label: "Gastroenterologist consultation", value: "GastroenterologistConsultation" },
    { label: "Nephrologist consultation", value: "NephrologistConsultation" },
    { label: "Pulmonologist consultation", value: "PulmonologistConsultation" },
    { label: "Rheumatologist consultation", value: "RheumatologistConsultation" },
    { label: "Neurologist consultation", value: "NeurologistConsultation" },
    { label: "ENT consultation", value: "ENTConsultation" },
    { label: "Ophthalmologist consultation", value: "OphthalmologistConsultation" },
    { label: "Pediatrician consultation", value: "PediatricianConsultation" },
    { label: "Geriatrician consultation", value: "GeriatricianConsultation" },
    { label: "Obstetrician consultation", value: "ObstetricianConsultation" },
    { label: "Anesthesiologist consultation", value: "AnesthesiologistConsultation" },
  ];

function ContentCreateNewChallenge() {

    const [selected, setSelected] = useState([]);

    return (
        <>
            <div className="container-fluid px-0">
                <div className='content-challenges p-3 mb-3'>
                    <div className="row">
                        <div className="col-md-8">
                                <h1 class="main-subtitle mt-3">Create New Challenge</h1>

                                <div className="row">
                                    
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="NameOfChallenge" className="form-label">Name of Challenge</label>
                                        <input type="text" className="form-control input-field-ui" id="NameOfChallenge" placeholder="Eat Healthy"/>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="UploadPicture" className="form-label">Display Image</label>
                                        <div className="row">
                                        <div className="col pe-0">
                                            <input type="file" id="UploadPicture" className="form-control input-field-ui" placeholder='Display Image'/>
                                        </div>
                                        <div className="col-auto ps-1">
                                            <button type="button" className="btn btn-primary uplaod-file-btn">Upload</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="targetMembers" className="form-label">Select Target Members</label>
                                        <MultiSelect
                                                options={options}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select Appointment"
                                                id="targetMembers"
                                            />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="StartDate" className="form-label">StartDate</label>
                                        <input type="date" className="form-control input-field-ui" id="StartDate" placeholder="Start Date"/>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="EndDate" className="form-label">End Date</label>
                                        <input type="date" className="form-control input-field-ui" id="EndDate" placeholder="End Date"/>
                                    </div>

                                    <div className="col-md-12 mb-3"> 
                                        <button type="button" className="btn btn-primary btn-primary program-save-btn">Submit</button> <button type="button" className="btn clear-btn">Clear</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContentCreateNewChallenge;
