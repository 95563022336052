import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

function OngoingChallengesSlider() {


  return (
    <>
      <>
      <Swiper
        slidesPerView={2}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="myOngoingChallengesSlider"
      >
        <SwiperSlide>
            <div className='challenges-box challenge-running'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Walkathon Warriors</h2>
                        <h3 className="challengeSubTitle">Challenger : <span>Hui Shang</span></h3>
                        <h3 className="challengeSubTitle">Participants : <span>5</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-success">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className='challenges-box challenge-running'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Walkathon Warriors</h2>
                        <h3 className="challengeSubTitle">Challenger : <span>Hui Shang</span></h3>
                        <h3 className="challengeSubTitle">Participants : <span>5</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-success">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
            <div className='challenges-box challenge-running'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/xuda7/74cjh854ws.png" alt="Walkathon Warriors" className="challenge-logo mb-2"/>
                        <h2 className="challengeTitle">Walkathon Warriors</h2>
                        <h3 className="challengeSubTitle">Challenger : <span>Hui Shang</span></h3>
                        <h3 className="challengeSubTitle">Participants : <span>5</span></h3>
                        <h3 className="challengeSubTitle">Nov 26 to Dec 30, 2024</h3>
                    </div>
                    <div className='col-md-12 mt-3'>
                    <button type="button" className="small-btn btn btn-outline-success">View Leaderboard</button>
                    <button type="button" className="small-btn btn btn-outline-success">View Team Leaderboard</button>
                    </div>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
    </>
    </>
  );
}

export default OngoingChallengesSlider;
