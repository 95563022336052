import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import './MemberProfile.css';
import TimelineProfile from './TimelineProfile';
import './TimelineProfile.css'; // Import the CSS file
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CustomLoader, exportTableData } from '../utils/CommonLibrary';
import { fetchMember360Profile } from '../../services/medengage/Service';
import { NotificationToast, showToast } from '../componenents/ContentCareNavigator/NotificationToast';


function MemberProfile() {

  const [searchStr, setSearchStr] = useState("");
  const [memberBasicInfo, setMemberBasicInfo] = useState([]);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [memberPathways, setMemberPathways] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [distinctActions, setDistinctActions] = useState([]);
  const [filterText, setFilterText] = useState([]);

  useEffect(() => {
    if (filterText.length === 0) {
      setEvents(allEvents);
    } else {
      setEvents(allEvents.filter(event => filterText.includes(event.action)));
    }
  }, [filterText, allEvents]);

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    const searchString = document.getElementById("searchStr").value.trim();
    setShowUserProfile(false);
    if ((searchStr).trim() !== "") {
      setLoading(true);
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }

      await fetchMember360Profile(searchString)
        .then((response) => {
          if (response.data.code === 200) {
            setShowUserProfile(true);
            setMemberBasicInfo(response.data.data.info);
            setMemberPathways(response.data.data.info.memberPathways);
            setFamilyMembers(response.data.data.info.family);
            setEvents(response.data.data.info.events);
            setAllEvents(response.data.data.info.events);
            const da = [...new Set(response.data.data.info.events.map(event => event.action))];
            setDistinctActions(da);
            console.log("Distinct actions", da);
          } else {
            showToast(response.data.data.message, 'error', 'top-right');
          }
          setLoading(false);
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    } else {
      showToast("Please enter member ID", 'error', 'top-right');
    }
  }

  const handleExport = () => {
    const modifiedData = events.map(item => {
      return {
        'Date': item.readableDate,
        'Action': item.action,
        'Description': item.description
      };
    });
    exportTableData("MemberProfile", modifiedData);
  }

  const handleFamilyMemberProfileClick = (membershipNumber) => {
    setSearchStr(membershipNumber);
    document.getElementById("searchStr").value = membershipNumber.trim();
    document.getElementById("submitButton").click();
  }

  const handleTagClick = (item) => {
    setFilterText((prevFilterText) => {
      if (prevFilterText.includes(item)) {
        return prevFilterText.filter((i) => i !== item);
      } else {
        return [...prevFilterText, item];
      }
    });
  };

  return (
    <>
      {/* Uncomment Navbar if needed */}
      {/* <Navbar /> */}
      <NotificationToast />
      {
        loading === true ? <CustomLoader /> : ""
      }
      <div id="content-wrapper" className="ContentDashboard">
        <div className=" mb-3">
          <div className="container-fluid ">
            <div className='row'>
              <form method="post" id="searchMemberForm" onSubmit={handleSubmit}>
                <div className='col-md-3 mt-3'>
                  <input type="text" className='form-control' placeholder="Search by Member ID" value={searchStr} onChange={(e) => setSearchStr(e.target.value.trim())} name="searchStr" id="searchStr" />
                </div>
                <div className='col-md-3 mt-3'>
                  <button id="submitButton" className="btn btn-primary addPrograms-1-btn" type="submit">Search</button>
                  {' '}
                  {
                    showUserProfile &&
                    <button className="btn btn-primary addPrograms-1-btn" type="button" onClick={() => {
                      handleExport();
                    }}>Export</button>
                  }
                </div>
                <div className='col-md-6 mt-3'></div>
              </form>
            </div>
            {
              showUserProfile === true &&
              <div className='row'>
                <div className='col-md-12 mt-3'>
                  <div className="profile-card-new">
                    <div className='row'>
                      {/* <div className='col-md-auto'>
                      <div className="profile-image-1">
                        <img src="https://appcdn.goqii.com/user/user/l_1437644704.png" alt="Profile Image" />
                      </div>
                    </div> */}
                      <div className='col-md'>
                        <div className='row'>
                          <div className='col-md'>
                            <div className="profile-info">
                              <div className="info-line">
                                <div className="info">
                                  <a className="name">{memberBasicInfo.memberName}</a>
                                  <span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                    {memberBasicInfo.city}</span>
                                </div>
                              </div>

                              <div className="info-line">
                                <div className="info">
                                  <span className="gender big-text me-3">
                                    <i className="" aria-hidden="true">
                                      <img src={memberBasicInfo.gender === "M" ? "https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" : "https://storage.googleapis.com/ksabupatest/2024/09/24/hhqe5/gl8hw4hhl9.png"} style={{ height: "18px" }} alt="" />
                                    </i> {memberBasicInfo.gender === "M" ? "Male" : "Female"} {memberBasicInfo.age}
                                  </span>
                                  <span className="physical big-text me-3">
                                    <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8u7jd/njt3shm0c4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.height} cms
                                  </span>
                                  <span className="physical big-text me-3">
                                    <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4jhy/qhl8vj7lx4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.weight} kgs
                                  </span>
                                  <span className="physical big-text">
                                    <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/zetj7/b4loqjyhqa.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.bmi}
                                  </span>
                                </div>
                              </div>

                              <div className="info-line">
                                <div className="info">
                                  <span className="email"><i className="fa fa-envelope pad-right-10" aria-hidden="true"></i>{memberBasicInfo.email}</span>
                                  <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                    {memberBasicInfo.mobile}</span>
                                  <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                    {memberBasicInfo.altMobile ? memberBasicInfo.altMobile : "-"}</span>

                                </div>
                              </div>
                              <hr className='my-3' />
                              <div className="info-line">
                                <div className="info row">
                                  <h5 className="membership-id col-3 mb-2"><b>Membership:</b> {memberBasicInfo.membershipNumber}</h5>
                                  <h5 className="membership-id col-3 mb-2"><b>Care Navigator:</b> {memberBasicInfo.carenavigatorName ? memberBasicInfo.carenavigatorName : "-"}</h5>
                                  <h5 className="membership-id col-6 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                                </div>
                              </div>
                              <div className="info-line">
                                <div className="info row">
                                  <h5 className="membership-id col-3 mb-2"><b>Main Membership:</b> {memberBasicInfo.mainMembershipNumber}</h5>
                                  <h5 className="membership-id col-3 mb-2"><b>Membership Type:</b> {memberBasicInfo.policyMemberType ? memberBasicInfo.policyMemberType : "-"}</h5>
                                  <h5 className="dates col-6 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                                </div>
                              </div>
                              <div className="info-line">
                                <div className="info row">
                                  <h5 className="dates col-3 mb-2"><b>Policy Start Date:</b> {memberBasicInfo.policyStartDate} </h5>
                                  <h5 className="dates col-3 mb-2"><b>Policy End Date:</b> {memberBasicInfo.policyEndDate}</h5>
                                </div>
                              </div>
                              {/* <div className="info-line">
                              <div className="info row">
                                <h5 className="membership-id col-3 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                                <h5 className="dates col-3 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                              </div>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  {
                    memberPathways.map((item, index) => {
                      return <>
                        <div className='col-md-3 mt-2'>
                          <div className='profile-box-n1'>

                            <div className="info-line row">
                              <div className='col'>
                                <h3 className='profile-card-title mb-2'>{item.programName}</h3>
                                <h5 className="program" ><b>Condition:</b><br /> {item.conditionName}</h5>
                                <div className="info-line mt-2">
                                  <div className='row'>
                                    <div className='col'>
                                      <h5 className="status mb-0"><b>Pathway:</b></h5>
                                      <h5 className="status d-flex">{item.pathwayName}</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div >
                      </>
                    })
                  }
                </div>
                <div className='row'>
                  {
                    familyMembers.map((item, index) => {
                      return <>
                        <div style={{ cursor: 'pointer' }} className='col-md-3 mt-2' onClick={() => handleFamilyMemberProfileClick(item.membershipNumber)}>
                          <div className='profile-box-n1'>
                            <div className="info-line row">
                              <div className='col'>
                                <h3 className='profile-card-title mb-2'>{item.memberName}</h3>
                                <h5 className="program" ><b>Member ID:</b><br /> {item.membershipNumber}</h5>
                              </div>
                            </div>
                          </div>
                        </div >
                      </>
                    })
                  }
                </div>
                {
                  distinctActions.length > 0 &&
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <div className="button-row">
                        <button className="action-button blue-btn" onClick={() => setFilterText([])}>All</button>
                        {
                          distinctActions.map((item, index) => {
                            return <button key={index} className={`action-button blue-btn ${filterText.includes(item) ? "action-btn-active" : ""}`} onClick={() => handleTagClick(item)}>{item}</button>
                          })
                        }
                      </div>
                    </div>
                  </div>
                }

                <div className='col-md-12 my-3'>
                  <div className='comntent-MemberDeEnrollment profile-card-timeline mb-3'>
                    <div className="row">
                      <div className="col-md-12">
                        <div id="container-timeline">
                          <ol className="timeline-profile">
                            {
                              events.map((item, index) => {
                                return <li className="call" >
                                  <time>
                                    <small>{item.readableDate}</small>
                                    <div className="big-profile">{item.action}</div>
                                  </time>
                                  <article>
                                    <p>{item.description}</p>
                                  </article>
                                </li>
                              })
                            }

                          </ol>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberProfile;
