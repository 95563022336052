import React, { useState, useRef, useEffect } from 'react';
import { useUserTabs } from '../components/common/UserTabsContext';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Dashboard.css'
import TopBar from '../components/topbar/TopBar';
import UsersList from '../components/userslist/UsersList';
import Pie from '../components/graphs/Pie';
import Column from '../components/graphs/Column';
import WorkingPlansAndBreaks from '../components/workingplansandbreaks/WorkingPlansAndBreaks';
import TodaysAppointmentProgressBar from './TodaysAppointmentProgressBar';
import ContentAppointments from './ContentAppointments';
import ContentNewReports from './ContentNewReports';
import ContentOtherTasks from './ContentOtherTasks';
import AppointmentSchedule from './AppointmentSchedule';
import { dashboardPendingThisWeek, fetchTodaysAppointmentsCount, fetchWeeklySummary, pathwayBreakup, fetchConditions } from "../../../services/cn/Service";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';
import ServiceTracking from './ServiceTracking';
import {setWebexCalling, setWebexCall, setWebexTransferCall, setIsIncoming, setCallerInfo} from '../../../store/webexSlice';
import { useDispatch, useSelector } from 'react-redux';
import { initWebexCalling } from '../../../store/webexSlice';
import { LoaderLoaderConnect } from '../../utils/CommonLibrary';

const Dashboard = () => {
    const [dashboardPendingResult, setDashboardPendingResult] = useState([]);
    const [todaysAppointmentCountResult, setTodaysAppointmentCountResult] = useState([]);
    const [weeklySummaryResult, setWeeklySummaryResult] = useState([]);
    const [pathwayBreakupResult, setPathwayBreakupResult] = useState([]);
    const [conditions, setConditions] = useState([]);

    const [tasksView, setTasksView] = useState("appointments");
    const [weekCount, setWeekCount] = useState(0);
    const [showWPModal, setShowWPModal] = useState(false);
    const handleCloseWPModal = () => setShowWPModal(false);
    const handleShowWPModal = () => setShowWPModal(true);
    const { userTabs, addUserTab, closeUserTab } = useUserTabs();

    const webex = useSelector(state => state.webex);
    const { webexCalling, webexCall, webexTransferCall, webexLine } = webex;

    const dispatch = useDispatch(); 

    const handleUserClick = (user) => {
        addUserTab(user);
    };


    const handlePlusWeekCount = () => {
        setWeekCount(weekCount + 1);
    }

    const handleMinusWeekCount = () => {
        setWeekCount(weekCount - 1);
    }

    // init webex if not already intiialzied 
    // useEffect(() => { 
    //     if(!webex.webexCalling){ 
    //         dispatch(initWebexCalling()); 
    //     }

        // de registereing  the device 
        // return () =>{ 
        //     if(webexCalling){ 
        //       webexCalling.deregister();
        //     }
        // }
    // }, []);


    // listening for the webex events
    // useEffect(() => { 
    //     if(webex.webexLine){ 
    //         alert('I am listening the event');
    //         webex.webexLine.on('line:incoming_call', (call) =>{

    //             dispatch(setWebexCall(call)); 
    //             dispatch(setIsIncoming(true)); 

    //             call.on('caller_id', (CallerIdEmitter) => { 
    //                 dispatch(setCallerInfo(CallerIdEmitter.callerId)); 
    //             });

    //             call.on('disconnect', () => { 
    //                 dispatch(setIsIncoming(false)); 
    //             }); 

    //             call.on('connect', () => { 
    //                 alert('Hey, I am connected'); 
    //             }); 
    //         });
    //     }
    // }, [webex.webexLine]); 

    useEffect(() => {
        getWeeklySummary();
        //getPathwayBreakup();
        getConditions();
        //getDashboardPendingThisWeek();
        //getTodaysAppointemntsCount();
    }, [weekCount])


    const getPathwayBreakup = async (conditionId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await pathwayBreakup(conditionId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setPathwayBreakupResult(response.data.data.info);
                }

            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getConditions = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchConditions(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setConditions(response.data.data.conditions);
                    if (response.data.data.conditions.length > 0) {
                        getPathwayBreakup(response.data.data.conditions[0]['conditionId']);
                    }
                }

            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getWeeklySummary = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchWeeklySummary(weekCount, ct)
            .then((response) => {
                console.log("summary", response);
                if (response.data.code === 200) {

                    setWeeklySummaryResult(response.data.data);
                }

            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getDashboardPendingThisWeek = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await dashboardPendingThisWeek(ct)
            .then((response) => {
                if (response.data.data.pending)
                    setDashboardPendingResult(response.data.data.pending);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getTodaysAppointemntsCount = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchTodaysAppointmentsCount(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setTodaysAppointmentCountResult(response.data.data);
                }

            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    // initialize webex 
    // async function initWebexCalling(){ 
    //     // setIsWebexDeviceReady(true);

    //     // webex config
    //     const webexConfig = {
    //       config: {
    //         logger: {
    //           level: 'debug' // Set the desired log level
    //         },
    //         meetings: {
    //           reconnection: {
    //             enabled: true
    //           },
    //           enableRtx: true
    //         },
    //         encryption: {
    //           kmsInitialTimeout: 8000,
    //           kmsMaxTimeout: 40000,
    //           batcherMaxCalls: 30,
    //           caroots: null
    //         },
    //         dss: {}
    //       },
    //       credentials: {
    //         access_token: 'ZDA4OTRmMWMtNThhMC00ZDg2LThjOTktZmFiNmU5N2ZkNTg4OGI2ZGZjNDUtNzMx_P0A1_c45bf8a7-9858-433a-8d96-933bc190383e'
    //       }
    //     };

    //     // Create the calling configuration 
    //     const callingConfig = {
    //       clientConfig: {
    //         calling: true,
    //         contact: true,
    //         callHistory: true,
    //         callSettings: true,
    //         voicemail: true
    //       },
    //       callingClientConfig: {
    //         logger: {
    //           level: 'info'
    //         }
    //       },
    //       logger: {
    //         level: 'info'
    //       }
    //     };

    //     let calling = await window.Calling.init({webexConfig, callingConfig});
        
    //     dispatch(setWebexCalling(calling));
      
    //     let callingClient;
    //     calling.on('ready', () => {
    //       calling.register().then(() => {
    //         callingClient = calling.callingClient;
            
    //         let line = Object.values(callingClient.getLines())[0];
    //         dispatch(setWebexLine(line));

    //         // create a device  
    //         line.register(); 
    //         // setIsWebexDeviceReady(false);
            

    //       }).catch(()  => { 
    //         // setIsWebexDeviceReady(false); 
    //         alert('Something went wrong');
    //       });
    //     });
    // }



    return (
        <>
            <div id="content-wrapper" className="ContentDashboard">
                <div className="content-dashboard mb-3">
                    <div className="container-fluid CNDashboardSection">
                        <LoaderLoaderConnect isLoading={webex.isWebexRegistering} />
                        <TopBar active="dashboard" userTabs={userTabs} closeUserTab={closeUserTab} />
                        <div className="box-content dashboard-content-wrapper">
                            <div className='dashboard-content-wrapper-inner'>
                                {/*Left Working Area*/}
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className='card card-task'>
                                            <div className='card-body card-border-orange'>
                                                <div className='card-text'>
                                                    <div className='row align-items-center'>
                                                        {
                                                            weeklySummaryResult &&
                                                            <div className='col'>
                                                                <h5 className='card-title mb-0'>Weekly Task Summary</h5>
                                                                <div className='row align-items-center  mb-2'>
                                                                    {
                                                                        (weekCount > 0) &&
                                                                        <div className='col-auto px-0'>
                                                                            <button type="button" className="btn px-1" onClick={handleMinusWeekCount}>
                                                                                <i className="bi bi-chevron-left"></i>
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    <div className='col-auto'>
                                                                        <h6 className='card-title1'>{weeklySummaryResult.weekStartDate} - {weeklySummaryResult.weekEndDate} </h6>
                                                                    </div>
                                                                    <div className='col-auto px-0'>
                                                                        <button type="button" className="btn px-1" onClick={handlePlusWeekCount}>
                                                                            <i className="bi bi-chevron-right"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>





                                                                <div className='task-list'>
                                                                    <div className='task-item'>
                                                                        <i className="bi bi-telephone-fill card-phone-icon"></i> {weeklySummaryResult.calls}
                                                                    </div>
                                                                    <div className='task-item'>
                                                                        <i className="bi bi-chat-square-dots-fill card-phone-message"></i> {weeklySummaryResult.services}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className='col-auto text-end'>
                                                            {/* <div className='row mb-2 align-items-center'>
                                                        <div className='col-auto'>
                                                            <button type="button" className="btn px-0"><i className="bi bi-chevron-left"></i></button>
                                                        </div>
                                                        <div className='col'>
                                                        <h6 className='card-title1'>14/25</h6>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <button type="button" className="btn px-0"><i className="bi bi-chevron-right"></i></button>
                                                        </div>
                                                    </div> */}
                                                            <div className='card-right-box'>
                                                                <h2 className='mb-0'>{weeklySummaryResult.total}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='card card-task mt-3'>
                                            <div className='card-body'>
                                                <div className='card-text'>
                                                    {/* <div className="no-data-box">
                                                <h5>No data yet. </h5>
                                                <h6 className='card-title1 '>Start engaging with members to see your graphs here.</h6>
                                            </div> */}
                                                    <div className="diabeticdata">
                                                        <>
                                                            <Swiper
                                                                pagination={true}
                                                                navigation={true}
                                                                modules={[Pagination, Navigation]}
                                                                className="mySwiper"
                                                            >
                                                                {
                                                                    conditions.length > 0 &&
                                                                    <SwiperSlide>
                                                                        <Pie pieGraphTitle='Members Stratification' pieGraphType="diabetic_data" conditions={conditions} />
                                                                    </SwiperSlide>
                                                                }
                                                                <SwiperSlide>
                                                                    <Pie pieGraphTitle='Gender Distribution' pieGraphType="gender_distribution" />
                                                                </SwiperSlide>

                                                                <SwiperSlide>
                                                                    <Column columnGraphTitle='Age Distribution' columnGraphType="age_distribution" />
                                                                </SwiperSlide>

                                                                <SwiperSlide>
                                                                    <Column columnGraphTitle='Distribution across cities' columnGraphType="demographic_data" />
                                                                </SwiperSlide>
                                                            </Swiper>
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Col className="">
                                <div className='card card-task'>
                                    <div className='card-body card-border-green'>
                                        <h5 className='card-title mb-4'>Todays Appointment</h5>
                                        <div className='card-text'>
                                            <div className='row align-items-center'>
                                                <div className='col'>
                                                    <TodaysAppointmentProgressBar progress={(todaysAppointmentCountResult.completedCalls ? todaysAppointmentCountResult.completedCalls : 0)} />
                                                </div>
                                                <div className='col-auto'>
                                                    <div className='card-right-box-member'>
                                                        <h2 className='mb-0'><i className="bi bi-person-fill"></i> {(todaysAppointmentCountResult.totalMemberCount ? todaysAppointmentCountResult.totalMemberCount : 0)}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                                    <div className="col ps-1">
                                        <div className='card card-task' style={{ height: "548px" }}>
                                            <div className='card-body p-2'>
                                                <div className="tabs">
                                                    <a className={`tab ${tasksView === "appointments" ? "active-tab" : ""}`} onClick={() => setTasksView("appointments")}>My Appointments</a>
                                                    <a className={`tab ${tasksView === "othertasks" ? "active-tab" : ""}`} onClick={() => setTasksView("othertasks")}>My Tasks</a>
                                                    <a className={`tab ${tasksView === "newreports" ? "active-tab" : ""}`} onClick={() => setTasksView("newreports")}>New Reports</a>
                                                    <a className={`tab ${tasksView === "servicetracking" ? "active-tab" : ""}`} onClick={() => setTasksView("servicetracking")}>Service Tracking</a>
                                                </div>
                                                <div className="content appointments-report">
                                                    <div id="appointments" className={`tab-content ${tasksView === "appointments" ? "" : "hide"}`}>
                                                        <ContentAppointments onUserClick={handleUserClick} />
                                                    </div>
                                                    <div id="tasks" className={`tab-content pb-0 ${tasksView === "othertasks" ? "" : "hide"}`}>
                                                        <ContentOtherTasks onUserClick={handleUserClick} />
                                                    </div>
                                                    <div id="reports" className={`tab-content ${tasksView === "newreports" ? "" : "hide"}`}>
                                                        <ContentNewReports />
                                                    </div>
                                                    <div id="servicetracking" className={`tab-content ${tasksView === "servicetracking" ? "" : "hide"}`}>
                                                        <ServiceTracking />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <div className='dashboard-graph-box-cn'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <div className='card card-task'>
                                                        <div className='card-body'>
                                                            <div className='card-text'>
                                                            <div className='row align-items-center'>
                                                            <div className='col'>
                                                                        <h5 className="card-title mb-0" style={{ fontSize: "20px", color: "#000" }}>Pathway Breakup</h5>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                    <select name="breakupCondition" className=' service-action' style={{width:"190px !important"}} onChange={(e) => {
                                                                            getPathwayBreakup(e.target.value)
                                                                        }}>
                                                                            {
                                                                                conditions.map((item, index) => {
                                                                                    return <option value={item.conditionId}>{item.conditionName}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                            </div>
                                                                <div className='row align-items-center'>
                                                                    
                                                                    <div className='col-12'>
                                                                        <div className="table-responsive mt-3" style={{ borderRadius: "0px" }}>
                                                                            <div className="table-container">
                                                                                <table className="styled-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="bg-white" rowSpan="2">Initial Pathway</th>
                                                                                            {
                                                                                                pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                                                                    return <th key={initialPathwayIndex} className={`${initialPathwayIndex % 2 > 0 ? "table-title bg-lightblue brd-l brd-r" : "table-title"}`} colSpan={pathwayBreakupResult.length}>{initialPathway.pathwayName}</th>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                        <tr>
                                                                                            {
                                                                                                pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                                                                    return <th key={initialPathwayIndex} className={`${initialPathwayIndex % 2 > 0 ? "bg-white table-number brd-l brd-r" : "bg-white table-number"}`} colSpan={pathwayBreakupResult.length}>{initialPathway.initialPathwayCount}</th>
                                                                                                })
                                                                                            }
                                                                                        </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th className="bg-white" rowSpan="3">Current Pathway</th>
                                                                                            {
                                                                                                pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={initialPathwayIndex}>
                                                                                                            {
                                                                                                                initialPathway.currentPathways.map((currentPathway, currentPathwayIndex) => {
                                                                                                                    return (
                                                                                                                        <th key={currentPathwayIndex} className={`${initialPathwayIndex % 2 > 0 ? (currentPathwayIndex === 0) ? "bg-lightblue brd-l" : (currentPathwayIndex === initialPathway.currentPathways.length - 1) ? "bg-lightblue brd-r" : "bg-lightblue" : ""}`}>
                                                                                                                            {currentPathway.pathwayName}
                                                                                                                        </th>
                                                                                                                    );
                                                                                                                })
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                })
                                                                                            }

                                                                                        </tr>
                                                                                        <tr>
                                                                                            {
                                                                                                pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={initialPathwayIndex}>
                                                                                                            {
                                                                                                                initialPathway.currentPathways.map((currentPathway, currentPathwayIndex) => {
                                                                                                                    return (
                                                                                                                        <td>
                                                                                                                            {currentPathway.currentPathwayCount}
                                                                                                                            <br />
                                                                                                                            <span className={`${currentPathway.currentPathwayArrowColor}-text`}>{currentPathway.currentPathwayPerc}% <i className={`bi bi-arrow-${currentPathway.currentPathwayArrow}`}></i></span>
                                                                                                                        </td>
                                                                                                                    );
                                                                                                                })
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                        {/*<tr>
                                                                                            <td>{pathwayBreakupResult.wc2wc}
                                                                                                <br />
                                                                                                <span className="">{pathwayBreakupResult.wc2wcP}%</span>
                                                                                            </td>
                                                                                            <td>{pathwayBreakupResult.wc2ipc}
                                                                                                <br />
                                                                                                <span className="red-text">{pathwayBreakupResult.wc2ipcP}% <i className="bi bi-arrow-down"></i></span>
                                                                                            </td>
                                                                                            <td>{pathwayBreakupResult.wc2ppc}
                                                                                                <br />
                                                                                                <span className="red-text">{pathwayBreakupResult.wc2ppcP}% <i className="bi bi-arrow-down"></i></span>
                                                                                            </td>

                                                                                            <td className="brd-l">{pathwayBreakupResult.ipc2wc}
                                                                                                <br />
                                                                                                <span className="green-text">{pathwayBreakupResult.ipc2wcP}% <i className="bi bi-arrow-up"></i></span>
                                                                                            </td>
                                                                                            <td>{pathwayBreakupResult.ipc2ipc}
                                                                                                <br />
                                                                                                <span className="">{pathwayBreakupResult.ipc2ipcP}%</span>
                                                                                            </td>
                                                                                            <td className="brd-r">{pathwayBreakupResult.ipc2ppc}
                                                                                                <br />
                                                                                                <span className="red-text">{pathwayBreakupResult.ipc2ppcP}% <i className="bi bi-arrow-down"></i></span>
                                                                                            </td>

                                                                                            <td>{pathwayBreakupResult.ppc2wc}
                                                                                                <br />
                                                                                                <span className="green-text">{pathwayBreakupResult.ppc2wcP}% <i className="bi bi-arrow-up"></i></span>
                                                                                            </td>
                                                                                            <td>{pathwayBreakupResult.ppc2ipc}
                                                                                                <br />
                                                                                                <span className="green-text">{pathwayBreakupResult.ppc2ipcP}% <i className="bi bi-arrow-up"></i></span>
                                                                                            </td>
                                                                                            <td>{pathwayBreakupResult.ppc2ppc}
                                                                                                <br />
                                                                                                <span className="">{pathwayBreakupResult.ppc2ppcP}%</span>
                                                                                            </td>
                                                                                        </tr>*/}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className='card card-task'>
                                                    <div className='card-body'>
                                                        <div className='card-text'>

                                                            <div className='row align-items-center'>
                                                                <div className="col-md-12">
                                                                    <div className='row align-items-center calendar-status-text'>
                                                                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div className="legend-box" style={{ background: '#1b75bc', width: '20px', height: '20px', marginRight: '4px' }}></div>
                                                                            <span className="me-3">Scheduled</span>
                                                                        </div>
                                                                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div className="legend-box" style={{ background: '#84bd93', width: '20px', height: '20px', marginRight: '4px' }}></div>
                                                                            <span className="me-3">Completed</span>
                                                                        </div>
                                                                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div className="legend-box" style={{ background: '#FBC525', width: '20px', height: '20px', marginRight: '4px' }}></div>
                                                                            <span className="me-3">Missed</span>
                                                                        </div>
                                                                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div className="legend-box" style={{ background: '#F76E66', width: '20px', height: '20px', marginRight: '4px' }}></div>
                                                                            <span className="me-3">Cancelled/Unsuccessful</span>
                                                                        </div>
                                                                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div className="legend-box" style={{ background: '#C0C0C0', width: '20px', height: '20px', marginRight: '4px' }}></div>
                                                                            <span className="me-3">Member No-Show</span>
                                                                        </div>
                                                                        <div className='col hand-cursor text-end'><button className='btn btn-primary working-plans-btn' onClick={handleShowWPModal} >Working Plans and Breaks</button> </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <AppointmentSchedule onUserClick={handleUserClick} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<Row className='mt-2'>
                            <Col className='box'>
                                <Column />
                            </Col>
                        </Row>*/}
                                {
                                    (showWPModal) ?
                                        <div className='row'>
                                            <div className='col'>
                                                <WorkingPlansAndBreaks showWPModal={showWPModal} handleShowWPModal={handleShowWPModal} handleCloseWPModal={handleCloseWPModal} />
                                            </div>
                                        </div> : ""
                                }
                            </div >


                            <div className='dashboard-content-aside'>
                                {/*Members area*/}
                                <UsersList onUserClick={handleUserClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Dashboard