import React from 'react';
import './UserManagementPage.css';
import ContentUserManagement from '../components/ContentUserManagement'; 

function UserManagementPage() {

  return (
    <>
      <ContentUserManagement/>        
    </>
  );
}

export default UserManagementPage;
