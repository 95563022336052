import React from "react";
import ConsultationTable from "./ConsultationTable";


function ContentCareNavigatorPerformanceList() {

    const stats = [
        { count: '133 (95%)', label: 'Successful Consultation', bgColor: 'bg-lightgreen' },
        { count: 5, label: 'Missed Calls Consultation', bgColor: 'bg-lightblue' },
        { count: 2, label: 'Upcoming Consultation', bgColor: 'bg-lightred' },
      ];
    
    return (
        <>
            <div className="container-fluid px-0 mb-3 ">

                <div className='content-CareNavigatorPerformance mb-3'>
                <div className=" row text-center">
                    {stats.map((stat, index) => (
                        <div className="col" key={index}>
                            <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                {/* <img src={stat.icon} alt={`${stat.label} icon`} className="card-icon" /> */}
                                    <h1 className="card-title">{stat.count.toLocaleString()}</h1>
                                    <h5 className="card-text" dir="rtl">{stat.label}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                </div>

                <div className='content-CareNavigatorPerformance ' style={{marginBottom:"5rem"}}>
                <div className="row">
                    <div className="col-md-12">
                    <div className="table-responsive">
                        <ConsultationTable />
                    </div>
                    </div>
                </div>
                </div>

            </div>

            
        </>
    );
}

export default ContentCareNavigatorPerformanceList;
