import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

// export const fetchProgramList = () => {
//     var dataPass = common_axios.get(baseURL + 'endtoenddashboard/fetch_program_condition_list')
//     return dataPass;
// };

//fetch members per program
export const fetchRcmInsight = (startDateNew,endDateNew,programId,conditionId,formPage) => {
    const params = { 'startRange': startDateNew, 'endRange': endDateNew, 'program': programId, 'condition': conditionId, 'formPage': formPage }
    var dataPass = common_axios.post(baseURL + 'endtoenddashboard/fetch_rcm_view_end_to_end', params)
    return dataPass;
};

export const fetch_role_data_insight = () => {
    var dataPass = common_axios.get( baseURL + 'endtoenddashboard/fetch_role_data');
    return dataPass;
};

export const insight_user_creation_data = (formData) => {
    var dataPass = common_axios.post( baseURL + 'endtoenddashboard/eted_user_creation_data',formData);
    return dataPass;
};

export const fetch_program_data_insight = () => {
    var dataPass = common_axios.get( baseURL + 'endtoenddashboard/fetch_program_data');
    return dataPass;
};

export const fetch_ad_users_list_insight = (searchString) => {
    var requestBody={searchString};
    var dataPass = common_axios.post( baseURL + 'endtoenddashboard/fetch_ad_users',requestBody);
    return dataPass;
};

export const fetch_admin_user_data = () => {
    var dataPass = common_axios.get( baseURL + 'endtoenddashboard/fetch_admin_user_data');
    return dataPass;
};

export const insight_admin_user_update_data = (userId,formData) => {
    var dataPass = common_axios.post( baseURL + 'endtoenddashboard/ete_admin_user_update_data?userId='+userId,formData);
    return dataPass;
};

export const insight_fetch_admin_user_data = (userId) => {
    var dataPass = common_axios.get( baseURL + 'endtoenddashboard/ete_fetch_admin_user_data?userId='+userId);
    return dataPass;
};