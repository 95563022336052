import {configureStore} from '@reduxjs/toolkit';
import webexSlice from './webexSlice'; 
import memberSlice from './memberSlice';


const store = configureStore({
    reducer: {
        webex: webexSlice, 
        member: memberSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export default store; 