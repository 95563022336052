import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import MembesAccrossPathwayMonthsBar from "../componenents/DashboardGraphsCNP/MembesAccrossPathwayMonthsBar";
// import MemberPathwayStatusPie from "../componenents/DashboardGraphsCNP/MemberPathwayAssignedStatusPie";
// import CalendarDatePicker from "../componenents/DashboardGraphsCNP/CalendarDatePicker";
import moment from "moment";
// import { encryptData, decryptData } from "../../";
 import MembesAccrossPathwayMonthsBar from "../componenents/DashboardGraphsCNP/MembesAccrossPathwayMonthsBar";
import MemberPathwayStatusPie from "../componenents/DashboardGraphs/MemberPathwayStatusPie";
import CalendarDatePicker from "../componenents/CalendarDatePicker";
import { encryptData,decryptData } from "../utils/Utils";
import { fetch_count } from '../../services/whatsapp/Service';


const AnalyticsDashboard = () => {
    
  const [loading] = useState(false);
  const [analyticsData,setAnalyticsData]=useState([]);
  const [seriesGraph, setSeriesGraph] = useState(() => {
    // Create arrays for sorting
    const data = [
      { column: "CN-1", value: 5 },
      { column: "CN-3", value: 9 },
      { column: "CN-5", value: 5 },
      { column: "CN-7", value: 6 },
      { column: "CN-9", value: 8 },
      { column: "CN-11", value: 7 },
      { column: "CN-13", value: 9 },
      { column: "CN-15", value: 5 },
      { column: "CN-17", value: 8 },
      { column: "CN-19", value: 7 },
      { column: "CN-21", value: 6 },
      { column: "CN-23", value: 5 },
      { column: "CN-25", value: 7 },
      { column: "CN-27", value: 8 },
      { column: "CN-29", value: 9 },
      { column: "CN-31", value: 8 },
      { column: "CN-33", value: 5 },
      { column: "CN-35", value: 5 },
      { column: "CN-37", value: 7 },
      { column: "CN-39", value: 9 },
    ];

    // Sort the data by value
    const sortedData = [...data].sort((a, b) => b.value - a.value);

    return {
      memberCategory: [],
      memberPathway: [],
      currentPathway: [],
      pathwayMonth: [],
      ageDistribution: [],
      genderMember: [],
      memberTypeFamily: [],
      cityGraph: {
        // columnName: 
        // sortedData.map((item) => item.column),
        // dataValue: sortedData.map((item) => item.value),
        // color: "#2196F3",
        // displayName: "CareNavigator Responses",
      },
      homeCareGraph: [],
      labHealthGraph: [],
      medicationGraph: [],
      hraGraph: [],
      smokingGraph: [],
      stressGraph: [],
      currentEnrollMonth: [],
    };
  });

  // Add these useState hooks at the beginning of your component
  const [whatsAppResponses, setWhatsAppResponses] = useState({
    cityGraph: {
      columnName: ["Deepika", "Pravin"],
      dataValue: [2.0, 0],
      color: "#0066CC",
      displayName: "WhatsApp Engagement Admin Responses",
    },
  });

  const [avgResponseTime, setAvgResponseTime] = useState({
    cityGraph: {
      columnName: ["Deepika", "Pravin"],
      dataValue: [5, 0],
      color: "#003366",
      displayName: "Avg Response Time (min)",
    },
  });

  const [templateBreakdown, setTemplateBreakdown] = useState({
    memberTypeFamily: [
      { name: "Demo_Template", y: 90, color: "#2196F3" },
      { name: "Others", y: 10, color: "#0A2472" },
    ],
  });

  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });

  const [coordinatorData] = useState([
    {
      name: "Deepika",
      conversations: 2,
      responded: 2,
      pending: 0,
      waitTime: 5,
    },
    {
      name: "Pravin",
      conversations: 1,
      responded: 0,
      pending: 1,
      waitTime: 0,
    },
  ]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <i className="fa fa-sort tw-ml-2 tw-text-gray-400"></i>;
    }
    return sortConfig.direction === 'asc' ? 
      <i className="fa fa-sort-up tw-ml-2"></i> : 
      <i className="fa fa-sort-down tw-ml-2"></i>;
  };

  const getSortedData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  // Get sorted data
  const sortedData = getSortedData(coordinatorData);

  // Add this handler function
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Mock the localStorage for the date range picker
  useEffect(() => {
    const mockStoredDates = [
      moment().subtract(7, "days"),
      moment(),
      moment().startOf("year"),
      moment(),
    ];
    localStorage.setItem(
      "selectedDates",
      encryptData(JSON.stringify(mockStoredDates))
    );
  }, []);

  

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const fetchData = async (start, end) => {
    try {
        const response = await fetch_count({ startDate: start,endDate:end }); // Pass the current page to the API
        if (response.data.code==200 && response.data.data.insights.length > 0) {
            setAnalyticsData(response.data.data.insights); 
        } 
      // Assuming the API returns an array of data objects that you need for your dashboard
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(formatDate(startDate), formatDate(endDate))
  }, [startDate, endDate]); // Trigger when either startDate or endDate changes

  return (
    <div className="container-fluid tw-mb-16">
      <div className="tw-sticky tw-top-12 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
  <div className="tw-w-full  tw-pb-3">
    <div className="tw-flex tw-items-center tw-justify-between">
      <h4 className="tw-text-gray-600 tw-m-0">Engagement Analytics</h4>
      <div className="tw-w-[300px]">
        <CalendarDatePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />
      </div>
    </div>
  </div>
</div>

      <div className="reports-programs mb-3">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <img
              src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png"
              alt="Invited Members icon"
              style={{ height: "140px" }}
              className="img-fluid mb-0"
            />
          </div>
          <div className="col-md">
            <p className="mb-3">
              The Engagememt Analytics Dashboard provides key performance
              metrics for Engagement Admins and CareNavigators, including total conversations,
              responses, pending responses, and opt-outs. It features
              visualizations like wait time, average response time, opt-out
              trends, and template usage. A performance table with detailed
              metrics and tooltips helps team leads monitor efficiency and
              member engagement.
            </p>
          </div>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mt-2">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px] tw-h-full">
              <div className="tw-p-2">
                <div className="tw-flex tw-flex-col">
                  <div>
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value}
                    </h1>
                    <p className="tw-text-base tw-text-gray-500 tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                CareNavigator Responses
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Total number of CareNavigator responses
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {loading ? (
              <></>
            ) : seriesGraph.cityGraph.dataValue ? (
              <MembesAccrossPathwayMonthsBar
                dataMonth={seriesGraph.cityGraph}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by CareNavigator
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average response time by care navigator
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {loading ? (
              <></>
            ) : seriesGraph.cityGraph.dataValue ? (
              <MembesAccrossPathwayMonthsBar
                dataMonth={seriesGraph.cityGraph}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-3 tw-gap-4">
        {/* WhatsApp Coordinator Responses Graph */}
        <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                WhatsApp Engagement Admin Responses (2)
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      WhatsApp Engagement Admin response distribution
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : (
              whatsAppResponses.cityGraph && (
                <MembesAccrossPathwayMonthsBar
                  dataMonth={whatsAppResponses.cityGraph}
                />
              )
            )}
          </div>
        </div>

        {/* Average Response Time Graph */}
        <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by WhatsApp Engagement Admin
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average response time by Engagement Admin
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : (
              avgResponseTime.cityGraph && (
                <MembesAccrossPathwayMonthsBar
                  dataMonth={avgResponseTime.cityGraph}
                />
              )
            )}
          </div>
        </div>

        {/* Template Breakdown Pie Chart */}
        <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : (
              templateBreakdown.memberTypeFamily && (
                <MemberPathwayStatusPie
                  series={templateBreakdown.memberTypeFamily}
                />
              )
            )}
          </div>
        </div>
      </div>

      {/* Care Coordinator Performance Table */}
      <div className="tw-bg-white tw-rounded-xl tw-shadow-md tw-p-6 tw-mt-4">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-6">
          <h1 className="tw-text-xl tw-font-medium tw-text-gray-500">
          Engagement Admin Performance Summary
          </h1>
          {/* <div className="tw-flex tw-gap-2">
      <input 
        type="text" 
        placeholder="Search By Name or Email"
        className="tw-px-4 tw-py-2 tw-border tw-border-gray-200 tw-rounded-lg tw-w-64"
      />
      <select className="tw-px-4 tw-py-2 tw-border tw-border-gray-200 tw-rounded-lg tw-w-48">
        <option value="">Select Role</option>
      </select>
      <select className="tw-px-4 tw-py-2 tw-border tw-border-gray-200 tw-rounded-lg tw-w-48">
        <option value="">--Select Status--</option>
      </select>
      <button className="tw-px-6 tw-py-2 tw-bg-[#002C43] tw-text-white tw-rounded-lg">
        Clear
      </button>
    </div> */}
        </div>

        <div className="tw-overflow-x-auto tw-rounded-lg tw-shadow">
          <table className="tw-w-full tw-border-collapse">
            <thead>
              <tr className="tw-bg-[#d4e6f0]">
                <th 
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('name')}
                >
                  <div className="tw-flex tw-items-center">
                    Engagement Admin
                    {getSortIcon('name')}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Name of the Engagement Admin handling conversations.</Tooltip>}
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th 
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('conversations')}
                >
                  <div className="tw-flex tw-items-center">
                    Total Conversations
                    {getSortIcon('conversations')}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Total number of conversations initiated with the Engagement Admin.</Tooltip>}
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th 
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('responded')}
                >
                  <div className="tw-flex tw-items-center">
                    Total Responded
                    {getSortIcon('responded')}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Number of conversations where the Engagement Admin responded at least once.</Tooltip>}
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th 
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('pending')}
                >
                  <div className="tw-flex tw-items-center">
                    Pending Responses
                    {getSortIcon('pending')}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Number of conversations awaiting a response from the Engagement Admin.</Tooltip>}
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th 
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort('waitTime')}
                >
                  <div className="tw-flex tw-items-center">
                    Average Wait Time (mins)
                    {getSortIcon('waitTime')}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Average time spent on each conversation by the Engagement Admin.</Tooltip>}
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((coordinator, index) => (
                <tr key={index} className={`${index % 2 === 0 ? "" : "tw-bg-gray-100"} tw-text-sm`}>
                  <td className="tw-px-4 tw-py-3">{coordinator.name}</td>
                  <td className="tw-px-4 tw-py-3">{coordinator.conversations}</td>
                  <td className="tw-px-4 tw-py-3">{coordinator.responded}</td>
                  <td className="tw-px-4 tw-py-3">{coordinator.pending}</td>
                  <td className="tw-px-4 tw-py-3">{coordinator.waitTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div className="rows-per-page">
            <label>Rows per page: </label>
            <select>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
            </select>
          </div>

          <div className="records-info">
            5 of 50 records
          </div>

          <div className="pagination-controls tw-mb-3.5">
            <button>
              <i className="bx bx-first-page"></i>
            </button>
            <button>
              <i className="bx bx-chevron-left"></i>
            </button>
            <button>
              <i className="bx bx-chevron-right"></i>
            </button>
            <button>
              <i className="bx bx-last-page"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;