import React, { useState, useEffect,useCallback,useRef } from "react";
import { useUserTabs } from "../../components/common/UserTabsContext";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Chat.css";
import { BiCheckDouble, BiCheck  } from "react-icons/bi";

import { fetch_chat,check_status } from "../services/Service";

const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };
  
  const CustomTooltip = ({ text, visible }) => {
    if (!visible) return null;
    
    return (
      <div 
        className="tw-absolute tw-right-[70%] tw-top-1/2 tw--translate-y-1/2 tw-mr-2 tw-z-[9999] tw-pointer-events-none"
      >
        <div className="tw-bg-[#1279BE] tw-text-white tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-whitespace-nowrap tw-relative">
          {text}
          {/* Arrow pointing right */}
          <div className="tw-absolute tw-right-[-6px] tw-top-1/2 tw--translate-y-1/2 tw-w-0 tw-h-0 tw-border-l-4 tw-border-y-4 tw-border-solid tw-border-y-transparent tw-border-l-[#1279BE]" />
        </div>
      </div>
    );
  };

const Chat = ({ userId }) => {
  const [chatPage, setChatpage] = useState(1);
  const [chatResult, setChatResult] = useState([]);
  const [loadingMore, setLoadingMore] = useState(true); // Loading state
  const [id, setId] = useState(""); // Loading state
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [newMessageCount, setNewMessageCount] = useState(0); // New message count
  const [initialMessageCount, setInitialMessageCount] = useState(0); // Track initial message count
  const previousMessageCountRef = useRef(initialMessageCount);
  const [showNewMessageAlert, setShowNewMessageAlert] = useState(false); // Track initial message count


  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null); // Ref for chat container
  const chatEndRef = useRef(null);
  const previousScrollHeightRef = useRef(0);
  useEffect(() => {
    if (!userId) return;
    setId(userId);
  }, []);

    
  
  useEffect(() => {
    // Fetch initial message count if it hasn’t been set yet
    const fetchInitialMessageCount = async () => {
      try {
        if(!id) return
        const response = await check_status({ id });
        if(response.data.code==200){
          const initialCount = response.data.data.msgCount;
          setInitialMessageCount(initialCount);
          previousMessageCountRef.current = initialCount; // Set the ref to this initial value
        }
        
      } catch (error) {
        console.error("Failed to fetch initial message count:", error);
      }
    };

    // Only fetch if initialMessageCount is unset or selected contact changes
    if (initialMessageCount === 0 && id) {
      fetchInitialMessageCount();
    }
  }, [id, initialMessageCount, setInitialMessageCount]);

  useEffect(() => {
    // Check for new messages based on current and previous message count
    const checkForNewMessages = async () => {
      try {
        if(!id) return;
        const response = await check_status({ id });
        if(response.data.code==200){
          const newMessageCount = response.data.data.msgCount;
          console.log('previousMessageCountRef.current',previousMessageCountRef.current)
          if (newMessageCount > previousMessageCountRef.current) {
            console.log('New messages detected!');
            const diff=newMessageCount-previousMessageCountRef.current;
            setNewMessageCount(diff); // Set initial message count
            previousMessageCountRef.current = newMessageCount; // Set the ref to this initial value
            setInitialMessageCount(newMessageCount);
            setShowNewMessageAlert(true)
            setChatpage(1); // Call API on Enter
            setTimeout(() => {
              setChatResult([]);
              getchat(id, 1); // Fetch chat for the new message
              setNewMessageCount(0)
            }, 1000);
  
            setTimeout(() => setShowNewMessageAlert(false), 3000);
          }
        }
        
      } catch (error) {
        console.error("Failed to check for new messages:", error);
      }
    };

    // Set up polling for new messages every 15 seconds
    const interval = setInterval(() => checkForNewMessages(), 15000);
    return () => clearInterval(interval);
  }, [id, setInitialMessageCount]);

  const debouncedGetChat = useCallback(
    debounce((id, page) => {
      getchat(id, page);
    }, 500), // 500ms delay
    [] // Empty dependency array since getchat is likely coming from props
  );

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;
    
    const container = chatContainerRef.current;
    const scrollPosition = Math.round(container.scrollTop);
    const totalHeight = Math.round(container.scrollHeight);
    const visibleHeight = Math.round(container.clientHeight);
    const threshold = 50;
    console.log(chatPage,'chatpage')
    // Check if user is at the top (for loading more messages)
    if (scrollPosition <= 10) {
      previousScrollHeightRef.current = totalHeight;
      if (chatPage > 1) {
        debouncedGetChat(id, chatPage);
      }
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }

    // Check if user is near bottom
    const distanceFromBottom = totalHeight - (scrollPosition + visibleHeight);
    const isAtBottom = distanceFromBottom <= threshold;
    
    // Update states based on scroll position
    setIsAtBottom(isAtBottom);
    setShowScrollButton(!isAtBottom);
  }, [chatPage, id]); // Add dependencies as needed

  console.log(id, "userId22");

  
  const debouncedHandleScroll = useCallback(
    debounce(() => {
      handleScroll();
    }, 100), // 100ms delay for scroll handling
    [handleScroll]
  );

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        container.removeEventListener('scroll', debouncedHandleScroll);
        // Clean up any pending debounced calls
        debouncedHandleScroll.cancel?.();
        debouncedGetChat.cancel?.();
      };
    }
  }, [debouncedHandleScroll, debouncedGetChat]);

  

  const scrollToBottom = () => {
    alert("in bttom")
    if (!chatEndRef.current || !chatContainerRef.current) return;
    
    const container = chatContainerRef.current;
    const targetPosition = chatEndRef.current.offsetTop;
    const startPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const targetHeight = chatEndRef.current.clientHeight;
    
    // Calculate the exact position to scroll to
    const scrollDistance = targetPosition - startPosition - containerHeight + targetHeight + 32; // adding padding
    
    try {
      container.scrollTo({
        top: container.scrollTop + scrollDistance,
        behavior: 'instant' // Use 'instant' for initial load
      });
    } catch (error) {
      // Fallback for older browsers
      container.scrollTop = container.scrollTop + scrollDistance;
    }
  };

  

  

  const getchat = async (id, newStartPage) => {
    try {
      const response = await fetch_chat(id, newStartPage); // Pass the current page to the API
      if (response.data.data.chat && response.data.data.chat.length > 0) {
        setChatResult((prevChats) => [
          ...response.data.data.chat,
          ...prevChats,
        ]); // Prepend new messages
        setChatpage(newStartPage + 1); // Update the current page
        // setLoadingMore(true);
      } else {
        setLoadingMore(false); // No more data to load
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    }
  };

  useEffect(() => {
    if (!id) return;

    const loadChatAndStatus = async () => {
      try {
        console.log("inside chat");
        setChatResult([]); // Clear previous chat messages
        setLoadingMore(true); // Set loading state
        await getchat(id, 1); // Load chat messages
        setLoadingMore(false); // Reset loading statep
        
      } catch (error) {
        console.error("Failed to load chat and status:", error);
        setLoadingMore(false);
      }
    };

    loadChatAndStatus();
  }, [id]);

  const getMessageStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      
      case 'sent':
        return <BiCheck className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case 'delivered':
      case 'unread':
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case 'read':
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-blue-500" />;
      case 'undelivered':
      case 'failed':
        return <i className="bi bi-info-circle tw-w-4 tw-h-4 tw-text-red-500"></i>;
      case 'queued':
      default:
        return <i className="bi bi-clock-history tw-text-gray-500"></i>;
    }
  };

  const replaceVariables = (content) => {
    if (!content) return '';
    
    return content
      // Handle line breaks first
      .replace(/\\n/g, '<br />')
      
      // Bold: *text* (not preceded or followed by space)
      .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
      
      // Italic: _text_ (not preceded or followed by space)
      .replace(/(?<!\s)_(.*?)_(?!\s)/g, '<em>$1</em>')
      
      // Strikethrough: ~text~ (not preceded or followed by space)
      .replace(/(?<!\s)~(.*?)~(?!\s)/g, '<del>$1</del>')
      
      // Monospace: ```text``` 
      .replace(/```(.*?)```/g, '<code>$1</code>')
      
      // Single line code: `text`
      .replace(/`(.*?)`/g, '<code>$1</code>')
  
      // URLs: automatically link URLs
      .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">$1</a>')
  
      // WhatsApp style quotes: > at start of line
      .replace(/(^|\n)&gt; ?([^\n]*)/g, '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>')
      
      // Lists: - or • at start of line
      .replace(/(^|\n)[-•] ?([^\n]*)/g, '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>')
      
      // Handle multiple underscores or asterisks in a single word
      .replace(/(\*{2}|_{2})(.*?)\1/g, '<strong>$2</strong>')
      .replace(/(\*{3}|_{3})(.*?)\1/g, '<strong><em>$2</em></strong>')
      
      // Fix any potentially broken HTML from nested formatting
      .replace(/<(\/?)(strong|em|del|code)>/g, '<$1$2>');
  };

  return (
    <>
      <div className="chatDiv">
        <Row>
          <div className="tw-h-[71vh] tw-flex tw-flex-col tw-bg-gray-50">
            {/* Header */}
            <div className="tw-bg-white tw-border-b tw-px-4 tw-py-2 tw-flex tw-justify-between tw-items-center">
              <div className="tw-flex tw-items-start tw-flex-col">
                <h1 className="tw-font-bold tw-text-xl">Name</h1>
                <h2 className="tw-font-bold tw-text-sm tw-text-gray-500">
                  {/* {selectedContact[0]['mobile']} */}
                  XXXXXXXXXX
                </h2>
              </div>
              <span
                //   onClick={refreshchat}
                className="tw-inline-flex tw-items-center tw-mt-2 tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
              >
                <i className="bi bi-arrow-clockwise tw-text-lg"></i>
              </span>
            </div>

            {/* Chat Body */}
            {/* <div className="tw-flex-1 tw-overflow-y-auto tw-p-4">
        <div className="tw-space-y-6">
          <div className="tw-flex tw-justify-center">
            <div className="tw-bg-blue-50 tw-text-gray-600 tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm">
              This conversation will be ended if you don't reply stop
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 9:19 AM</div>
            </div>
          </div>

          
          <div className="tw-flex tw-justify-end tw-items-start tw-gap-2">
            <div className="tw-flex tw-flex-col tw-items-end">
              <div className="tw-bg-blue-50 tw-rounded-lg tw-px-4 tw-py-2 tw-shadow-sm">
                hi
              </div>
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 12:52 PM</div>
            </div>
            <div className="tw-w-8 tw-h-8 tw-bg-blue-900 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white">
              S
            </div>
          </div>

          <div className="tw-flex tw-items-start tw-gap-2">
            <div className="tw-w-8 tw-h-8 tw-bg-blue-900 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white">
              S
            </div>
            <div className="tw-flex tw-flex-col">
              <div className="tw-bg-white tw-rounded-lg tw-px-4 tw-py-2 tw-shadow-sm">
                Hello
              </div>
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 2:29 PM</div>
            </div>
          </div>
          <div className="tw-flex tw-items-start tw-gap-2">
            <div className="tw-w-8 tw-h-8 tw-bg-blue-900 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white">
              S
            </div>
            <div className="tw-flex tw-flex-col">
              <div className="tw-bg-white tw-rounded-lg tw-px-4 tw-py-2 tw-shadow-sm">
                Hello
              </div>
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 2:29 PM</div>
            </div>
          </div>
        </div>
      </div> */}

            {/* Chat messages */}
            {/* <div className='tw-'> */}
            <div
              onScroll={handleScroll}
              ref={chatContainerRef}
              className="tw-flex-1 tw-overflow-y-auto tw-p-4 tw-relative tw-bg-gray-100"
              style={{
                minHeight: 0, // Important for proper flex behavior
                scrollbarWidth: "thin",
                scrollbarColor: "#CBD5E0 #F3F4F6",
              }}
            >
              {chatResult.length > 0 &&
                chatResult.map((message, index) => (
                  <React.Fragment>
                    <div
                      key={message.id}
                      className={`tw-mb-4 tw-gap-2 tw-flex ${
                        message.senderType === "agent" ? "tw-justify-end" : ""
                      }`}
                      // ref={message.lastElement === true ? chatEndRef : null}
                      ref={index === chatResult.length - 1 ? chatEndRef : null}
                    >
                      {/* User message */}
                      {message.senderType === "user" && (
                        <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-2 tw-max-w-[300px]">
                          <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
                            {message.sender
                              .split(" ")[0]
                              .charAt(0)
                              .toUpperCase()}
                          </div>
                          <div className="tw-flex tw-flex-col tw-w-full tw-items-start tw-justify-start tw-relative tw-z-10 tw-text-xs">
                            {/* Reply Preview */}

                            {/* {message.originalId ? (
        <div className="tw-absolute -tw-bottom-12 -tw-z-10 border tw-w-full tw-max-w-[142px] tw-pb-4 tw-pt-1 tw-pl-1 tw-rounded-lg tw-flex tw-items-center tw-gap-2 tw-mt-2 tw-bg-gray-200">
          <i class="bi bi-reply-all-fill"></i>
          <p className='tw-mb-0'>Reply</p>
        </div>
      ) : null } */}

                            {/* <div className={`${message.originalId ? "tw-flex tw-flex-row" : ""}`}> */}
                            {message.originalId ? (
                              <>
                                {message.headerType.toLowerCase() ===
                                  "image" && (
                                  <div className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2 hello">
                                    <img
                                      src={message.mediaUrl}
                                      alt={message.templateName}
                                      className="tw-w-full tw-h-full tw-object-cover tw-rounded"
                                    />
                                  </div>
                                )}
                                {/* {message.headerType.toLowerCase() === "text" && (
               <span className="tw-text-sm">{message.replyText}</span>
             )} */}
                              </>
                            ) : (
                              <>
                                {message.msgType === "image" && (
                                  <div className="tw-max-w-[300px]">
                                    <div
                                      className={`tw-bg-white tw-rounded-t-lg ${
                                        message.originalText
                                          ? "border tw-border-b-0"
                                          : ""
                                      } tw-p-1 tw-shadow-sm`}
                                    >
                                      <img
                                        src={message.imgUrl}
                                        alt="image"
                                        className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg"
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {message.originalId ? null : (
                              <>
                                {message.msgType === "audio" && (
                                  <div
                                    className={`tw-bg-white tw-rounded-t-lg ${
                                      message.originalText ? "border" : ""
                                    } tw-p-2 tw-shadow-sm tw-max-w-[280px]`}
                                  >
                                    <div className="tw-flex tw-items-center tw-gap-2">
                                      <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                                        <i className="bi bi-mic-fill tw-text-white"></i>
                                      </div>
                                      <audio
                                        controls
                                        className="tw-w-full tw-h-[36px]"
                                      >
                                        <source
                                          src={message.imgUrl}
                                          type="audio/mpeg"
                                        />
                                      </audio>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {message.originalId ? (
                              <>
                                {message.headerType.toLowerCase() ===
                                  "video" && (
                                  <video
                                    className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2"
                                    controls
                                  >
                                    <source
                                      src={message.mediaUrl}
                                      type="video/mp4"
                                    />
                                  </video>
                                )}
                              </>
                            ) : (
                              <>
                                {message.msgType === "video" && (
                                  <div className="tw-max-w-[300px]">
                                    <div
                                      className={`tw-bg-white ${
                                        message.originalText ? "border" : ""
                                      } tw-rounded-t-lg tw-p-1 tw-shadow-sm`}
                                    >
                                      <video
                                        controls
                                        src={message.imgUrl}
                                        className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            {message.originalId ? (
                              <>
                                {message.headerType.toLowerCase() ===
                                  "document" && (
                                  <div className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2">
                                    <svg
                                      className="tw-w-6 tw-h-6"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                      />
                                    </svg>
                                    <span className="tw-text-sm">
                                      Document attached
                                    </span>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {message.msgType === "document" && (
                                  <div
                                    className={`tw-bg-white ${
                                      message.originalText ? "border" : ""
                                    } tw-rounded-t-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden`}
                                  >
                                    <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50">
                                      <div className="tw-flex-shrink-0">
                                        <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                                      </div>
                                      <div className="tw-flex-1 tw-min-w-0">
                                        <div className="tw-truncate tw-text-sm">
                                          Document
                                        </div>
                                        <div className="tw-text-xs tw-text-gray-500">
                                          PDF
                                        </div>
                                      </div>
                                    </div>
                                    {message.mimeType === "application/pdf" ? (
                                      <iframe
                                        src={message.imgUrl}
                                        title="PDF Document"
                                        className="tw-w-full tw-h-[200px]"
                                      />
                                    ) : (
                                      <a
                                        href={message.imgUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-gray-50"
                                      >
                                        View Document
                                      </a>
                                    )}
                                  </div>
                                )}
                              </>
                            )}

                            {!/\.(pdf|csv|jpeg|mp4)$/i.test(
                              message.messageText
                            ) &&
                              message.messageText && (
                                <div className="tw-max-w-[300px] tw-w-[300px]">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: message.originalId
                                        ? replaceVariables(message.originalText)
                                        : message.messageText
                                            .split("")
                                            .reduce((result, char, index) => {
                                              if (
                                                index > 0 &&
                                                index % 25 === 0
                                              ) {
                                                return result + "\u200B" + char;
                                              }
                                              return result + char;
                                            }, ""),
                                    }}
                                    className={`
        tw-bg-white tw-p-2.5 tw-shadow-sm tw-break-words tw-whitespace-pre-wrap
        ${(() => {
          // If this is a reply message
          if (message.originalId) {
            // If there's a header type (image, video, etc)
            if (message.headerType) {
              return "border tw-border-t-0 tw-border-b-0";
            }
            // If it's just text reply
            return "border tw-rounded-t-lg";
          }

          // If there's a reply text but not a reply message
          if (message.originalText) {
            // If there's media above
            if (
              message.msgType === "image" ||
              message.msgType === "video" ||
              message.msgType === "document" ||
              message.msgType === "audio"
            ) {
              return "border tw-border-t-0 tw-rounded-b-lg";
            }
            return "border tw-rounded-lg";
          }

          // Regular message
          // Check if there's only imgUrl without messageText
          if (message.imgUrl && !message.messageText) {
            return "border tw-border-t-0 tw-rounded-b-lg";
          }
          return "tw-rounded-lg";
        })()}
      `}
                                  />
                                </div>
                              )}
                            {/* </div> */}
                            {message.originalId && (
                              <div className="tw-max-w-[300px] tw-w-full tw-mb-1">
                                <div
                                  className={`tw-bg-gray-200 tw-rounded-b-lg tw-p-2 tw-border-l-4 tw-border-[#03335b]`}
                                >
                                  <div className="tw-text-xs tw-font-medium tw-text-[#03335b] tw-mb-1">
                                    {message.originalId ? message.sender : ""}
                                  </div>
                                  <div className="">
                                    {message.msgType === "image" && (
                                      <div className="tw-max-w-[300px]">
                                        <div
                                          className={`tw-bg-white ${
                                            message.originalId === ""
                                              ? "tw-rounded-lg"
                                              : "border tw-border-b-0 tw-rounded-t-lg"
                                          } tw-p-1 tw-shadow-sm`}
                                        >
                                          <img
                                            src={message.imgUrl}
                                            alt="image"
                                            className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg"
                                          />
                                        </div>
                                      </div>
                                    )}

                                    {message.msgType === "audio" && (
                                      <div
                                        className={`tw-bg-white tw-rounded-t-lg ${
                                          message.originalText ? "border" : ""
                                        } tw-p-2 tw-shadow-sm tw-max-w-[280px]`}
                                      >
                                        <div className="tw-flex tw-items-center tw-gap-2">
                                          <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                                            <i className="bi bi-mic-fill tw-text-white"></i>
                                          </div>
                                          <audio
                                            controls
                                            className="tw-w-full tw-h-[36px]"
                                          >
                                            <source
                                              src={message.imgUrl}
                                              type="audio/mpeg"
                                            />
                                          </audio>
                                        </div>
                                      </div>
                                    )}

                                    {message.msgType === "video" && (
                                      <div className="tw-max-w-[300px]">
                                        <div
                                          className={`tw-bg-white ${
                                            message.originalText ? "border" : ""
                                          } tw-rounded-t-lg tw-p-1 tw-shadow-sm`}
                                        >
                                          <video
                                            controls
                                            src={message.imgUrl}
                                            className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
                                          />
                                        </div>
                                      </div>
                                    )}

                                    {message.msgType === "document" && (
                                      <div
                                        className={`tw-bg-white ${
                                          message.originalText ? "border" : ""
                                        } tw-rounded-t-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden`}
                                      >
                                        <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50">
                                          <div className="tw-flex-shrink-0">
                                            <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                                          </div>
                                          <div className="tw-flex-1 tw-min-w-0">
                                            <div className="tw-truncate tw-text-sm">
                                              Document
                                            </div>
                                            <div className="tw-text-xs tw-text-gray-500">
                                              PDF
                                            </div>
                                          </div>
                                        </div>
                                        {message.mimeType ===
                                        "application/pdf" ? (
                                          <iframe
                                            src={message.imgUrl}
                                            title="PDF Document"
                                            className="tw-w-full tw-h-[200px]"
                                          />
                                        ) : (
                                          <a
                                            href={message.imgUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-gray-50"
                                          >
                                            View Document
                                          </a>
                                        )}
                                      </div>
                                    )}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: message.messageText,
                                      }}
                                      className="tw-text-base tw-text-gray-600 tw-truncate"
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="tw-text-xs tw-text-gray-500 tw-mt-1 tw-ml-1">
                              {message.logDateTime}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <span className='tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-mx-auto'>{message.day ? message.day : '' }</span> */}

                      {/* Agent message */}
                      {message.senderType === "agent" && (
                        <>
                          <div
                            className={`tw-max-w-[300px] ${
                              message.messageStatus === "undelivered" ||
                              message.messageStatus === "failed"
                                ? "tw-flex tw-items-center tw-gap-2"
                                : ""
                            } `}
                          >
                            {message.messageStatus === "undelivered" ||
                            message.messageStatus === "failed" ? (
                              <div className="tw-relative">
                                <div
                                  className="tw-cursor-pointer"
                                  onMouseEnter={() =>
                                    setActiveTooltip(message.id)
                                  }
                                  onClick={() => setActiveTooltip(message.id)}
                                  // onMouseLeave={() => setActiveTooltip(null)}
                                >
                                  {getMessageStatusIcon(message.messageStatus)}
                                </div>

                                {activeTooltip === message.id && (
                                  <div
                                    className="tw-absolute tw-z-[99999] tw-w-64 tw-h-72 tw-overflow-y-auto tw-right-[19px] tw-bottom-[0px] tw-bg-white tw-rounded-lg tw-border tw-shadow-lg tw-p-4"
                                    onMouseEnter={() =>
                                      setActiveTooltip(message.id)
                                    }
                                    onMouseLeave={() => setActiveTooltip(null)}
                                    style={{
                                      scrollbarWidth: "thin",
                                      scrollbarColor: "#CBD5E0 #F3F4F6",
                                    }}
                                  >
                                    <div className="tw-relative">
                                      <p className="tw-text-sm tw-font-bold tw-text-red-600 tw-mb-2">
                                        ERROR: {message.errorCode}
                                      </p>
                                      <p className="tw-text-sm tw-text-gray-600 tw-mb-3">
                                        {message.description}
                                      </p>
                                      <div className="tw-flex tw-flex-col tw-gap-2">
                                        <span className="tw-text-sm tw-text-wrap">
                                          <a
                                            href={`https://www.twilio.com/docs/api/errors/${message.errorCode}`}
                                            className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            View Error Details
                                          </a>
                                          about Error code{" "}
                                          <strong>{message.errorCode}</strong>
                                        </span>
                                        <span className="tw-text-sm tw-break-words">
                                          Possible Solution: {message.solution}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null}
                            <div>
                              {message.msgType === "image" && (
                                <div className="tw-max-w-[300px]">
                                  <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-1 tw-shadow-sm">
                                    <img
                                      src={message.imgUrl}
                                      alt="image"
                                      className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg"
                                    />
                                  </div>
                                </div>
                              )}

                              {message.msgType === "audio" && (
                                <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-2 tw-shadow-sm tw-max-w-[280px]">
                                  <div className="tw-flex tw-items-center tw-gap-2">
                                    <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                                      <i className="bi bi-mic-fill tw-text-white"></i>
                                    </div>
                                    <audio
                                      controls
                                      className="tw-w-full tw-h-[36px]"
                                    >
                                      <source
                                        src={message.imgUrl}
                                        type="audio/mpeg"
                                      />
                                    </audio>
                                  </div>
                                </div>
                              )}

                              {message.msgType === "video" && (
                                <div className="tw-max-w-[300px]">
                                  <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-1 tw-shadow-sm">
                                    <video
                                      controls
                                      src={message.imgUrl}
                                      className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
                                    />
                                  </div>
                                </div>
                              )}

                              {message.msgType === "document" && (
                                <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden">
                                  <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-sky-100">
                                    <div className="tw-flex-shrink-0">
                                      <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                                    </div>
                                    <div className="tw-flex-1 tw-min-w-0">
                                      <div className="tw-truncate tw-text-sm">
                                        Document
                                      </div>
                                      <div className="tw-text-xs tw-text-gray-500">
                                        PDF
                                      </div>
                                    </div>
                                  </div>
                                  {message.mimeType === "application/pdf" ? (
                                    <iframe
                                      src={message.imgUrl}
                                      title="PDF Document"
                                      className="tw-w-full tw-h-[200px]"
                                    />
                                  ) : (
                                    <a
                                      href={message.imgUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-sky-200"
                                    >
                                      View Document
                                    </a>
                                  )}
                                </div>
                              )}

                              {!/\.(pdf|csv|jpeg|mp4)$/i.test(
                                message.messageText
                              ) && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: replaceVariables(
                                      message.messageText
                                    ),
                                  }}
                                  className="tw-bg-[#f0f4ff] tw-rounded-b-lg tw-p-2.5 tw-shadow-sm tw-break-words tw-whitespace-pre-wrap"
                                ></div>
                              )}

                              <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-mt-1 tw-mr-1">
                                <span className="tw-text-xs tw-text-gray-500">
                                  {" "}
                                  {message.logDateTime}
                                </span>
                                {message.messageStatus === "undelivered" ||
                                message.messageStatus === "failed"
                                  ? null
                                  : getMessageStatusIcon(message.messageStatus)}
                              </div>
                            </div>
                          </div>

                          <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
                            {message.sender
                              .split(" ")[0]
                              .charAt(0)
                              .toUpperCase()}
                          </div>
                        </>
                      )}
                    </div>
                  </React.Fragment>
                ))}
              {showScrollButton && (
                <span
                  onClick={scrollToBottom}
                  className="tw-sticky tw-float-right tw-bottom-0 tw-right-0 tw-bg-[#1279BE] tw-text-white tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-shadow-lg hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-z-50"
                  aria-label="Scroll to bottom"
                >
                  {newMessageCount ? (
                    newMessageCount
                  ) : (
                    <i className="bi bi-chevron-down tw-text-lg"></i>
                  )}
                </span>
              )}
            </div>

            {/* Footer Input */}
            <div className="tw-bg-white">
              <div className="tw-relative">
                <textarea
                  placeholder="Reply here"
                  className="tw-w-full tw-pr-24 tw-pl-4 tw-py-3 tw-border tw-rounded-lg tw-resize-none tw-h-[100px]"
                />
                <div className="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-w-10 tw-flex tw-flex-col tw-justify-between tw-py-2">
                  {/* Emoji Picker */}
                  <div className="tw-relative tw-h-6 tw-flex tw-items-center tw-justify-center">
                    {/* <OverlayTrigger placement="left" overlay={<Tooltip>Emoji</Tooltip>}> */}
                    <span
                      // onMouseEnter={() => setActiveTooltip('emoji')}
                      // onMouseLeave={() => setActiveTooltip(null)}
                      //   onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      className="tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-select-none"
                    >
                      😊
                    </span>
                    {/* <CustomTooltip 
              text="Emoji"
              visible={activeTooltip === 'emoji'}
            /> */}
                    {/* </OverlayTrigger> */}
                    {/* {showEmojiPicker && (
            <div 
              ref={emojiPickerRef} 
              className="tw-absolute tw-right-10 tw-bottom-0 tw-z-50"
            >
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )} */}
                  </div>

                  {/* Attachment */}
                  <div className="tw-h-8 tw-flex tw-items-center tw-justify-center">
                    {/* <OverlayTrigger placement="left" overlay={<Tooltip>Attachment</Tooltip>}> */}
                    <span
                      // onMouseEnter={() => setActiveTooltip('attachment')}
                      // onMouseLeave={() => setActiveTooltip(null)}
                      //   onClick={() => fileInputRef.current?.click()}
                      className="tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors"
                    >
                      <i className="bi bi-paperclip tw-rotate-45" />
                      {/* <CustomTooltip 
              text="Attachment"
              visible={activeTooltip === 'attachment'}
            /> */}
                      <input
                        // ref={fileInputRef}
                        type="file"
                        className="tw-hidden"
                        accept="image/*,application/pdf"
                        // onChange={handleImageChange}
                      />
                    </span>
                    {/* </OverlayTrigger> */}
                  </div>

                  {/* Template */}
                  <div className="tw-h-8 tw-flex tw-items-center tw-justify-center">
                    {/* <OverlayTrigger placement="left" overlay={<Tooltip>Select Template</Tooltip>}> */}
                    <span
                      // onMouseEnter={() => setActiveTooltip('template')}
                      // onMouseLeave={() => setActiveTooltip(null)}
                      //   onClick={() => setShowTemplatePopup(true)}
                      className="tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-relative"
                    >
                      <i className="bi bi-stack" />
                      {/* <CustomTooltip 
              text="Select Template"
              visible={activeTooltip === 'template'}
            /> */}
                    </span>
                    {/* </OverlayTrigger> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Row> */}
          {/* </Col> */}
        </Row>
      </div>
    </>
  );
};

export default Chat;
