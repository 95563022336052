import React from 'react';
import './ContentCareNavigatorPerformance.css';
import ContentCareNavigatorPerformanceDetail from './ContentCareNavigatorPerformance/ContentCareNavigatorPerformanceDetail';

function ContentCareNavigatorPerformance() {

  return (
    <>
        <section className='ReportsPrograms'>

            <ContentCareNavigatorPerformanceDetail />

        </section>

        
    </>
  );
}

export default ContentCareNavigatorPerformance;
