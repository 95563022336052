import React from 'react';
import DataTable from 'react-data-table-component';
import DropDownActionNew from "./../../../pages/componenents/ContentPrograms/DropDownActionNew";
import { Link } from 'react-router-dom';

function ReportsDateTable({ logData }) {
    const columns = [
        {
            name: 'Date Uploaded',
            selector: row => row.dateUploaded,
            sortable: true,
        },
        {
            name: 'Uploaded Member Records',
            selector: row => row.uploadedMembers,
            sortable: true,
        },
        {
            name: 'Successful Migration',
            selector: row => row.successfullyUploaded,
            sortable: true,
        },
        // {
        //     name: 'Migration Failed',
        //     selector: row => row.failed,
        //     sortable: true,
        // },
        {
            name: 'Onboarded Members',
            selector: row => row.enrolledMembers,
            sortable: true,
        },
        // {
        //     name: 'Untouched Members',
        //     selector: row => row.notEnrolled,
        //     sortable: true,
        // },
        {
            name: 'Uploaded By',
            selector: row => row.uploadedBy,
            sortable: true,
        },
        {
            name: 'Upload Notes',
            selector: row => row.notes,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.approvalStatus,
            sortable: true,
            cell: row => (
                <span style={{ color: row.color }}>
                    {row.approvalStatus}
                </span>
            ),
        },
        {
            name: 'Reviewed By',
            selector: row => row.lastActionBy,
            sortable: true,
        },
        {
            name: 'Review Date',
            selector: row => row.lastActionDate,
            sortable: true,
        },
        {
            name: 'Review Notes',
            selector: row => row.remarks,
            sortable: true,
        },
        {
            name: 'Options',
            selector: row => row.uploadId.id,
            sortable: true,
            cell: row => (
                <>
                    <Link to={`/file-insight/${row.uploadId.id}`} className='btn btn-Edit mx-3'><i className="bi bi-eye"></i></Link>
                    |
                    <DropDownActionNew uploadId={row.uploadId} />
                </>
            ),
        }
        // {
        //     name: 'View',
        //     selector: row => row.uploadId.id,
        //     sortable: true,
        //     cell: row => (
        //         <>
        //             <Link to={`/file-insight/${row.uploadId.id}`} className='btn btn-primary back-btn mb-0'>
        //                 View
        //             </Link>
        //         </>
        //     ),
        // },
        // {
        //     name: 'Download',
        //     selector: row => row.uploadId,
        //     sortable: true,
        //     cell: row => <DropDownActionNew uploadId={row.uploadId}/>,
        // },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    return (
        <DataTable
            columns={columns}
            data={logData}
            pagination
            paginationPerPage={10}
            striped
            responsive
            noHeader
            customStyles={customStyles}
        />
    );
}

export default ReportsDateTable;
