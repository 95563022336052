import React, { useEffect, useState } from "react";
import { fetch_whatsapp_template_data, frequency_configuration_template_creation, single_whatsapp_template } from "../../../services/mtp/Service";
import { LoaderLoader } from "../../utils/CommonLibrary";

function ContentWhatsapp({labelValue,labelDepth,labelApoinment}) {
    const [whatsAppTemplate, setWhatsAppTemplateData] = useState([]);
    const [singleWhatsAppTemplateData, setSingleWhatsAppTemplateData] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [templateFrequency, setTemplateFrequency] = useState('');
    const [scheduleSend, setScheduleSend] = useState('');
    const [followUpMessages, setFollowUpMessages] = useState([{ message: '', frequency: '', fschedule: '', templateId: '' }]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [templateMessage, setTemplateMessage] = useState('');
    const [isScheduleSendButtonVisible, setIsScheduleSendButtonVisible] = useState(true);

    useEffect(() => {
        fetchWhatAppsTemplateData();
    }, []);

    const fetchWhatAppsTemplateData = async () => {
        try {
            const response = await fetch_whatsapp_template_data();
            setWhatsAppTemplateData(response.data.data || []);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        templateSelection(value);
    };

    const handleFrequencyChange = (event) => {
        const value = event.target.value;
        setTemplateFrequency(value);
    };

    const handleScheduleSendChange = (value) => {
        setScheduleSend(value);
        setIsScheduleSendButtonVisible(false);
    };

    const handleFollowUpMessageChange = (index, event) => {
        const newFollowUpMessages = [...followUpMessages];
        newFollowUpMessages[index].message = event.target.value;
        setFollowUpMessages(newFollowUpMessages);
    };

    const handleFollowUpFrequencyChange = (index, event) => {
        const newFollowUpMessages = [...followUpMessages];
        newFollowUpMessages[index].frequency = event.target.value;
        setFollowUpMessages(newFollowUpMessages);
    };

    const handleFollowUpScheduleChange = (index, value) => {
        const newFollowUpMessages = [...followUpMessages];
        newFollowUpMessages[index].fschedule = value;
        setFollowUpMessages(newFollowUpMessages);
    };

    const handleFollowUpTemplateChange = async (index, event) => {
        const newFollowUpMessages = [...followUpMessages];
        const templateId = event.target.value;
        newFollowUpMessages[index].templateId = templateId;

        try {
            setIsLoading(true);
            const resp = await single_whatsapp_template(templateId);
            if (resp.data.code === 200) {
                newFollowUpMessages[index].message = resp.data.data[0].templateName || '';
            } else {
                newFollowUpMessages[index].message = '';
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }

        setFollowUpMessages(newFollowUpMessages);
    };

    const addFollowUpMessage = () => {
        setFollowUpMessages([...followUpMessages, { message: '', frequency: '', fschedule: '', templateId: '' }]);
    };

    const removeFollowUpMessage = (index) => {
        const newFollowUpMessages = [...followUpMessages];
        newFollowUpMessages.splice(index, 1);
        setFollowUpMessages(newFollowUpMessages);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!selectedValue) {
            newErrors.selectedValue = "Template selection is required";
        }

        if (singleWhatsAppTemplateData.trim().length < 10) {
            newErrors.singleWhatsAppTemplateData = "WhatsApp message must be at least 10 characters long";
        }

        if (!templateFrequency) {
            newErrors.templateFrequency = "Template frequency is required";
        }

        if (!scheduleSend) {
            newErrors.scheduleSend = "Schedule send is required";
        }

        followUpMessages.forEach((followUp, index) => {
            if (followUp.message.trim().length < 2) {
                newErrors[`followUpMessage${index}`] = `Follow-up message ${index + 1} must be at least 2 characters long`;
            }
            if (!followUp.frequency) {
                newErrors[`followUpFrequency${index}`] = `Frequency for follow-up message ${index + 1} is required`;
            }
            if (!followUp.fschedule) {
                newErrors[`followUpSchedule${index}`] = `Schedule send for follow-up message ${index + 1} is required`;
            }
            if (!followUp.templateId) {
                newErrors[`followUpTemplate${index}`] = `Template selection for follow-up message ${index + 1} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const templateSelection = async (templateId) => {
        try {
            setIsLoading(true);
            const resp = await single_whatsapp_template(templateId);
            if (resp.data.code === 200) {
                setSingleWhatsAppTemplateData(resp.data.data[0].templateName || '');
            } else {
                setSingleWhatsAppTemplateData('');
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!validateForm()) {
            return;
        }
        setIsLoading(true);
        const formData = {
            templateId: selectedValue,
            message: singleWhatsAppTemplateData,
            frequency: templateFrequency,
            scheduleSend: scheduleSend,
            followUpMessages: followUpMessages,
            labelValue:labelValue,
            labelDepth:labelDepth,
            labelApoinment:labelApoinment,
            type:'whatsApp',
        };

        try {
            // Submit the formData to your backend service here
            let result = await frequency_configuration_template_creation(formData);
            if (result.data.code === 200) {
                setTemplateMessage(result.data.data.message || []);
                setSelectedValue();
                setSingleWhatsAppTemplateData('');
                setTemplateFrequency('');
                setScheduleSend('');
                setFollowUpMessages([{ message: '', frequency: '', fschedule: '', templateId: ''  }]);
                setErrors({});

            } else {
                setTemplateMessage([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {templateMessage ? (<p style={{color:'green',textAlign:'center'}}>{templateMessage}</p>) : ''}
            <div className="row mt-3">
                <div className="col-md-2">
                    <label htmlFor='templateId' className="col-form-label">Template</label>
                </div>
                <div className="col-md-10">
                <LoaderLoader isLoading={isLoading} />
                    <select
                        className="form-select input-field-ui"
                        id="templateId"
                        value={selectedValue}
                        aria-label="Default select example"
                        onChange={handleChange}
                    >
                        <option value="">Select Template</option>
                        {whatsAppTemplate.map(option => (
                            <option key={option.templateId} value={option.templateId}>{option.whatsAppTemplateId}</option>
                        ))}
                    </select>
                    {errors.selectedValue && <div className="text-danger">{errors.selectedValue}</div>}
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col-md-2">
                    <label htmlFor='whatsappMessage' className="col-form-label">Booking Message</label>
                </div>
                <div className="col-md-10">
                    <textarea
                        className="form-control input-field-ui"
                        id="whatsappMessage"
                        placeholder="Please select Template to see Whatsapp Message Text."
                        rows="3"
                        value={singleWhatsAppTemplateData}
                        onChange={(e) => setSingleWhatsAppTemplateData(e.target.value)}
                        readOnly="readOnly"
                        disabled
                    />
                    {errors.singleWhatsAppTemplateData && <div className="text-danger">{errors.singleWhatsAppTemplateData}</div>}
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <select
                                className="form-select input-field-ui"
                                id="templateFrequency"
                                name="templateFrequency"
                                value={templateFrequency}
                                aria-label="Default select example"
                                onChange={handleFrequencyChange}
                            >
                                <option value="">Select Frequency</option>
                                <option value="1">1 Time</option>
                                <option value="2">2 Times</option>
                                <option value="3">3 Times</option>
                                <option value="4">4 Times</option>
                                <option value="5">5 Times</option>
                            </select>
                            {errors.templateFrequency && <div className="text-danger">{errors.templateFrequency}</div>}
                        </div>
                        <div className="col-md-4">
                            <div className="dropdown">
                                {/* <button
                                    className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="bi bi-calendar4-week"></i> Schedule Send {scheduleSend}
                                </button> */}

                                  {isScheduleSendButtonVisible ? (
                                <button
                                    type="button"
                                    className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                     <i className="bi bi-calendar4-week"></i>Schedule Send
                                </button>
                                    ) : (
                                        <button
                                        type="button"
                                        className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">Selected: {scheduleSend}</button>
                                    )}


                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('immediate')}>Immediate</font></li>
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('after 15 mins')}>After 15 mins</font></li>
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('after 30 mins')}>After 30 mins</font></li>
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('after 1 hour')}>After 1 hour</font></li>
                                    {/* <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('12 hour')}>12 hour</font></li>
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('24 hour')}>24 hour</font></li>
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('day')}>Day</font></li>
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('week')}>Week</font></li>
                                    <li><font className="dropdown-item" href="#" onClick={() => handleScheduleSendChange('month')}>Month</font></li> */}
                                </ul>
                            </div>
                            {errors.scheduleSend && <div className="text-danger">{errors.scheduleSend}</div>}
                        </div>
                    </div>
                </div>
            </div>

            {followUpMessages.map((followUp, index) => (
                <div key={index} className="row mb-3">

<div className="row mt-3">
                <div className="col-md-2">
                    <label htmlFor='templateId' className="col-form-label">Template {index + 1}</label>
                </div>
                    <div className="col-md-10">
                                <select
                                    className="form-select input-field-ui"
                                    id={`followUpTemplate${index}`}
                                    value={followUp.templateId}
                                    onChange={(e) => handleFollowUpTemplateChange(index, e)}
                                >
                                    <option value="">Select Template</option>
                                    {whatsAppTemplate.map(option => (
                                        <option key={option.templateId} value={option.templateId}>{option.whatsAppTemplateId}</option>
                                    ))}
                                </select>
                                {errors[`followUpTemplate${index}`] && <div className="text-danger">{errors[`followUpTemplate${index}`]}</div>}
                            </div>
                    </div>


                    <div className="col-md-2 mt-3">
                        <label htmlFor={`followUpMessage${index}`} className="col-form-label">Follow-up Message</label>
                    </div>
                    <div className="col-md-10 mt-3">
                        <textarea
                            className="form-control input-field-ui"
                            placeholder="Please select Template to see Whatsapp Message Text."
                            rows="3"
                            value={followUp.message}
                            onChange={(e) => handleFollowUpMessageChange(index, e)}
                            readOnly="readOnly"
                            disabled
                        />
                        {errors[`followUpMessage${index}`] && <div className="text-danger">{errors[`followUpMessage${index}`]}</div>}
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <select
                                    className="form-select input-field-ui"
                                    value={followUp.frequency}
                                    onChange={(e) => handleFollowUpFrequencyChange(index, e)}
                                >
                                    <option value="">Select Frequency</option>
                                    <option value="1">1 Time</option>
                                    <option value="2">2 Times</option>
                                    <option value="3">3 Times</option>
                                    <option value="4">4 Times</option>
                                    <option value="5">5 Times</option>
                                </select>
                                {errors[`followUpFrequency${index}`] && <div className="text-danger">{errors[`followUpFrequency${index}`]}</div>}
                            </div>
                            <div className="col-md-4">
                                <div className="dropdown">
                                    {/* <button
                                        className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="bi bi-calendar4-week"></i> Schedule Send {followUp.fschedule}
                                    </button> */}

                                {followUp.fschedule ? (
                                <button type="button"
                                className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">Selected: {followUp.fschedule}</button>
                                    ) : (
                                        <button
                                        className="btn btn-secondary dropdown-toggle Schedule-Send-btn"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                             <i className="bi bi-calendar4-week"></i> Schedule Send
                                        </button>
                                    )}
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, 'before 15 mins')}>before 15 mins</font></li>
                                        <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, 'before 30 mins')}>before 30 mins</font></li>
                                        <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, 'before 1 hrs')}>before 1 hrs</font></li>
                                        {/* <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, '12 hour')}>12 hour</font></li>
                                        <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, '24 hour')}>24 hour</font></li>
                                        <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, 'day')}>Day</font></li>
                                        <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, 'week')}>Week</font></li>
                                        <li><font className="dropdown-item" onClick={() => handleFollowUpScheduleChange(index, 'month')}>Month</font></li> */}
                                    </ul>
                                </div>
                                {errors[`followUpSchedule${index}`] && <div className="text-danger">{errors[`followUpSchedule${index}`]}</div>}
                            </div>
                            {/* <div className="col-md-3">
                                <button type="button" className="btn btn-danger" onClick={() => removeFollowUpMessage(index)}>Remove</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            ))}

            <div className="row mt-5">
                <div className="col-md-12 text-center">
                    <button type="button" className="btn btn-primary Schedule-Send-btn" onClick={addFollowUpMessage}>
                        <i className="bi bi-card-checklist me-2"></i> Add More Follow up message
                    </button>
                </div>
                <div className="col-md-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary program-save-btn">
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ContentWhatsapp;
