import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { 
  FaRegFileAlt, 
  FaRegListAlt, 
  FaRegImage, 
  FaRegCommentDots,
  FaRegCopyright,
  FaRegHandPointRight
} from 'react-icons/fa';
import { HiOutlineLightBulb } from 'react-icons/hi';

const WhatsAppTemplateModal = ({ showModal, handleSkip }) => {

    const variable1 = "{{1}}"
    const variable2 = "{{2}}"

  return (
    <Modal show={showModal} onHide={handleSkip} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="tw-text-[#03335b] tw-font-semibold">WhatsApp Template Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-px-6 tw-py-4">
        {/* Template Details Grid */}
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
          {/* Template Name */}
          <div className="tw-flex tw-items-start tw-gap-4 tw-p-4 tw-bg-blue-50 tw-rounded-xl tw-transition-all hover:tw-shadow-md">
            <FaRegFileAlt className="tw-text-xl tw-text-[#03335b] tw-mt-1" />
            <div>
              <h5 className="tw-font-semibold tw-text-[#03335b] tw-mb-2">Template Name (Unique)</h5>
              <ul className="tw-text-gray-600 tw-text-sm tw-space-y-1">
                <li> Each template must have a unique name</li>
                <li> Can be up to 512 characters long</li>
              </ul>
            </div>
          </div>

          {/* Category */}
          <div className="tw-flex tw-items-start tw-gap-4 tw-p-4 tw-bg-blue-50 tw-rounded-xl tw-transition-all hover:tw-shadow-md">
            <FaRegListAlt className="tw-text-xl tw-text-[#03335b] tw-mt-1" />
            <div>
              <h5 className="tw-font-semibold tw-text-[#03335b] tw-mb-2">Category</h5>
              <p className="tw-text-gray-600 tw-text-sm">
                The template should belong to a specific category, such as "Transactional" or "Utility."
              </p>
            </div>
          </div>

          {/* Header */}
          <div className="tw-flex tw-items-start tw-gap-4 tw-p-4 tw-bg-blue-50 tw-rounded-xl tw-transition-all hover:tw-shadow-md">
            <FaRegImage className="tw-text-xl tw-text-[#03335b] tw-mt-1" />
            <div>
              <h5 className="tw-font-semibold tw-text-[#03335b] tw-mb-2">Header</h5>
              <ul className="tw-text-gray-600 tw-text-sm tw-space-y-1">
                <li> Optional: text, image, video, or document</li>
                <li> Text limit: 60 characters</li>
                <li> Only one type of header allowed</li>
              </ul>
            </div>
          </div>

          {/* Body */}
          <div className="tw-flex tw-items-start tw-gap-4 tw-p-4 tw-bg-blue-50 tw-rounded-xl tw-transition-all hover:tw-shadow-md">
            <FaRegCommentDots className="tw-text-xl tw-text-[#03335b] tw-mt-1" />
            <div>
              <h5 className="tw-font-semibold tw-text-[#03335b] tw-mb-2">Body</h5>
              <ul className="tw-text-gray-600 tw-text-sm tw-space-y-1">
                <li> Maximum length: 1,024 characters</li>
                <li> Up to 10 variables ({variable1}, {variable2}, etc.)</li>
              </ul>
            </div>
          </div>

          {/* Footer */}
          <div className="tw-flex tw-items-start tw-gap-4 tw-p-4 tw-bg-blue-50 tw-rounded-xl tw-transition-all hover:tw-shadow-md">
            <FaRegCopyright className="tw-text-xl tw-text-[#03335b] tw-mt-1" />
            <div>
              <h5 className="tw-font-semibold tw-text-[#03335b] tw-mb-2">Footer</h5>
              <ul className="tw-text-gray-600 tw-text-sm tw-space-y-1">
                <li> Character limit: 60</li>
                <li> Default opt-out option</li>
                <li> Reply "STOP" to unsubscribe</li>
              </ul>
            </div>
          </div>

          {/* Buttons */}
          <div className="tw-flex tw-items-start tw-gap-4 tw-p-4 tw-bg-blue-50 tw-rounded-xl tw-transition-all hover:tw-shadow-md">
            <FaRegHandPointRight className="tw-text-xl tw-text-[#03335b] tw-mt-1" />
            <div>
              <h5 className="tw-font-semibold tw-text-[#03335b] tw-mb-2">Buttons</h5>
              <ul className="tw-text-gray-600 tw-text-sm tw-space-y-1">
                <li> Call to Action buttons (max 2)</li>
                <li> Quick Reply buttons (max 5)</li>
                <li> Cannot combine both types</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Help Section */}
        <div className="tw-mt-8 tw-p-4 tw-bg-[#03335b] tw-text-white tw-rounded-xl tw-flex tw-items-center tw-gap-3">
          <HiOutlineLightBulb className="tw-text-5xl" />
          <div>
            <h3 className="tw-font-semibold tw-mb-2">Need Help Getting Started?</h3>
            <p className="tw-text-sm">
              Check {' '}
              <a 
                href="https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines/" 
                target="_blank"
                rel="noopener noreferrer"
                className="tw-text-blue-200 hover:tw-text-blue-100 tw-underline"
              >
                Best Practices for WhatsApp Template Creation
              </a>
              {' '} for more guidance.
            </p>
          </div>
        </div>

        {/* Skip Button */}
        <div className="tw-mt-6 tw-flex tw-justify-center">
          <Button
            className="tw-px-8 tw-py-2 tw-rounded-full tw-border-2 tw-border-[#03335b] tw-text-[#03335b] tw-font-bold tw-bg-white hover:tw-bg-[#03335b] hover:tw-text-white tw-transition-colors"
            onClick={handleSkip}
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WhatsAppTemplateModal;