import React, {useState} from 'react';
import './ContentUploadMemberDetails.css';
import ContentUploadMemberNew from './ContentUploadMemberDetails/ContentUploadMemberNew';


function ContentUploadMemberDetails() {


  

  return (
    <>
        <section className='ContentUploadMemberDetails'>
            <ContentUploadMemberNew />
        </section>
    </>
  );
}

export default ContentUploadMemberDetails;
