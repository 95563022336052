import React from 'react';
import './ContentMemberEngagement.css';
import ContentMemberEngagementTable from './ContentMemberEngagement/ContentMemberEngagementTable';
import ContentMemberEngagementDetails from './ContentMemberEngagement/ContentMemberEngagementDetails';
import ContentCallDisposition from './ContentMemberEngagement/ContentCallDisposition';
import ContentCallHistory from './ContentMemberEngagement/ContentCallHistory';

function ContentMemberEngagement() {

  return (
    <>
        <section className='ContentMemberEngagement'>
         
            <ContentMemberEngagementTable />

            {/* <ContentMemberEngagementDetails /> */}

            {/* <ContentCallDisposition /> */}

            {/* <ContentCallHistory /> */}

        </section>

        
    </>
  );
}

export default ContentMemberEngagement;
