import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useUserTabs } from '../../components/common/UserTabsContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './MemberServices.css'
import { Modal } from 'react-bootstrap';
import {
    fetchMemberServicesMonthCategoryWise, bookMemberServices, updateMemberServicesStatus, fetchServiceCategories, fetchServiceByCategory, addMemberAdditionalServices, fetchServiceNotes, fetchReportTypes, services_action, bulkServicesAction, fetchServiceNameByRecordId
} from "../../components/services/Service";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';

const MemberServices = forwardRef((props, ref) => {

    const memberBasicInfo = props.memberBasicInfo ? props.memberBasicInfo : {};
    const userId = props.userId ? props.userId : "";
    const openRespectiveServiceModal = props.openRespectiveServiceModal ? props.openRespectiveServiceModal : "";
    const handleMemberBulkHomeCareRequest = props.handleMemberBulkHomeCareRequest ? props.handleMemberBulkHomeCareRequest : "";
    // Expandable Table
    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRowExpansion = (index) => {
        setExpandedRow(prevState => (prevState === index ? null : index));
    };

    const [expandedRow1, setExpandedRow1] = useState(null);

    const toggleRowExpansion1 = (index) => {
        setExpandedRow1(prevState => (prevState === index ? null : index));
    };
    // Expandable

    useEffect(() => {
        getMemberServicesMonthCategoryWise(userId);
        getServiceCategories();
    }, [userId, memberBasicInfo]);

    useImperativeHandle(ref, () => ({
        getMemberServicesMonthCategoryWise
    }));

    const getMemberServicesMonthCategoryWise = async (userId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchMemberServicesMonthCategoryWise(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setMemberServices(response.data.data.services);
                    //props.setSharedMemberServices(response.data.data.services);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const addServiceObject = {
        pathwayId: (memberBasicInfo.memberPathways && memberBasicInfo.memberPathways.length > 0) ? memberBasicInfo.memberPathways[0].pathwayId : "",
        categoryId: '',
        serviceId: '',
        date: new Date(),
        time: null,
        notes: ""
    }
    const [addServiceData, setAddServiceData] = useState(addServiceObject);
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [memberServices, setMemberServices] = useState([]);
    const [serviceNotes, setServiceNotes] = useState([]);
    const [addServiceError, setAddServiceError] = useState("");
    const [addServiceMessage, setAddServiceMessage] = useState("");

    const [showAddServiceModal, setShowAddServiceModal] = useState(false);
    const handleAddServiceModal = () => {
        setShowAddServiceModal(!showAddServiceModal);
    }

    /*const [showAddServicesModal, setShowAddServicesModal] = useState(false);
    const handleShowServicesModal = () => {
        setShowAddServicesModal(true);
    }*/


    const showAddServicesModal = props.showAddServicesModal;
    const setShowAddServicesModal = props.setShowAddServicesModal;
    const handleShowServicesModal = props.handleShowServicesModal;

    const [showServiceNotesModal, setShowServiceNotesModal] = useState(false);

    const selectedRecordObject = { recordId: "", serviceName: "", serviceType: "", serviceRequestType: "" }
    const bookingFormObject = {
        date: new Date(),
        time: "",
        notes: ""
    }

    const bookingStatusUpdateObject = {
        status: "",
        notes: "",
        files: [{
            id: new Date(),
            reportTypeId: "",
            title: "",
            file: null,
            originalName: ""
        }]
    }
    const [reportTypes, setReportTypes] = useState([]);
    const [bookingFormData, setBookingFormData] = useState(bookingFormObject);
    const [bookingStatusUpdateFormData, setBookingStatusUpdateFormData] = useState(bookingStatusUpdateObject);

    const [selectedServiceRecord, setSelectedServiceRecord] = useState(selectedRecordObject);
    const [showBookMemberServiceModal, setShowBookMemberServiceModal] = useState(false);
    const [showUpdateMemberServiceModal, setShowUpdateMemberServiceModal] = useState(false);
    const [bookingMessage, setBookingMessage] = useState("");
    const [bookingError, setBookingError] = useState("");

    const [msUpdateMessage, setMsUpdateMessage] = useState("");
    const [msUpdateError, setMsUpdateError] = useState("");


    const now = new Date();
    const isTodayAS = addServiceData.date instanceof Date && (addServiceData.date).toDateString() === now.toDateString();
    const minTimeAS = isTodayAS ? now : new Date().setHours(0, 0, 0, 0);

    const isToday = bookingFormData.date instanceof Date && (bookingFormData.date).toDateString() === now.toDateString();
    const minTime = isToday ? now : new Date().setHours(0, 0, 0, 0);

    const getServiceNotes = async (userId, recordId, serviceType) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchServiceNotes(userId, recordId, serviceType, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setServiceNotes(response.data.data.info);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleServiceNotes = (userId, recordId, serviceType) => {
        setServiceNotes([]);
        getServiceNotes(userId, recordId, serviceType);
    }

    const getServiceCategories = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchServiceCategories(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setCategories(response.data.data.categories);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getServiceByCategory = async (categoryId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchServiceByCategory(categoryId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setServices(response.data.data.services);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getReportTypes = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchReportTypes(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setReportTypes(response.data.data.reportTypes);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleBookingChange = (e) => {
        const { name, value } = e.target;
        setBookingFormData({
            ...bookingFormData,
            [name]: value
        });
    }

    const handleBookingUpdateChange = (e) => {
        const { name, value } = e.target;
        setBookingStatusUpdateFormData({
            ...bookingStatusUpdateFormData,
            [name]: value
        });
    }

    const handleFileChange = (event, id) => {
        const file = event.target.files[0];
        const originalName = file.name;
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileData = e.target.result;
                setBookingStatusUpdateFormData(prevState => ({
                    ...prevState,
                    files: prevState.files.map(fileInput =>
                        fileInput.id === id ? { ...fileInput, file: fileData, originalName: originalName } : fileInput
                    )
                }));
            }
            reader.readAsDataURL(file);
        }
    };

    const handleTitleChange = (event, id) => {
        const name = event.target.name;
        if (name === "title") {
            const title = event.target.value;
            setBookingStatusUpdateFormData(prevState => ({
                ...prevState,
                files: prevState.files.map(fileInput =>
                    fileInput.id === id ? { ...fileInput, title } : fileInput
                )
            }));
        } else if (name === "reportTypeId") {
            const reportTypeId = event.target.value;
            setBookingStatusUpdateFormData(prevState => ({
                ...prevState,
                files: prevState.files.map(fileInput =>
                    fileInput.id === id ? { ...fileInput, reportTypeId } : fileInput
                )
            }));
        }
    };

    const addFile = () => {
        setBookingStatusUpdateFormData(prevState => ({
            ...prevState,
            files: [...prevState.files, { id: Date.now(), title: "", file: null, originalName: "" }]
        }));
    };

    const removeFile = (id) => {
        setBookingStatusUpdateFormData(prevState => ({
            ...prevState,
            files: prevState.files.filter(fileInput => fileInput.id !== id)
        }));
    };


    const handleAddServiceChange = (e) => {
        const { name, value } = e.target;
        if (name == "categoryId") {
            getServiceByCategory(value);
            setAddServiceData({
                ...addServiceData,
                [name]: value,
                ['serviceId']: ""
            });
        } else {
            setAddServiceData({
                ...addServiceData,
                [name]: value
            });
        }

    }

    const addNewMemberAdditionalService = async () => {
        setAddServiceError("");
        setAddServiceMessage("");
        let error = "";
        let fd = {};
        fd = structuredClone(addServiceData);
        const formattedDate = moment(addServiceData.date).format('YYYY-MM-DD');
        const formattedTime = (moment(addServiceData.time).isValid()) ? moment(addServiceData.time).format('HH:mm:ss') : "";
        fd['date'] = formattedDate;
        fd['time'] = formattedTime;

        if (addServiceData.categoryId === "") {
            error = "Please select the category";
        } else if (addServiceData.serviceId === "") {
            error = "Please select the service";
        } else if (addServiceData.date === "" || addServiceData.date === undefined) {
            error = "Please select preferred date";
        } else if (addServiceData.time === "" || addServiceData.time === undefined || addServiceData.time === null) {
            error = "Please select preferred time";
        }

        if (error === "") {
            if (fd.pathwayId === "") {
                fd['pathwayId'] = (memberBasicInfo.memberPathways) ? memberBasicInfo.memberPathways[0].pathwayId : 0;
            }
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addMemberAdditionalServices(userId, fd, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setAddServiceData(addServiceObject)
                        setAddServiceMessage(response.data.data.message)
                        setShowAddServiceModal(false);
                        setShowAddServicesModal(false);
                        getMemberServicesMonthCategoryWise(userId);
                        setServices([]);
                    } else {
                        setAddServiceError(response.data.data.message)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        } else {
            setAddServiceError(error);
        }
    }

    const bookMemberService = async () => {
        setBookingError("");
        setBookingMessage("");
        let error = "";
        if (bookingFormData.date === "" || bookingFormData.date === undefined) {
            error = "Please select preferred date";
        } else if (bookingFormData.time === "" || bookingFormData.time === undefined) {
            error = "Please select preferred time";
        }

        if (error === "") {
            const formattedDate = moment(bookingFormData.date).format('YYYY-MM-DD');
            const formattedTime = moment(bookingFormData.time).format('HH:mm:ss');
            let fd = bookingFormData;

            fd['date'] = formattedDate;
            fd['time'] = formattedTime;

            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }

            await bookMemberServices(userId, selectedServiceRecord.recordId, selectedServiceRecord.serviceType, fd, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setBookingFormData(bookingFormObject)
                        setBookingMessage(response.data.data.message)
                        setShowBookMemberServiceModal(false);
                        getMemberServicesMonthCategoryWise(userId);
                    } else {
                        setBookingError(response.data.data.message)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        } else {
            setBookingError(error);
        }
    }

    const updateMemberConditionService = async () => {
        setBookingError("");
        setBookingMessage("");
        let error = "";
        if (bookingStatusUpdateFormData.status === "" || bookingStatusUpdateFormData.status === undefined) {
            error = "Please select the status";
        } else {
            for (let index = 0; index < bookingStatusUpdateFormData.files.length; index++) {
                const fileInput = bookingStatusUpdateFormData.files[index];
                if (fileInput.file !== null && fileInput.reportTypeId === "") {
                    error = "Please select the report type";
                    break;
                }
            }
        }

        if (error === "") {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }

            await updateMemberServicesStatus(userId, selectedServiceRecord.recordId, bookingStatusUpdateFormData, selectedServiceRecord.serviceType, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setBookingStatusUpdateFormData(bookingStatusUpdateFormData)
                        setMsUpdateMessage(response.data.data.message)
                        setShowUpdateMemberServiceModal(false);
                        getMemberServicesMonthCategoryWise(userId);
                    } else {
                        setMsUpdateError(response.data.data.message)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        } else {
            setMsUpdateError(error);
        }
    }

    //Opening respective forms for the services on book - 
    const handleBookService = (recordId, serviceName, serviceType, serviceRequestType) => {
        const sr = { recordId: recordId, serviceName: serviceName, serviceType: serviceType, serviceRequestType: serviceRequestType };
        setSelectedServiceRecord(sr);
        if (serviceRequestType === "Medication Request") {
            openRespectiveServiceModal(sr)
        } else if (serviceRequestType === "Lab Request") {
            openRespectiveServiceModal(sr)
        } else if (serviceRequestType === "Home Care Request") {
            openRespectiveServiceModal(sr)
        } else if (serviceRequestType === "Home Based Vaccine") {
            openRespectiveServiceModal(sr)
        } else {
            setShowBookMemberServiceModal(true);
            setBookingFormData(bookingFormObject);
            setBookingError("")
            setBookingMessage("");
        }
    }

    async function changeAdditionalServiceAction(e, service, index, mainIndex) {

        setMemberServices((prevMemberServices) => {
            // Create a copy of the previous state to avoid mutation
            const updatedMemberServices = [...prevMemberServices];

            updatedMemberServices[mainIndex] = {
                ...updatedMemberServices[mainIndex],
                additionalServices: updatedMemberServices[mainIndex].additionalServices.map((service, idx) => {
                    if (idx === index) {
                        return {
                            ...service,
                            bookingStatus: e.target.value,
                        };
                    }
                    return service;
                }),
            };

            return updatedMemberServices;
        });

        try {
            let response = await services_action({ serviceId: service.recordId, serviceType: "additional", status: e.target.value });
            getMemberServicesMonthCategoryWise(userId);
            props.callServiceEventHistory();
        } catch (e) {

        }

    }

    // async function changeServiceAction(e, mainIndex, sIndex , catIndex, service, msIndex){ 




    //     setMemberServices((prevMemberServices) => {
    //         // Create a copy of the previous state to avoid mutation
    //         const updatedMemberServices = [...prevMemberServices];

    //         updatedMemberServices[mainIndex] = {
    //           ...updatedMemberServices[mainIndex],
    //           services: updatedMemberServices[mainIndex].services[sIndex].categories[catIndex].services.map((finalService, finalIdx) => { 
    //             if (finalIdx === msIndex) { 
    //                 return {
    //                 ...finalService,
    //                  bookingStatus: e.target.value, 
    //                 };
    //             }

    //             return finalService
    //           })


    //         };

    //         return updatedMemberServices; 
    //       });
    // }


    async function changeServiceAction(e, mainIndex, sIndex, catIndex, service, msIndex) {
        setMemberServices((prevMemberServices) => {
            // Create a new array to avoid mutating the previous state
            const updatedMemberServices = [...prevMemberServices];

            // Update the specific service's bookingStatus based on indices
            updatedMemberServices[mainIndex] = {
                ...updatedMemberServices[mainIndex],
                services: updatedMemberServices[mainIndex].services.map((s, indexS) => {
                    if (indexS === sIndex) {
                        return {
                            ...s,
                            categories: s.categories.map((cat, indexCat) => {
                                if (indexCat === catIndex) {
                                    return {
                                        ...cat,
                                        services: cat.services.map((finalService, finalIdx) => {
                                            if (finalIdx === msIndex) {
                                                return {
                                                    ...finalService,
                                                    bookingStatus: e.target.value,
                                                };
                                            }
                                            return finalService;
                                        }),
                                    };
                                }
                                return cat;
                            }),
                        };
                    }
                    return s;
                }),
            };

            return updatedMemberServices;
        });


        try {
            let response = await services_action({ serviceId: service.recordId, serviceType: "service", status: e.target.value });
            getMemberServicesMonthCategoryWise(userId);
            props.callServiceEventHistory();
        } catch (e) {

        }
    }

    /*Bulk Action*/
    const [checkedState, setCheckedState] = useState({});

    const handleCheckboxChange = (monthIndex, categoryIndex, serviceIndex, isChecked, recordId) => {
        setCheckedState((prevState) => ({
            ...prevState,
            [monthIndex]: {
                ...prevState[monthIndex],
                [categoryIndex]: {
                    ...prevState[monthIndex]?.[categoryIndex],
                    [serviceIndex]: isChecked ? recordId : false,
                },
            },
        }));
    };

    const handleBulkCheckboxChange = (data, monthIndex, isChecked) => {
        const newCheckedState = { ...checkedState };
        data[monthIndex].categories.forEach((category, categoryIndex) => {
            newCheckedState[monthIndex] = {
                ...newCheckedState[monthIndex],
                [categoryIndex]: category.services.reduce((acc, _, serviceIndex) => {
                    acc[serviceIndex] = isChecked ? category.services[serviceIndex].recordId : false;
                    return acc;
                }, {}),
            };
        });

        setCheckedState(newCheckedState);
    };

    const isAnyCheckboxChecked = (monthIndex) => {
        const month = checkedState[monthIndex];
        if (!month) return false;

        return Object.values(month).some((category) =>
            Object.values(category).some((recordId) => recordId !== false)
        );
    };
    const areAllCheckboxesChecked = (data, monthIndex) => {
        const month = checkedState[monthIndex];

        if (!month || month === undefined) return false;

        const expectedServiceIds = data[monthIndex].categories.flatMap(category =>
            category.services.map((_, serviceIndex) => serviceIndex) // Replace with the appropriate ID if you have it
        );

        const checkedServiceIds = Object.entries(month).flatMap(([categoryIndex, services]) =>
            Object.entries(services)
                .filter(([serviceIndex, isChecked]) => isChecked) // Check if the service is marked as checked
                .map(([serviceIndex]) => Number(serviceIndex)) // Convert serviceIndex to number if needed
        );

        return expectedServiceIds.every(id => checkedServiceIds.includes(id));
    };


    const updateBulkAction = async (bulkActionSelectedServices, status) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await bulkServicesAction(bulkActionSelectedServices, 'service', status, ct)
            .then((response) => {
                setCheckedState([]);
                getMemberServicesMonthCategoryWise(userId);
                props.callServiceEventHistory();
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleBulkAction = (tableId, status) => {
        if (tableId !== "" && status !== "") {
            const data = checkedState[tableId];
            let bulkActionSelectedServices = [];
            Object.entries(data).forEach(([outerKey, innerObject]) => {
                Object.entries(innerObject).forEach(([innerKey, value]) => {
                    if (value !== false) {
                        bulkActionSelectedServices.push(value);
                    }
                });
            });
            updateBulkAction(bulkActionSelectedServices, status);
        }
    }

    const getServiceNameByRecordId = async (bulkActionSelectedServices) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchServiceNameByRecordId(bulkActionSelectedServices, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    handleMemberBulkHomeCareRequest(response.data.data.services, "Home Care Request");
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleBulkHomeCareRequests = (tableId) => {
        if (tableId !== "") {
            const data = checkedState[tableId];
            let bulkActionSelectedServices = [];
            Object.entries(data).forEach(([outerKey, innerObject]) => {
                Object.entries(innerObject).forEach(([innerKey, value]) => {
                    if (value !== false) {
                        bulkActionSelectedServices.push(value);
                    }
                });
            });
            getServiceNameByRecordId(bulkActionSelectedServices)
        }
    }
    /*===========*/

    return (
        <>
            {
                (memberServices.length > 0) ?
                    <div className='col-md-12 mt-3'>
                        <div className='card card-task'>
                            <div className='card-body'>
                                <div className='card-text'>
                                    {
                                        memberServices.map((mainItem, mainIndex) => {
                                            return <>
                                                <div className='row align-items-center' key={mainIndex}>
                                                    <div className='col'>
                                                        {/*<h5 className='card-title'>{mainItem.programName} / {mainItem.conditionName}</h5>*/}
                                                        <h5 className='card-title'>Services</h5>
                                                    </div>
                                                    <div className='col-auto'>
                                                        {
                                                            /*memberBasicInfo.readonly === "N" &&
                                                            <button className="btn btn-primary addServices-btn" onClick={handleShowServicesModal}>Add Services <i className="bi bi-plus-lg"></i></button>*/
                                                        }
                                                    </div>
                                                    {
                                                        <div>
                                                            <div className="table-responsive mt-2" style={{ maxHeight: "380px", overflowX: "hidden" }}>
                                                                <table className="table table-striped table-bordered mb-0">
                                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                                        <tr>
                                                                            <th scope="col">Additional Services</th>
                                                                            <th scope="col" style={{ width: "15%" }}>Date</th>
                                                                            <th scope="col" style={{ width: "20%" }}>Action</th>
                                                                            {/* <th scope="col" style={{ width: "15%" }}>Notes</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            mainItem?.additionalServices?.map((item, index) => {
                                                                                return <>
                                                                                    <tr style={{ verticalAlign: 'middle' }}>
                                                                                        <td className={`${item.bookingStatus === "due" ? item.alarmColor : "bg-td-green"}`} onClick={() => toggleRowExpansion(index)}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/43t1m/vat93u8oj1.png" style={{ height: "20px" }} className='me-2' alt="" />{item.serviceName}</td>
                                                                                        <td>{item.dueDate}</td>
                                                                                        <td>
                                                                                            <select disabled={(memberBasicInfo.readonly === "Y") ? true : false} class="service-action"
                                                                                                value={item.bookingStatus}
                                                                                                onChange={e => changeAdditionalServiceAction(e, item, index, mainIndex)}
                                                                                            >
                                                                                                <option value="due">-</option>
                                                                                                <option value="booked">Booked</option>
                                                                                                <option value="scheduled">Scheduled</option>
                                                                                                <option value="cancelled">Cancelled</option>
                                                                                                <option value="postponed">Postponed</option>
                                                                                                <option value="completed">Completed</option>
                                                                                                <option value="no-answer">No-answer</option>
                                                                                                <option value="notrequired">Not required</option>
                                                                                                <option value="completedbyotherprovider">Completed By Other Provider</option>
                                                                                            </select>
                                                                                        </td>

                                                                                        <td style={{ display: "none" }}>

                                                                                            {
                                                                                                (item.actionAllowed == "Y") ?
                                                                                                    (item.bookingStatus === "due") ?
                                                                                                        <button className="btn btn-success" onClick={() => handleBookService(item.recordId, item.serviceName, "additional", item.serviceRequestType)}>Book</button> :
                                                                                                        (item.bookingStatus === "scheduled") ?
                                                                                                            <button className="btn btn-info" onClick={() => {
                                                                                                                setSelectedServiceRecord({ recordId: item.recordId, serviceName: item.serviceName, serviceType: "additional" });
                                                                                                                getReportTypes();
                                                                                                                setShowUpdateMemberServiceModal(true);
                                                                                                                setBookingStatusUpdateFormData(bookingStatusUpdateObject)
                                                                                                                setMsUpdateError("");
                                                                                                                setMsUpdateMessage("");
                                                                                                            }}>Scheduled</button> :
                                                                                                            (item.bookingStatus === "completed") ?
                                                                                                                <button className="btn btn-primary complated-btn">Completed</button> :
                                                                                                                <button className="btn btn-danger">Missed</button>
                                                                                                    :
                                                                                                    <button className="btn btn-secondary">Book</button>
                                                                                            }
                                                                                        </td>
                                                                                        {/* <td>
                                                                                            {
                                                                                                (item.bookingStatus != "due") ?
                                                                                                    <button class="btn btn-primary viewNotes-btn" onClick={() => {
                                                                                                        setSelectedServiceRecord({ recordId: item.recordId, serviceName: item.serviceName, serviceType: "additional" });
                                                                                                        handleServiceNotes(userId, item.recordId, "additional");
                                                                                                        setShowServiceNotesModal(true);
                                                                                                    }}>View Notes</button>
                                                                                                    : ""
                                                                                            }
                                                                                        </td> */}
                                                                                    </tr >

                                                                                </>
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div>
                                                        <div className="table-responsive mt-3" style={{ maxHeight: "380px", overflowX: "hidden" }}>
                                                            <table className="table table-striped table-bordered mb-0">
                                                                <thead style={{ position: "sticky", top: "0" }}>
                                                                    <tr>
                                                                        <th scope="col">Suggested Services</th>
                                                                        {/* <th scope="col"></th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        mainItem.services.map((item, index) => {
                                                                            return <>
                                                                                <tr style={{ verticalAlign: 'middle' }}>


                                                                                    <td className='bg-td-green hand' >

                                                                                        <div className='row align-items-center'>
                                                                                            <div className='col-auto pe-0'>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className='form-check-input form-check-input-ui'
                                                                                                    checked={areAllCheckboxesChecked(mainItem.services, index)}
                                                                                                    onChange={(e) =>
                                                                                                        handleBulkCheckboxChange(mainItem.services, index, e.target.checked)
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className='col ps-2' onClick={() => toggleRowExpansion1(index)}>
                                                                                                ({item.month} Month)
                                                                                            </div>

                                                                                            <div className='col-auto'>
                                                                                                {isAnyCheckboxChecked(index) && memberBasicInfo.readonly === "N" && (
                                                                                                    <>
                                                                                                        <select disabled={(memberBasicInfo.readonly === "Y") ? true : false} class="service-action" onChange={e => handleBulkAction(index, e.target.value)}
                                                                                                        >
                                                                                                            <option value="due">-</option>
                                                                                                            <option value="booked">Booked</option>
                                                                                                            <option value="scheduled">Scheduled</option>
                                                                                                            <option value="cancelled">Cancelled</option>
                                                                                                            <option value="postponed">Postponed</option>
                                                                                                            <option value="completed">Completed</option>
                                                                                                            <option value="no-answer">No-answer</option>
                                                                                                            <option value="notrequired">Not required</option>
                                                                                                            <option value="completedbyotherprovider">Completed By Other Provider</option>
                                                                                                        </select>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className='col-auto ps-0'>
                                                                                                {isAnyCheckboxChecked(index) && memberBasicInfo.readonly === "N" && (
                                                                                                    <>
                                                                                                        <button type="button" className="btn homeCareR-btn" onClick={() => handleBulkHomeCareRequests(index)}>Home Care Request</button>

                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>


                                                                                        {/*<img src="https://storage.googleapis.com/ksabupatest/2024/09/24/43t1m/vat93u8oj1.png" style={{ height: "20px" }} className='me-2' alt="" />*/}



                                                                                    </td>
                                                                                    {/* <td className='bg-td-green hand col-3'>
                                                                                        {isAnyCheckboxChecked(index) && memberBasicInfo.readonly === "N" && (
                                                                                            <>
                                                                                                <button type="button" className="btn btn-primary" onClick={() => handleBulkHomeCareRequests(index)}>Home Care Request</button>
                                                                                                <select disabled={(memberBasicInfo.readonly === "Y") ? true : false} class="service-action" onChange={e => handleBulkAction(index, e.target.value)}
                                                                                                >
                                                                                                    <option value="due">-</option>
                                                                                                    <option value="booked">Booked</option>
                                                                                                    <option value="scheduled">Scheduled</option>
                                                                                                    <option value="cancelled">Cancelled</option>
                                                                                                    <option value="postponed">Postponed</option>
                                                                                                    <option value="completed">Completed</option>
                                                                                                    <option value="no-answer">No-answer</option>
                                                                                                    <option value="notrequired">Not required</option>
                                                                                                    <option value="completedbyotherprovider">Completed By Other Provider</option>
                                                                                                </select>
                                                                                            </>
                                                                                        )}
                                                                                    </td> */}
                                                                                </tr >
                                                                                {expandedRow1 === index && (
                                                                                    <tr>
                                                                                        <td colSpan="3" className='pathways-table-expandable-td-bg p-0' >
                                                                                            <div style={{ width: "98%" }}>
                                                                                                <div className='row pathways-table-expandable'>
                                                                                                    <div className="col-12 pe-0">
                                                                                                        <div className="table-responsive pathways-new-table" style={{ borderRadius: "0" }}>
                                                                                                            {
                                                                                                                item.categories.map((catItem, catIndex) => {
                                                                                                                    return <>
                                                                                                                        <table key={catIndex} className="table table-striped mb-0 p-0">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th scope="col"><h5 className="main-pathways-title">{catItem.categoryName}</h5></th>
                                                                                                                                    <th scope="col" style={{ width: "15%" }}>Due Date</th>
                                                                                                                                    <th scope="col" style={{ width: "20%" }}>Action</th>
                                                                                                                                    {/* <th scope="col" style={{ width: "15%" }}>Notes</th> */}
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                {
                                                                                                                                    catItem.services.map((msItem, msIndex) => {
                                                                                                                                        return <>
                                                                                                                                            <tr key={msIndex} style={{ verticalAlign: 'middle' }}>
                                                                                                                                                <td className={`${msItem.bookingStatus === "due" ? msItem.alarmColor : "bg-td-green"}`} onClick={() => toggleRowExpansion(index)}>
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        className='form-check-input form-check-input-ui'
                                                                                                                                                        checked={checkedState[index]?.[catIndex]?.[msIndex] || false}
                                                                                                                                                        onChange={(e) =>
                                                                                                                                                            handleCheckboxChange(
                                                                                                                                                                index,
                                                                                                                                                                catIndex,
                                                                                                                                                                msIndex,
                                                                                                                                                                e.target.checked,
                                                                                                                                                                msItem.recordId
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                    />

                                                                                                                                                    {msItem.serviceName}
                                                                                                                                                </td>
                                                                                                                                                <td>{msItem.dueDate}</td>
                                                                                                                                                <td>
                                                                                                                                                    <select disabled={(memberBasicInfo.readonly === "Y") ? true : false} class="service-action"
                                                                                                                                                        value={msItem.bookingStatus}
                                                                                                                                                        onChange={e => changeServiceAction(e, mainIndex, index, catIndex, msItem, msIndex)}
                                                                                                                                                    >
                                                                                                                                                        <option value="due">-</option>
                                                                                                                                                        <option value="booked">Booked</option>
                                                                                                                                                        <option value="scheduled">Scheduled</option>
                                                                                                                                                        <option value="cancelled">Cancelled</option>
                                                                                                                                                        <option value="postponed">Postponed</option>
                                                                                                                                                        <option value="completed">Completed</option>
                                                                                                                                                        <option value="no-answer">No-answer</option>
                                                                                                                                                        <option value="notrequired">Not required</option>
                                                                                                                                                        <option value="completedbyotherprovider">Completed By Other Provider</option>
                                                                                                                                                    </select>
                                                                                                                                                </td>
                                                                                                                                                <td style={{ display: "none" }}>

                                                                                                                                                    {
                                                                                                                                                        (msItem.actionAllowed == "Y") ?
                                                                                                                                                            (msItem.bookingStatus === "due") ?
                                                                                                                                                                <button className="btn btn-success" onClick={() => handleBookService(msItem.recordId, msItem.serviceName, "main", msItem.serviceRequestType)}>Book</button> :
                                                                                                                                                                (msItem.bookingStatus === "scheduled") ?
                                                                                                                                                                    <button className="btn btn-info" onClick={() => {
                                                                                                                                                                        setSelectedServiceRecord({ recordId: msItem.recordId, serviceName: msItem.serviceName, serviceType: "main" });
                                                                                                                                                                        getReportTypes();
                                                                                                                                                                        setShowUpdateMemberServiceModal(true);
                                                                                                                                                                        setBookingStatusUpdateFormData(bookingStatusUpdateObject)
                                                                                                                                                                        setMsUpdateError("");
                                                                                                                                                                        setMsUpdateMessage("");
                                                                                                                                                                    }}>Scheduled</button> :
                                                                                                                                                                    (msItem.bookingStatus === "completed") ?
                                                                                                                                                                        <button className="btn btn-primary complated-btn">Completed</button> :
                                                                                                                                                                        <button className="btn btn-danger">Missed</button>
                                                                                                                                                            :
                                                                                                                                                            <button className="btn btn-secondary">Book</button>
                                                                                                                                                    }
                                                                                                                                                </td>
                                                                                                                                                {/* <td>
                                                                                                                                                    {
                                                                                                                                                        (msItem.bookingStatus != "due") ?
                                                                                                                                                            <button class="btn btn-primary viewNotes-btn" onClick={() => {
                                                                                                                                                                setSelectedServiceRecord({ recordId: msItem.recordId, serviceName: msItem.serviceName, serviceType: "main" });
                                                                                                                                                                handleServiceNotes(userId, msItem.recordId, "main");
                                                                                                                                                                setShowServiceNotesModal(true);
                                                                                                                                                            }}>View Notes</button>
                                                                                                                                                            : ""
                                                                                                                                                    }
                                                                                                                                                </td> */}
                                                                                                                                            </tr>
                                                                                                                                        </>
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </tbody>
                                                                                                                        </table></>
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                                }
                                                                                {/*<tr style={{ verticalAlign: 'middle' }}>
                                                                                    <td className='bg-td-green' ><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/43t1m/vat93u8oj1.png" style={{ height: "20px" }} className='me-2' alt="" />{item.serviceName} ({item.pathwayMonth} Month)</td>
                                                                                    <td>{item.dueDate}</td>
                                                                                    <td>

                                                                                        {
                                                                                            (item.actionAllowed == "Y") ?
                                                                                                (item.bookingStatus === "due") ?
                                                                                                    <button className="btn btn-success" onClick={() => {
                                                                                                        setSelectedServiceRecord({ recordId: item.recordId, serviceName: item.serviceName, serviceType: "main" });
                                                                                                        setShowBookMemberServiceModal(true);
                                                                                                        setBookingFormData(bookingFormObject);
                                                                                                        setBookingError("")
                                                                                                        setBookingMessage("");
                                                                                                    }}>Book</button> :
                                                                                                    (item.bookingStatus === "scheduled") ?
                                                                                                        <button className="btn btn-info" onClick={() => {
                                                                                                            setSelectedServiceRecord({ recordId: item.recordId, serviceName: item.serviceName, serviceType: "main" });
                                                                                                            setShowUpdateMemberServiceModal(true);
                                                                                                            setBookingStatusUpdateFormData(bookingStatusUpdateObject)
                                                                                                            setMsUpdateError("");
                                                                                                            setMsUpdateMessage("");
                                                                                                        }}>Scheduled</button> :
                                                                                                        (item.bookingStatus === "completed") ?
                                                                                                            <button className="btn btn-primary complated-btn">Completed</button> :
                                                                                                            <button className="btn btn-danger">Missed</button>
                                                                                                :
                                                                                                <button className="btn btn-secondary">Book</button>
                                                                                        }
                                                                                    </td>
                                                                                    <td><button className="btn btn-primary viewNotes-btn">View Notes</button></td>

                                                                                </tr>*/}

                                                                            </>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>


                                                </div >


                                            </>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div > : ""
            }

            {/*New Design
            <div>
                <div className="table-responsive mt-3" style={{ maxHeight: "380px" }}>
                    <table className="table table-striped table-bordered table-expandable mb-0">
                        <thead style={{ position: "sticky", top: "0" }}>
                            <tr>
                                <th scope="col">Services</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mainItem.services.map((item, index) => {
                                    return <>
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <td className='bg-td-green' onClick={() => toggleRowExpansion1(index)}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/43t1m/vat93u8oj1.png" style={{ height: "20px" }} className='me-2' alt="" /> ({item.pathwayMonth} Month)</td>

                                        </tr>
                                        {expandedRow1 === index && (
                                            <tr>
                                                <td colSpan="3" className='pathways-table-expandable-td-bg p-0' >
                                                    <div style={{ width: "98%" }}>
                                                        <div className='row pathways-table-expandable'>
                                                            <div className="col-12 pe-0">
                                                                <div className="table-responsive pathways-new-table" style={{ borderRadius: "0" }}>
                                                                    <table className="table table-striped mb-0 p-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col"><h5 className="main-pathways-title">Consultation, Examination, and Counselling</h5></th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <span>Comprehensive Medical Evaluation ( History &amp; Physical Examination)</span>
                                                                                    <span>Dental Examination</span>
                                                                                    <span>Albumin/Creatinin Ratio</span>
                                                                                    <span>Eye Examination</span>
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>
                                                                                    <button class="btn btn-success">Book</button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <table className="table table-striped mb-0 p-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col"><h5 className="main-pathways-title">Pre-Clinic Assessment</h5></th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <span>Albumin/Creatinin Ratio</span>
                                                                                    <span>Eye Examination</span>
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>
                                                                                    <button class="btn btn-success">Book</button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>*/}

            < Modal id="bookingModal" show={showBookMemberServiceModal} centered onHide={() => setShowBookMemberServiceModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false} >
                <Modal.Header closeButton>
                    <h1 className='modal-title' style={{ width: "90%" }}>{selectedServiceRecord.serviceName}</h1>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} className='mb-3'>
                            <label className='form-label'>Preferred Date <span className='required'>*</span></label>
                            <DatePicker
                                selected={bookingFormData.date}
                                onChange={(date) => setBookingFormData({
                                    ...bookingFormData,
                                    date: date
                                })}
                                dateFormat="dd-MM-yyyy"
                                placeholderText=""
                                minDate={new Date()}
                                className='form-control control-input-ui'
                                onKeyDown={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <label className='form-label'>Preferred Time <span className='required'>*</span></label>
                            <DatePicker
                                selected={bookingFormData.time}
                                onChange={(date) => setBookingFormData({
                                    ...bookingFormData,
                                    time: date
                                })}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15} // Set time intervals, e.g., 15 minutes
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                minTime={minTime}
                                maxTime={new Date().setHours(23, 59)}
                                className='form-control control-input-ui'
                                onKeyDown={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Notes</label>
                            <textarea name="notes" className='form-control control-input-ui' onChange={handleBookingChange} value={bookingFormData.notes}></textarea>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'>
                            <div className={`${bookingError != "" ? "errorDiv" : "hide"}`}>{bookingError}</div>
                            <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => bookMemberService()}>Book</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >

            <Modal id="bookingStatusUpdateModal" show={showUpdateMemberServiceModal} centered onHide={() => setShowUpdateMemberServiceModal(false)} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title' style={{ width: "90%" }}>{selectedServiceRecord.serviceName}</h1>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Select Status <span className='required'>*</span></label>
                            <select className='form-select control-input-ui' name="status" value={bookingStatusUpdateFormData.status} onChange={handleBookingUpdateChange}>
                                <option value="">Select</option>
                                <option value="missed">Missed</option>
                                <option value="completed">Completed</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Notes </label>
                            <textarea name="notes" className='form-control control-input-ui' onChange={handleBookingUpdateChange} value={bookingStatusUpdateFormData.notes}></textarea>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col md className='mb-1'><label>Attachments </label></Col>
                        <Col md="auto" className='mb-1'>
                            <button className="btn btn-primary addNewRow-btn" onClick={addFile}><i class="bi bi-plus"></i> Add </button>
                        </Col>


                        {bookingStatusUpdateFormData.files.map((item, index) => (
                            <div key={item.id} className='col-12 mb-1'>
                                <Row className='align-items-center'>
                                    <Col md={4}>
                                        <input type="text" value={item.title} name="title" className='form-control control-input-ui' onChange={(event) => handleTitleChange(event, item.id)} />
                                    </Col>
                                    <Col>
                                        <select name="reportTypeId" className="form-select select-input-ui" value={item.reportTypeId} onChange={(event) => handleTitleChange(event, item.id)}>
                                            <option value="">Select</option>
                                            {
                                                reportTypes.map((rtItem, index) => {
                                                    return <option value={rtItem.reportTypeId}>{rtItem.reportType}</option>
                                                })
                                            }
                                        </select>
                                    </Col>
                                    <Col md className='px-0'>
                                        <input
                                            type="file"
                                            accept=".pdf,.jpeg,.jpg,.png,.tiff,.doc,.docx,.xls,.xlsx,.csv,.txt,.dcm"
                                            className='file-control control-input-ui'
                                            onChange={(event) => handleFileChange(event, item.id)}
                                            style={{ width: "100%", padding: "5px 8px !important" }}
                                        />

                                    </Col>
                                    <Col md="auto">
                                        <button type="button" className="btn-close cross-btn-close" aria-label="Close" onClick={() => removeFile(item.id)}></button>
                                    </Col>
                                </Row>
                            </div>
                        ))}

                    </Row>
                    <Row>
                        <Col className='text-center'>
                            <div className={`${msUpdateError != "" ? "errorDiv" : "hide"}`}>{msUpdateError}</div>
                            <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => updateMemberConditionService()}>Update</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal id="addNewService" show={showAddServicesModal} onHide={() => setShowAddServicesModal(false)} centered size="" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Add New Service</h1>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <label className="form-label">Select Category<span class="required">*</span></label>
                            <select className='form-select control-input-ui' name="categoryId" value={addServiceData.categoryId} onChange={handleAddServiceChange}>
                                <option value="">Select</option>
                                {
                                    categories.map((item, index) => {
                                        return <option value={item.categoryId}>{item.categoryName}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-12 mb-3'>
                            <label className="form-label">Select Service<span class="required">*</span></label>
                            <select className='form-select control-input-ui' name="serviceId" value={addServiceData.serviceId} onChange={handleAddServiceChange}>
                                <option value="">Select</option>
                                {
                                    services.map((item, index) => {
                                        return <option value={item.serviceId}>{item.serviceName}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-6 mb-3'>
                            <label className="form-label">Preferred Date<span class="required">*</span></label>
                            <DatePicker
                                selected={addServiceData.date}
                                onChange={(date) => setAddServiceData({
                                    ...addServiceData,
                                    date: date
                                })}
                                dateFormat="dd-MM-yyyy"
                                placeholderText=""
                                minDate={new Date()}
                                className='form-control control-input-ui'
                                onKeyDown={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </div>

                        <div className='col-6 mb-3'>
                            <label className="form-label">Preferred Time <span class="required">*</span></label>
                            <DatePicker
                                selected={addServiceData.time}
                                onChange={(date) => setAddServiceData({
                                    ...addServiceData,
                                    time: date
                                })}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                minTime={minTimeAS}
                                maxTime={new Date().setHours(23, 59)}
                                className='form-control control-input-ui'
                                onKeyDown={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </div>

                        <div className='col-12 mb-3'>
                            <label className="form-label">Remarks</label>
                            <textarea name="notes" className='form-control control-input-ui' onChange={handleAddServiceChange} value={addServiceData.notes}></textarea>
                        </div>

                    </div>


                    <div className='col-12 text-center'>
                        <div className={`${addServiceError != "" ? "errorDiv" : "hide"}`}>{addServiceError}</div>
                        <button className="btn btn-primary save-btn me-3" onClick={() => addNewMemberAdditionalService()}>Add</button>

                    </div>

                </Modal.Body>
            </Modal>

            <Modal id="serviceNotes" show={showServiceNotesModal} centered onHide={() => setShowServiceNotesModal(false)} size="md" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title' style={{ width: "90%" }}>{selectedServiceRecord.serviceName}</h1>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Due Date:</label>
                            <span>{serviceNotes.dueDate}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Booking Date:</label>
                            <span>{serviceNotes.bookingDate}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Booking Notes:</label>
                            <span>{serviceNotes.bookingNotes}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Status Update Date:</label>
                            <span>{serviceNotes.statusUpdateDate}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Status Update Notes:</label>
                            <span>{serviceNotes.statusUpdateNotes}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='mb-3'>
                            <label className='form-label'>Attachments:</label>
                            {
                                (serviceNotes.files) &&
                                    (serviceNotes.files.length > 0) ?
                                    serviceNotes.files.map((file, index) => {
                                        return <>
                                            <Link to={file} target="_blank"><i className="fa fa-paperclip" aria-hidden="true"></i>
                                            </Link></>
                                    }) : ""
                            }
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        </>
    )
});

export default MemberServices