import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { whatsAppCreateCampaign } from '../../../services/mtp/Service';
import { filterUserDataDownload } from '../../../services/whatsapp/Service';

const SetMessageLive = ({
  campaignName,
  setCampaignName,
  scheduling,
  dateTime,
  selectedTemplate,
  setSelectedTemplate,
  selectedFilteredList,
  setSelectedFilteredList,
  totalCountUserList,
  setTotalCountUserList,
  memberFilterSelectQuery,
  setMemberFilterSelectQuery,
  filterQueries,
  optOutNCount,
  setOptOutNCount,
  optOutYCount,
  setOptOutYCount
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Handle ESC key press to close modal
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) setIsModalOpen(false);
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const finalSubmitCampaign = () => {
    const formData = new FormData();
    setErrorMessage('');
    
    // Validation logic
    if (!campaignName) {
      setErrorMessage('Campaign Name is required.');
      setSuccessMessage('');
      return;
    }

    if (!selectedFilteredList || selectedFilteredList.length === 0 || memberFilterSelectQuery === '') {
      setErrorMessage('Please select at least one filter');
      setSuccessMessage('');
      return;
    }

    if (!scheduling) {
      setErrorMessage('Scheduling is required.');
      setSuccessMessage('');
      return;
    }
    if (!dateTime) {
      setErrorMessage('Date and Time are required.');
      setSuccessMessage('');
      return;
    }
    if (!selectedTemplate) {
      setErrorMessage('A template must be selected.');
      setSuccessMessage('');
      return;
    }

    if (errorMessage === "") {
      const data = {
        'campaignName': campaignName,
        'scheduling': scheduling,
        'dateTime': dateTime,
        'selectedTemplate': selectedTemplate,
        'memberFilterSelectQuery': memberFilterSelectQuery,
        'totalCountUserList': totalCountUserList,
        'filterQueries': filterQueries,
      }
      formData.append('data', JSON.stringify(data));
      whatsAppCreateCampaign(JSON.stringify(data))
        .then((response) => {
          if (response.data.code === 200) {
            setSuccessMessage(response.data.data.message);
            setErrorMessage('');
            setSelectedFilteredList([]);
            setTotalCountUserList('');
            setOptOutNCount('');
            setOptOutYCount('');
            setMemberFilterSelectQuery('');
            setSelectedTemplate(null);
            setCampaignName('');
            navigate('/notification-dashboard');
          } else {
            setErrorMessage(response.data.data.message);
            setSuccessMessage('');
          }
        })
    }
  }

  const filterDataDownload = async (userStatus) => {
    try {
      let response = await filterUserDataDownload(memberFilterSelectQuery, userStatus);
      const blob = new Blob([response.data], { type: 'text/csv' });
      
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      if(campaignName === ''){
      link.setAttribute('download', 'data.csv');
      }else{
        link.setAttribute('download', campaignName+'.csv');
      }
      document.body.appendChild(link);
      link.click();
      link.remove();
  
      window.URL.revokeObjectURL(downloadUrl);
      if (response.data.code == 200) {
        console.log("download", response.data.data);
      } else {
        console.log("No record found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="tw-p-4">
      <h3 className="tw-text-xl tw-font-bold tw-mb-4">Are you sure you are ready?</h3>

      {totalCountUserList > 0 && 
        <div className="tw-mb-4 tw-bg-gray-100 tw-p-4 tw-rounded-xl">
          <p className="tw-mb-2 tw-flex tw-flex-row tw-items-center ">
            The campaign will be set for {optOutNCount} members.&nbsp;
            {/* <a href="#" className="tw-text-blue-500 hover:tw-text-blue-700 tw-underline" onClick={(e) => filterDataDownload('N')}>
              [Download]
            </a> */}
            <button 
            type="button" 
            onClick={(e) => filterDataDownload('N')}
            className="dropdown-item btns-downloads ms-2 btn btn-primary">
              <i className="bi bi-download"></i>
              </button>
          </p>
          {optOutYCount > 0 && 
              <p className="tw-mb-2 tw-flex tw-flex-row tw-items-center ">
                A total of {totalCountUserList} members were filtered, however, {optOutYCount} members cannot receive this message.&nbsp;
                {/* <a href="#" className="tw-text-blue-500 hover:tw-text-blue-700 tw-underline" onClick={(e) => filterDataDownload('Y')}>
                  [Download details on why]
                </a> */}
                <button 
                type="button" 
                onClick={(e) => filterDataDownload('Y')}
                className="dropdown-item btns-downloads ms-2 btn btn-primary">
                  <i className="bi bi-download"></i>
                  </button>
              </p>
          }
          <span onClick={() => setIsModalOpen(true)} className='tw-text-gray-500 '>Click <span className="tw-text-sky-700 tw-cursor-pointer">here</span> to more information on message delivery</span>
          {/* <button 
            className="btn btn-success uplaod-file-btn"
            onClick={() => setIsModalOpen(true)}
          >
            More Information on Message Delivery
          </button> */}
        </div>
      }

      {/* Custom Modal */}
      {isModalOpen && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
          <div className="tw-bg-white tw-rounded-2xl tw-relative tw-max-w-2xl tw-w-full tw-mx-4 tw-max-h-[75vh] tw-overflow-y-auto no-scrollbar">
            <div className="tw-flex tw-justify-between tw-items-center  tw-px-4  tw-mb-4 tw-sticky tw-top-0 tw-bg-white">
              <h2 className="tw-text-xl tw-font-bold">Important Information: Message Delivery Limitations</h2>
              <span 
                onClick={() => setIsModalOpen(false)}
                className="tw-text-gray-500 tw-cursor-pointer hover:tw-text-gray-700 tw-text-5xl tw-mb-4"
              >
                ×
              </span>
            </div>
            
            <div className="tw-space-y-4  tw-px-4 ">
              <p className="tw-text-gray-700">
                Please be aware that message delivery may still fail due to several factors, including:
              </p>
              <ul className="tw-space-y-2 tw-list-decimal tw-pl-6">
                <li><span className="tw-font-semibold">Member Not on WhatsApp:</span> The mobile number registered is not linked to an active WhatsApp account.</li>
                <li><span className="tw-font-semibold">Opted Out of WhatsApp Communication:</span> The member has opted out and chosen not to receive WhatsApp messages.</li>
                <li><span className="tw-font-semibold">Meta Restrictions:</span> Meta may choose not to deliver the message if the member has already received a message from another business within a short period.</li>
                <li><span className="tw-font-semibold">Future-Scheduled Campaigns:</span> EngageHub will check the messaging limit at the time the campaign is set to start. For example, if a campaign is scheduled after 2 days and another campaign is sent tomorrow, any shared members between the campaigns may have restricted delivery due to the daily message limit.</li>
                <li><span className="tw-font-semibold">Phone Number Issues:</span> The mobile number provided is invalid, disconnected, or has other technical issues preventing delivery.</li>
                <li><span className="tw-font-semibold">WhatsApp Account Restrictions:</span> The member's WhatsApp account may have settings that limit message delivery from business accounts.</li>
                <li><span className="tw-font-semibold">Network Issues:</span> Temporary network or connectivity issues on the member's end may cause delivery failure.</li>
                <li><span className="tw-font-semibold">Message Blocked by Member:</span> The member may have actively blocked the business number on WhatsApp, preventing message delivery.</li>
                <li><span className="tw-font-semibold">Exceeded Daily Limit for the Business Account:</span> If the business account has exceeded its daily or monthly message quota, delivery to some members may be delayed or restricted.</li>
              </ul>
              <p className="tw-text-gray-700 tw-mt-4">
                For optimal message delivery, consider these factors when scheduling campaigns.
              </p>
            </div>
          </div>
        </div>
      )}

      <p className="tw-text-gray-600 tw-mb-6">
        Please make sure you have confirmed all the details before hitting the button.
        Once started, a campaign cannot be stopped midway and this might lead to unnecessary expenses at your end.
      </p>

      {errorMessage && (
        <p className="tw-text-red-600 tw-mb-4">{errorMessage}</p>
      )}
      {successMessage && (
        <p className="tw-text-green-600 tw-mb-4">{successMessage}</p>
      )}

      <div className="tw-flex tw-space-x-4">
        <button className="btn btn-success uplaod-file-btn" onClick={finalSubmitCampaign}>
          Set live
        </button>
      </div>
    </div>
  );
};

export default SetMessageLive;