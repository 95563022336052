import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

export const fetch_all_dashboards_list = () => {
    return common_axios.get(baseURL + 'observations/fetch_all_dashboards_list');
};


export const add_observation_data = (data) => {
    return common_axios.post(baseURL + 'observations/add_observation_data', data);
}

export const list_all_observations =  (data) => { 
    return common_axios.post(baseURL + 'observations/list_all_observations', data);
}

export const fetch_single_observation = (data)  => { 
    return common_axios.post(baseURL + 'observations/fetch_single_observation', data);
}


export const approve_or_reject_observation = (data) => { 
    return common_axios.post(baseURL + 'observations/approve_or_reject_observation', data);
}

export const comment_on_observation = (data) => { 
    return common_axios.post(baseURL + 'observations/comment_on_observation', data);
}

export const fetch_observation_thread = (data) => { 
    return common_axios.post(baseURL + 'observations/fetch_observation_thread', data);
}

export const fetch_all_status_list = (data) => { 
    return common_axios.post(baseURL + 'observations/fetch_all_statuses', data);
}

export const change_observation_status = (data) => { 
    return common_axios.post(baseURL + 'observations/change_observation_status', data);
}

export const fetch_all_observation_statuses = (data) => {
    return common_axios.post(baseURL + 'observations/fetch_all_observation_statuses', data);
}

export const export_observations_data = (filters) => {
    return common_axios.get(baseURL + 'observations/export_observations_data', { 
        params: filters,
        responseType: 'blob'  // Important for handling file downloads
    });
};
