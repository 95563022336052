import React from "react";
import WhatsAppTemplates from "../../WhatsAppTemplates/WhatsAppTemplates";

const CreateTemplateMet = () =>{
console.log("met");
return(
<WhatsAppTemplates/>
);

}
export default CreateTemplateMet;