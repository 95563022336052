import React from "react";

const WhatsappFlow = ({
  buttonText,
  setButtonText,
  flowId,
  setFlowId,
  flowAction,
  setFlowAction,
  screenId,
  setScreenId,
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-mt-4">
      <h3 className="tw-text-lg tw-font-semibold tw-mb-2">WhatsApp Flow</h3>
      <p className="tw-mb-4">
        Create interactive forms in the WhatsApp interface.
        <a href="#" className="tw-text-blue-600 tw-ml-1">
          Know more here
        </a>
      </p>

      <div className="tw-bg-gray-100 tw-p-4 tw-rounded-md">
        <div className="tw-mb-4">
          <label className="tw-block tw-font-semibold tw-mb-2">
            Flow Button Text
          </label>
          <div className="tw-relative">
            <input
              type="text"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
              placeholder="Enter text for the button"
              className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
              maxLength={25}
            />
            <span className="tw-absolute tw-right-2 tw-top-2 tw-text-gray-400">
              {buttonText.length}/25
            </span>
          </div>
        </div>

        <div className="tw-mb-4">
          <label className="tw-block tw-font-semibold tw-mb-2">Flow ID</label>
          <input
            type="text"
            value={flowId}
            onChange={(e) => setFlowId(e.target.value)}
            placeholder="Enter input here"
            className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
          />
        </div>

        <div>
          <label className="tw-block tw-font-semibold tw-mb-2">
            Flow Action
          </label>
          <div className="tw-flex tw-flex-col tw-gap-2">
            <label className="tw-flex tw-items-center">
              <input
                type="radio"
                value="Navigate"
                checked={flowAction === "Navigate"}
                onChange={() => setFlowAction("Navigate")}
                className="tw-mr-2"
              />
              Navigate
            </label>
            {flowAction === "Navigate" && (
              <input
                type="text"
                value={screenId}
                onChange={(e) => setScreenId(e.target.value)}
                placeholder="Screen ID to navigate to"
                className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-ml-5"
              />
            )}
            <label className="tw-flex tw-items-center">
              <input
                type="radio"
                value="Data Exchange"
                checked={flowAction === "Data Exchange"}
                onChange={() => setFlowAction("Data Exchange")}
                className="tw-mr-2"
              />
              Data Exchange
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappFlow;
