import React, { useState, useEffect, useRef } from 'react';
import {Device} from 'twilio-client'; 
import { useSelector } from 'react-redux';
import { fetchsoftphonetokencn, clearfirebasecnnode } from '../services/Service';
import { setIsIncoming, setLocalAudiostream, setWebexCall } from '../../../../store/webexSlice';
import { useDispatch } from 'react-redux';



const IncommingAudioCall = ({incommingConfNumber, isAcceptedCall, setIsAcceptedCall, cnId, memberName, agentName, callerInfo}) => {

    const webexCallData = useSelector(state => state.webex); 
    const callAudio = useRef(); 

    const dispatch = useDispatch(); 
    const [callStatus, setCallStatus] = useState("idle"); 
    const [displayCallStatus, setDisplayCallStatus] = useState("Connecting"); 
    const [isCallOnHold, setIsCallOnHold] = useState(false); 
    const [conferenceNumber, setConferenceNumber] = useState(""); 
    const [currDevice, setCurrDevice] = useState(null); 
    

    const [timer ,setTimer] = useState({ 
        seconds: "00",
        minutes: "00",
        hours: "00",
        isTimerAlreadyStarted: false,
        intervalId: null,
    }); 

    async function acceptIncomingcall(){
      let token; 
      let localAudioStream; 

      localAudioStream  = await window.Calling.createMicrophoneStream({audio: true});
      
      let call = webexCallData.webexCall; 
      
      if(!call) return;     
      
      
      
      
      try{ 
        // get localAudio steram 
        dispatch(setLocalAudiostream(localAudioStream));     
        
        setIsAcceptedCall(true); 
        token = await fetchsoftphonetokencn({confNumber: incommingConfNumber, setIsAcceptedCall}); 
        token = token.data.token; 

        webexCallData.webexCall.answer(localAudioStream); 

        call.on('connect', () => { 

        }); 

       
        call.on('established', () => { 
          setDisplayCallStatus("Connected"); 
        }); 

        call.on('disconnect', () => {
          setIsAcceptedCall(false); 
        });

        call.on('remote_media', (track) => {
          callAudio.current.srcObject = new MediaStream([track]);
        });

        toggleTimer(); 

      }catch(e){ 

      }
    }
    
  // twillio call accept functin
// async function acceptIncomingcall(){ 
//     let token; 
//     try{    
//         setIsAcceptedCall(true); 
//         // stopRingtone(); 
//         token = await fetchsoftphonetokencn({confNumber: incommingConfNumber, setIsAcceptedCall}); 
        
//         token = token.data.token; 

//         // creating device  
//         const device = new Device(token, { 
//             debug: true
//         }); 

//         setCurrDevice(device);

//         device.on('ready', function  (){ 
//             device.connect({ From: incommingConfNumber, type: "carenavigator", typeId: cnId  });
//         }); 

//         device.on('connect', () => { 
//           console.log('conected now....................'); 
//         })

//         device.on('disconnect', () => { 
//           device.disconnectAll(); 
//           setIsAcceptedCall(false); 
//           toggleTimer(true); 
//           // stopRingtone(); 
//         })
        
//         await handleCall("accept"); 
//         toggleTimer(); 

//     }catch(e){ }

// }

async function handleCall(type){  
    try{        
        await clearfirebasecnnode({type, confNumber: incommingConfNumber}); 
    }catch(e){ 
        // handle erorr
    }
    
}





function toggleTimer(stop = false) {
    let { seconds, minutes, hours, isTimerAlreadyStarted, intervalId } = timer;
  
    seconds = parseInt(seconds);
    minutes = parseInt(minutes);
    hours = parseInt(hours);
  
    if (!stop && !isTimerAlreadyStarted) {

      intervalId = setInterval(() => {
        seconds++;
        
        if (seconds === 60) {
          seconds = 0;
          minutes++;
        }
  
        if (minutes === 60) {
          minutes = 0;
          hours++;
        }
  
        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedHours = hours < 10 ? "0" + hours : hours;
  
        setTimer({
          seconds: formattedSeconds,
          minutes: formattedMinutes,
          hours: formattedHours,
          isTimerAlreadyStarted: true,
          intervalId: intervalId,
        });
      }, 1000);
  
    } else {
      clearInterval(intervalId);
      setTimer({
        ...timer, 
        isTimerAlreadyStarted: false,
        intervalId: null,
      });
    }
  } 

  // reject incoming cal 
  async function rejctCall(){ 

    webexCallData.webexCall?.end(); 
    dispatch(setWebexCall(null)); 
    dispatch(setIsIncoming(false)); 
    

    // Twillio code 
    
    // if(currDevice) currDevice.disconnectAll();
    // // stopRingtone(); 
    // await handleCall("reject"); 
    // setIsAcceptedCall(false);   
    // toggleTimer(true); 
  }



//   disconnect call
async function disconnectCall(){ 

  webexCallData?.webexCall?.end(); 
  setIsAcceptedCall(false); 
  dispatch(setIsIncoming(false)); 

  // old twillio code
    // if(currDevice) currDevice.disconnectAll();
    // // stopRingtone(); 
    // await handleCall("disconnect"); 
    // toggleTimer(true); 
    // setIsAcceptedCall(false); 
}

  return (
      <>
        {
            !isAcceptedCall ? 

            <div className=''>
               <div className='col-12 p-3'>
                <h3 class="coach-card-subtitle text-center" style={{fontSize:"20px", lineHeight:"1.4"}}>Agent <b>{callerInfo?.name}</b> invites you to join a call <br/>with Member <b>{memberName}</b></h3>
              </div>
              <div className='col-12 mt-2 mb-3 text-center'>
                <button 
                    className="btn btn-dark coach-call-accept-btn mx-2"
                    alt="soft phone call"
                    onClick={e => acceptIncomingcall(e)}
                    
                >   
                    Accept
                </button>
                <button 
                    className="btn btn-dark coach-call-reject-btn mx-2" 
                    style={{background: 'red !important'}}
                    alt="soft phone call"
                    onClick={e => rejctCall()}
                >
                    Reject
                </button>
                </div>
            </div>: 

            <>
                <span className=' mx-2 me-5'>
                <h2 class="coach-card-title">Agent</h2>
                <h3 class="coach-card-subtitle">Call Status: {displayCallStatus}</h3>
                <h3 class="coach-card-subtitle">Call Duration: {timer.hours}:{timer.minutes}:{timer.seconds}</h3>


                </span>



                {/* <button 
                className="btn btn-dark coach-calling-btn mx-1"
                alt="soft phone call"
                onClick={e => acceptIncomingcall(e)}
                >   
                <i class="bx bxs-phone"></i>
                </button> */}



                <button 
                className="btn btn-dark coach-call-close-btn mx-1" 
                alt="soft phone call"
                onClick={disconnectCall}
                >
                <i class="bx bxs-phone"></i>
                </button>
            </>
        }

            


        <audio ref={callAudio} id="remote-audio" autoPlay></audio>

          
        </>
         

  );
}

export default IncommingAudioCall;
