
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import VideoTable from "./ContentManagement/VideoTable";
import BlogsTable from "./ContentManagement/BlogsTable";
import AddNewBlog from "./ContentManagement/AddNewBlog";
import AddNewVideo from "./ContentManagement/AddNewVideo";
import {CommingsoonPage} from "../utils/CommonLibrary";

function ContentManagementDetails() {


    return (
        <>
          <section className='ContentCareNavigator'>
            <div className="row align-items-center pb-3 sticky-top-bar">
              <div className="col-md ">
                <h1 className="main-title">Content Management</h1>
              </div>
            </div>
            
            <div className="container-fluid">
              <div className="row align-items-center pb-3">
                <div className='comntent-CareNavigator mb-3'>
                  <div className="row">
                    <div className="col-md-12">
                    <CommingsoonPage />
                      {/* <Tabs>
                        <TabList>
                          <Tab>Video</Tab>
                          <Tab>Blogs (Healthy Reads)</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="table-responsive">
                            <VideoTable />
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="table-responsive">
                            <BlogsTable />
                          </div>
                        </TabPanel>
                      </Tabs> */}
                    </div>
                  </div>
                  {/* <AddNewVideo />
                  <AddNewBlog /> */}
                </div>
              </div>
            </div>
          </section>
        </>
    );
}

export default ContentManagementDetails;

