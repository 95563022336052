import React from 'react';
import './CategoryMaster.css';


function CategoryMaster() {

  return (
    <>
      <section className='CategoryMaster'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Category Master</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='category-master mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qhkh8/ws4h7sc4wj.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Add an image that represents the "Rewards program" category. This image will enhance category visibility and appeal on the platform.</p>
              </div>
            </div>
          </div>

          <div className='category-master p-3'>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Category Name</label>
                    <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Category Name"/>
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Upload Category Image</label>
                    <input type="file" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Upload Category Image"/>
                </div>
            </div>


            <div className="row mb-3">
                <div className="col-md-6">
                <button type="button" id="startProcess" className="btn btn-success uplaod-file-btn">Create Category</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                <hr className='mb-4' />
                <div className="table-responsive">
                <table className="table table-striped mb-0">
                    <thead className='table-dark '>
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Image</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                            Category Name 1
                            </td>
                            <td>
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qhkh8/ws4h7sc4wj.jpg" className='img-fluid' alt="" />
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                            Category Name 2
                            </td>
                            <td>
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qhkh8/ws4h7sc4wj.jpg" className='img-fluid' alt="" />
                            </td>
                            <td>
                                <button className="btn btn-Edit">Edit</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>


                        <tr>
                            <td>
                            <input type="text" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Category Name"/>
                            </td>
                            <td>
                            <input type="file" className="form-control input-field-ui" id="nameProgram" placeholder="Enter Upload Category Image"/>
                            </td>
                            <td>
                                <button className="btn btn-Edit">Save</button> | <button className="btn btn-Cancel">Delete</button>
                            </td>
                        </tr>

                    </tbody>
                    </table>
                    </div>
                    
                </div>
               
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
}

export default CategoryMaster;
