import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videoPreview, height }) => {
  return (
    <ReactPlayer
      url={videoPreview}
      width="100%"
      height={height}
      controls={true}
    />
  );
};

export default VideoPlayer;