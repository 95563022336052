import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";

import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import {
    addNotes,
    fetchNotesList
} from "../../components/services/Service";

import './MemberServices.css';

const MemberNotes = ({ memberBasicInfo, showNotesModal, setShowNotesModal, userId }) => {
    const [notes, setNotes] = useState("");
    const [notesList, setNotesList] = useState([]);
    const [addNotesError, setAddNotesError] = useState("");
    const [addNotesMessage, setAddNotesMessage] = useState("");

    useEffect(() => {
        setAddNotesError("");
        getNotesList();
    }, [userId])

    const getNotesList = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchNotesList(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setNotesList(response.data.data.notes);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const saveNotes = async () => {
        setAddNotesError("");
        setAddNotesMessage("");
        if (notes.trim() === "") {
            setAddNotesError("Please enter notes.");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addNotes(userId, notes.trim(), ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setAddNotesError("");
                        setAddNotesMessage("");
                        setNotes("");
                        getNotesList();
                    } else {
                        setAddNotesError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }
    return (
        <>
            <Modal id="notesModal" className='HealthManagement' show={showNotesModal} onHide={() => {
                setShowNotesModal(false);
            }} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Notes - {memberBasicInfo.memberName}</h1>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <div className='table-responsive mt-2 mb-3' style={{ maxHeight: '53vh', overflowY: 'auto' }}>
                                <table className="table table-striped table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Role</th>
                                            <th style={{ "width": "46%" }}>Notes</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            notesList.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.role}</td>
                                                    <td>{item.notes}</td>
                                                    <td>{item.date}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {
                        memberBasicInfo.readonly === "N" &&
                        <>
                            <Row>
                                <Col>
                                    <textarea name="notes" className='form-control control-input-ui' onChange={(e) => setNotes(e.target.value)} value={notes}></textarea>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <br />
                                    <div className={`${addNotesError != "" ? "errorDiv" : "hide"}`}>{addNotesError}</div>
                                    <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => saveNotes()}>Save</Button>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MemberNotes