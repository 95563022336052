import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL;

// fetch admin userdata 
export const addHealthLockerFile = (membershipNumber, reportTypeId, title, originalName, file, cancelToken) => {
    const params = { 'membershipNumber': membershipNumber, 'reportTypeId': reportTypeId, 'title': title, 'originalName': originalName, 'file': file }
    var dataPass = common_axios.post(baseURL + 'rpa/add_healthlocker_file', params, cancelToken)
    return dataPass;
};

export const fetchReportTypes = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'rpa/fetch_report_types', params, cancelToken)
    return dataPass;
};

export const fetchHealthLockerFiles = (membershipNumber, reportTypeId, pageNumber, perPage, cancelToken) => {
    const params = { 'membershipNumber': membershipNumber, 'reportTypeId': reportTypeId, 'pageNumber': pageNumber, 'perPage': perPage }
    var dataPass = common_axios.post(baseURL + 'rpa/fetch_healthlocker_files_v2', params, cancelToken)
    return dataPass;
};

export const fetchHealthlockerFileUrl = (fileId, cancelToken) => {
    const params = { 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'rpa/fetch_healthlocker_file_url', params, cancelToken)
    return dataPass;
};
