import React, { useState, useRef, useCallback } from "react";
import {
  FiSearch,
  FiFilter,
  FiBarChart2,
  FiChevronLeft,
  FiX,
  FiUser,
  FiCheck,
} from "react-icons/fi";
import { useInboxContext } from "./InboxContext";
import { useEffect } from "react";
import {
  member_details,
  update_status,
  member_list,
} from "../../../services/whatsapp/Service";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import moment from "moment";

const ContactList = () => {
  const {
    contacts,
    setSelectedContact,
    selectedContacts,
    setSelectedContacts,
    currentPage,
    setCurrentPage,
    hasMore,
    setHasMore,
    loading,
    setFamily,
    setSearchTerm,
    search,
    setStatus,
    setContacts,
    sortOrder,
    setSortOrder,
    status,
    setShowKeyBoard,
    setChatResult,
    setChatpage,
    setLoadingMore,
    setchangeFilterState,
    setLoading,
    unreadCount,
    setUnreadCount,
    currentlySelectedContact,
    setCurrentlySelectedContact,
    chatPage,
    setTemplateStatus,
    optOut,
    setOptOut
  } = useInboxContext();

  const observer = useRef();

  const backbutton=()=>{
    setActiveMode(null);
    setContacts([]);
    setHasMore(true);
    if(currentPage>1){
      setCurrentPage(1);
    }else{
      fetchContacts("all",1,"",sortOrder)
    }
    setSearchTerm("");
  }

  const fetchContacts = async (status, page, search, sortOrder) => {
    setLoading(true);
    try {
      const response = await member_list({
        status: status,
        page: page,
        search: search,
        sort: sortOrder,
      }); // Pass the current page to the API
      if (response.data.data.list && response.data.data.list.length > 0) {
        setContacts((prevContacts) => [
          ...prevContacts,
          ...response.data.data.list,
        ]); // Append new contacts
        setUnreadCount(response.data.data.unreadcount);
        //setChatpage(page + 1); // Update the current page
      } else {
        setHasMore(false); // No more data to load
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchContacts(status, currentPage, search, sortOrder);
  }, [currentPage, search]);

  const dropdownFilter = (e) => {
    console.log("e.target.value", e.target.value);
    const selectedValue = e.target.value; // Capture selected value
    setStatus(selectedValue); // Update status in context
    setContacts([]); // Clear previous contacts
    setHasMore(true);
    //alert(currentPage)
    if (currentPage == 1) {
      fetchContacts(selectedValue, 1);
    } else {
      setCurrentPage(1);
    }
  };

  // Callback to handle loading the next page when reaching the bottom of the list
  const lastContactElementRef = useCallback(
    (node) => {
      console.log(loading,'loading');
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((currentPage) => currentPage + 1); // Load next pag
          // fetchContacts(status, currentPage);
        }
      },
      {
        // Add root margin to trigger slightly before reaching the bottom
        rootMargin: '20px',
        threshold: 0.5,
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const [activeMode, setActiveMode] = useState(null); // 'search', 'filter', or 'selection'
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [memberType, setMemberType] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());


  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };
  

  const toggleMode = (mode) => {
    if (mode === null) {
      setSearchTerm("");
      setContacts([]); // Clear results when exiting search mode
    }
    setActiveMode(activeMode === mode ? null : mode);
  };
  const handleReset = () => {
    setStatus("all"); // Reset chat status
    setSortOrder("Newest"); // Reset sort order
    //setCurrentPage(1); // Call API on Enter
  };

  const filterReset = () => {
    // alert("here");
    setActiveMode(null);
    setContacts([]);
    setHasMore(true);
    if (currentPage == 1) {
      fetchContacts("all", 1, "", "");
    } else {
      setCurrentPage(1);
    }
    setSearchTerm("");
  };

  const handleApply = () => {
    setActiveMode(null); // Close filter view
    setHasMore(true);
    setContacts([]);
    if (currentPage == 1) {
      fetchContacts(status, 1, "", sortOrder);
    } else {
      setCurrentPage(1);
    }
  };

  

  const handleKeyDown = (event) => {
    if(!event.target.value) return;
    if (event.key === "Enter") {
      setSearchTerm(event.target.value); // Set search term
      setContacts([]);
      setCurrentPage(1); // Call API on Enter
      setHasMore(true);
    }
  };

  
  const toggleSelection = (id) => {
    setSelectedContacts((prev) =>
      prev.includes(id)
        ? prev.filter((contactId) => contactId !== id)
        : [...prev, id]
    );
  };

  const refreshmembers = () => {
    setActiveMode(null);
    setContacts([]);
    setHasMore(true);
    if(currentPage>1){
      setCurrentPage(1);
    }else{
      fetchContacts("all",1,"",sortOrder)
    }
    setSearchTerm("");
  };


  const handleContactClick = async (contact) => {
    if (!activeMode || activeMode !== "selection") {
      console.log("contact", contact);
      if(!contact) return;
      try {
        setChatpage(1);
        setLoadingMore(true);
        setChatResult([]);

        const response = await member_details({ id: contact.id }); // Pass the current page to the API
        if (response.data.code==200 && response.data.data.member.length > 0) {
          setSelectedContact(response.data.data.member);
          setCurrentlySelectedContact(contact.id);
          setFamily(response.data.data.family);
          setShowKeyBoard(response.data.data.showKeyboard);
          setTemplateStatus(response.data.data.templateStatus)
          setOptOut(response.data.data.optOut)
          console.log(response.data.data.showKeyboard,'ss')
          // if(chatPage>1){
          //   //setChatpage(1);
          // }
          // setTimeout(() => {
          //   setChatpage(1);
          // }, 1000);

          // setLoadingMore(true);
          // setChatResult([]);
          update_status({ id: contact.id });
          
        }
      } catch (error) {
        console.error("Failed to fetch contacts:", error);
      }
    }
  };

  return (
    <div className="tw-h-full tw-flex tw-flex-col">
      {/* <Header 
        activeMode={activeMode}
        toggleMode={toggleMode}
        selectedCount={selectedContacts.length}
      /> */}
      <div className="tw-h-full tw-flex tw-flex-col">
        <Header
          activeMode={activeMode}
          toggleMode={toggleMode}
          selectedCount={selectedContacts.length}
          setchangeFilterState={setchangeFilterState}
          fetchContacts={fetchContacts}
          currentPage={currentPage}
          dropdownFilter={dropdownFilter}
          unreadCount={unreadCount}
          refreshmembers={refreshmembers}
          setActiveTooltip={setActiveTooltip}
          activeTooltip={activeTooltip}
        />

        {/* Search and Filter View */}
        <div className="tw-h-full tw-overflow-hidden tw-mb-1.5">
          {activeMode === "search" ? (
            <SearchView
              setActiveMode={setActiveMode}
              setSearchTerm={setSearchTerm}
              handleKeyDown={handleKeyDown}
              fetchContacts={fetchContacts}
              search={search}
              setContacts={setContacts}
              setHasMore={setHasMore}
              setCurrentPage={setCurrentPage}
              backbutton={backbutton}
              searchJSX={
                <div className="tw-mt-2 tw-relative tw-h-full tw-overflow-y-auto">
                  {contacts.length > 0 ? (
                    <ContactItems
                      contacts={contacts}
                      selectionMode={activeMode === "selection"}
                      selectedContacts={selectedContacts}
                      toggleSelection={toggleSelection}
                      handleContactClick={handleContactClick}
                      currentlySelectedContact={currentlySelectedContact}
                      lastContactElementRef={lastContactElementRef}
                      setTemplateStatus={setTemplateStatus}

                  />
                  ) : (
                    <div className="tw-text-center tw-py-4 tw-text-gray-500">
                      No results found
                    </div>
                  )}
                </div>
              }
            />
          ) : (
            activeMode === "filter" ? (
              <FilterView
              setActiveMode={setActiveMode}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setStatus={setStatus}
              status={status}
              handleReset={handleReset}
              handleApply={handleApply}
              setHasMore={setHasMore}
              setCurrentPage={setCurrentPage}
              setContacts={setContacts}
              setSearchTerm={setSearchTerm}
              filterReset={filterReset}
              memberType={memberType}
              setMemberType={setMemberType}
              handleDateChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
            />
            ) : (
              <div className="tw-mt-2 tw-relative tw-h-full tw-overflow-y-auto">
                <ContactItems
                  contacts={contacts}
                  selectionMode={activeMode === "selection"}
                  selectedContacts={selectedContacts}
                  toggleSelection={toggleSelection}
                  handleContactClick={handleContactClick}
                  currentlySelectedContact={currentlySelectedContact}
                  lastContactElementRef={lastContactElementRef}
                  setTemplateStatus={setTemplateStatus}
            />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const CustomTooltip = ({ text, visible }) => {
  if (!visible) return null;
  
  return (
    <div 
      className="tw-absolute -tw-left-[160%] -tw-bottom-[170%] tw--translate-y-1/2 tw-mr-2 tw-z-[999999] tw-pointer-events-none"
    >
      <div className="tw-bg-[#1279BE] tw-text-white tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-whitespace-nowrap tw-relative">
        {text}
        {/* Arrow pointing up */}
<div className="tw-absolute tw-left-1/2 tw-top-[-6px] tw--translate-x-1/2 tw-w-0 tw-h-0 tw-border-b-4 tw-border-x-4 tw-border-solid tw-border-x-transparent tw-border-b-[#1279BE]" />
      </div>
    </div>
  );
};

const Header = ({
  activeMode,
  toggleMode,
  selectedCount,
  setchangeFilterState,
  fetchContacts,
  currentPage,
  dropdownFilter,
  unreadCount,
  refreshmembers,
  setActiveTooltip,
  activeTooltip,
}) => {
  const {
    setStatus,
    setCurrentPage,
    setContacts,
    status,
    setHasMore,
  } = useInboxContext(); // Include status

  if (activeMode === "selection") {
    return (
      <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-bg-white tw-rounded-">
        <div className="tw-flex tw-items-center">
          <button onClick={() => toggleMode(null)} className="tw-mr-2">
            <FiChevronLeft />
          </button>
          <span>{selectedCount} selected</span>
        </div>
        <div>
          <button className="tw-mr-2">
            <FiUser />
          </button>
          <button>
            <FiCheck />
          </button>
        </div>
      </div>
    );
  }
  

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-p-2 tw-border-b tw-bg-white tw-relative tw-z-[9999]">
  <div className="tw-flex tw-items-center tw-gap-3 tw-flex-1 tw-max-w-[600px]">
  <div className={`tw-flex-1 ${
  unreadCount.toString().length === 1 ? 'tw-max-w-[150px]' :
  unreadCount.toString().length === 2 ? 'tw-max-w-[160px]' :
  unreadCount.toString().length === 3 ? 'tw-max-w-[170px]' :
  'tw-max-w-[180px]'
} tw-relative`}>
  <select
    onChange={(e) => dropdownFilter(e)}
    value={status}
    className="form-select input-field-ui"
  >
    <option value="all">All</option>
    <option value="read">Read</option>
    <option value="unread">Unread</option>
  </select>
  {unreadCount >= 0 && (
    <div className="tw-absolute tw-right-8 tw-top-1/2 tw-transform -tw-translate-y-1/2">
      
        <span 
        onMouseEnter={() => setActiveTooltip('unreadCount')}
        onMouseLeave={() => setActiveTooltip(null)} 
        className="tw-inline-flex tw-items-center tw-justify-center tw-min-w-[30px] tw-h-[28px] tw-bg-[#03335b] tw-text-white tw-rounded-xl tw-px-2 tw-relative">
          <span className="tw-text-sm">{unreadCount}</span>
          <CustomTooltip 
              text="Unread Members"
              visible={activeTooltip === 'unreadCount'}
            />
        </span>
    </div>
  )}
</div>

    {/* {unreadCount>=0 && (
      <div className="tw-flex-shrink-0">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Unread Chats
            </Tooltip>
          }>
        <span className="tw-inline-flex tw-items-center tw-justify-center tw-min-w-[40px] tw-h-[38px] tw-bg-[#03335b] tw-text-white tw-rounded-xl tw-px-3">
          <span className="tw-text-base">{unreadCount}</span>
        </span>
        </OverlayTrigger>
      </div>
    )} */}

  </div>

  <div className="tw-flex tw-items-center tw-gap-2 tw-ml-2">
    <span
      onClick={refreshmembers}
      className="tw-flex-shrink-0 tw-inline-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
    >
      <i className="bi bi-arrow-clockwise tw-text-lg"></i>
    </span>
    <span 
      onClick={() => toggleMode("search")} 
      className="tw-inline-flex tw-items-center tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
    >
      <FiSearch size={18} />
    </span>
    
    {/* <span 
      onClick={() => toggleMode("filter")} 
      className="tw-inline-flex tw-items-center tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
    >
      <FiFilter size={18} />
    </span> */}
  </div>
</div>
  );
};

const SearchView = ({
  setActiveMode,
  handleKeyDown,
  searchTerm,
  setSearchTerm,
  fetchContacts,
  search,
  setContacts,
  setHasMore,
  setCurrentPage,
  backbutton,
  searchJSX,
}) => (
  <div className="tw-absolute tw-inset-0 tw-bg-white tw-z-[999999] tw-flex tw-flex-col">
    <div className="tw-p-4">
      <div className="tw-flex tw-items-center tw-mb-4">
        <span
          onClick={backbutton}
          className="tw-mr-2 tw-mb-2 tw-cursor-pointer tw-text-gray-500 tw-text-lg tw-font-bold"
        >
          <FiChevronLeft />
        </span>
        <h5 className="tw-font-bold tw-text-gray-500">Search Inbox</h5>
      </div>
      <div className="tw-relative">
        <input
          type="text"
          placeholder="Search By Member ID"
          className="form-control input-field-ui tw-pl-9"
          value={searchTerm}
          onKeyDown={handleKeyDown}
        />
        <FiSearch
          className="tw-absolute tw-left-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-text-gray-400"
          size={20}
        />
      </div>
    </div>
    <div className="tw-flex-1 tw-overflow-hidden">
      {searchJSX}
    </div>
  </div>
);

const FilterView = ({
  setActiveMode,
  setStatus,
  status,
  setContacts,
  setHasMore,
  setCurrentPage,
  setSearchTerm,
  sortOrder,
  setSortOrder,
  handleApply,
  handleReset,
  filterReset,
  memberType,
  setMemberType,
  handleDateChange,
  startDate,
  endDate,
}) => (
  <div
    className="tw-absolute tw-inset-0 tw-bg-white tw-z-[999999] tw-overflow-y-auto"
  >
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-start tw-items-center tw-mb-4">
      <span
          onClick={filterReset}
          className="tw-mr-2 tw-mb-1 tw-cursor-pointer tw-text-gray-500 tw-text-lg"
        >
          <FiChevronLeft className="tw-mb-1.5" />
        </span>
      <h5 className="tw-font-bold tw-text-gray-500">Select filters</h5>
      </div>
      {/* <div className="tw-mb-4">
        <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Chat Status</p>
        <select
          className="form-select input-field-ui"
          value={status} // Bind chatStatus prop
          onChange={(e) => setStatus(e.target.value)} // Update state on change
        >
          <option value="">All</option>
          <option value="Read">Read</option>
          <option value="Unread">Unread</option>
        </select>
      </div> */}
      {/* <div className="tw-mb-4">
      <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Sort</p>
        <select
          className="form-select input-field-ui"
          value={sortOrder} // Bind sortOrder prop
          onChange={(e) => setSortOrder(e.target.value)} // Update state on change
        >
          <option value="Newest">Newest</option>
          <option value="Oldest">Oldest</option>
        </select>
      </div> */}

<div className="tw-mb-4">
      <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Member Type</p>
        <select
          className="form-select input-field-ui"
          // value={sortOrder} // Bind sortOrder prop
          // onChange={(e) => setSortOrder(e.target.value)} // Update state on change
          value={memberType}
          onChange={(e) => setMemberType(e.target.value)}
        >
          <option value="enrolled">Enrolled</option>
          <option value="not-enrolled">Not Enrolled</option>
        </select>
      </div>
      {/* <div className="tw-mb-4">
      <p className="tw-font-semibold tw-mb-2 tw-text-gray-500">Date Range Filter</p>
      <div className="tw-w-full">
        <CalendarDatePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
          left={true}
        />
      </div>
      </div> */}
      <div className="tw-flex tw-justify-between">
        <button onClick={handleReset} className="btn btn-outline-dark">
          Reset
        </button>
        <button
          onClick={handleApply}
          className="btn btn-primary clear-search-btn"
        >
          Apply Filters
        </button>
      </div>
    </div>
  </div>
);

const ContactItems = ({
  contacts,
  selectionMode,
  selectedContacts,
  toggleSelection,
  handleContactClick,
  currentlySelectedContact,
  lastContactElementRef,
  setTemplateStatus

}) => {

  const truncateText = (text) => {
    const words = text.split(' ');
    if (words.length > 3) {
      return words.slice(0, 3).join(' ') + '...';
    }
    return text;
  };

  const replaceVariables = (content) => {
    if (!content) return '';
    
    return content
      // Handle line breaks first
      .replace(/\\n/g, '<br />')
      
      // Bold: *text* (not preceded or followed by space)
      .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
      
      // Italic: _text_ (not preceded or followed by space)
      .replace(/(?<!\s)_(.*?)_(?!\s)/g, '<em>$1</em>')
      
      // Strikethrough: ~text~ (not preceded or followed by space)
      .replace(/(?<!\s)~(.*?)~(?!\s)/g, '<del>$1</del>')
      
      // Monospace: ```text``` 
      .replace(/```(.*?)```/g, '<code>$1</code>')
      
      // Single line code: `text`
      .replace(/`(.*?)`/g, '<code>$1</code>')
  
      // URLs: automatically link URLs
      .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">$1</a>')
  
      // WhatsApp style quotes: > at start of line
      .replace(/(^|\n)&gt; ?([^\n]*)/g, '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>')
      
      // Lists: - or • at start of line
      .replace(/(^|\n)[-•] ?([^\n]*)/g, '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>')
      
      // Handle multiple underscores or asterisks in a single word
      .replace(/(\*{2}|_{2})(.*?)\1/g, '<strong>$2</strong>')
      .replace(/(\*{3}|_{3})(.*?)\1/g, '<strong><em>$2</em></strong>')
      
      // Fix any potentially broken HTML from nested formatting
      .replace(/<(\/?)(strong|em|del|code)>/g, '<$1$2>');
  };

  return (
  <div className="tw-overflow-y-auto">
    {contacts.map((contact, index) => (
      <div 
        key={contact.id}
        ref={index === contacts.length - 1 ? lastContactElementRef : null} // Attach ref to last item
        className={`tw-flex tw-relative tw-z-0 border-bottom hover:tw-bg-gray-100 tw-rounded-xl tw-transition-all tw-duration-300 tw-items-center tw-p-4 tw-cursor-pointer 
          ${selectionMode && selectedContacts.includes(contact.mobile) ? "tw-bg-blue-300" : ""}
          ${!selectionMode && currentlySelectedContact === contact.mobile ? "tw-bg-blue-100" : ""}
          ${currentlySelectedContact === contact.id ? 'tw-bg-[#f0f4ff] tw-pointer-events-none' : ''} // Add styles for disabled
        `}
        onClick={() => {
            handleContactClick(contact); // Call the handler only if the contact is different
        }}
      >
        <div className="tw-flex-grow">
          <div className="tw-flex tw-justify-between">
            <span className="tw-font-semibold">{contact.memberName}</span>
            <span className="tw-text-sm tw-text-gray-500">
              {contact.messageTime}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-justify-between">
            {/* <span className="tw-text-green-500 tw-mr-1">●</span> */}
            <span dangerouslySetInnerHTML={{ __html: truncateText(replaceVariables(contact.messageText)) }} className="tw-text-sm tw-text-gray-500">
            {/* {replaceVariables(truncateText(contact.messageText))}   */}
            </span>
            {contact.unreadCount > 0 &&  currentlySelectedContact !== contact.id  && (

<span className="tw-text-xs tw-text-white tw-px-2 tw-py-1 tw-bg-[#03335b] tw-rounded-full">{contact.unreadCount}</span>
)}
          </div>
        </div>
        {/* <hr></hr> */}
      </div>
    ))}
  </div>
);
}

export default ContactList;
