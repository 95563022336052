import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './LabRequestForms.css';
import { fetchUserProfile, fetchFormData, saveLabRequest } from '../services/Service';
import axios from "axios";
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';

const LabRequestForms = ({ closeModal, selectedServiceRecord, callHomeBasedServicesHistory }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [labProviderList, setLabProviderList] = useState([]);
    const [partner, setPartner] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [cityList, setCityList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [serviceList, setServiceList] = useState([]);

    const [formData, setFormData] = useState({
        userId: userId,
        selectedServiceRecord: selectedServiceRecord,
        membership: '',
        name: '',
        approvalNumber: '',
        mobileNumber: '',
        deductible: '',
        partnerClinic: '',
        labProvider: '',
        city: '',
        district: '',
        remarks: '',
        requestedLabTest: '',
        nationality: '',
        selectedUserNames: ''
    });

    useEffect(() => {
        fetchUserData();
        dropdownData();
    }, [])


    const fetchUserData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        setDisabledButton(true);
        await fetchUserProfile(userId)
            .then((response) => {
                if (response.data.code === 200) {
                    setFormData((prev) => ({ ...prev, membership: response.data.data.info.membershipNumber, name: response.data.data.info.memberName, mobileNumber: response.data.data.info.mobile, city: response.data.data.info.city, nationality: response.data.data.info.nationality }));
                    setDisabledButton(false);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const dropdownData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchFormData()
            .then((response) => {
                console.log('test', response.data)
                if (response.data.code === 200) {
                    setLabProviderList(response.data.data.lab);
                    setPartner(response.data.data.partner);
                    setCityList(response.data.data.city);
                    setServiceList(response.data.data.labTest);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleChange = async (e) => {
        const { name, value } = e.target;
        // if (name === "deductible") {
        //     if (!/^\d*\.?\d*$/.test(value)) {
        //         return false;
        //     }
        // }
        setFormData({
            ...formData,
            [name]: value,
        });

        setError('');
    };

    const validateForm = () => {
        const mandatoryFields = ['membership', 'name', 'mobileNumber', 'deductible', 'partnerClinic', 'labProvider', 'city', 'requestedLabTest', 'nationality'];

        for (let field of mandatoryFields) {
            if ((field === 'city')) {
                if (formData[field] == "") {
                    setError('Enter all mandatory fields');
                    return false;
                }
            } else if (!formData[field].trim()) {
                setError('Enter all mandatory fields');
                return false;
            }
        }

        return true;
    };

    const handleChangeCity = (selectedOption) => {
        setFormData({
            ...formData,
            ['city']: selectedOption.label,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            setDisabledButton(true);
            await saveLabRequest(formData)
                .then((response) => {
                    if (response.data.code === 200) {
                        setError('');
                        setMessage(response.data.data.message);
                        callHomeBasedServicesHistory();
                        setTimeout(() => {
                            closeModal();
                        }, 1000);
                    } else {
                        setMessage('');
                        setError(response.data.data.message);
                        setDisabledButton(false);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleChangeService = (selected) => {
        setSelectedOptions(selected);
        const selectedValues = selected.map(option => option.value).join(',');
        setFormData({ ...formData, requestedLabTest: selectedValues });
    };

    const selectedUserNames = selectedOptions.map(option => option.label).join(', ');

    return (
        <>
            <div className='col-md-12'>
                <div className='card card-task-appoint'>
                    <div className='card-body'>
                        <div className='card-text'>
                            <form id="medicationForm" onSubmit={handleSubmit} style={{ width: "100%" }}>

                                <div className='row'>

                                    <div className='col-md-6 mb-3 '>
                                        <div className='row'>
                                            <div className='col-md-6 mb-4'>
                                                <label className="form-label">Name
                                                    {/* <span className='text-danger'>*</span> */}
                                                </label>
                                                <h5 name="name">{formData['name']}</h5>
                                            </div>

                                            <div className='col-md-6 mb-4'>
                                                <label className="form-label">Membership
                                                    {/* <span className='text-danger'>*</span> */}
                                                </label>
                                                <h5 name="membership">{formData['membership']}</h5>
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Mobile Number
                                                    {/* <span className='text-danger'>*</span> */}
                                                </label>
                                                {/* <h5 name="mobileNumber">{formData['mobileNumber']}</h5> */}
                                                <input type="text" name="mobileNumber" className='form-control control-input-ui' value={formData['mobileNumber']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">City
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                {/* <h5 name="city">{formData['city']}</h5> */}
                                                {/* <select className="form-select select-input-ui" name="city" id="city" value={formData['city']} onChange={handleChange} >
                                <option value="">-- Select City --</option>
                                {cityList.map(option => (
                                    <option key={option.id} value={option.cityName}>{option.cityName}</option>
                                ))}
                            </select> */}

                                                <Select
                                                    value={cityList.find(option => option.label === formData.city) || null}
                                                    onChange={handleChangeCity}
                                                    options={cityList}
                                                    isSearchable={true}
                                                    placeholder="Select City"
                                                    className="form-control select-input-ui select-input-ui-select"
                                                />
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">District</label>
                                                <input type="text" name="district" className='form-control control-input-ui' value={formData['district']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Nationality
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <select className="form-select select-input-ui" name="nationality" id="nationality" value={formData['nationality']} onChange={handleChange} >
                                                    <option value="">-- Select Nationality --</option>
                                                    <option value="Saudi Arabian">Saudi Arabian</option>
                                                    <option value="Non Saudi Arabian">Non Saudi Arabian</option>
                                                </select>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Approval Number
                                                </label>
                                                <input type="text" name="approvalNumber" className='form-control control-input-ui' value={formData['approvalNumber']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Deductible
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <input type="text" name="deductible" className='form-control control-input-ui' value={formData['deductible']} onChange={handleChange} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-md-6 mb-3 '>
                                        <div className='row'>


                                            {/* <div className='col-md-4 mb-3'>
                            <label className="form-label">District</label>
                            <input type="text" name="district" className='form-control control-input-ui' value={formData['district']}  onChange={handleChange}/>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <label className="form-label">Nationality
                                <span className='text-danger'>*</span>
                            </label>
                            <input type="text" name="nationality" className='form-control control-input-ui' value={formData['nationality']} onChange={handleChange}/>
                        </div> */}



                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Partner Clinic
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <select className="form-select select-input-ui" name="partnerClinic" id="partnerClinic" value={formData['partnerClinic']} onChange={handleChange} >
                                                    <option value="">-- Select Partner Clinic --</option>
                                                    {partner.map(option => (
                                                        <option key={option.id} value={option.id}>{option.partnerName}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Lab Provider
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <select className="form-select select-input-ui" name="labProvider" id="labProvider" value={formData['labProvider']} onChange={handleChange} >
                                                    <option value="">-- Select Lab Provider --</option>
                                                    {labProviderList.map(option => (
                                                        <option key={option.id} value={option.id}>{option.labName}</option>
                                                    ))}
                                                </select>
                                            </div>



                                            <div className='col-md-12 mb-3'>
                                                <label className="form-label">Remarks
                                                </label>
                                                <input type="text" name="remarks" className='form-control control-input-ui' value={formData['remarks']} onChange={handleChange} />
                                            </div>

                                            <div className='col-md-12 mb-3'>
                                                <label className="form-label">Requested Lab Test
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                {/* <textarea type="text" name="requestedLabTest" className='form-control control-input-ui' style={{ height: "130px" }} rows="7" value={formData['requestedLabTest']} onChange={handleChange}></textarea> */}
                                                <MultiSelect
                                                    options={serviceList}
                                                    value={selectedOptions}
                                                    onChange={handleChangeService}
                                                    labelledBy="Select"
                                                    hasSelectAll={false}
                                                    disableSearch={false}
                                                    overrideStrings={{
                                                        selectAll: 'Select All',
                                                        allItemsAreSelected: 'All items are selected',
                                                        selectSomeItems: 'Select items',
                                                    }}
                                                    className="form-select select-input-ui select-input-ui-select"
                                                    name="requestedLabTest"
                                                    id="requestedLabTest"

                                                />

                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                {selectedUserNames}
                                            </div>
                                        </div>


                                    </div>
                                    <div className='col-md-12 text-center mt-2'>
                                        <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                        <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                                        <Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" disabled={disabledButton}>Save Lab Request</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LabRequestForms;