import React,{useState} from "react";
import UserManagementTable from "./UserManagementTable";
import { Link } from "react-router-dom";


function ContentUserManagementDetails() {
    const [totalAdminUsersData, setTotalAdminUsersData] = useState(0);

    const totalAdminUserData= (data) => {
        setTotalAdminUsersData(data);
      };

    return (
        <>
            <div className="container-fluid">
            <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md"> 
                        <h4 className="mb-0 content-main-title">User Management</h4>
                    </div>
                    <div className="col-md">
                        <div className="row g-3 align-items-center">
                            {/* <div className="col-auto">
                                <h1 class="main-title mb-0">Total Users: {totalAdminUsersData}</h1>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-md text-end">
                        <Link to="/add-new-user" className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-person-plus-fill me-2"></i> Add New User
                        </Link>
                    </div>
                </div>
                
                <div className="row align-items-center pb-3">
                <div className='reports-programs'>
                    <div className=" row align-items-center">
                    <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/usermanagement.png" alt="Invited Members icon" style={{height:"140px"}}/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The User Management module allows admins to easily add or remove users of the apps and provide them with access to the application. This is essential for maintaining and controlling user access.</p>
                        </div>
                    </div>
                </div>
                </div>

                <div className="row align-items-center pb-3">
                <div className='comntent-UserManagement mb-3'>
                <div className="row">
                    <div className="col-md-12">
                    <div className="table-responsive tableUserManagement">
                        <UserManagementTable setTotalAdminUsersData = {totalAdminUserData} />
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>

            
        </>
    );
}

export default ContentUserManagementDetails;
