import React, { useState } from 'react';
import './UserManagementPage.css';
import ContentUserManagement from '../components/ContentuserManagement'; 

function UserManagementPage() {

  return (
    <>
      <ContentUserManagement/>        
    </>
  );
}

export default UserManagementPage;
