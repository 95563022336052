import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { CustomLoader } from '../../utils/CommonLibrary';

function MemberTransferTablev2({ data: initialData, onCheckboxChange, onDropdownChange, loading, setLoading, totalRows, page, perPage, sortColumn, sortDirection, loadUserData, setSortColumn, setSortDirection, setPage, setPerPage }) {

    const [data, setData] = useState(initialData);
    const [checked, setChecked] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [dropdownValues, setDropdownValues] = useState({});

    useEffect(() => {
        setAllChecked(false);
        setChecked([])
        setData(initialData);
    }, [initialData]);

    useEffect(() => {
        const nonDisabledItems = data.filter(item => !item.disable);
        if (nonDisabledItems.length > 0) {
            setAllChecked(nonDisabledItems.length > 0 && checked.length === nonDisabledItems.length);
        } else {
            setAllChecked(false);
        }
    }, [checked, data]);

    useEffect(() => {
        onCheckboxChange(checked);
    }, [checked, onCheckboxChange]);


    const handleAllChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            setChecked(data.filter(item => !item.disable).map(item => item.id));
        } else {
            setChecked([]);
        }
    };

    const handleCheckboxChange = (id) => {
        setChecked(prevChecked =>
            prevChecked.includes(id) ? prevChecked.filter(item => item !== id) : [...prevChecked, id]
        );
    };

    const handleDropdownChange = (id, value) => {
        setDropdownValues(prevValues => ({
            ...prevValues,
            [id]: value,
        }));
        onDropdownChange(id, value);
    };

    const columns = [
        {
            name: (
                <div>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckAll"
                        onChange={handleAllChange}
                        checked={allChecked}
                    /> All
                </div>
            ),
            cell: row => (
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={`flexCheck${row.id}`}
                    onChange={() => handleCheckboxChange(row.id)}
                    checked={checked.includes(row.id)}
                    disabled={row.disable}
                />
            ),
            sortable: false,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership No',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: false,
        },
        {
            name: 'Care Program',
            selector: row => row.careProgram,
            sortable: false,
        },
        {
            name: 'Condition',
            selector: row => row.condition,
            sortable: false,
        },
        {
            name: 'Pathway',
            selector: row => row.pathway,
            sortable: false,
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: false,
        },
        {
            name: 'To Care Navigator',
            cell: row => (
                <div>
                    {row.disable ?
                        <p style={{ color: '#03335b' }}>Transfer already Scheduled</p>
                        :
                        <select
                            className="form-select input-field-ui-table"
                            onChange={(e) => handleDropdownChange(row.id, e.target.value)}
                            value={dropdownValues[row.id] || ''}
                            dangerouslySetInnerHTML={{ __html: row.select }}
                        />
                    }
                </div>

            ),
            sortable: false,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: row => row.disable,
            style: {
                backgroundColor: '#d6d6d6',
                pointerEvents: 'none',
            },
        },
    ];

    return (
        <div>
            {
                loading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        columns={columns}
                        fetchTableData={loadUserData}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
        </div>
    );
}

export default MemberTransferTablev2;
