import React from 'react';
import './ContentUserManagementDetails.css';
import ContentEditUser from './ContentUserManagement/ContentEditUser';


function ContentEditUserManagement() {

  return (
    <>
        <section className='ContentUserManagement'>
            <ContentEditUser />
        </section>
        
    </>
  );
}

export default ContentEditUserManagement;