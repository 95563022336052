import React, { useState,useEffect,useRef } from 'react';
import DataTable from 'react-data-table-component';
import { fetch_audio_play_list, fetch_member_communication_data } from '../../../services/met/Service';
import { LoaderLoader, exportExcelTableData } from '../../utils/CommonLibrary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatDate } from '../utils/CommonLibrary';
import CommunicationAudioPlayer from '../membercommunication/CommunicationAudioPlayer';




function MemberCommunicationsTable({startDate,endDate}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [communicationData, setCommunicationData] = useState([]);
    const [communicationMessage, setCommunicationMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const[audioId,setAudioId] = useState('');
    const[recordUrl,setRecordUrl] = useState('');
    const[agentCallName,setAgentCallName] = useState('');
    const[memberCallName,setMemberCallName] = useState('');
    const[randomKey,setRandomKey] = useState(0);
    const [timestamp, setTimestamp] = useState(Date.now());
    const statusOptions = {
        All: [
          { value: 'busy', label: 'Busy' },
          { value: 'completed', label: 'Completed' },
          { value: 'canceled', label: 'Cancelled' },
          { value: 'no-answer', label: 'No-Answer' },
          { value: 'failed', label: 'Failed' },
          { value: 'delivered', label: 'Delivered' },
          { value: 'undelivered', label: 'Undelivered' },
        ],
        Call: [
          { value: 'busy', label: 'Busy' },
          { value: 'completed', label: 'Completed' },
          { value: 'canceled', label: 'Cancelled' },
          { value: 'no-answer', label: 'No-Answer' },
          { value: 'failed', label: 'Failed' },
          
        ],
        Whatsapp: [
          { value: 'delivered', label: 'Delivered' },
          { value: 'undelivered', label: 'Undelivered' }
        ],
        SMS: [
          { value: 'delivered', label: 'delivered' },
          { value: 'undelivered', label: 'Undelivered' }
        ],
        Email: [
            { value: 'delivered', label: 'Delivered' },
            { value: 'undelivered', label: 'Undelivered' }
        ],
        'Push Notification': [
            { value: 'delivered', label: 'Delivered' },
            { value: 'undelivered', label: 'Undelivered' }
        ]
      };
    
      const handleChannelChange = (value) => {
        setSelectedChannel(value);
        setSelectedStatus('All'); // Reset status when channel changes
      };
    
    const audioRef = useRef(null);

useEffect(() => {
       
        if (audioRef.current && recordUrl) {
            audioRef.current.load();
          }else{
            fetchMemberCommunicationData();
          }
},[recordUrl]);


const handleStop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

const fetchMemberCommunicationData = async () => {
    try {
        setIsLoading(true); // show loader
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let response = await fetch_member_communication_data(sdate,edate);
        //if(response.data.code === 200){
            setCommunicationData(response.data.data);
        //}
    } catch (error) {
      console.error(error);
    } 
    finally {
        setIsLoading(false);  // Will be called regardless of success or failure
    }
};

const handlePlay = (e, itemName) => { 
    let allAudios = document.querySelectorAll('.call_recording_audio'); 

    // pause other audio's except current one 
    Array.from(allAudios).forEach((item) => { 
        let name  = item.getAttribute('name');
        if(name !== itemName){ 
            item.pause(); 
        }
    });
}

const playAudioRecording =async (callId) => {
    try {
       
        let response = await fetch_audio_play_list(callId);
        
        // if (response.data && response.data.data && response.data.data.recordingUrl) {
           
            setRecordUrl(response.data.data.recordingUrl);
            setRandomKey(response.data.data.recordingUrl);
            setAgentCallName(response.data.data.AgentName);
            setMemberCallName(response.data.data.memberName);
        // } else {
        //     console.error('Invalid response format or missing recordingUrl.');
        // }
} catch (error) {
    console.error('Error fetching audio data:', error);
}
   };


    const renderMETP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Unique identifier for the member.
        </Tooltip>
    );
    const renderMETP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Main Membership No for the member.
        </Tooltip>
    );

    const renderMETP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the member.
        </Tooltip>
    );

    const renderMETP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
               Name of the Agent.
        </Tooltip>
    );
   

    const renderMETP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The method used for communication
        </Tooltip>
    );

    const renderMETP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The date and time when the communication was sent or made.
        </Tooltip>
    );

    
    const renderMETP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           The method used for communication status
        </Tooltip>
    );

    const renderMETP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
               Click to listen to the recorded communication message.
        </Tooltip>
    );
   

    const optionSelectedChannel = (e) =>{

        setSelectedChannel(e);


    }


    const columns = [
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Member ID</span>
                </OverlayTrigger>
            ),
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP12Tooltip}>
                    <span>Main Membership No</span>
                </OverlayTrigger>
            ),
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP2Tooltip}>
                    <span>Member Name</span>
                </OverlayTrigger>
            ),
            selector: row => row.memberName,
            sortable: true,
        },
        {
          
          name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Agent Name</span>
                </OverlayTrigger>
            ),
            selector: row => row.agentName,
            sortable: true,
        },
       
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP4Tooltip}>
                    <span>Communication<br/>Channel</span>
                </OverlayTrigger>
            ),
            selector: row => row.communicationType,
            sortable: true,
            width:"100px",
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP5Tooltip}>
                    <span>Communication Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.placedCallDate,
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Communication <br/>Status</span>
                </OverlayTrigger>
            ),
            selector: row => row.status,
            sortable: true,
            // cell: row => (
            //     <span style={{ color: row.status === 'Delivered' ? '#01CD88' : '#FF0000' }}>
            //         {row.status}
            //     </span>
            // ),
        },
         {
             name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Communication Playback</span>
                </OverlayTrigger>
            ),
             selector: row => row.recordingUrl,
             sortable: true,
            //  cell:row=><CommunicationAudioPlayer recordingUrl={row.recordingUrl} />
             cell: row => (
                <audio className={`call_recording_audio`} controls onPlay={e => handlePlay(e, row.communicationId)} name={`${row.communicationId}`}>
                <source src={row.recordingUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            ),
         },
         {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP7Tooltip}>
                    <span>Communication <br/>Duration</span>
                </OverlayTrigger>
            ),

            selector: row => row.recordingDuration,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width:"125px",
            
            cell: row => (
                <span>
                    {/* <a data-bs-toggle="modal" data-bs-target="#audioPopupModal" style={{cursor: "pointer"}} onClick={(e)=>playAudioRecording(row.communicationId)}><span style={{color:row.color}}>{row.recordingUrl}</span> <br/>{row.recordingDuration}</a> */}
                    {row.recordingDuration}
                </span>
            ),
        },


        {
            name: (
               <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                   <span>Sentimental Analysis</span>
               </OverlayTrigger>
           ),
            selector: row => row.recordingUrl,
            sortable: true,
           //  cell:row=><CommunicationAudioPlayer recordingUrl={row.recordingUrl} />
            cell: row => (
                <span>
                    {row.sentimentAnalysis || "-"}
                </span>
           ),
        },

    ];
    const data = communicationData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedChannel('All');
        setSelectedStatus('All');
    };
   
    const filteredData = data.filter(user => {
        const matchesSearchQuery = 
        (typeof user.memberName === 'string' && user.memberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof user.membershipNo === 'string' && user.membershipNo.toString().includes(searchQuery));
        
    
        const matchChannel = selectedChannel === 'All' || user.communicationType === selectedChannel;

        const matchStatus = selectedStatus === 'All' || user.status === selectedStatus;

        return matchesSearchQuery && matchChannel && matchStatus;
    });

    const handleExport = (downloadType) => {
        if(downloadType === 'excel'){

            const modifiedData = filteredData.map(item => {
                return {
                    'Member ID': item.membershipNo,
                    'Main Membership No': item.mainMembershipNo,
                    'Member Name': item.memberName,
                    'Agent Name': item.agentName,
                    'Communication Channel': item.communicationType,
                    'Communication Date': item.placedCallDate,
                    'Communication Status': item.status,
                };
            });
            modifiedData.forEach(function(v){ 
                delete v.communicationId;
               
              });
            exportExcelTableData("member_communication",modifiedData);
        }
    };

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
           
            <div className='container-fluid'>
                
                <div className='row mb-3'>
                    
                    <div className='col-md d-flex px-1'>
                    <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Name or Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedChannel}
                            onChange={e => handleChannelChange(e.target.value)}
                            className='form-select input-field-ui'>
                            <option value="All">--Select Channel--</option>
                            <option value="Call">Calls</option>
                            <option value="Whatsapp">Whatsapp</option>
                            <option value="SMS">SMS</option>
                            <option value="Email">Email</option>
                            <option value="Push Notification">Push Notification</option>
                        </select>
                    </div>
                    {/* <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="completed">Completed</option>
                            <option value="canceled">Cancelled</option>
                            <option value="no-answer">No-Answer</option>
                            
                            <option value="Delivered">Delivered</option>
                            <option value="Undelivered">Undelivered</option>
                        </select>
                    </div> */}
                    <div className='col-md-3 px-1'>
                     <select
        value={selectedStatus}
        onChange={e => setSelectedStatus(e.target.value)}
        className='form-select input-field-ui'
      >
         <option value="All">--Select Status--</option>
        {statusOptions[selectedChannel].map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}

      </select>
      </div>
                    
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                           <div className="dropdown">
                            <button className="btn btn-primary btn-primary clear-search-btn " type="button"  onClick={(e)=>handleExport('excel')}>
                                <i className="bi bi-download"></i> Export 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* {communicationData === 0 ? <div>{communicationMessage}</div> : */}
            {/* (<> */}
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            
            <div className="modal fade" id="audioPopupModal" tabindex="1" aria-labelledby="audioPopupModal" data-backdrop='static' aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                              <div class="modal-header">

                                <h6 class="modal-subtitle mb-2" id="exampleModalLabel">Agent Name:  {memberCallName}</h6>

                                <h6 class="modal-subtitle" id="exampleModalLabel">Member Name:  {agentCallName}</h6>
                                
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleStop} ></button> 
                            </div>
                            <div class="modal-body mt-2">
                                <audio ref={audioRef} controls style={{width:'100%'}}>
                                        <source src={recordUrl} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                                  {/* <div className="modal-header mb-4">                   
                                        <CommunicationAudioPlayer recordingUrl={recordUrl} key ={timestamp}/>
                                        
                                        <audio ref={audioRef} controls>
                                        <source src={recordUrl} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                     <div>
                                </div>
                                 
                            </div> */}
                        </div>
                    </div>
            </div>

            
            
            
          
          

                                  
                                

            
            </>
        //     )}
        // </>
    );

    
}

export default MemberCommunicationsTable;
