import React, { useState } from 'react';
//import './MemberCommunications.css';
import ContentMemberCommunicationsDetails from '../components/ContentMemberCommunicationsDetails';

function MemberCommunications() {

  const [title, setTitle] = useState('Member Communications');

  return (
    <>
       <ContentMemberCommunicationsDetails/>
    </>
  );
}
export default MemberCommunications;