import React,{ useState,useEffect } from "react";
import {fetch_program_data,fetch_uploaded_summery_data_list} from "../../../services/met/Service";
import { formatDate } from "./CommonLibrary";



export const EngagementDetailsSummery = () =>{

    const [invitedNumbers,setInvitedNumbers] = useState(0);
    const [enrolledNumbers,setEnrolledNumbers] = useState(0);
    const [notEnrolledNumbers,setNotEnrolledNumbers] = useState(0);
    const [isLoading,setIsLoading] = useState(false);
  
    const fetchProgramSummeryData = async () => {
      setIsLoading(true); //to show loader
      try {
          let resp = await fetch_uploaded_summery_data_list();
          if (resp.data.code == 200) {
             setInvitedNumbers(resp.data.data[0].invitedNumbers);
             setEnrolledNumbers(resp.data.data[0].enrolledNumbers);
             setNotEnrolledNumbers(resp.data.data[0].notEnrolledNumbers);
          }
      } catch (error) {
          console.error(error);
      }finally {
        setIsLoading(false); // Will be called regardless of success or failure
        }
      }
      useEffect(() => {
        fetchProgramSummeryData();
    }, []);
                        return(
                            <div className="row">
                                <div className="col-md">
                                    <div className="card card-box-member">
                                        <div className="card-body card-box-member-dflex">
                                            <h1 className="member-card-title">{invitedNumbers}</h1>
                                            <h5 className="member-card-text mb-0">Total Sent</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md px-1">
                                    <div className="card card-box-member">
                                        <div className="card-body card-box-member-dflex">
                                            <h1 className="member-card-title">{enrolledNumbers}</h1>
                                            <h5 className="member-card-text mb-0">Enrolled Member</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="card card-box-member">
                                        <div className="card-body card-box-member-dflex">
                                            <h1 className="member-card-title">{notEnrolledNumbers}</h1>
                                            <h5 className="member-card-text mb-0">Not Enrolled Member</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
    }

export const EngagementNotificationResult =()=>{
    const HandleComingSoon = () =>{
        console.log("coming soon")
    }

    return(
    <div className="card card-box-member">
            <div className="card-body card-box-member-dflex">
                <div className="row" style={{"width":"100%"}} data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"  onClick={HandleComingSoon}>
                    <div className="col-md">
                    <h1 className="member-card-title1">
                    <div>
                        - <br/>
                        <span className="success-card-text">Sucess</span>
                    </div>
                    <div>
                        - <br/>
                        <span className="failed-card-text">Failed</span>
                    </div>
                </h1>
                <h5 className="member-card-text1 mb-0">WhatsApp</h5>
            </div>
            <div className="col-md">
                    <h1 className="member-card-title1">
                    <div>
                        - <br/>
                        <span className="success-card-text">Sucess</span>
                    </div>
                    <div>
                        - <br/>
                        <span className="failed-card-text">Failed</span>
                    </div>
                </h1>
                <h5 className="member-card-text1 mb-0">SMS</h5>
            </div>
            <div className="col-md">
                    <h1 className="member-card-title1">
                    <div>
                        - <br/>
                        <span className="success-card-text">Sucess</span>
                    </div>
                    <div>
                        - <br/>
                        <span className="failed-card-text">Failed</span>
                    </div>
                </h1>
                <h5 className="member-card-text1 mb-0">Email</h5>
            </div>
            <div className="col-md">
                    <h1 className="member-card-title1">
                    <div>
                        - <br/>
                        <span className="success-card-text">Sucess</span>
                    </div>
                    <div>
                        - <br/>
                        <span className="failed-card-text">Failed</span>
                    </div>
                </h1>
                <h5 className="member-card-text1 mb-0">Push Notification</h5>
            </div>
            
            </div>
        </div>
</div> );
}

export const CalculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };

  export const Filterdashboard=({ onChange })=> {
    const [programData,setProgramData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
  
    const fetchProgramData = async () => {
      setIsLoading(true); //to show loader
      try {
          let resp = await fetch_program_data();
          if (resp.data.code === 200) {
              setProgramData(resp.data.data);
          }
      } catch (error) {
          console.error(error);
      }finally {
        setIsLoading(false); // Will be called regardless of success or failure
        }
      }
      useEffect(() => {
        fetchProgramData();
    }, []);
   
          return (
              <>
                <div className="col-md-3">
                            {isLoading ? (
                                <div>Loading...</div>
                              ) : (
                                   <select className="form-select input-field-ui" id="SelectProgram" onChange={onChange} aria-label="Default select example">
                                   <option value="">Select Program</option>
                                    {programData.map((option, index) => (
                                      <option key={index} value={option.programId}>
                                        {option.programName}
                                      </option>
                                    ))}
                                    </select>
                                  )
                            }
                      </div>
              </>
          );
}


export const FilterdashboardMember=({ onChange })=> {
    const [programData,setProgramData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
  
    const fetchProgramData = async () => {
      setIsLoading(true); //to show loader
      try {
          let resp = await fetch_program_data();
          if (resp.data.code === 200) {
              setProgramData(resp.data.data);
          }
      } catch (error) {
          console.error(error);
      }finally {
        setIsLoading(false); // Will be called regardless of success or failure
        }
      }
      useEffect(() => {
        fetchProgramData();
    }, []);
   
          return (
              <>
                <div className="col-md-3">
                            {isLoading ? (
                                <div>Loading...</div>
                              ) : (
                                   <select className="form-select input-field-ui" id="SelectProgram" onChange={onChange} aria-label="Default select example">
                                   <option value="">Select Program</option>
                                    {programData.map((option, index) => (
                                      <option key={index} value={option.programName}>
                                        {option.programName}
                                      </option>
                                    ))}
                                    </select>
                                  )
                            }
                      </div>
              </>
          );
}

export const SummeryDashboardData =({startDate,endDate})=>{

    const [invitedNumbers,setInvitedNumbers] = useState(0);
    const [enrolledNumbers,setEnrolledNumbers] = useState(0);
    const [notEnrolledNumbers,setNotEnrolledNumbers] = useState(0);
    const [contactedNumbers,setContactedNumbers] = useState(0);
    const [notContactedNumbers,setNotContactedNumbers] = useState(0);
    const [enrollLaterNumbers,setEnrollLaterNumbers] = useState(0);
    const [invitedExtra,setInvitedExtra] = useState(0);
    const [isLoading,setIsLoading] = useState(false);
  
    const fetchProgramSummeryData = async () => {
      setIsLoading(true); //to show loader
      try {
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
          let resp = await fetch_uploaded_summery_data_list(sdate,edate);
          if (resp.data.code == 200) {
             setInvitedNumbers(resp.data.data[0].invitedNumbers);
             setEnrolledNumbers(resp.data.data[0].enrolledNumbers);
             setNotEnrolledNumbers(resp.data.data[0].notEnrolledNumbers);
             setContactedNumbers(resp.data.data[0].contactedNumbers);
             setNotContactedNumbers(resp.data.data[0].notContactedNumbers);
             setEnrollLaterNumbers(resp.data.data[0].enrolledLaterNumbers);
             setInvitedExtra(resp.data.data[0].invitedExtra)
          }
      } catch (error) {
          console.error(error);
      }finally {
        setIsLoading(false); // Will be called regardless of success or failure
        }
      }
      useEffect(() => {
     fetchProgramSummeryData();
    }, [startDate,endDate]);

    const stats = [
      { count: invitedNumbers, label: 'Touched', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/vsksd/k88eveca7u.png' },
      { count: invitedExtra, label: 'Extra Manual Entries', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/8ogjs/whn8x2c8f4.png' },
      { count: enrolledNumbers, label: 'Total Onboarded', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/48t4m/30kswhl360.png' },
      { count: enrollLaterNumbers, label: 'Onboard Later', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/48t4m/30kswhl360.png' },
      { count: notEnrolledNumbers, label: 'Not Interested', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/8ogjs/whn8x2c8f4.png' },
    ];

    return(
    <div className=" row text-center">
            {stats.map((stat, index) => (
                    <div className="col px-1" key={index}>
                        <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                            <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto ps-2">
                                    <img src={stat.icon} alt={`${stat.label} icon`} className="card-icon mb-0" />
                                </div>
                                <div className="col px-0">
                                    <h1 className="card-title">{stat.count}</h1>
                                    <h5 className="card-text">{stat.label}</h5>
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
    );
}

export const SummeryDashboardDataPhase2 =({startDate,endDate})=>{

    const [invitedNumbers,setInvitedNumbers] = useState(0);
    const [enrolledNumbers,setEnrolledNumbers] = useState(0);
    const [notEnrolledNumbers,setNotEnrolledNumbers] = useState(0);
    const [contactedNumbers,setContactedNumbers] = useState(0);
    const [notContactedNumbers,setNotContactedNumbers] = useState(0);
    const [isLoading,setIsLoading] = useState(false);
  
    const fetchProgramSummeryData = async () => {
      setIsLoading(true); //to show loader
      try {
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
            let resp = await fetch_uploaded_summery_data_list(startDate,endDate);
            if (resp.data.code == 200) {
                setInvitedNumbers(resp.data.data[0].invitedNumbers);
                setEnrolledNumbers(resp.data.data[0].enrolledNumbers);
                setNotEnrolledNumbers(resp.data.data[0].notEnrolledNumbers);
                setContactedNumbers(resp.data.data[0].contactedNumbers);
                setNotContactedNumbers(resp.data.data[0].notContactedNumbers);
            }
      } catch (error) {
          console.error(error);
      }finally {
        setIsLoading(false); // Will be called regardless of success or failure
        }
      }
      useEffect(() => {
     fetchProgramSummeryData();
    }, []);

    const stats = [
      { count: invitedNumbers, label: 'Attempted Members', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/vsksd/k88eveca7u.png' },
      { count: contactedNumbers, label: 'Contacted Successfully', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/48t4m/30kswhl360.png' },
      { count: enrolledNumbers, label: 'Onboarded Members', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/48t4m/30kswhl360.png' },
      { count: notEnrolledNumbers, label: 'Not Interested', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/8ogjs/whn8x2c8f4.png' },
    ];

    return(
    <div className=" row text-center">
            {stats.map((stat, index) => (
                    <div className="col px-1" key={index}>
                        <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                            <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto ps-2">
                                    <img src={stat.icon} alt={`${stat.label} icon`} className="card-icon mb-0" />
                                </div>
                                <div className="col px-0">
                                    <h1 className="card-title">{stat.count}</h1>
                                    <h5 className="card-text">{stat.label}</h5>
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
    );
}