import React from 'react';
import ObservationsBar from './componenents/DashboardGraphsSupportTeam/ObservationsBar';
import ObservationsPie from './componenents/DashboardGraphsSupportTeam/ObservationsPie';
import OpenObservationsBar from './componenents/DashboardGraphsSupportTeam/OpenObservationsBar';

function ObservationReportersDashboard() {


  return (
    <>
      <section className='ReportsPrograms'>
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <h1 className="main-title">Observation Reporters Dashboard
                        </h1>
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wul4w/gh7ss4yhho.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Provides admins with an overview of program and condition-specific data, including key performance indicators (KPIs) on member engagement and service utilization.</p>
                        </div>
                    </div>
                </div>


                <div className="row">

                       <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                            <div class="col-md-4 mb-3">
                            <label for="selectRole" class="form-label">Observation Category:
                            </label>
                            <select
                                class="form-select input-field-ui"
                                id="roleId"
                                name="roleId"
                                aria-label="Default select example"
                            >
                                <option value="blocker">Blocker</option>
                                <option value="major">Major</option>
                                <option value="minor">Minor</option>
                                <option value="niceToHave">Nice to Have</option>
                            </select>
                            </div>
                            <div className='cleafix'></div>
                            <div class="col-md-4 mb-3">
                            <label class="form-label">Description</label
                            ><textarea
                                type="text"
                                name="title"
                                placeholder="Description"
                                rows="3"
                                class="form-control control-input-ui"
                            ></textarea>
                            </div>
                            <div className='cleafix'></div>
                            <div class="row align-items-center">
  
  <div class="col-4">
  <label class="form-label">Upload Screenshots (Max 5MB per image):</label
  >
    <input
      type="file"
      id="xlaxFile_upload"
      class="form-control input-field-ui"
      accept=".xls, .xlsx, application/excel,application/vnd.msexcel, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      required=""
    />
  </div>
  
  <div class="col-4 mt-4 mb-1">
    <h4 class="text-allowed-file">
    You can upload multiple images. Each image should not exceed 5MB.
    </h4>
  </div>
  <div class="col-6">
    <button
      type="button"
      id="xlsxSubmit"
      class="btn btn-success uplaod-file-btn"
    >
      Submit Observation
    </button>
  </div>
</div>


                            </div>
                        </div>


                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                               <div className='table-responsive'>
                               <table class="table table-striped mb-0">
                               <thead>
                                <tr>
                                    <th>Observation ID</th>
                                    <th>Description</th>
                                    <th>Submission Date</th>
                                    <th>Approval Date</th>
                                    <th>Resolved Date</th>
                                    <th>Revalidation Status</th>
                                    <th>Revalidation Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>001</td>
                                    <td>Issue with login</td>
                                    <td>2024-08-01</td>
                                    <td>2024-08-03</td>
                                    <td>2024-08-05</td>
                                    <td>Pending</td>
                                    <td>--</td>
                                    <td>
                                        <select>
                                            <option value="revalidate">Revalidate</option>
                                            <option value="reopen">Reopen</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                                </table>
                               </div>

                            </div>
                        </div>


                </div>
        </div>
      </section>
    </>
  );
}

export default ObservationReportersDashboard;
