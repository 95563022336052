import React from 'react';
import CareNavigatorCommunicationsDetails from '../components/CareNavigatorCommunicationsDetails/CareNavigatorCommunicationsDetails';

function CareNavigatorCommunications() {

  return (
    <>
       <CareNavigatorCommunicationsDetails />
    </>
  );
}
export default CareNavigatorCommunications;