import React,{useEffect,useState} from "react";
import { fetch_role_data,medengage_user_creation_data,mtp_check_username_available, fetch_program_data,fetch_ad_users_list } from "../../../services/medengage/Service";
import { validateForm } from "../../utils/validationUtils";
import { Sync } from "@mui/icons-material";
import { CustomFieldLevelLoader, LoaderLoader } from "../../utils/CommonLibrary";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from "react-router-dom";


function ContentAddNewUser() {

const [roleData, setRoleData] = useState([]);
const [programData, setprogramData] = useState([]);
const [successMessage, setSuccessMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');
const [suggestions, setSuggestions] = useState([]);
const [isDisabled, setIsDisabled] = useState(true);
const [isLoading, setIsLoading] = useState(false);
const [showProgram, setShowProgram] = useState(false);

const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    programId: [],
    roleId:'',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    programId: [],
    roleId:'',
  });

  const [loading, setLoading] = useState(false)

useEffect(() => {
        fetchRoleData();
        fetchProgramData();
},[]);

//role master data
const fetchRoleData = async () => {
    try {
        const response = await fetch_role_data();
        setRoleData(response.data.data);
    } catch (error) {
      console.error(error);
    }
};

//fetch program data
const fetchProgramData = async () => {
    try {
        const resp = await fetch_program_data();
        if(resp.data.code == 200){
            setprogramData(resp.data.data);
        }    
    } catch (error) {
        console.error(error);
    }
}

const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
        let newProgramId = formData.programId.slice(); // Copy the array
        if (checked) {
            newProgramId.push(value); // Add the checked role ID
        } else {
            newProgramId = newProgramId.filter(id => id !== value); // Remove the unchecked role ID
        }
        setFormData({
            ...formData,
            programId: newProgramId
        });
    } else {
        setFormData({
            ...formData,
            [name]: value,
        });
    }
   
    setErrors({
      ...errors,
      [name]: '',
    });
  };
 
 
 
  const handleOnTypingEmail = async (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
setErrorMessage('');
   if (!value) {
      setFormData((prevData) => ({
        ...prevData,
        firstName: '',
        lastName: '',
      }));
      //  setIsLoading(false);
       setLoading(false);
       setSuggestions([]);
    }
    // Fetch suggestions from backend
    if (value) {
      try {
         
      //  setIsLoading(true);
      setLoading(true);
        const response = await fetch_ad_users_list(value);
        setSuggestions(response.data.data);
        //  setIsLoading(false);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching email suggestions', error);
      }
    } else {
      setSuggestions([]);
      // setIsLoading(false);
      setLoading(false);
    }

    if (name === 'emailId' && !validateEmail(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };


   const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({
      ...prevData,
      emailId: suggestion.mail,
      firstName: suggestion.givenName || '', // Assuming givenName is present in the suggestion
      lastName: suggestion.surname || '', // Assuming surname is present in the suggestion
    }));

    setSuggestions([]); // Clear suggestions after selection
    setErrors((prev) => ({ ...prev, emailId: '', firstName: '', lastName: ''}));
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      }else{
            try {
                    setIsLoading(true); //show loader
                    const response = await medengage_user_creation_data(formData);
                    if(response.data.code == 200){
                        setSuccessMessage(response.data.data.message);
                        window.location.href = "/member-management-medengage";
                       setFormData({ emailId: '', firstName: '', lastName: '', roleId: [],programId: '' });
                       setErrors({ emailId: '', firstName: '', lastName: '', roleId: '',programId: '' });
                    }else{
                        setErrorMessage(response.data.data.message);
                    }
                    setIsLoading(false); //show loader
            }catch (error) {
                console.error(error);
              }
      }
  }

  const handleFormClear = () => {
    setFormData({ emailId: '', firstName: '', lastName: '', roleId: '',programId: [] });
    setErrors({ emailId: '', firstName: '', lastName: '', roleId: '',programId: [] });
    const checkboxes = document.querySelectorAll('.checkProgram');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  const handleBackButton = () => {
    navigate('/member-management-medengage');
}
const navigate = useNavigate();
    return (
        <>
        <section className='ContentUserManagement'>
            <div className="container-fluid px-0">
            <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    {/* <IconButton onClick={handleBackButton}>
                        <ArrowBackIcon />
                    </IconButton> */}
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i class="bi bi-chevron-left me-1"></i> Back</button>
                    <h1 className="main-title" style={{ margin: '0' }}>Add New User</h1>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                <div className='comntent-UserManagement p-3 mb-3'>
                   
                    <div className="row">
                       
                        <div className="col-md-7 px-4">
                                {successMessage && <div className="text-success">{successMessage}</div>}
                                {errorMessage && <div className="text-danger">{errorMessage}</div>}
                                <LoaderLoader isLoading={isLoading} />
                                <div className="row">

<div className="col-md-12 mb-3">
                                        <label htmlFor="emailId" className="form-label">Email Id</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <div className="d-flex">
                                          <input type="text" className="form-control input-field-ui" autoComplete="off" id="emailId" name="emailId" value={formData['emailId']}
                                      onChange={handleOnTypingEmail} placeholder="Enter Email Id"/>
                                          {loading && <CustomFieldLevelLoader /> }
                                        </div>
                                    {errors['emailId'] && <div className="text-danger">{errors['emailId']}</div>}
                                     {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.givenName} {suggestion.surname} ({suggestion.mail})
            </li>
          ))}
        </ul>
      )}
       {/* {errors['emailId'] && <div className="text-danger">{errors['emailId']}</div>} */}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text" readOnly className="form-control input-field-ui" id="firstName" name ="firstName" value={formData['firstName']}
                                    onChange={handleChange} placeholder="Enter Name" disabled={isDisabled}  />
                                    {errors['firstName'] && <div className="text-danger">{errors['firstName']}</div>}
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text"  className="form-control input-field-ui" readOnly id="lastName" name="lastName" value={formData['lastName']}
                                    onChange={handleChange} placeholder="Enter Last Name" disabled={isDisabled}  />
                                   
                                    {errors['lastName'] && <div className="text-danger">{errors['lastName']}</div>}
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="selectProgram" className="form-label">Select Role</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <select className={`form-select input-field-ui  ${errors['roleId'] ? 'is-invalid1' : ''}`} id="roleId" name="roleId" value={formData['roleId']}
                                    onChange={handleChange} aria-label="Default select example">
                                            <option value="">Select Role</option>
                                            {roleData.map(option => (
                                                <option key={option.roleId} value={option.roleId}>{option.roleName}</option>
                                            ))}
                                        </select>
                                        {errors['roleId'] && <div className="text-danger">{errors['roleId']}</div>}
                                    </div>

                                    {showProgram && <div className="col-md-12 mb-3">
                                      <label htmlFor="programId" className="form-label">Select Program(s)</label>
                                      <span style={{color:'red'}}>&nbsp;*</span>
                                      <div className={`form input-field-ui ${errors['programId'] ? 'is-invalid' : ''}`} >
                                        {programData.map((item, index) => (
                                          <div  key={index}>
                                            <label className="me-4">
                                              <input
                                                  type="checkbox"
                                                  className="me-2 checkProgram"
                                                  name="programId"
                                                  value={item['programId']}
                                                  id={`programId-${item['programId']}`}
                                                  onChange={handleChange}
                                              />

                                              {item['programName']}
                                            </label>
                                            <br />
                                          </div>
                                        ))}
                                      </div>
                                      {errors['programId'] && <div className="text-danger">{errors['programId']}</div>}
                                    </div> }


                                    <div className="col-md-12 mb-3">
                                        <button type="submit" className="btn btn-primary btn-primary program-save-btn me-2">Add User</button>
                                        <button type="button" className="btn clear-btn" onClick={handleFormClear}>Clear</button>
                                    </div>
                                </div>
                               
                        </div>
                    </div>
                </div>
                </form>
            </div>
            </section>
        </>
    );
}

export default ContentAddNewUser;