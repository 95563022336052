import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);

const MembersDeEnrollmentsBar = () => {
  // Define your data
  const programs = ['A', 'B', 'C', 'D', 'E'];
  const membersEnrolled = [50, 75, 100, 125, 150];
  const membersDeEnrolled = [10, 15, 20, 25, 30]; // Example data for members de-enrolled

  const yAxisJson = {
    title: {
      text: ''
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ' members'
  };

  const columnData = [{
    name: 'Members Enrolled',
    data: membersEnrolled,
    color: '#1279be'
  }, {
    name: 'Members De-Enrolled',
    data: membersDeEnrolled,
    color: '#f45b5b'
  }];

  const options4 = {
    chart: {
      type: 'column',
      height: 220,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 25 // Adjusted viewDistance for better 3D effect
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: programs,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 20, // Set border radius for columns
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true
    },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options4}
      />
    </div>
  );
};

export default MembersDeEnrollmentsBar;
