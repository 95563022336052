import React, { useState } from 'react';
import ContentCNReports from './componenents/CareNavigatorReports/ContentCNReports';
import './componenents/CareNavigatorReports/CareNavigatorReports.css';

function CareNavigatorReports() {

  const [title, setTitle] = useState('Programs');
 
return (
    <>
        <ContentCNReports/>
    </>
  );
}

export default CareNavigatorReports;
