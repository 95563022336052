import React from 'react';
import './ContentUserManagementDetails.css';
import ContentUserManagementDetails from './ContentUserManagement/ContentUserManagementDetails';
// import ContentAddNewUser from './ContentUserManagement/ContentAddNewUser';

function ContentUserManagement() {

  return (
    <>
        <section className='ContentUserManagement'>

            <ContentUserManagementDetails />

            {/* <ContentAddNewUser /> */}

        </section>


        
    </>
  );
}

export default ContentUserManagement;
