import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ContentWhatsapp from "./ContentWhatsapp";
import ContentSMS from "./ContentSMS";
import ContentEmail from "./ContentEmail";
import ContentNotification from "./ContentNotification";
import { fetch_frequency_selected_service_data, fetch_program_data } from "../../../services/mtp/Service";
import { Commingsoon, LoaderLoader } from "../../utils/CommonLibrary";
import DropdownTreeSelect from 'react-dropdown-tree-select';

const options1 = [];


function ContentConfigurationDetails() {

    const [selected, setSelected] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [selectedAppPrograms, setSelectedAppPrograms] = useState([]);
    const [programData, setprogramData] = useState([]);
    const [selectedServiceData, setSelectedServiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [labelValue, setLabelValueUpdate] = useState(1);
    const [labelDepth, setLabelDepthUpdate] = useState(0);
    const [labelApoinment, setLabelApoinment] = useState(1);

    useEffect(() => {
        fetchProgramData();
      },[]);

    //To get program list
    const fetchProgramData = async () => {
        try {
            setIsLoading(true); // show loader
            let resp = await fetch_program_data();
            if(resp.data.code === 200){
                setprogramData(resp.data.data);
            }
            setIsLoading(false); // hide loader     
        } catch (error) {
            console.error(error);
        }
    }

    const mapProgramData = (data) => {
        return data.map(program => ({
          label: program.programName,
          value: program.programId,
          children: program.children ? mapProgramData(program.children) : [],
          children: program.children ? mapProgramData(program.children) : [],
        }));
    };

    const options = mapProgramData(programData);
    const getFilteredData = (data, selected) => {
        return data.filter(item => !selected.map(sel => sel.value).includes(item.value));
    };


    const searchProgramSummery = () =>{
        getGoSearch();
    }

    const getGoSearch = () =>{
        //fetchData();
    }

    const filterDropDown = (currentNode,selectedNodes) =>{
        setLabelValueUpdate(currentNode.value);
        setLabelDepthUpdate(currentNode._depth);
        fetchFrequencySelectedServiceData(labelValue,labelDepth); 
    }

    const fetchFrequencySelectedServiceData = async (labelValue,labelDepth) =>{
        
        try {
            setIsLoading(true); // show loader
            let resp = await fetch_frequency_selected_service_data(labelValue,labelDepth);
            if(resp.data.code === 200){
                setSelectedServiceData(resp.data.data);
            }
            setIsLoading(false); // hide loader     
        } catch (error) {
            console.error(error);
        }
    } 

    

    const mapAppProgramData = (data) => {
        return data.map(appoinment => ({
          label: appoinment.serviceName,
          value: appoinment.serviceId,
          children: appoinment.children ? mapAppProgramData(appoinment.children) : [],
          children: appoinment.children ? mapAppProgramData(appoinment.children) : [],
        }));
    };

    const appOptions = mapAppProgramData(selectedServiceData);
    const getFilteredAppointmentData = (data, selected) => {
        return data.filter(item => !selected.map(sel => sel.value).includes(item.value));
    };

    const filterDropDownAppoinment = (currentNode,selectedNodes) =>{
        setLabelApoinment(currentNode.value);
    }

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <div className="row g-3 align-items-center">
                            <div className="col-md"> 
                                <h4 className="mb-0 content-main-title">Configuration</h4>
                            </div>
                            <div className="col-auto">
                                <label htmlFor='SelectProgram' className="col-form-label">Program Details</label>
                            </div>
                            <div className="col-3">
                                <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example">
                                    {/* <option>Select Program</option> */}
                                    <option value="1" selected>Care Connect Model</option>
                                    {/* <option value="2">Hypertension</option>
                                    <option value="3">Depression</option> */}
                                </select>
                                {/* <MultiSelect
                            options={ProgramOptions}
                            value={selectedPrograms}
                            onChange={onProgramChange}
                            labelledBy={"Select Program and Subprogram"}
                            
                            disableSearch={false} // Optional: disable search if needed
                        /> */}

                        {/* <DropdownTreeSelect data={getFilteredData(options, selectedPrograms)} 
                        keepTreeOnSearch={false}
                        keepChildrenOnSearch={true}
                        showPartiallySelected={true}
                        value={labelValue}
                        onChange={filterDropDown}
                        /> */}
                            </div>

                            <div className="col-auto">
                                <label htmlFor='SelectProgram' className="col-form-label">Appointments</label>
                            </div>
                            <div className="col-3">
                                <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example">
                                    {/* <option selected>Select Program</option> */}
                                    <option value="1" selected>Care Navigator Consultation</option>
                                    {/* <option value="2">Hypertension</option>
                                    <option value="3">Depression</option> */}
                                </select>
                                {/* <MultiSelect
                                    options={options}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Select Appointment"
                                /> */}
{/* 
                        <DropdownTreeSelect data={getFilteredAppointmentData(appOptions, selectedAppPrograms)} 
                        keepTreeOnSearch={false}
                        keepChildrenOnSearch={true}
                        showPartiallySelected={true}
                        onChange={filterDropDownAppoinment}
                        /> */}
                            
                            </div>
                        </div>
                    </div>

                </div>

                <div className='reports-programs mb-3'>
                    <div className=" row align-items-center">
                    <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3jbh8/58g4l8s8ca.png" alt="Invited Members icon" style={{height:"140px"}}/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The Communication Configuration module allows you to easily manage all communications sent to members for various events through multiple channels like Email, SMS, WhatsApp, and App Push Notifications. This is crucial for engaging members effectively.</p>
                        </div>
                    </div>
                </div>

                <div className='content-configuration mb-3'>
                <div className="row">
                    <div className="col-md-12">
                    <Tabs>
                        <TabList>
                        <Tab>Whatsapp</Tab>
                        <Tab>SMS</Tab>
                        <Tab  data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal">Email</Tab>
                        <Tab data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal">Notification</Tab>
                        </TabList>

                        <TabPanel>
                            <ContentWhatsapp  labelValue = {labelValue} labelDepth={labelDepth} labelApoinment={labelApoinment}/>
                        </TabPanel>

                        <TabPanel>
                            <ContentSMS labelValue = {labelValue} labelDepth={labelDepth} labelApoinment={labelApoinment} />
                        </TabPanel>

                        <TabPanel>
                            {/* <ContentEmail/> */}
                        </TabPanel>

                        <TabPanel>
                            {/* <ContentNotification/> */}
                        </TabPanel>
                    </Tabs>
                        
                    </div>
                </div>
                </div>

            </div>
            {/* <div className="modal fade" id="upcomingFeatureModal" tabindex="1" aria-labelledby="upcomingFeatureModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header mb-4">
                            <h4 className="modal-subtitle">Available in Phase 2!</h4>
                            <h1 className="modal-title mt-3">Upcoming Feature:</h1>
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{height:"260px"}} alt="" />
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div> */}
            <Commingsoon />
            
        </>
    );
}

export default ContentConfigurationDetails;
