import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ViewAssessmentV1 = (props) => {
    const assessmentJson = props.assessmentJson ? props.assessmentJson : {}
    return (
        <>
            <div>
                <div>
                    <label>Smoker: </label>
                    <span>{(assessmentJson.smoker == "Y") ? "Yes" : "No"}</span>
                </div>
                <div>
                    <label>Has Obese: </label>
                    <span>{(assessmentJson.obese == "Y") ? "Yes" : "No"}</span>
                </div>
                <div>
                    <label>Is Playing Sport: </label>
                    <span>{(assessmentJson.playSport == "Y") ? "Yes" : "No"}</span>
                </div>
                <div>
                    <label>T.B.D: </label>
                    <span>{(assessmentJson.tbd == "Y") ? "Yes" : "No"}</span>
                </div>
                <div>
                    <label>Medical History: </label>
                    <span>{assessmentJson.notes}</span>
                </div>
                {
                    assessmentJson.medications && assessmentJson.medications.length > 0 &&
                    <>
                        <div><br /><p><h5>Medications History:</h5></p></div>

                        <Row>
                            <Col>Category</Col>
                            <Col>Drug Name</Col>
                            <Col>Code</Col>
                            <Col>Frequency</Col>
                            <Col>Duration</Col>
                            <Col>Supply From</Col>
                            <Col>Supply To</Col>
                            <Col>Notes</Col>
                        </Row>
                        {

                            assessmentJson.medications ?

                                assessmentJson.medications.map((item, index) => {
                                    return <>
                                        <Row>
                                            <Col>{item.categoryName}</Col>
                                            <Col>{item.drugName}</Col>
                                            <Col>{item.code}</Col>
                                            <Col>{item.frequency}</Col>
                                            <Col>{item.duration}</Col>
                                            <Col>{item.supplyFrom}</Col>
                                            <Col>{item.supplyTo}</Col>
                                            <Col>{item.medicalHistory}</Col>
                                        </Row>
                                    </>
                                }) : ""
                        }
                    </>
                }

                {
                    assessmentJson.examinations && assessmentJson.examinations.length > 0 &&
                    <>
                        <div><br /><p><h5>Examination History:</h5></p></div>

                        <Row>
                            <Col>Category</Col>
                            <Col>Service</Col>
                            <Col>Code</Col>
                            <Col>Condition</Col>
                            <Col>Date</Col>
                            <Col>Attachment</Col>
                            <Col>Provider</Col>
                            <Col>Notes</Col>
                        </Row>
                        {

                            assessmentJson.examinations ?

                                assessmentJson.examinations.map((item, index) => {
                                    return <>
                                        <Row>
                                            <Col>{item.categoryName}</Col>
                                            <Col>{item.assessmentServiceName}</Col>
                                            <Col>{item.code}</Col>
                                            <Col>{item.patientCondition}</Col>
                                            <Col>{item.date}</Col>
                                            <Col>{item.attachmentUrl}</Col>
                                            <Col>{item.provider}</Col>
                                            <Col>{item.notes}</Col>
                                        </Row>
                                    </>
                                }) : ""
                        }
                    </>
                }
                {
                    assessmentJson.labResults && assessmentJson.labResults.length > 0 &&
                    <>
                        <div><br /><p><h5>Lab Results History:</h5></p></div>

                        <Row>
                            <Col>Category</Col>
                            <Col>Service</Col>
                            <Col>Code</Col>
                            <Col>Condition</Col>
                            <Col>Date</Col>
                            <Col>Attachment</Col>
                            <Col>Provider</Col>
                            <Col>Notes</Col>
                        </Row>
                        {

                            assessmentJson.labResults ?

                                assessmentJson.labResults.map((item, index) => {
                                    return <>
                                        <Row>
                                            <Col>{item.categoryName}</Col>
                                            <Col>{item.assessmentServiceName}</Col>
                                            <Col>{item.code}</Col>
                                            <Col>{item.patientCondition}</Col>
                                            <Col>{item.date}</Col>
                                            <Col>{item.attachmentUrl}</Col>
                                            <Col>{item.provider}</Col>
                                            <Col>{item.notes}</Col>
                                        </Row>
                                    </>
                                }) : ""
                        }
                    </>
                }

                {
                    assessmentJson.imagingResults && assessmentJson.imagingResults.length > 0 &&
                    <>
                        <div><br /><p><h5>Imaging Results History:</h5></p></div>

                        <Row>
                            <Col>Category</Col>
                            <Col>Service</Col>
                            <Col>Code</Col>
                            <Col>Condition</Col>
                            <Col>Date</Col>
                            <Col>Attachment</Col>
                            <Col>Provider</Col>
                            <Col>Notes</Col>
                        </Row>
                        {

                            assessmentJson.imagingResults ?

                                assessmentJson.imagingResults.map((item, index) => {
                                    return <>
                                        <Row>
                                            <Col>{item.categoryName}</Col>
                                            <Col>{item.assessmentServiceName}</Col>
                                            <Col>{item.code}</Col>
                                            <Col>{item.patientCondition}</Col>
                                            <Col>{item.date}</Col>
                                            <Col>{item.attachmentUrl}</Col>
                                            <Col>{item.provider}</Col>
                                            <Col>{item.notes}</Col>
                                        </Row>
                                    </>
                                }) : ""
                        }
                    </>
                }

            </div>
        </>
    )
}

export default ViewAssessmentV1