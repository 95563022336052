import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import './Assessment.css';
import { addAssessment, fetchLastAssessment } from "../../components/services/Service";
import Medications from './Medications';
//import Examinations from './Examinations';
import LabResults from './LabResults';
import ImagingResults from './ImagingResults';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const AssessmentV2 = (props) => {
    const memberInfo = props.memberBasicInfo ? props.memberBasicInfo : [];
    const qsParams = useParams();
    const userId = qsParams.memberId;
    const assessmentVersion = '1.1';

    const medicalHistoryObj = {
        activeComplaints: '',
        pastHistory: '',
        previousTreatments: '',
        previousTreatmentsDetails: '',
        allergyHistory: '',
        allergyHistoryDetails: '',
        mentalHealthAssessment: ''
    }
    const patientAdherenceObj = {
        compliancePrescribedMedicines: '',
        followLifestyleChanges: '',
        attendanceAtAppointments: '',
        understaningOfTretmentPlan: '',
        goalForManagingCondition: ''
    }
    const obGynHistoryObj = {
        currentPregnancy: '',
        lastMenses: ''
    }

    const previousScreeningsObj = {
        mammography: '',
        mammographyDate: '',
        papsmear: '',
        papsmearDate: '',
        boneDensityTests: '',
        boneDensityTestsDate: '',
        psa: '',
        psaDate: '',
        previousFIT: '',
        previousFITDate: '',
        colonoscopy: '',
        colonoscopyDate: '',
        other: ''
    }
    const medicationsObj = {
        code: '',
        categoryId: '0',
        categoryName: '',
        drugId: '0',
        drugName: '',
        frequency: '',
        duration: '',
        supplyFrom: '',
        supplyTo: '',
        medicalHistory: ''
    }
    /*const examinationsObj = {
        categoryId: '0',
        categoryName: '',
        code: '',
        assessmentServiceId: '0',
        assessmentServiceName: '',
        patientCondition: '',
        date: '',
        attachmentUrl: '',
        provider: '',
        notes: ''
    }*/

    const labResultsObj = {
        categoryId: '0',
        categoryName: '',
        code: '',
        assessmentServiceId: '0',
        assessmentServiceName: '',
        patientCondition: '',
        date: '',
        attachmentUrl: '',
        provider: '',
        notes: ''
    }
    const imagingResultsObj = {
        categoryId: '0',
        categoryName: '',
        code: '',
        assessmentServiceId: '0',
        assessmentServiceName: '',
        patientCondition: '',
        date: '',
        attachmentUrl: '',
        provider: '',
        notes: ''
    }

    const formObject = {
        userId: userId,
        fullName: memberInfo.memberName,
        email: memberInfo.email,
        city: memberInfo.city,
        nationality: 'KSA',
        dob: memberInfo.dob,
        referral: '',
        membershipNumber: memberInfo.membershipNumber,
        gender: (memberInfo.gender == "M") ? "Male" : "Female",
        mobile1: memberInfo.mobile,
        mobile2: '',
        smoker: '',
        obese: '',
        playSport: '',
        tbd: '',
        medicalHistory: medicalHistoryObj,
        medications: [medicationsObj],
        newMedications: '',
        newMedicationsDetails: '',
        patientAdherence: patientAdherenceObj,
        obGynHistory: obGynHistoryObj,
        familyMedicalHistory: '',
        previousScreenings: previousScreeningsObj,
        //examinations: [examinationsObj],
        labResults: [labResultsObj],
        imagingResults: [imagingResultsObj]
    }

    const getLastAssessment = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchLastAssessment(userId, assessmentVersion, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    /*const assessmentJson = response.data.data.assessmentJson;
                    Object.keys(assessmentJson).map((key, index) => {
                        if (key !== "userId") {
                            if (Array.isArray(formData[key].value)) {
                                formData[key] = assessmentJson[key]
                            } else {
                                formData[key] = assessmentJson[key]
                            }
                        }
                    })*/

                    const assessmentJson = response.data.data.assessmentJson;
                    const updatedFormData = { ...formData };
                    Object.keys(assessmentJson).forEach(key => {
                        if (key === "medications" || key === "examinations" || key === "labResults" || key === "imagingResults") {
                            if (assessmentJson[key].length > 0) {
                                updatedFormData[key] = assessmentJson[key];
                            }
                        } else if (key == "smoker" && assessmentJson[key] == "N") {
                            updatedFormData[key] = "";
                        } else if (key == "obese" && assessmentJson[key] == "N") {
                            updatedFormData[key] = "";
                        } else if (key == "playSport" && assessmentJson[key] == "N") {
                            updatedFormData[key] = "";
                        } else if (key == "tbd" && assessmentJson[key] == "N") {
                            updatedFormData[key] = "";
                        } else {
                            updatedFormData[key] = assessmentJson[key];
                        }

                    });
                    setFormData(updatedFormData);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const [formData, setFormData] = useState(formObject);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    useEffect(() => {
        getLastAssessment();
    }, [userId]);

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? (checked ? 'Y' : '') : value
        });
    };

    const [addMedicationsComponentCount, setAddMedicationsComponentCount] = useState(formData.medications.length);
    //const [addExaminationsComponentCount, setAddExaminationsComponentCount] = useState(formData.examinations.length);
    const [addLabResultsComponentCount, setAddLabResultsComponentCount] = useState(formData.labResults.length);
    const [addImagingResultsComponentCount, setAddImagingResultsComponentCount] = useState(formData.imagingResults.length);

    const handleSuggestionClick = (suggestion, index) => {
        const list = [...formData.medications];
        list[index]['drugId'] = suggestion.drugId;
        list[index]['drugName'] = suggestion.drugName;
        list[index]['code'] = suggestion.drugCode;
        setFormData({ ...formData, medications: list });
    };

    const handleFieldChange = (e, index, listType) => {
        const { name, value } = e.target;
        if (listType === "medications") {
            const list = [...formData.medications];
            list[index][name] = value;
            if (name == "drugId") {
                list[index]["code"] = ''
                list[index]["drugName"] = ''
            }
            if (name === "categoryId") {
                const { options, selectedIndex } = e.target;
                list[index]["categoryName"] = options[selectedIndex].text;
            }
            setFormData({ ...formData, medications: list });
        } /*else if (listType === "examinations") {
            const list = [...formData.examinations];
            list[index][name] = value;
            if (name === "categoryId") {
                const { options, selectedIndex } = e.target;
                list[index]["categoryName"] = options[selectedIndex].text;
            } else if (name === "assessmentServiceId") {
                const { options, selectedIndex } = e.target;
                list[index]["assessmentServiceName"] = options[selectedIndex].text;
                list[index]["code"] = options[selectedIndex].getAttribute('data-code');;
            }
            setFormData({ ...formData, examinations: list });
        } */else if (listType === "labResults") {
            const list = [...formData.labResults];
            list[index][name] = value;
            if (name === "categoryId") {
                const { options, selectedIndex } = e.target;
                list[index]["categoryName"] = options[selectedIndex].text;
            } else if (name === "assessmentServiceId") {
                const { options, selectedIndex } = e.target;
                list[index]["assessmentServiceName"] = options[selectedIndex].text;
                list[index]["code"] = options[selectedIndex].getAttribute('data-code');;
            }
            setFormData({ ...formData, labResults: list });
        } else if (listType === "imagingResults") {
            const list = [...formData.imagingResults];
            list[index][name] = value;
            if (name === "categoryId") {
                const { options, selectedIndex } = e.target;
                list[index]["categoryName"] = options[selectedIndex].text;
            } else if (name === "assessmentServiceId") {
                const { options, selectedIndex } = e.target;
                list[index]["assessmentServiceName"] = options[selectedIndex].text;
                list[index]["code"] = options[selectedIndex].getAttribute('data-code');;
            }
            setFormData({ ...formData, imagingResults: list });
        }
    }


    const addComponent = (listType) => {
        if (listType === "medications") {
            setAddMedicationsComponentCount(addMedicationsComponentCount + 1);
            setFormData({
                ...formData,
                medications: [medicationsObj, ...formData.medications]
            });
        }/* else if (listType === "examinations") {
            setAddExaminationsComponentCount(prevCount => prevCount + 1);
            setFormData({
                ...formData,
                examinations: [examinationsObj, ...formData.examinations]
            });
        } */ else if (listType === "labResults") {
            setAddLabResultsComponentCount(prevCount => prevCount + 1);
            setFormData({
                ...formData,
                labResults: [labResultsObj, ...formData.labResults]
            });
        } else if (listType === "imagingResults") {
            setAddImagingResultsComponentCount(prevCount => prevCount + 1);
            setFormData({
                ...formData,
                imagingResults: [imagingResultsObj, ...formData.imagingResults]
            });
        }
    };


    {/*const addComponent = (listType) => {
        const mappings = {
            medications: {
                countSetter: setAddMedicationsComponentCount,
                dataObject: medicationsObj,
                dataKey: 'medications'
            },
            examinations: {
                countSetter: setAddExaminationsComponentCount,
                dataObject: examinationsObj,
                dataKey: 'examinations'
            },
            labResults: {
                countSetter: setAddLabResultsComponentCount,
                dataObject: labResultsObj,
                dataKey: 'labResults'
            },
            imagingResults: {
                countSetter: setAddImagingResultsComponentCount,
                dataObject: imagingResultsObj,
                dataKey: 'imagingResults'
            }
        };

        const mapping = mappings[listType];
        if (mapping) {
            mapping.countSetter(prevCount => prevCount + 1);
            setFormData(prevFormData => ({
                ...prevFormData,
                [mapping.dataKey]: [mapping.dataObject, ...prevFormData[mapping.dataKey]]
            }));

            console.log("medication count = ", addMedicationsComponentCount);
        }
    };*/}


    const removeComponent = (index, listType) => {
        if (listType === "medications") {
            setFormData((prevFormObject) => {
                const newArr = [...prevFormObject.medications];
                newArr.splice(index, 1);
                return { ...prevFormObject, medications: newArr };
            });

            setAddMedicationsComponentCount(prevCount => prevCount - 1);
        } /*else if (listType === "examinations") {
            setFormData((prevFormObject) => {
                const newArr = [...prevFormObject.examinations];
                newArr.splice(index, 1);
                return { ...prevFormObject, examinations: newArr };
            });
            setAddExaminationsComponentCount(prevCount => prevCount - 1);
        }*/ else if (listType === "labResults") {
            setFormData((prevFormObject) => {
                const newArr = [...prevFormObject.labResults];
                newArr.splice(index, 1);
                return { ...prevFormObject, labResults: newArr };
            });
            setAddLabResultsComponentCount(prevCount => prevCount - 1);
        } else if (listType === "imagingResults") {
            setFormData((prevFormObject) => {
                const newArr = [...prevFormObject.imagingResults];
                newArr.splice(index, 1);
                return { ...prevFormObject, imagingResults: newArr };
            });
            setAddImagingResultsComponentCount(prevCount => prevCount - 1);
        }
    };
    //==========
    const isEmptyRow = (row) => {
        return Object.values(row).every(value => value === '' || value === '0');
    };

    const validateForm = () => {
        setError("");
        // Fields to ignore in validation
        const ignoredKeys = [
            "userId",
            "fullName",
            "email",
            "city",
            "nationality",
            "dob",
            "referral",
            "membershipNumber",
            "gender",
            "mobile1",
            "mobile2"
        ];

        // Helper function to check if an object or array is empty
        const isEmpty = (value) => {
            if (Array.isArray(value)) {
                return value.every((item) => isEmpty(item));
                //return value.length === 0; // Array is empty
            } else if (typeof value === "object" && value !== null) {
                return Object.keys(value).every((key) => isEmpty(value[key])); // Recursively check all keys
            } else {
                return value === "" || value === null || value === undefined || value === '0'; // Primitive is empty
            }
        };

        // Loop through formData and check non-ignored keys for values
        const hasValidData = Object.keys(formData).some((key) => {
            //console.log(key, " = ", formData[key])
            if (ignoredKeys.includes(key)) {
                return false;
            }
            return !isEmpty(formData[key]); // Return true if any value is found
        });

        if (!hasValidData) {
            if (formData.smoker === "" && formData.obese === "" && formData.playSport === "" && formData.tbd === "") {
                return false;
            } else {
                return true;
            }
            //return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let cancelToken = {
                cancelToken: request.token
            }

            await addAssessment(userId, formData, assessmentVersion, cancelToken)
                .then((response) => {
                    if (response.data.code === 200) {
                        setAddMedicationsComponentCount(1);
                        //setAddExaminationsComponentCount(1);
                        setAddLabResultsComponentCount(1);
                        setAddLabResultsComponentCount(1);

                        setFormData(formObject);
                        setMessage(response.data.data.message);
                    } else {
                        setError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        } else {
            //setError("Please fill in all the mandatory fields")
            setError("There's nothing to save")
        }
    };

    //console.log(JSON.stringify(formData));

    return (
        <>
            <div className='col-md-12'>
                <div className='card card-task-appoint assessment'>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className='card-text'>
                                <div className='row align-items-center'>
                                    <div className='col-md-3 mb-3'>
                                        <div className="form-check">
                                            <input className="form-check-input form-check-input-ui" type="checkbox" name="smoker" id="flexCheckChecked1" checked={formData.smoker === "Y"} onChange={handleChange} />
                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked1">
                                                Smoker
                                            </label>
                                        </div>
                                        <div className="form-check mt-3">
                                            <input className="form-check-input form-check-input-ui" type="checkbox" id="flexCheckChecked2" name="playSport" checked={formData.playSport === "Y"} onChange={handleChange} />
                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked2">
                                                Is Playing Sport
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-3 mb-3'>
                                        <div className="form-check">
                                            <input className="form-check-input form-check-input-ui" type="checkbox" id="flexCheckChecked3" name="obese" checked={formData.obese === "Y"} onChange={handleChange} />
                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked3">
                                                Has Obese
                                            </label>
                                        </div>
                                        <div className="form-check mt-3">
                                            <input className="form-check-input form-check-input-ui" type="checkbox" id="flexCheckChecked4" name="tbd" checked={formData.tbd === "Y"} onChange={handleChange} />
                                            <label className="form-check-label mt-1" htmlFor="flexCheckChecked4">
                                                T.B.D
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-3'>

                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Medical History</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className="form-label">Active Complaints</label>
                                            <textarea name="activeComplaints" className="form-control control-input-ui" id="activeComplaints" rows="2" onChange={(e) => {
                                                const { value } = e.target;
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    medicalHistory: {
                                                        ...prevFormData.medicalHistory,
                                                        activeComplaints: value,
                                                    },
                                                }));
                                            }} value={formData.medicalHistory.activeComplaints}></textarea>
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor='Notes' className="form-label">Past History</label>
                                            <textarea name="pastHistory" className="form-control control-input-ui" id="pastHistory" rows="2" onChange={(e) => {
                                                const { value } = e.target;
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    medicalHistory: {
                                                        ...prevFormData.medicalHistory,
                                                        pastHistory: value,
                                                    },
                                                }));
                                            }} value={formData.medicalHistory.pastHistory}></textarea>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='mt-3'>Previous hospitalizations, surgeries, or treatments related to the chronic condition</div>
                                            <label><input type="radio" name="previousTreatments" checked={formData.medicalHistory.previousTreatments === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    medicalHistory: {
                                                        ...prevFormData.medicalHistory,
                                                        previousTreatments: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="previousTreatments" checked={formData.medicalHistory.previousTreatments === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    medicalHistory: {
                                                        ...prevFormData.medicalHistory,
                                                        previousTreatments: "N",
                                                        previousTreatmentsDetails: ""
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.medicalHistory.previousTreatments === "Y" &&
                                                <textarea name="previousTreatmentsDetails" className="form-control control-input-ui" id="previousTreatmentsDetails" rows="2" onChange={(e) => {
                                                    const { value } = e.target;
                                                    setFormData((prevFormData) => ({
                                                        ...prevFormData,
                                                        medicalHistory: {
                                                            ...prevFormData.medicalHistory,
                                                            previousTreatmentsDetails: value,
                                                        },
                                                    }));
                                                }}
                                                    value={formData.medicalHistory.previousTreatmentsDetails}></textarea>
                                            }
                                        </div>
                                        <div className='col-6'>
                                            <div className='mt-3'>Allergy History</div>
                                            <label><input type="radio" name="allergyHistory" checked={formData.medicalHistory.allergyHistory === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    medicalHistory: {
                                                        ...prevFormData.medicalHistory,
                                                        allergyHistory: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="allergyHistory" checked={formData.medicalHistory.allergyHistory === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    medicalHistory: {
                                                        ...prevFormData.medicalHistory,
                                                        allergyHistory: "N",
                                                        allergyHistoryDetails: ""
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.medicalHistory.allergyHistory === "Y" &&
                                                <textarea name="allergyHistoryDetails" className="form-control control-input-ui" id="allergyHistoryDetails" rows="2" onChange={(e) => {
                                                    const { value } = e.target;
                                                    setFormData((prevFormData) => ({
                                                        ...prevFormData,
                                                        medicalHistory: {
                                                            ...prevFormData.medicalHistory,
                                                            allergyHistoryDetails: value,
                                                        },
                                                    }));
                                                }} value={formData.medicalHistory.allergyHistoryDetails}></textarea>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className="form-label mt-3">Mental Health Assessment</label>
                                            <textarea name="mentalHealthAssessment" className="form-control control-input-ui" id="mentalHealthAssessment" rows="2" onChange={(e) => {
                                                const { value } = e.target;
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    medicalHistory: {
                                                        ...prevFormData.medicalHistory,
                                                        mentalHealthAssessment: value,
                                                    },
                                                }));
                                            }} value={formData.medicalHistory.mentalHealthAssessment}></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Medications History</h5>
                                    </div>
                                    <div className='col-auto'>
                                        <button type="button" className="btn btn-primary addNewRow-btn me-2" onClick={() => addComponent("medications")}><i class="bi bi-plus"></i> Add </button>
                                        {/*<button className="btn btn-primary clearRow-btn">Clear</button>*/}
                                    </div>
                                    <div className='col-12'>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-1">
                                                <thead>
                                                    <tr>
                                                        <td className='ps-1 pb-1'>Category</td>
                                                        <td className='ps-1 pb-1'>Drug Name</td>
                                                        <td className='ps-1 pb-1'>Code</td>
                                                        <td className='ps-1 pb-1'>Frequency</td>
                                                        <td className='ps-1 pb-1'>Duration</td>
                                                        <td className='ps-1 pb-1'>Supply From</td>
                                                        <td className='ps-1 pb-1'>Supply To</td>
                                                        <td className='ps-1 pb-1'>Remarks</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(addMedicationsComponentCount)].map((_, index) => (
                                                        <Medications c={index} key={index} handleFieldChange={handleFieldChange} data={formData.medications[index]} removeComponent={removeComponent} listType="medications" handleSuggestionClick={handleSuggestionClick} />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='col-6'>
                                            <label className='mt-2'>Recent changes in medication or new medications</label><br />
                                            <label><input type="radio" name="newMedications" checked={formData.newMedications === "Y"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    newMedications: "Y",
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="newMedications" checked={formData.newMedications === "N"} onChange={(e) => {
                                                const { value } = e.target;
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    newMedications: "N",
                                                    newMedicationsDetails: ''
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.newMedications === "Y" &&
                                                <textarea name="newMedicationsDetails" className="form-control control-input-ui" id="newMedicationsDetails" rows="2" onChange={(e) => {
                                                    const { value } = e.target;
                                                    setFormData((prevFormData) => ({
                                                        ...prevFormData,
                                                        newMedicationsDetails: value,
                                                    }));
                                                }} value={formData.newMedicationsDetails}></textarea>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Patient Adherence</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 mb-3'>
                                            <div >Compliance with prescribed medications</div>
                                            <label><input type="radio" name="compliancePrescribedMedicines" checked={formData.patientAdherence.compliancePrescribedMedicines === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        compliancePrescribedMedicines: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="compliancePrescribedMedicines" checked={formData.patientAdherence.compliancePrescribedMedicines === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        compliancePrescribedMedicines: "N",
                                                    },
                                                }));
                                            }} /> No</label>
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div>Follow-through with recommended lifestyle changes</div>
                                            <label><input type="radio" name="followLifestyleChanges" checked={formData.patientAdherence.followLifestyleChanges === "Y"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        followLifestyleChanges: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="followLifestyleChanges" checked={formData.patientAdherence.followLifestyleChanges === "N"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        followLifestyleChanges: "N",
                                                    },
                                                }));
                                            }} /> No</label>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-6 mb-3'>
                                            <div>Attendance at follow-up appointments</div>
                                            <label><input type="radio" name="attendanceAtAppointments" checked={formData.patientAdherence.attendanceAtAppointments === "Y"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        attendanceAtAppointments: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="attendanceAtAppointments" checked={formData.patientAdherence.attendanceAtAppointments === "N"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        attendanceAtAppointments: "N",
                                                    },
                                                }));
                                            }} /> No</label>
                                        </div>
                                        <div className='col-6'>
                                            <div>Patient's understanding of their condition and treatment plan </div>
                                            <label><input type="radio" name="understaningOfTretmentPlan" checked={formData.patientAdherence.understaningOfTretmentPlan === "Y"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        understaningOfTretmentPlan: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="understaningOfTretmentPlan" checked={formData.patientAdherence.understaningOfTretmentPlan === "N"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        understaningOfTretmentPlan: "N",
                                                    },
                                                }));
                                            }} /> No</label>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-6'>
                                            <div>Patient's goals for managing their condition</div>
                                            <label><input type="radio" name="goalForManagingCondition" checked={formData.patientAdherence.goalForManagingCondition === "Y"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        goalForManagingCondition: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="goalForManagingCondition" checked={formData.patientAdherence.goalForManagingCondition === "N"} onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    patientAdherence: {
                                                        ...prevFormData.patientAdherence,
                                                        goalForManagingCondition: "N",
                                                    },
                                                }));
                                            }} /> No</label>
                                        </div>
                                        <div className='col-6'>
                                        </div>
                                    </div>
                                </div>

                                {
                                    memberInfo.gender === "F" &&
                                    <>
                                        <div className='row align-items-center'>
                                            <div className='col-12'>
                                                <hr />
                                            </div>
                                            <div className='col'>
                                                <h5 className='card-title'>Obstetric & Gynecology history</h5>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label>Current Pregnancy</label><br />
                                                    <label><input type="radio" name="currentPregnancy" checked={formData.obGynHistory.currentPregnancy === "Y"} onChange={(e) => {

                                                        setFormData((prevFormData) => ({
                                                            ...prevFormData,
                                                            obGynHistory: {
                                                                ...prevFormData.obGynHistory,
                                                                currentPregnancy: "Y",
                                                            },
                                                        }));
                                                    }} /> Yes</label>
                                                    <label><input type="radio" name="currentPregnancy" checked={formData.obGynHistory.currentPregnancy === "N"} onChange={(e) => {

                                                        setFormData((prevFormData) => ({
                                                            ...prevFormData,
                                                            obGynHistory: {
                                                                ...prevFormData.obGynHistory,
                                                                currentPregnancy: "N",
                                                                lastMenses: ''
                                                            },
                                                        }));
                                                    }} /> No</label>

                                                    {
                                                        formData.obGynHistory.currentPregnancy === "Y" &&
                                                        <>
                                                            <br />
                                                            <label>Last Menstrual Period</label>
                                                            <DatePicker
                                                                maxDate={new Date()}
                                                                onChange={(date) => {

                                                                    setFormData((prevFormData) => ({
                                                                        ...prevFormData,
                                                                        obGynHistory: {
                                                                            ...prevFormData.obGynHistory,
                                                                            lastMenses: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : ""
                                                                        },
                                                                    }));
                                                                }}
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="Date"
                                                                className='form-control control-input-ui'
                                                                selected={formData.obGynHistory.lastMenses}
                                                                name="lastMenses"
                                                                onKeyDown={(e) => e.preventDefault()}
                                                                onPaste={(e) => e.preventDefault()}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Family Medical History</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className="form-label">Family medical history</label>
                                            <textarea name="familyMedicalHistory" className="form-control control-input-ui" id="familyMedicalHistory" rows="2" onChange={(e) => {

                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    familyMedicalHistory: e.target.value
                                                }))
                                            }} value={formData.familyMedicalHistory}></textarea>
                                        </div>
                                        <div className='col-6'>
                                        </div>
                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Previous screening</h5>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 mb-3'>
                                            <div>Mammography</div>
                                            <label><input type="radio" name="mammography" checked={formData.previousScreenings.mammography === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        mammography: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="mammography" checked={formData.previousScreenings.mammography === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        mammography: "N",
                                                        mammographyDate: ''
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.previousScreenings.mammography === "Y" &&
                                                <>
                                                    
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                        <label>Date</label>
                                                        <DatePicker
                                                            maxDate={new Date()}
                                                            onChange={(date) => {
                                                                setFormData((prevFormData) => ({
                                                                    ...prevFormData,
                                                                    previousScreenings: {
                                                                        ...prevFormData.previousScreenings,
                                                                        mammographyDate: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : ""
                                                                    },
                                                                }));
                                                            }}
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="Date"
                                                            className='form-control control-input-ui'
                                                            selected={formData.previousScreenings.mammographyDate}
                                                            name="mammographyDate"
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            onPaste={(e) => e.preventDefault()}
                                                        />
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </>
                                            }
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div>Pap Smear</div>
                                            <label><input type="radio" name="papsmear" checked={formData.previousScreenings.papsmear === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        papsmear: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="papsmear" checked={formData.previousScreenings.papsmear === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        papsmear: "N",
                                                        papsmearDate: ''
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.previousScreenings.papsmear === "Y" &&
                                                <>
                                                 <div className='row'>
                                                 <div className='col-5'>
                                                    <label>Date:</label>
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        onChange={(date) => {
                                                            setFormData((prevFormData) => ({
                                                                ...prevFormData,
                                                                previousScreenings: {
                                                                    ...prevFormData.previousScreenings,
                                                                    papsmearDate: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : ""
                                                                },
                                                            }));
                                                        }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Date"
                                                        className='form-control control-input-ui'
                                                        selected={formData.previousScreenings.papsmearDate}
                                                        name="papsmearDate"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                    />
                                                    </div>
                                                    </div>
                                                </>
                                                
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-6  mb-3'>
                                            <div>Bone density tests</div>
                                            <label><input type="radio" name="boneDensityTests" checked={formData.previousScreenings.boneDensityTests === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        boneDensityTests: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="boneDensityTests" checked={formData.previousScreenings.boneDensityTests === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        boneDensityTests: "N",
                                                        boneDensityTestsDate: ''
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.previousScreenings.boneDensityTests === "Y" &&
                                                <>
                                                     <div className='row'>
                                                     <div className='col-5'>
                                                    <label>Date</label>
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        onChange={(date) => {
                                                            setFormData((prevFormData) => ({
                                                                ...prevFormData,
                                                                previousScreenings: {
                                                                    ...prevFormData.previousScreenings,
                                                                    boneDensityTestsDate: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : ""
                                                                },
                                                            }));
                                                        }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Date"
                                                        className='form-control control-input-ui'
                                                        selected={formData.previousScreenings.boneDensityTestsDate}
                                                        name="boneDensityTests"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                    />
                                                    </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div>PSA</div>
                                            <label><input type="radio" name="psa" checked={formData.previousScreenings.psa === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        psa: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="psa" checked={formData.previousScreenings.psa === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        psa: "N",
                                                        psaDate: ''
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.previousScreenings.psa === "Y" &&
                                                <>
                                                    <div className='row'>
                                                    <div className='col-5'>
                                                    <label>Date:</label>
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        onChange={(date) => {
                                                            setFormData((prevFormData) => ({
                                                                ...prevFormData,
                                                                previousScreenings: {
                                                                    ...prevFormData.previousScreenings,
                                                                    psaDate: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : ""
                                                                },
                                                            }));
                                                        }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Date"
                                                        className='form-control control-input-ui'
                                                        selected={formData.previousScreenings.psaDate}
                                                        name="psaDate"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                    />
                                                    </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-6 mb-3'>
                                            <div>Previous Fecal immunochemical test (FIT)</div>
                                            <label><input type="radio" name="previousFIT" checked={formData.previousScreenings.previousFIT === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        previousFIT: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="previousFIT" checked={formData.previousScreenings.previousFIT === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        previousFIT: "N",
                                                        previousFITDate: ''
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.previousScreenings.previousFIT === "Y" &&
                                                <>
                                                     <div className='row'>
                                                     <div className='col-5'>
                                                    <label>Date</label>
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        onChange={(date) => {
                                                            setFormData((prevFormData) => ({
                                                                ...prevFormData,
                                                                previousScreenings: {
                                                                    ...prevFormData.previousScreenings,
                                                                    previousFITDate: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : ""
                                                                },
                                                            }));
                                                        }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Date"
                                                        className='form-control control-input-ui'
                                                        selected={formData.previousScreenings.previousFITDate}
                                                        name="previousFITDate"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                    />
                                                    </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div>Colonscopy</div>
                                            <label><input type="radio" name="colonoscopy" checked={formData.previousScreenings.colonoscopy === "Y"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        colonoscopy: "Y",
                                                    },
                                                }));
                                            }} /> Yes</label>
                                            <label><input type="radio" name="colonoscopy" checked={formData.previousScreenings.colonoscopy === "N"} onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        colonoscopy: "N",
                                                        colonoscopyDate: ''
                                                    },
                                                }));
                                            }} /> No</label>

                                            {
                                                formData.previousScreenings.colonoscopy === "Y" &&
                                                <>
                                                     <div className='row'>
                                                     <div className='col-5'>
                                                    <label>Date:</label>
                                                    <DatePicker
                                                        maxDate={new Date()}
                                                        onChange={(date) => {
                                                            setFormData((prevFormData) => ({
                                                                ...prevFormData,
                                                                previousScreenings: {
                                                                    ...prevFormData.previousScreenings,
                                                                    colonoscopyDate: (moment(date).isValid()) ? moment(date).format('YYYY-MM-DD') : ""
                                                                },
                                                            }));
                                                        }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Date"
                                                        className='form-control control-input-ui'
                                                        selected={formData.previousScreenings.colonoscopyDate}
                                                        name="colonoscopyDate"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                    />
                                                    </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-6'>
                                            <div>Other</div>
                                            <textarea name="other" className="form-control control-input-ui" id="other" rows="2" onChange={(e) => {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    previousScreenings: {
                                                        ...prevFormData.previousScreenings,
                                                        other: e.target.value
                                                    },
                                                }));
                                            }} value={formData.previousScreenings.other}></textarea>
                                        </div>
                                        <div className='col-6'>

                                        </div>
                                    </div>

                                </div>

                                {/*<div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Examination History</h5>
                                    </div>
                                    <div className='col-auto'>
                                        <button type="button" className="btn btn-primary addNewRow-btn me-2" onClick={() => addComponent("examinations")}><i class="bi bi-plus"></i> Add </button>
                                        {/*<button className="btn btn-primary clearRow-btn">Clear</button>*/}
                                {/*</div>
                                    <div className='col-12'>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-1">
                                                <thead>
                                                    <tr>
                                                        <td className='ps-1 pb-1'>Category</td>
                                                        <td className='ps-1 pb-1'>Service</td>
                                                        <td className='ps-1 pb-1'>Code</td>
                                                        <td className='ps-1 pb-1'>Condition</td>
                                                        <td className='ps-1 pb-1'>Date</td>
                                                        <td className='ps-1 pb-1'>Attachment (Optional)</td>
                                                        <td className='ps-1 pb-1'>Provider</td>
                                                        <td className='ps-1 pb-1'>Remarks</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(addExaminationsComponentCount)].map((_, index) => (
                                                        <Examinations c={index} key={index} handleFieldChange={handleFieldChange} data={formData.examinations[index]} removeComponent={removeComponent} listType="examinations" />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>*/}

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Lab Results History</h5>
                                    </div>
                                    <div className='col-auto'>
                                        <button type="button" className="btn btn-primary addNewRow-btn me-2" onClick={() => addComponent("labResults")}><i class="bi bi-plus"></i> Add </button>
                                        {/*<button className="btn btn-primary clearRow-btn">Clear</button>*/}
                                    </div>
                                    <div className='col-12'>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-1">
                                                <thead>
                                                    <tr>
                                                        <td className='ps-1 pb-1'>Category</td>
                                                        <td className='ps-1 pb-1'>Service</td>
                                                        <td className='ps-1 pb-1'>Code</td>
                                                        <td className='ps-1 pb-1'>Condition</td>
                                                        <td className='ps-1 pb-1'>Date</td>
                                                        <td className='ps-1 pb-1'>Attachment (Optional)</td>
                                                        <td className='ps-1 pb-1'>Provider</td>
                                                        <td className='ps-1 pb-1'>Remarks</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(addLabResultsComponentCount)].map((_, index) => (
                                                        <LabResults c={index} key={index} handleFieldChange={handleFieldChange} data={formData.labResults[index]} removeComponent={removeComponent} listType="labResults" />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col'>
                                        <h5 className='card-title'>Imaging Results History</h5>
                                    </div>
                                    <div className='col-auto'>
                                        <button type="button" className="btn btn-primary addNewRow-btn me-2" onClick={() => addComponent("imagingResults")}><i class="bi bi-plus"></i> Add </button>
                                        {/*<button className="btn btn-primary clearRow-btn">Clear</button>*/}
                                    </div>
                                    <div className='col-12'>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-1">
                                                <thead>
                                                    <tr>
                                                        <td className='ps-1 pb-1'>Category</td>
                                                        <td className='ps-1 pb-1'>Service</td>
                                                        <td className='ps-1 pb-1'>Code</td>
                                                        <td className='ps-1 pb-1'>Condition</td>
                                                        <td className='ps-1 pb-1'>Date</td>
                                                        <td className='ps-1 pb-1'>Attachment (Optional)</td>
                                                        <td className='ps-1 pb-1'>Provider</td>
                                                        <td className='ps-1 pb-1'>Remarks</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(addImagingResultsComponentCount)].map((_, index) => (
                                                        <ImagingResults c={index} key={index} handleFieldChange={handleFieldChange} data={formData.imagingResults[index]} removeComponent={removeComponent} listType="imagingResults" />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-12'>
                                        <hr />
                                    </div>
                                    <div className='col-md-12 text-center mt-2'>
                                        <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                        <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>

                                        <button type="submit" className="btn btn-primary save-btn">Save Assessment</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export default AssessmentV2