import React, { useState } from 'react'; 
import { comment_on_observation } from '../../../services/observations/Service';


const CommentForm = ({observationId, commentBy, fetchObservationThread}) => {

    const [commentForm, setCommentForm] = useState({ 
        comment: "", 
        files: ""
    });

    const [commentFormErrors, setCommentFormErrors]= useState({ 
        comment: "", 
        files: ""
    });

    function handleCommentChange(e) {

        if (e.target.name === 'files[]') {
            setCommentForm({ ...commentForm, ["files"]: e.target.files });
            setCommentFormErrors({ ...commentFormErrors, files: '' });
            return;
        }

        setCommentForm({ ...commentForm, [e.target.name]: e.target.value });
        setCommentFormErrors({ ...commentFormErrors, [e.target.name]: '' });
    }

    function validateForm(){ 
        let errors = {}; 

        if (commentForm.files) {
            if (commentForm.files.size > 10000000) {
              errors.files = 'File size is too large. Max size is 10MB';
            }
      
            const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png','mp4'];
            Array.from(commentForm.files).forEach(file => {
              const fileExtension = file.name.split('.').pop().toLowerCase();        
              if (!allowedExtensions.includes(fileExtension)) {
                errors.files = 'Invalid file type. Only PDF, JPEG, JPG, PNG, TIFF, DOC, DOCX, XLS, XLSX, CSV, TXT, and DCM files are allowed';
              }
            });
      
        }


        if(!commentForm.comment.trim()){
            errors.comment = 'Comment is required';
        }
        
        return errors; 
    }

    const submitComment = async () => { 
        let errors = validateForm(); 
        if(Object.keys(errors).length > 0){ 
            setCommentFormErrors(errors); 
            return; 
        }

        let formData = new FormData();

        for (const file of commentForm.files) {
            formData.append('files[]', file);         
        }
        
        let commentCopy = {...commentForm};
        delete commentCopy.files;

        let commentDataObj  = {...commentCopy, observationId: observationId, commentBy: commentBy};
        formData.append('commentData', JSON.stringify(commentDataObj));    

        let requestData = commentForm.files.length > 0 ? formData : {commentData: JSON.stringify(commentDataObj)};

        // setIsrequestLoading(true);
        try{
             let response = await comment_on_observation(requestData); 

             if(response.data.code === 200){
                fetchObservationThread();
                // clear the state data 
                setCommentForm({ comment: '', files: '' });
                setCommentFormErrors({ comment: '', files: '' });

             }else { 

             }
        }catch(error){
            console.error(error);
        }

    }




  return (
    <>
    <div className='bg-light p-3 input-field-ui'>
    <div className="row align-items-center">
            <div className="col-md-12 mb-3">
                {/* <label className="form-label">Description <span className="text-danger">*</span></label> */}
                <textarea
                    name="comment"
                    placeholder="Description"
                    rows="3"
                    className="form-control control-input-ui"
                    value={commentForm.comment}
                    onChange={e => handleCommentChange(e)}
                />
                {commentFormErrors.comment && <p className="text-danger">{commentFormErrors.comment}</p>}
            </div>


            <div className="col-5">
                <label className="form-label">Upload Screenshots (Max 5MB per image):</label>
                <input
                    type="file"
                    id="xlaxFile_upload"
                    name="files[]"
                    className="form-control input-field-ui"
                    accept=".png, .jpg, .jpeg, .gif, .doc, .docx, .pdf, .csv, .xls, .xlsx"
                    onChange={e => handleCommentChange(e)}
                    required
                />

                {commentFormErrors.files && <p className="text-danger">{commentFormErrors.files}</p>}
                
            </div>


            <div className="col-12 mt-3">
                <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn"
                    onClick={e => submitComment(e)}
                >
                Submit
                </button>
            </div>
        </div>
    </div>
        

    </>
  )
}

export default CommentForm
