import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

export const serviceFetchFailedLogs = (uidStore) => {
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/insight_fail_data',{
        "uidStore" : uidStore
    });
    return dataPass;
};

export const updateFileUploadStatus = (actionType,uploadId,notes,requestFrom) => {
    let params = { 'action': actionType,'uploadId': uploadId,'notes': notes,'requestFrom': requestFrom }
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/rcm_update_upload_file_status',params);
    return dataPass;
};

export const insightFetchdata = (uploadId) => {
    let params = { 'uploadId': uploadId }
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/insight_check_approve',params);
    return dataPass;
};

export const getFileLink = () => {
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_fileurl');
    return dataPass;
};

export const displayUploadedReport = (startDateNew,endDateNew) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/fetch_members_logs_data', {
        "startRange" : startDateNew,
        "endRange" : endDateNew
    });
    return dataPass;
};

export const displayUploadedCheckerReport = (startDateNew,endDateNew) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/fetch_file_checker_data', {
        "startRange" : startDateNew,
        "endRange" : endDateNew
    });
    return dataPass;
};

export const displayRemoveMemberList = (startDateNew,endDateNew) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/display_remove_member_list', {
        "startRange" : startDateNew,
        "endRange" : endDateNew
    });
    return dataPass;
};

export const uploadFile = (uploadId) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/upload_file', {
        "uploadId" : uploadId
    });
    return dataPass;
};

export const preUploadFile = (fileData) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/pre_upload_file', fileData, {
    });
    return dataPass;
};

export const downloadFile = (fileType,uploadId) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/download_file',{
        "fileType" : fileType,
        "uploadId" : uploadId
    });
    return dataPass;
};

export const fetch_role_data = () => {
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_role_data');
    return dataPass;
};



//fetch program master data MTP
export const fetch_program_data = () => {
    console.log("fetch_program_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_program_data');
    return dataPass;
};

// add user list
export const fetch_ad_users_list = (searchString) => {
    var requestBody={searchString};
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/fetch_ad_users',requestBody);
    return dataPass;
};

//post data user creation rcm
export const rcm_user_creation_data = (formData) => {
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/rcm_user_creation_data',formData);
    return dataPass;
};

// fetch admin user data
export const fetch_admin_user_data = () => {
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_admin_user_data');
    return dataPass;
};


// admin user update data
export const rcm_admin_user_update_data = (userId,formData) => {
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/rcm_admin_user_update_data?userId='+userId,formData);
    return dataPass;
};

// fetch admin userdata 
export const rcm_fetch_admin_user_data = (userId) => {
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/rcm_fetch_admin_user_data?userId='+userId);
    return dataPass;
};


export const fetchFileInsightData = (uploadId) => {
    const params = { 'uploadId': uploadId }
    var dataPass = common_axios.post(baseURL + 'rcmdashboard/fetch_file_insight_data', params)
    return dataPass;
}

export const fetchUntouchRemove = (queries) => {
    const params = { 'filterCondition': queries }
    var dataPass = common_axios.post(baseURL + 'rcmdashboard/fetch_untouch_remove', params)
    return dataPass;
}

export const removeUntouchMembers = (removeId,remarks) => {
    const params = { 'removeId': removeId,'remarks': remarks }
    var dataPass = common_axios.post(baseURL + 'rcmdashboard/remove_untouch_members', params)
    return dataPass;
}


export const createTempLink = (fileUrl) => {
    const params = { 'fileUrl': fileUrl }
    var dataPass = common_axios.post(baseURL + 'rcmdashboard/create_temp_link', params)
    return dataPass;
}