import React from 'react';
import ContentMemberRegisterLaterDetails from '../components/ContentMemberRegisterLaterDetails';



function MemberRegisterLaterPage() {

  return (
    <>
        <ContentMemberRegisterLaterDetails/>
    </>
  );
}

export default MemberRegisterLaterPage;
