import React, { useState, useEffect, memo } from 'react';
import { FaChevronLeft, FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ChooseAudience from './CreateCampaignComponents/ChooseAudience';
import CreateMessage from './CreateCampaignComponents/CreateMessage';
import SetNextSteps from './CreateCampaignComponents/SetNextSteps';
import ScheduleMessage from './CreateCampaignComponents/ScheduleMessage';
import SetMessageLive from './CreateCampaignComponents/SetMessageLive';
import { fetch_tag_list, whatsappCampaignList } from '../../services/mtp/Service';
import { LoaderLoader } from '../utils/CommonLibrary';

// Memoize the content components to prevent unnecessary re-renders
const MemoizedChooseAudience = memo(ChooseAudience);
const MemoizedCreateMessage = memo(CreateMessage);
const MemoizedScheduleMessage = memo(ScheduleMessage);
const MemoizedSetMessageLive = memo(SetMessageLive);

const CreateCampaign = () => {
  const [campaignName, setCampaignName] = useState('');
  const [activeStage, setActiveStage] = useState(0);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();

  const [optionTags, setOptionTags] = useState([]);
  const [whatsTemplateData, setWhatsTemplateData] = useState([]);
  const [selectedFilteredList, setSelectedFilteredList] = useState([]);
  const [totalCountUserList, setTotalCountUserList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [scheduling, setScheduling] = useState('inprogress');
  const [dateTime, setDateTime] = useState(new Date());
  const [memberFilterSelectQuery, setMemberFilterSelectQuery] = useState('');
  const [filterQueries, setFilterQueries] = useState([]);
  const [optOutNCount, setOptOutNCount] = useState('');
  const [optOutYCount, setOptOutYCount] = useState('');
  const[isLoading,setIsLoading] = useState(false);
  
  // Track visibility state separately from content mounting
  const [stageVisibility, setStageVisibility] = useState({
    0: true,
    1: false,
    2: false,
    3: false
  });

  useEffect(() => {
    fetchAllTags();
    setMounted(true);
    fetchWhatsAppList();
  }, []);

  const fetchAllTags = async () => {
    try {
      let row = await fetch_tag_list();
      const data = row.data.data?.map((t) => ({
        label: t.tagName,
        value: t.tagId
      }));
      setOptionTags(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWhatsAppList = async () => {
    try {
      let response = await whatsappCampaignList();
      if (response.data.code == 200) {
        setWhatsTemplateData(response.data.data.templateData);
      } else {
        console.log(response.data.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Create stages with memoized components
  const stages = [
    {
      title: 'Choose Members',
      component: (
        <MemoizedChooseAudience
          optionTags={optionTags}
          selectedFilteredList={selectedFilteredList}
          setSelectedFilteredList={setSelectedFilteredList}
          totalCountUserList={totalCountUserList}
          setTotalCountUserList={setTotalCountUserList}
          memberFilterSelectQuery={memberFilterSelectQuery}
          setMemberFilterSelectQuery={setMemberFilterSelectQuery}
          filterQueries={filterQueries}
          setFilterQueries={setFilterQueries}
          optOutNCount={optOutNCount}
          setOptOutNCount={setOptOutNCount}
          optOutYCount={optOutYCount}
          setOptOutYCount={setOptOutYCount}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      )
    },
    {
      title: 'Create your message',
      component: (
        <MemoizedCreateMessage
          whatsTemplateData={whatsTemplateData}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
        />
      )
    },
    {
      title: 'Schedule your message',
      component: (
        <MemoizedScheduleMessage
          setScheduling={setScheduling}
          scheduling={scheduling}
          setDateTime={setDateTime}
          dateTime={dateTime}
        />
      )
    },
    {
      title: 'Set your campaign live',
      component: (
        <MemoizedSetMessageLive
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          scheduling={scheduling}
          dateTime={dateTime}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          selectedFilteredList={selectedFilteredList}
          setSelectedFilteredList={setSelectedFilteredList}
          totalCountUserList={totalCountUserList}
          setTotalCountUserList={setTotalCountUserList}
          memberFilterSelectQuery={memberFilterSelectQuery}
          setMemberFilterSelectQuery={setMemberFilterSelectQuery}
          filterQueries={filterQueries}
          optOutNCount={optOutNCount}
          setOptOutNCount={setOptOutNCount}
          optOutYCount={optOutYCount}
          setOptOutYCount={setOptOutYCount}
        />
      )
    }
  ];

  const handleStageClick = (index) => {
    // Toggle visibility instead of unmounting
    setStageVisibility(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
    setActiveStage(index === activeStage ? -1 : index);
  };

  return (
    <div className={`tw-h-full tw-mb-8 tw-p-4 tw-m-4 tw-rounded-xl tw-transition-opacity tw-duration-500 ${mounted ? 'tw-opacity-100' : 'tw-opacity-0'}`}>
      <style jsx>{`
        @keyframes slideDown {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        .animate-slide-down {
          animation: slideDown 0.5s ease-out forwards;
        }
      `}</style>
      
      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-6 animate-slide-down">
        <button
          className="btn btn-primary back-btn mb-0"
          onClick={() => navigate('/notification-dashboard')}
        >
          <i className="bi bi-chevron-left me-1"></i> Back
        </button>
        <h1 className="tw-text-2xl md:tw-text-2xl tw-font-bold tw-text-gray-500 tw-mt-2">
          Create WhatsApp Campaign
        </h1>
      </div>

      <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-mb-8 animate-slide-down">
        <input
          type="text"
          placeholder="Enter Campaign Name"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          className="form-control input-field-ui"
        />
      </div>      
      {stages.map((stage, index) => (
        <div key={index} className="tw-mb-6">
          <div
            className={`tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-t-lg tw-cursor-pointer tw-transition-all tw-duration-300 tw-relative ${
              activeStage === index ? 'tw-bg-[#03335b] tw-text-white' : 'tw-bg-white tw-border tw-border-sky-300 hover:tw-bg-[#03335b] hover:tw-text-white'
            }`}
            onClick={() => handleStageClick(index)}
          >
            <div className="tw-flex tw-items-center">
              <span className={`tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center ${activeStage === index ? "tw-bg-white tw-text-[#03335b]" : "tw-bg-[#03335b] tw-text-white"} tw-rounded-full tw-mr-4 tw-font-bold`}>
                {index + 1}
              </span>
              <span className="tw-text-lg">{stage.title}</span>
            </div>
            <FaChevronDown className={`tw-transition-transform tw-duration-300 ${stageVisibility[index] ? 'tw-rotate-180' : ''}`} />
          </div>
          {/* <LoaderLoader isLoading={isLoading} /> */}
          {/* Keep component mounted but hide it */}
          <div 
            className={`tw-transition-all tw-duration-300 tw-overflow-hidden ${stageVisibility[index] ? 'tw-max-h-[2000px] tw-opacity-100' : 'tw-max-h-0 tw-opacity-0'}`}
          >
            <div className="tw-border tw-border-t-0 tw-border-[#03335b] tw-rounded-b-lg tw-p-4 tw-bg-white tw-shadow-md tw-relative">
              {stage.component}
            </div>
          </div>
        </div>
      ))}
      
      
    </div>
  );
};

export default CreateCampaign;