import React, { useState,useEffect } from 'react';
import './AddProgramsPage.css';
// import UpcomingSession from '../componenents/UpcomingSession';
// import Sidenavmenubar from '../../componenents/Sidenavmenubar';
// import Navbar from '../../componenents/Navbar';
import ContentAddPrograms from '../../componenents/ContentAddPrograms';
// import { fetchTemplateList} from '../../../services/mtp/Service';


function AddProgramsPage() {

  

//   useEffect(() => {
//     fetchData();
//   },[]);

  

//   const fetchData = async () => {
//     fetchTemplateList('')
//         .then((res) => {
//             //console.log(res.data);
            
//         });
// }

  const [title, setTitle] = useState('Programs');

  return (
    <>
        <ContentAddPrograms/>
    </>
  );
}

export default AddProgramsPage;
