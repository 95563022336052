import React, { useState,useEffect,useRef } from 'react';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatDate,LoaderLoader } from '../../../utils/CommonLibrary';
import { fetchCareCommunicationData,fetchAudioPlayListCare } from '../../../../services/medengage/Service';


function CareNavigatorCommunicationsTable({startDate,endDate}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [communicationData, setCommunicationData] = useState([]);
    const [communicationMessage, setCommunicationMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const[audioId,setAudioId] = useState('');
    const[recordUrl,setRecordUrl] = useState('');
    const[agentName,setAgentName] = useState('');
    const[memberName,setMemberName] = useState('');
    const[randomKey,setRandomKey] = useState(0);
    const [timestamp, setTimestamp] = useState(Date.now());
    const[agentCallName,setAgentCallName] = useState('');
    const[memberCallName,setMemberCallName] = useState('');

    const audioRef = useRef(null);

    useEffect(() => {
        
        if (audioRef.current && recordUrl) {
            audioRef.current.load();
        }else{
            fetchMemberCommunicationData();
        }
    },[recordUrl]);


    const handleStop = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
    };

    const fetchMemberCommunicationData = async () => {
        try {
            fetchCareCommunicationData(formatDate(startDate),formatDate(endDate))
            .then((response) => {
                console.log(response.data);
                setCommunicationData(response.data.data);
            });
        } catch (error) {
            console.error(error);
        } 
        finally {
            setIsLoading(false);
        }
    };

    const playAudioRecording =async (callId) => {
        try {
            fetchAudioPlayListCare(callId)
            .then((response) => {
                setRecordUrl(response.data.data.recordingUrl);
                setRandomKey(response.data.data.recordingUrl);
                setAgentCallName(response.data.data.AgentName);
                setMemberCallName(response.data.data.memberName);
            });
        } catch (error) {
            console.error('Error fetching audio data:', error);
        }
    };


    const renderMETP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Unique identifier for the member.
        </Tooltip>
    );

    const renderMETP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the member.
        </Tooltip>
    );

    const renderMETP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           The method used for communication
        </Tooltip>
    );

    const renderMETP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           The date and time when the communication was sent or made.
        </Tooltip>
    );

    const renderMETP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           The current status of the communication.
        </Tooltip>
    );

    const renderMETP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Click to listen to the recorded communication message.
        </Tooltip>
    );

  


    const columns = [
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Member ID</span>
                </OverlayTrigger>
            ),
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP2Tooltip}>
                    <span>Member Name</span>
                </OverlayTrigger>
            ),
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Agent Name',
            selector: row => row.agentName,
            sortable: true,
        },
       
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Communication<br/>Channel</span>
                </OverlayTrigger>
            ),
            selector: row => row.communicationType,
            sortable: true,
            width:"100px",
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP4Tooltip}>
                    <span>Communication Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.placedCallDate,
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP5Tooltip}>
                    <span>Communication <br/>Status</span>
                </OverlayTrigger>
            ),
            selector: row => row.status,
            sortable: true,
        },
         {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Communication <br/>Playback</span>
                </OverlayTrigger>
            ),

            selector: row => row.recordingDuration,
            ignoreRowClick: true,
            allowoverflow: true,
            button: 'true',
            width:"125px",
            
            cell: row => (
                <span>
                    <a data-bs-toggle="modal" data-bs-target="#audioPopupModal" style={{cursor: "pointer"}} onClick={(e)=>playAudioRecording(row.communicationId)}><span style={{color:row.color}}>{row.recordingUrl}</span> <br/>{row.recordingDuration}</a>
                </span>
            ),
        },

    ];
    const data = communicationData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedChannel('All');
        setSelectedStatus('All');
    };
   
    const filteredData = data.filter(user => {
        const matchesSearchQuery = 
        (typeof user.memberName === 'string' && user.memberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof user.membershipNo === 'string' && user.membershipNo.toString().includes(searchQuery));
        
    
        const matchChannel = selectedChannel === 'All' || user.communicationType === selectedChannel;

        const matchStatus = selectedStatus === 'All' || user.status === selectedStatus;

        return matchesSearchQuery && matchChannel && matchStatus;
    });

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
           
            <div className='container-fluid'>
                
                <div className='row mb-3'>
                    
                    <div className='col-md d-flex px-1'>
                    <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Name or Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wjm48/fh6lw97sch.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedChannel}
                            onChange={e => setSelectedChannel(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Channel--</option>
                            <option value="Call">Calls</option>
                            <option value="Whatsapp">Whatsapp</option>
                            <option value="SMS">SMS</option>
                            <option value="Email">Email</option>
                            <option value="Push Notification">Push Notification</option>
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="completed">Completed</option>
                            <option value="canceled">Cancelled</option>
                            <option value="no-answer">No-Answer</option>
                            
                            <option value="Delivered">Delivered</option>
                            <option value="Undelivered">Undelivered</option>
                        </select>
                    </div>
                    
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            
            <div className="modal fade" id="audioPopupModal" tabIndex="1" aria-labelledby="audioPopupModal" data-backdrop='static' aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                              <div className="modal-header">

                                <h6 className="modal-subtitle mb-2" id="exampleModalLabel">Agent Name:  {memberCallName}</h6>

                                <h6 className="modal-subtitle" id="exampleModalLabel">Member Name:  {agentCallName}</h6>
                                
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleStop} ></button> 
                            </div>
                            <div className="modal-body mt-2">
                                <audio ref={audioRef} controls style={{width:'100%'}}>
                                        <source src={recordUrl} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                        </div>
                    </div>
            </div>
        </>
    );

    
}

export default CareNavigatorCommunicationsTable;
