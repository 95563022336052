import React, { useState,useEffect } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { encryptData,decryptData } from "../utils/Utils";

const CalendarDatePicker = ({ startDate, endDate, onDateChange,index }) => {
  const [label, setLabel] = useState('');
  let ytdStart,ytdEnd;

  const storedDates = JSON.parse(decryptData(localStorage.getItem('selectedDates')));
  ytdStart = storedDates[2]
  ytdEnd = storedDates[3];

  const handleCallback = (start, end) => {

    setLabel(`${startDate.format('D MMM, YYYY')} - ${endDate.format('D MMM, YYYY')}`);


    if (onDateChange) {
      onDateChange({ start, end,index });
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setLabel(`${startDate.format('D MMM, YYYY')} - ${endDate.format('D MMM, YYYY')}`);
    }
  }, [startDate, endDate]);

  return (
    <>
      <DateRangePicker
        initialSettings={{
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          locale: {
            format: 'D MMM, YYYY',  // Change the date format here
          },
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
              moment().subtract(6, 'days').toDate(),
              moment().toDate(),
            ],
            'Last 30 Days': [
              moment().subtract(29, 'days').toDate(),
              moment().toDate(),
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate(),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'PSTD': [
              moment(ytdStart).toDate(),
              moment(ytdEnd).toDate(),
            ],
          },
        }}
        onCallback={handleCallback}
      >
        <div
          id="reportrange"
          style={{
            background: '#fff',
            cursor: 'pointer',
            padding: '10px 14px',
            border: '1px solid #DCDCDC',
            width: '100%',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <i className="bi bi-calendar4-week"></i>&nbsp;
          <span>{label}</span> <i className="fa fa-caret-down"></i>
        </div>
      </DateRangePicker>
    </>
  );
};

export default CalendarDatePicker;