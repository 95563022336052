import React from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';

const Pagination = ({ 
  currentPage, 
  totalEntries, 
  handlePageChange,
  limit = 8,
}) => {
  const pageCount = Math.ceil(totalEntries / limit);
  const startEntry = ((currentPage - 1) * limit) + 1;
  const endEntry = Math.min(currentPage * limit, totalEntries);

  const handlePageClick = (page) => {
    if (page < 1 || page > pageCount) return;
    handlePageChange(page);
  };

  if (totalEntries <= 0 || pageCount <= 1) return null;

  return (
    <div className="tw-flex tw-items-center tw-justify-end tw-gap-6 tw-text-sm tw-mt-6 tw-mb-4 tw-mr-4">
      {/* Entry Counter */}
      <div className="tw-flex tw-items-center tw-gap-1">
        <span className="tw-whitespace-nowrap tw-text-gray-600 tw-font-medium">
          {startEntry.toLocaleString()}-{endEntry.toLocaleString()} of {totalEntries.toLocaleString()}
        </span>
      </div>

      {/* Navigation Controls */}
      <div className="tw-flex tw-items-center tw-gap-2 tw-border-l tw-pl-6 tw-border-gray-200">
        {/* First Page */}
        <button
          onClick={() => handlePageClick(1)}
          disabled={currentPage === 1}
          className="tw-p-1.5 disabled:tw-opacity-40 tw-border-none hover:tw-bg-gray-100 tw-rounded-full tw-transition-colors duration-200"
          aria-label="First page"
        >
          <ChevronsLeft className="tw-h-6 tw-w-6 tw-text-gray-600" />
        </button>

        {/* Previous Page */}
        <button
          onClick={() => handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          className="tw-p-1.5 disabled:tw-opacity-40 tw-border-none hover:tw-bg-gray-100 tw-rounded-full tw-transition-colors duration-200"
          aria-label="Previous page"
        >
          <ChevronLeft className="tw-h-6 tw-w-6 tw-text-gray-600" />
        </button>

        {/* Page Indicator */}
        <span className="tw-px-3 tw-py-1 tw-text-gray-700 tw-font-medium tw-min-w-[120px] tw-text-center">
          Page {currentPage} of {pageCount}
        </span>

        {/* Next Page */}
        <button
          onClick={() => handlePageClick(currentPage + 1)}
          disabled={currentPage === pageCount}
          className="tw-p-1.5 disabled:tw-opacity-40 tw-border-none hover:tw-bg-gray-100 tw-rounded-full tw-transition-colors duration-200"
          aria-label="Next page"
        >
          <ChevronRight className="tw-h-6 tw-w-6 tw-text-gray-600" />
        </button>

        {/* Last Page */}
        <button
          onClick={() => handlePageClick(pageCount)}
          disabled={currentPage === pageCount}
          className="tw-p-1.5 disabled:tw-opacity-40 tw-border-none hover:tw-bg-gray-100 tw-rounded-full tw-transition-colors duration-200"
          aria-label="Last page"
        >
          <ChevronsRight className="tw-h-6 tw-w-6 tw-text-gray-600" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;