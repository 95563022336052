import React from 'react';
import './ContentPrograms.css';
//import ContentProgramsNoActivePrograms from './ContentPrograms/ContentProgramsNoActivePrograms';
import ContentProgramsDetails from './ContentPrograms/ContentProgramsDetails';
// import ContentProgramsList from './ContentPrograms/ContentProgramsList';

function ContentAddPrograms() {

  return (
    <>
        <section className='ContentPrograms'>
         
            {/* <ContentProgramsNoActivePrograms /> */}

            <ContentProgramsDetails />

            {/*  <ContentProgramsList /> */}

        </section>


        
    </>
  );
}

export default ContentAddPrograms;
