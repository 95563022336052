import React, { useState, useEffect } from "react";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { fetchRcmInsight } from "../../../../services/insight/Service";
import HealthRiskAssessmentsBar from "../../../componenents/DashboardGraphsCNP/HealthRiskAssessmentsBar";
import CalendarDatePicker from "../../../componenents/CalendarDatePicker";
import MembesAccrossPathwayMonthsBar from "../../../componenents/DashboardGraphsCNP/MembesAccrossPathwayMonthsBar";
import CallCompletionRatesBar from "../../../componenents/DashboardGraphsCNP/CallCompetionRatesBar";
import MemberPathwayStatusPie from "../../../componenents/DashboardGraphs/MemberPathwayStatusPie";
import { decryptData } from "../../../utils/Utils";


function RCMInsight() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [programOverview, setProgramOverview] = useState('1');
    const [hideCalender, setHideCalender] = useState(false);
    const [programGraph, setProgramGraph] = useState('1');
    const [selectedCondition, setSelectedCondition] = useState('1');
    const [loading, setLoading] = useState(true);
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [statsValue, setStatsValue] = useState({
        'totalCount': '', 'successCount': '', 'failCount': '', 'touchCount': '', 'untouchCount': '', 'memberConnectRate': '',
        'onboardCount': '', 'onboardLaterCount': '', 'notInterestedCount': '', 'onboardTotal': '', 'cnTotalOnboarded': '',
        'homecareStats': '', 'labStats': '', 'medicationStats': '', 'teleHealthStats': '', 'serviceStats': '', 'noReferralStats': '', 'homeVaccineStats': '',
        'cnAssignPathwayStats': '', 'cnNotAssignPathwayStats': '', 'cnRateStats': '', 'validCount': '', 'invalidCount': '', 'extraManual': '', 'backPoolCount': '', 'cnExtraAdd': '', 'untouched': '', 'poolExaust': ''
    });

    const [seriesGraph, setSeriesGraph] = useState({
        migratedGraphData: [],
        dailyMigrateGraph: [],
        pathwayMemberGraph: [],
        referalsMemberGraph: [],
        touchMemberGraph: [],
        callMemberGraph: [],
        validMemberGraph: [],
    })

    useEffect(() => {
        //    fetchDropDownList();
    }, []);

    useEffect(() => {

        const performFilterEffectNew = async () => {
            filterDataProgram();
        };

        performFilterEffectNew();
    }, [programOverview]);

    useEffect(() => {
        const performFilterEffect = async () => {
            setLoading(true);
            await filterData();
        };

        performFilterEffect();
    }, [startDate, endDate, programGraph, selectedCondition]);

    const filterDataProgram = async () => {
        // await fetchData();
    };

    const filterData = async () => {
        await fetchMembersProgram();
        setLoading(false);
    };


    const fetchMembersProgram = async () => {
        await fetchRcmInsight(formatDate(startDate), formatDate(endDate), programGraph, selectedCondition, 'alldata')
            .then((response) => {
                setStatsValue(prevState => ({
                    ...prevState, totalCount: response.data.data.totalRecords, successCount: response.data.data.successRecords, failCount: response.data.data.failedRecords, touchCount: response.data.data.touchCount, untouchCount: response.data.data.untouchCount,
                    memberConnectRate: response.data.data.onboardConversionRate, onboardCount: response.data.data.onboardedValue, onboardLaterCount: response.data.data.laterOnboard, notInterestedCount: response.data.data.rejected, onboardTotal: response.data.data.onboardedTotal, cnTotalOnboarded: response.data.data.cnTotalOnboarded,
                    homecareStats: response.data.data.homeCareCount, labStats: response.data.data.labCount, medicationStats: response.data.data.medicationCount, teleHealthStats: response.data.data.teleHealthCount, serviceStats: response.data.data.totalService, noReferralStats: response.data.data.noReferralStats, homeVaccineStats: response.data.data.hbvCount,
                    cnAssignPathwayStats: response.data.data.asignedPathway, cnExtraAdd: response.data.data.extraManualOnboardedCare, cnNotAssignPathwayStats: response.data.data.notAsignedPathway, cnRateStats: response.data.data.cnConverstionEnrolled,
                    validCount: response.data.data.valid, invalidCount: response.data.data.invalid, extraManual: response.data.data.extraManualOnboarded, backPoolCount: response.data.data.backToPool, untouched: response.data.data.untouched, poolExaust: response.data.data.poolExaust
                }));
                setSeriesGraph(prevState => ({
                    ...prevState, migratedGraphData: response.data.data.migratedGraph.series.data, dailyMigrateGraph: response.data.data.migratedDailyGraph,
                    pathwayMemberGraph: response.data.data.pathwayGraph.series.data, referalsMemberGraph: response.data.data.referalsGraph, validMemberGraph: response.data.data.validMemberStatusGraph, touchMemberGraph: response.data.data.touchGraph.series.data, callMemberGraph: response.data.data.successfulCallDetails.series.data
                }));
            });
    }


    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleChangeCondition = (event) => {
        setSelectedCondition(event.target.value);
    };

    const handleChangeProgramOverview = (event) => {
        setProgramOverview(event.target.value);
    };

    const handleChangeProgramGraph = (event) => {
        setProgramGraph(event.target.value);
    };

    const renderPRC1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Overview of the uploaded member records by the RCM team.
        </Tooltip>
    );

    const renderPRC2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of member records uploaded by the RCM team.
        </Tooltip>
    );

    const renderPRC3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total count of member records successfully migrated/uploaded into the system.
        </Tooltip>
    );

    const renderPRC4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total count of member records that failed during the upload process.
        </Tooltip>
    );

    const renderPRC5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Breakdown of the upload status for the member records.
        </Tooltip>
    );

    const renderPRC6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Recent history of uploaded member data.
        </Tooltip>
    );

    const renderPRC7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Overview of the status breakdown for member outreach.
        </Tooltip>
    );

    const renderPRC8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members successfully migrated by the RCM team.
        </Tooltip>
    );

    const renderPRC9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who have been contacted.
        </Tooltip>
    );

    const renderPRC10Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who have not yet been contacted.
        </Tooltip>
    );

    const renderPRC11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members successfully reached by agents.
        </Tooltip>
    );

    const renderPRC12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members marked as invalid data by agents.
        </Tooltip>
    );

    const renderPRC13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who interested to join program.
        </Tooltip>
    );

    const renderPRC14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members manually uploaded into the system by agents.
        </Tooltip>
    );

    const renderPRC15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who are not interested in the program.
        </Tooltip>
    );

    const renderPRC16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who can be contacted again for onboarding.
        </Tooltip>
    );

    const renderPRC17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members interested in the program but will onboard later.
        </Tooltip>
    );

    const renderPRC18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Combined total of members onboarded by both RCM and agents.
        </Tooltip>
    );

    const renderPRC19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Rate of conversion from uploaded to onboarded members.
        </Tooltip>
    );

    const renderPRC20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Breakdown of contacted and uncontacted migrated members.
        </Tooltip>
    );

    const renderPRC21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Breakdown of valid and invalid contacted members.
        </Tooltip>
    );

    const renderPRC22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Breakdown of member status
        </Tooltip>
    );

    const renderPRC23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Care Navigator's efforts to engage and enroll members
        </Tooltip>
    );

    const renderPRC24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members onboarded by the member engagement team
        </Tooltip>
    );

    const renderPRC25Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members manually uploaded into the system by CareNavigators.
        </Tooltip>
    );

    const renderPRC26Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Combined total of members onboarded by both member engagement and CareNavigators.
        </Tooltip>
    );

    const renderPRC27Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members assigned a Pathway by Care Navigators after first call.
        </Tooltip>
    );

    const renderPRC28Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who have not yet been contacted or are not interested in the program.
        </Tooltip>
    );

    const renderPRC29Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Rate of conversion from onboarded to enrolled members.
        </Tooltip>
    );

    const renderPRC30Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of referrals for Home Health Care services.
        </Tooltip>
    );

    const renderPRC31Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of referrals for lab tests.
        </Tooltip>
    );

    const renderPRC32Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of referrals for home-based vaccination services.
        </Tooltip>
    );

    const renderPRC33Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of medication referrals.
        </Tooltip>
    );

    const renderPRC34Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of Telehealth referrals.
        </Tooltip>
    );

    const renderPRC35Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Indicates no referrals were required for the members.
        </Tooltip>
    );

    const renderPRC36Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of referrals for home-based vaccination services.
        </Tooltip>
    );



    return (
        <>
            <section className='ReportsPrograms'>
                <div className="container-fluid px-0 mb-3 ">
                    <div className="row align-items-center pb-3 sticky-top-bar">
                        <div className="col-md">
                            <h1 className="main-title">Management End To End Dashboard</h1>
                        </div>
                    </div>

                    <div className='reports-programs mb-3'>
                        <div className="row align-items-center">
                            <div className="col-md-auto">
                                <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                            </div>
                            <div className="col-md">
                                <p className="mb-3">Gain a quick snapshot of your program's performance, including key metrics, progress tracking, and overall impact.</p>
                            </div>
                        </div>
                    </div>




                    {hideCalender && <div className="row align-items-center mt-4 pb-3 sticky-top-bar">
                        <div className="col-md-4">
                            <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example" value={programGraph} onChange={handleChangeProgramGraph}>
                                <option value="">Select Program</option>
                                {programList.map(option => (
                                    <option key={option.programId} value={option.programId}>{option.programName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <select className="form-select input-field-ui" aria-label="Default select example" value={selectedCondition} onChange={handleChangeCondition}>
                                <option value="">Select Condition</option>
                                {conditionList.map(option => (
                                    <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                        </div>
                    </div>}


                    <div className="row">
                        <div className="col-md">
                            <h1 className="main-title mt-2">DATA MIGRATE (RCM Team)</h1>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-ProgramPerformance mb-3" style={{ backgroundColor: "transparent", padding: "0", boxShadow: "none" }}>
                                            <div className="row align-items-center mb-2">
                                                <div className="col"><h1 className='dashboard-graph-title' style={{ justifyContent: "start" }}>Activity Upload Members
                                                    <OverlayTrigger placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Overview of the uploaded member records by the RCM team.</Tooltip>}>
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                </h1></div>

                                            </div>

                                            <div className="row text-center px-2">

                                                <div className="col px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.totalCount}</h1>
                                                                    <h5 className="card-text1">Member Uploaded
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Total number of member records uploaded by the RCM team.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%", border: '2px solid #1279be' }}>
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.successCount}</h1>
                                                                    <h5 className="card-text1">Successfully Migrated
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Total count of member records successfully migrated/uploaded into the system.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                <div className="card-body p-2">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{statsValue.failCount}</h1>
                                        <h5 className="card-text1">Failed
                                        <OverlayTrigger placement="top"
                                            overlay={<Tooltip className="custom-tooltip">Total count of member records that failed during the upload process.</Tooltip>}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                   
                                </div>
                                </div>
                            </div>
                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                    </div>
                                    <div className="col-md-6">

                                        <h1 className='dashboard-graph-title '>
                                            <span>Uploading Members Status
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Breakdown of the upload status for the member records.</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                {/* <OverlayTrigger placement="top" overlay={renderPP7Tooltip}>
                                    <i className='bx bx-info-circle ms-2'></i>
                                </OverlayTrigger> */}
                                            </span>
                                        </h1>
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.migratedGraphData ? (
                                                <>
                                                    <div className="bg-grey-new">
                                                        <MemberPathwayStatusPie series={seriesGraph.migratedGraphData} />
                                                    </div>

                                                </>

                                            ) : (
                                                <div className="emty-pie bg-grey-new" style={{ backgroundBlendMode: "multiply", height: "90%" }}>
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <h1 className='dashboard-graph-title '>
                                            <span>Daily Uploading Members Status
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Recent history of uploaded member data.</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                {/* <OverlayTrigger placement="top" overlay={renderPP7Tooltip}>
                                    <i className='bx bx-info-circle ms-2'></i>
                                </OverlayTrigger> */}
                                            </span>
                                        </h1>
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.dailyMigrateGraph.status > 0 ? (
                                                <>
                                                    <div className="bg-grey-new">
                                                        <CallCompletionRatesBar dataMonth={seriesGraph.dailyMigrateGraph} height="300px" />
                                                    </div>
                                                </>

                                            ) : (
                                                <div className="emty-pie bg-grey-new" style={{ backgroundBlendMode: "multiply", height: "90%" }}>
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <div className="col-md-6 mb-3">
                        <div className="dashboard-graph-box mb-3">
                           
                        </div>
                    </div> */}

                    </div>




                    <div className="row">
                        <div className="col-md">
                            <h1 className="main-title mt-2">MEMBER CONNECT (Member Engagement Team)</h1>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <div className="content-ProgramPerformance mb-3" style={{ backgroundColor: "transparent", padding: "0", boxShadow: "none" }}>
                                    <div className="row align-items-center mb-2">
                                        <div className="col"><h1 className='dashboard-graph-title' style={{ justifyContent: "Start" }}>Reaching Out Members
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Overview of the status breakdown for member outreach.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h1></div>
                                        {/* <div className="col-3">
                                <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example"  value={programOverview} onChange={handleChangeProgramOverview}>
                                    <option value="">Select Program</option>
                                    {programList.map(option => (
                                        <option key={option.programId} value={option.programId}>{option.programName}</option>
                                    ))}
                                </select>
                            </div> */}
                                    </div>

                                    <div className="row text-center px-2">
                                        <div className="col" >
                                            <div className="row">
                                                <div className="col-auto px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', height: "110px" }}>
                                                        <div className="card-body p-2 d-flex  align-items-center">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.successCount}</h1>
                                                                    <h5 className="card-text1">Received Members <br /> Migrated
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Members successfully migrated by the RCM team.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                                <OverlayTrigger placement="top" overlay={renderPP1Tooltip}>
                                                    <i className='bx bx-info-circle'></i>
                                                </OverlayTrigger>
                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-auto  px-1" >
                                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', height: "110px" }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.touchCount}</h1>
                                                                    <h5 className="card-text1">Touched
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Members who have been contacted.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>

                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', }}>
                                                        <div className="card-body p-2 d-flex align-items-center">

                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h1 className="card-title1">{statsValue.untouchCount}</h1>
                                                                            <h5 className="card-text1">Untouched
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Members who have not yet been contacted</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>

                                                                            </h5>
                                                                        </div>
                                                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-auto  px-1" >
                                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', height: "110px" }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.validCount}</h1>
                                                                    <h5 className="card-text1">Valid
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Members successfully reached by agents.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                                        <div className="card-body p-2 d-flex align-items-center">

                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h1 className="card-title1">{statsValue.invalidCount}</h1>
                                                                            <h5 className="card-text1">Incorrect
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Members marked as invalid data by agents.</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>

                                                                            </h5>
                                                                        </div>
                                                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col" >
                                                    <div className="row">
                                                        <div className="col-12 px-1 mb-1" >
                                                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%", border: '2px solid #1279be', height: "110px" }}>
                                                                <div className="card-body p-2 d-flex align-items-center">
                                                                    <div className="row" style={{ "width": "100%" }}>
                                                                        <div className="col border-end">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <h1 className="card-title1">{statsValue.onboardCount}</h1>
                                                                                    <h5 className="card-text1">Onboarded
                                                                                        <OverlayTrigger placement="top"
                                                                                            overlay={<Tooltip className="custom-tooltip">Members who interested to join program.</Tooltip>}>
                                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                                        </OverlayTrigger>

                                                                                    </h5>
                                                                                </div>
                                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <h1 className="card-title1">{statsValue.extraManual}</h1>
                                                                                    <h5 className="card-text1">Extra Manual Onboarded
                                                                                        <OverlayTrigger placement="top"
                                                                                            overlay={<Tooltip className="custom-tooltip">Members manually uploaded into the system by agents.</Tooltip>}>
                                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                                        </OverlayTrigger>
                                                                                    </h5>
                                                                                </div>
                                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-1 mb-2" >
                                                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', }}>
                                                                <div className="card-body p-2 d-flex align-items-center">


                                                                    <div className="row" style={{ "width": "100%" }}>
                                                                        <div className="col-3 border-end">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <h1 className="card-title1">{statsValue.notInterestedCount}</h1>
                                                                                    <h5 className="card-text1">Not Interested
                                                                                        <OverlayTrigger placement="top"
                                                                                            overlay={<Tooltip className="custom-tooltip">Members who are not interested in the program.</Tooltip>}>
                                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                                        </OverlayTrigger>
                                                                                    </h5>
                                                                                </div>
                                                                               
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-3 border-end">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <h1 className="card-title1">{statsValue.backPoolCount}</h1>
                                                                                    <h5 className="card-text1">Back to pool
                                                                                        <OverlayTrigger placement="top"
                                                                                            overlay={<Tooltip className="custom-tooltip">Members who can be contacted again for onboarding.</Tooltip>}>
                                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                                        </OverlayTrigger>
                                                                                    </h5>
                                                                                </div>
                                                                           
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-3 border-end">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <h1 className="card-title1">{statsValue.poolExaust}</h1>
                                                                                    <h5 className="card-text1">Pool Exhausted
                                                                                    <OverlayTrigger 
                                                                                            placement="top"
                                                                                            overlay={<Tooltip className="custom-tooltip">Members that have been fully attempted with no successful outcome.</Tooltip>}
                                                                                        >
                                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                                        </OverlayTrigger>
                                                                                    </h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-3">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <h1 className="card-title1">{statsValue.onboardLaterCount}</h1>
                                                                                    <h5 className="card-text1">Onboard later
                                                                                        <OverlayTrigger placement="top"
                                                                                            overlay={<Tooltip className="custom-tooltip">Members interested in the program but will onboard later.</Tooltip>}>
                                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                                        </OverlayTrigger>
                                                                                    </h5>
                                                                                </div>
                                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', height: "110px" }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.onboardTotal}</h1>
                                                                    <h5 className="card-text1">Total Onboarded
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Combined total of members onboarded by both RCM and agents.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                                <OverlayTrigger placement="top" overlay={renderPP1Tooltip}>
                                                    <i className='bx bx-info-circle'></i>
                                                </OverlayTrigger>
                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', backgroundColor: "rgb(208 236 255)" }}>
                                                        <div className="card-body p-2 d-flex align-items-center ">
                                                            <div className="row ">
                                                                <div className="col">
                                                                    <h5 class="text-start">ONBOARDED</h5>
                                                                    <h5 class="text-start">{statsValue.onboardTotal}/{statsValue.validCount}</h5>
                                                                    <h1 className="card-title1">= {statsValue.memberConnectRate}</h1>
                                                                    <h5 className="card-text1">Conversion Rate
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Rate of conversion from uploaded to onboarded members.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                    
                                                                </div>
                                                              
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>







                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <h1 className='dashboard-graph-title '>
                                            <span>Migrated Members Status
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Breakdown of contacted and uncontacted migrated members.</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                {/* <OverlayTrigger placement="top" overlay={renderPP7Tooltip}>
                                    <i className='bx bx-info-circle ms-2'></i>
                                </OverlayTrigger> */}
                                            </span>
                                        </h1>
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.touchMemberGraph ? (
                                                <>
                                                    <div className="bg-grey-new">
                                                        <MemberPathwayStatusPie series={seriesGraph.touchMemberGraph} />
                                                    </div>
                                                </>

                                            ) : (
                                                <div className="emty-pie bg-grey-new" style={{ backgroundBlendMode: "multiply", height: "90%" }}>
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>

                                    <div className="col-md-4">
                                        <h1 className='dashboard-graph-title '>
                                            <span>Touched Members Status
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Breakdown of valid and invalid contacted members.</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                {/* <OverlayTrigger placement="top" overlay={renderPP7Tooltip}>
                                    <i className='bx bx-info-circle ms-2'></i>
                                </OverlayTrigger> */}
                                            </span>
                                        </h1>
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.callMemberGraph ? (
                                                <>
                                                    <div className="bg-grey-new">
                                                        <MemberPathwayStatusPie series={seriesGraph.callMemberGraph} />
                                                    </div>
                                                </>

                                            ) : (
                                                <div className="emty-pie">
                                                    <div className="emty-pie bg-grey-new" style={{ backgroundBlendMode: "multiply", height: "90%" }}>
                                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <h1 className='dashboard-graph-title '>
                                            <span>Valid Members Status
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Breakdown of member status</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                {/* <OverlayTrigger placement="top" overlay={renderPP7Tooltip}>
                                    <i className='bx bx-info-circle ms-2'></i>
                                </OverlayTrigger> */}
                                            </span>
                                        </h1>
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.validMemberGraph.dataValue ? (
                                                <>
                                                    <div className="bg-grey-new">
                                                        <HealthRiskAssessmentsBar dataMonth={seriesGraph.validMemberGraph} />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="emty-column">
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>

                                </div>

                            </div>
                        </div>



                    </div>




                    <div className="row">
                        <div class="col-md"><h1 class="main-title mt-2">CARENAVIGATE / CARECOORDINATE (Medical Engagement Team)</h1></div>
                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <div className="content-ProgramPerformance mb-3" style={{ backgroundColor: "transparent", padding: "0", boxShadow: "none" }}>
                                    <div className="row align-items-center mb-2">
                                        <div className="col"><h1 className='dashboard-graph-title' style={{ justifyContent: "start" }}>Care Navigator Calls Member
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Care Navigator's efforts to engage and enroll members</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h1></div>
                                    </div>

                                    <div className="row text-center px-2">

                                        <div className="col px-1">
                                            <div className="row">

                                                <div className="col px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', height: "100%" }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="" style={{ "width": "100%" }}>
                                                                <div className="col">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h1 className="card-title1">{statsValue.onboardTotal}</h1>
                                                                            <h5 className="card-text1">Received Onboarded
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Members onboarded by the member engagement team</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>
                                                                            </h5>
                                                                        </div>

                                                                    </div>
                                                                    <hr />
                                                                    <div className="row" style={{ width: "100%" }}>
                                                                        <div className="col">
                                                                            <h1 className="card-title1">{statsValue.cnExtraAdd}</h1>
                                                                            <h5 className="card-text1">Extra Manual Onboarded
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Members manually uploaded into the system by CareNavigators.</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>
                                                                            </h5>
                                                                        </div>
                                                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid #1279be', height: "110px" }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.cnTotalOnboarded}</h1>
                                                                    <h5 className="card-text1">Total Onboarded
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Combined total of members onboarded by both member engagement and CareNavigators.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-auto px-1" >
                                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', height: "110px" }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h1 className="card-title1">{statsValue.cnAssignPathwayStats}</h1>
                                                                            <h5 className="card-text1">Enrolled
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Pathway assigned to the members by CareNavigators</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>
                                                                            </h5>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: 'none' }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col">

                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h1 className="card-title1">{statsValue.cnNotAssignPathwayStats}</h1>
                                                                            <h5 className="card-text1">Not Enrolled
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Members who are not interested in the program.</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>
                                                                            </h5>
                                                                        </div>
                                                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col">

                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h1 className="card-title1">{statsValue.untouched}</h1>
                                                                            <h5 className="card-text1">Enrollment Pending
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Members who have not yet been pathway assigned</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>
                                                                            </h5>
                                                                        </div>
                                                                        {/* <div className="col- auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col ps-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', backgroundColor: "rgb(208 236 255)", height: "100%" }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="row align-items-center">
                                                                <div className="col pe-0">
                                                                    <h5 class="text-start">ENROLLED</h5>
                                                                    <h5 class="text-start">{statsValue.cnAssignPathwayStats}/{statsValue.cnTotalOnboarded}</h5>
                                                                    <h1 className="card-title1" style={{ fontSize: "22px" }}>= {statsValue.cnRateStats}</h1>
                                                                    <h5 className="card-text1">Conversion Rate
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Rate of conversion from onboarded to enrolled members.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                              
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP1Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col px-1" >
                                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', height: "100%" }}>
                                                <div className="card-body p-2 d-flex align-items-center">
                                                    <div className="row">
                                                        <div className="col-4 border-end">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.homecareStats}</h1>
                                                                    <h5 className="card-text1">HHC Referrals
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Total number of referrals for Home Health Care services.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-4 border-end">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.labStats}</h1>
                                                                    <h5 className="card-text1">Lab Referrals
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Total number of referrals for lab tests.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.homeVaccineStats}</h1>
                                                                    <h5 className="card-text1">HBV
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Total number of referrals for home-based vaccination services.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 ">
                                                            <hr />
                                                        </div>
                                                        <div className="col-4  border-end">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.medicationStats}</h1>
                                                                    <h5 className="card-text1">Medication Referrals
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Total number of medication referrals.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-4 border-end">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.teleHealthStats}</h1>
                                                                    <h5 className="card-text1">Telehealth Referrals
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Total number of Telehealth referrals.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.noReferralStats}</h1>
                                                                    <h5 className="card-text1">No Referral needed
                                                                        <OverlayTrigger placement="top"
                                                                            overlay={<Tooltip className="custom-tooltip">Indicates no referrals were required for the members.</Tooltip>}>
                                                                            <i className='bx bx-info-circle ms-1'></i>
                                                                        </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                                {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>







                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-6">
                                        <h1 className='dashboard-graph-title '>
                                            <span>Onboarded Members status
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Status of onboarded members</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                        </h1>
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.pathwayMemberGraph ? (
                                                <>
                                                    <div className="bg-grey-new">
                                                        <MemberPathwayStatusPie series={seriesGraph.pathwayMemberGraph} />
                                                    </div>
                                                </>

                                            ) : (

                                                <div className="emty-pie bg-grey-new" style={{ backgroundBlendMode: "multiply", height: "90%" }}>
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <h1 className='dashboard-graph-title '>
                                            <span>Enrolled Members Into Referral
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Bifurcation of enrolled members into referrals</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </span>
                                        </h1>
                                        {loading ? (
                                            <></>
                                        ) : (
                                            seriesGraph.referalsMemberGraph.dataValue ? (
                                                <>
                                                    <div className="bg-grey-new">
                                                        <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.referalsMemberGraph} />
                                                    </div>
                                                </>

                                            ) : (
                                                <div className="emty-pie bg-grey-new" style={{ backgroundBlendMode: "multiply", height: "90%" }}>
                                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                                </div>
                                            )
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </section>


        </>
    );
}

export default RCMInsight;
