import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import {
    fetchPathwayServicesMonthCategoryWise
} from "../../components/services/Service";
import './CarePlanChange.css';

const CarePlanChange = ({ pathwayId, showCarePlanModal, handleShowCarePlanModal, handleCloseCarePlanModal, memberPathways }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const totalPathwaysCount = memberPathways.filter((item) => item.pathwayId > 0).length;

    // Expandable Table
    const [expandedRow, setExpandedRow] = useState(null);
    const [selectedPathwayId, setSelectedPathwayId] = useState(pathwayId);

    const toggleRowExpansion = (index) => {
        setExpandedRow(prevState => (prevState === index ? null : index));
    };

    const [expandedRow1, setExpandedRow1] = useState(null);

    const toggleRowExpansion1 = (index) => {
        setExpandedRow1(prevState => (prevState === index ? null : index));
    };

    const [pathwayServices, setPathwayServices] = useState([]);


    useEffect(() => {
        getPathwayServicesMonthCategoryWise(userId, selectedPathwayId);
    }, [showCarePlanModal]);

    const getPathwayServicesMonthCategoryWise = async (userId, selectedPathwayId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchPathwayServicesMonthCategoryWise(userId, selectedPathwayId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setPathwayServices(response.data.data.services);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleTabClick = (pathwayId) => {
        setPathwayServices([]);
        setSelectedPathwayId(pathwayId);
        getPathwayServicesMonthCategoryWise(userId, pathwayId)
    }

    const TabBody = ({ pathwayServices }) => {
        return <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <div className='card-task'>
                        {
                            pathwayServices.map((mainItem, mainIndex) => {
                                return <>
                                    <div className="table-responsive" style={{ maxHeight: "430px" }}>
                                        <table className="table table-striped table-bordered table-expandable mb-0">
                                            <thead style={{ position: "sticky", top: "0" }}>
                                                <tr>
                                                    <th scope="col">Services</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    mainItem.services.map((item, index) => {
                                                        return <>
                                                            <tr style={{ verticalAlign: 'middle' }}>
                                                                <td className='bg-td-green hand' onClick={() => toggleRowExpansion1(index)}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/43t1m/vat93u8oj1.png" style={{ height: "20px" }} className='me-2' alt="" />{item.month} Month</td>
                                                            </tr>
                                                            {
                                                                <tr>
                                                                    <td colSpan="3" className='pathways-table-expandable-td-bg p-0'>
                                                                        <div style={{ width: "98%" }}>
                                                                            <div className='row pathways-table-expandable'>
                                                                                <div className="col-12 pe-0">
                                                                                    <div className="table-responsive pathways-new-table" style={{ borderRadius: "0" }}>
                                                                                        {
                                                                                            item.categories.map((catItem, catIndex) => {
                                                                                                return <>
                                                                                                    <table className="table table-striped mb-0 p-0">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th scope="col"><h5 className="main-pathways-title">{catItem.categoryName}</h5></th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        catItem.services.map((msItem, msIndex) => {
                                                                                                                            return <>
                                                                                                                                <span>{msItem.serviceName}</span>
                                                                                                                            </>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </>
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </>
                                                    })
                                                }
                                            </tbody>


                                        </table>
                                    </div>
                                </>
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    }
    return (
        <>
            <Modal id="wpnb1" className='HealthManagement' centered show={showCarePlanModal} onHide={handleCloseCarePlanModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Suggested Services</h1>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey={pathwayId} id="uncontrolled-tab-example" onSelect={handleTabClick}>
                        {
                            totalPathwaysCount > 1 &&
                            <Tab key="0" eventKey="0" title={`Combined`}>
                                <TabBody pathwayServices={pathwayServices} />
                            </Tab>
                        }
                        {
                            memberPathways
                                .filter((item) => item.pathwayId > 0)
                                .map((item, index) => {
                                    return (
                                        <Tab key={index} eventKey={item.pathwayId} title={`${item.conditionName} / ${item.pathwayName}`}>
                                            <TabBody pathwayServices={pathwayServices} />
                                        </Tab>
                                    );
                                })
                        }

                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CarePlanChange;
