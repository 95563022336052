import React,{useState} from 'react';
import './ConfigurationSettingsAgentCalls.css';
import ConfigurationSettingsAgentCallsDetails from '../components/ConfigurationSettingsAgentCallsDetails';

function ConfigurationSettingsAgentCalls() {
    const [title, setTitle] = useState('Member Connect');

  return (
    <>
         <ConfigurationSettingsAgentCallsDetails/>   
    </>
  );
}

export default ConfigurationSettingsAgentCalls;
