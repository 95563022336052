import React from "react";
import OngoingChallengesSlider from "./OngoingChallengesSlider";
import CompletedChallengesSlider from "./CompletedChallengesSlider";


function ContentChallengesList() {
    const stats = [
        { 
            count: 6, 
            label: 'Total Challenges', 
            bgColor: 'bg-challenges-1', 
        },
        { 
            count: 482, 
            label: 'Total Participants', 
            bgColor: 'bg-challenges-2', 
        },
        { 
            count: 2, 
            label: 'Ongoing Challenges', 
            bgColor: 'bg-challenges-3', 
        },
        { 
            count: 0, 
            label: 'Upcoming Challenges', 
            bgColor: 'bg-challenges-4', 
        },
        { 
            count: 4, 
            label: 'Completed Challenges', 
            bgColor: 'bg-challenges-5', 
        },
      ];
    
    return (
        <>
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    
                    <div className="col-md-12 text-end">
                        <button type="button" className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Create New Challenge
                        </button>
                    </div>
                </div>


                <div className='content-challenges mb-3'>
                <div className=" row text-center">
                    {stats.map((stat, index) => (
                        <div className="col" key={index}>
                            <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                    <h1 className="card-title">{stat.count.toLocaleString()}</h1>
                                    <h5 className="card-text" dir="rtl">{stat.label}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                </div>

                
                <div className="row">
                    <div className="col-md-5">
                        <div className='content-challenges'>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="challenges-title">Ongoing</h1>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <OngoingChallengesSlider />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className='content-challenges'>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="challenges-title">Completed</h1>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <CompletedChallengesSlider />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ContentChallengesList;
